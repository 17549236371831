import { useId } from 'react-id-generator';
import './BeeInfoLabel.scss';

type BeeInfoLabelProps = {
  id?: string;
  size?: string;
  label: string;
  visible?: boolean;
  type?: string;
};
export function BeeInfoLabel({
  id,
  size,
  label,
  visible,
  type
}: BeeInfoLabelProps) {
  const genId = useId(1, 'bee-info-label-')[0];
  const currentId = id ? id : genId;

  //classNames
  const sizeClassnames =
    size === 'small'
      ? ' bee-info-label-small'
      : size === 'medium'
      ? ' bee-info-label-medium'
      : size === 'large'
      ? ' bee-info-label-large'
      : ' bee-info-label-medium';
  const typeClassnames =
    type === 'neutral'
      ? 'bee-info-neutral-label'
      : type === 'valid'
      ? 'bee-info-valid-label'
      : '';
  const classNames = typeClassnames + sizeClassnames;

  return visible ? (
    <span id={currentId} className={classNames}>
      {label}
    </span>
  ) : null;
}

export default BeeInfoLabel;
