import {
  Slider,
  SliderChangeParams,
  SliderSlideEndParams
} from 'primereact/slider';
import { useId } from 'react-id-generator';

import './BeeSlider.scss';

type BeeSliderProps = {
  value?: number;
  min: number;
  max: number;
  step: number;
  type: string;
  disabled?: boolean;
  onChange: (e: SliderChangeParams) => void;
  onSlideEnd: (e: SliderSlideEndParams) => void;
};

export function BeeSlider({
  value,
  min,
  max,
  step,
  disabled,
  type,
  onChange,
  onSlideEnd
}: BeeSliderProps) {
  const currentId = useId(1, 'bee-slider-')[0];

  //classNames
  const classNames =
    type === 'primary'
      ? 'bee-slider-primary'
      : type === 'secondary'
      ? 'bee-slider-secondary'
      : 'bee-slider-primary';

  function change(e: SliderChangeParams) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }

  function slide(e: SliderSlideEndParams) {
    if (!disabled && onSlideEnd) {
      onSlideEnd(e);
    }
  }

  return (
    <Slider
      id={currentId}
      value={value}
      min={min}
      max={max}
      step={step}
      className={classNames}
      disabled={disabled}
      onChange={(e) => change(e)}
      onSlideEnd={(e) => slide(e)}
    />
  );
}

export default BeeSlider;
