import _ from 'lodash';
import { useEffect, useState } from 'react';
import { GebaeudeEnergiesteckbrief } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import { BeeNutzungsartMixHeizenergie } from '../../Atoms/BeeNutzungsartMixHeizenergie';
import BeeValidatedDateInput from '../../Atoms/BeeValidatedDateInput';
import BeeValidatedDropDown from '../../Atoms/BeeValidatedDropDown';
import BeeValidatedNumberInput from '../../Atoms/BeeValidatedNumberInput';
import { Dropdown } from '../../Organisms/Alpha/AlphaGebaeudeForm';
import './AlphaGebaeudeEnergieForm.scss';
import {
  MAX_NUMBER_OF_DECIMALS,
  MIN_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';

type AlphaGebaeudeEnergieFormProps = {
  data: GebaeudeEnergiesteckbrief | undefined;
  energieTypenOptions: Dropdown;
  disabled: boolean;
  readOnly: boolean;
  onChangeEnergie: (energieData: GebaeudeEnergiesteckbrief) => void;
};

export default function AlphaGebaeudeEnergieForm({
  data,
  energieTypenOptions,
  disabled,
  readOnly,
  onChangeEnergie
}: AlphaGebaeudeEnergieFormProps) {
  const [heizenergieVerbrauch, setHeizenergieVerbrauch] = useState(
    data && data.heizenergieVerbrauchWhM2a
      ? data.heizenergieVerbrauchWhM2a
      : undefined
  );
  const [heizenergieZeitraumStart, setHeizenergieZeitraumStart] = useState(
    data && data.heizenergieZeitraumStart
      ? data.heizenergieZeitraumStart
      : undefined
  );
  const [heizenergieZeitraumEnd, setHeizenergieZeitraumEnd] = useState(
    data && data.heizenergieZeitraumEnd
      ? data.heizenergieZeitraumEnd
      : undefined
  );
  const [heizenergieEmission, setHeizenergieEmission] = useState(
    data && data.heizenergieEmissionCo2Kg
      ? data.heizenergieEmissionCo2Kg
      : undefined
  );

  const [anteilFernwaerme, setAnteilFernwaerme] = useState(
    data && data.anteilFernwaerme ? data.anteilFernwaerme : undefined
  );
  const [anteilGas, setAnteilGas] = useState(
    data && data.anteilGas ? data.anteilGas : undefined
  );
  const [anteilOel, setAnteilOel] = useState(
    data && data.anteilOel ? data.anteilOel : undefined
  );
  const [anteilWaermepumpe, setAnteilWaermepumpe] = useState(
    data && data.anteilWaermepumpe ? data.anteilWaermepumpe : undefined
  );
  const [anteilHolz, setAnteilHolz] = useState(
    data && data.anteilHolz ? data.anteilHolz : undefined
  );
  const [anteilSonstiges, setAnteilSonstiges] = useState(
    data && data.anteilSonstiges ? data.anteilSonstiges : undefined
  );
  const [stromVerbrauch, setStromVerbrauch] = useState(
    data && data.stromVerbrauchWhM2a ? data.stromVerbrauchWhM2a : undefined
  );
  const [stromZeitraumStart, setStromZeitraumStart] = useState(
    data && data.stromZeitraumStart ? data.stromZeitraumStart : undefined
  );
  const [stromZeitraumEnd, setStromZeitraumEnd] = useState(
    data && data.stromZeitraumEnd ? data.stromZeitraumEnd : undefined
  );
  const [stromEmission, setStromEmission] = useState(
    data && data.stromEmissionCo2Kg ? data.stromEmissionCo2Kg : undefined
  );
  const [typ, setTyp] = useState(data && data.typ ? data.typ : undefined);

  useEffect(() => {
    if (data) {
      setHeizenergieVerbrauch(data.heizenergieVerbrauchWhM2a);
      setHeizenergieZeitraumStart(data.heizenergieZeitraumStart);
      setHeizenergieZeitraumEnd(data.heizenergieZeitraumEnd);
      setHeizenergieEmission(data.heizenergieEmissionCo2Kg);
      setAnteilFernwaerme(data.anteilFernwaerme);
      setAnteilGas(data.anteilGas);
      setAnteilOel(data.anteilOel);
      setAnteilWaermepumpe(data.anteilWaermepumpe);
      setAnteilHolz(data.anteilHolz);
      setAnteilSonstiges(data.anteilSonstiges);
      setStromVerbrauch(data.stromVerbrauchWhM2a);
      setStromZeitraumStart(data.stromZeitraumStart);
      setStromZeitraumEnd(data.stromZeitraumEnd);
      setStromEmission(data.stromEmissionCo2Kg);
      setTyp(data.typ);
    }
  }, [data]);

  function changeEnergieData(energieData: GebaeudeEnergiesteckbrief) {
    if (onChangeEnergie) {
      onChangeEnergie(energieData);
    }
  }

  function changeHeizenergieVerbrauch(currHeizenergie: number | undefined) {
    const currEnergie: GebaeudeEnergiesteckbrief = createEnergieStateTemplate();
    currEnergie.heizenergieVerbrauchWhM2a = currHeizenergie;

    changeEnergieData(currEnergie);
  }

  function changeHeizenergieZeitraumStart(
    currHeizenergieZeitraumStart: Date | undefined
  ) {
    const currEnergie: GebaeudeEnergiesteckbrief = createEnergieStateTemplate();
    currEnergie.heizenergieZeitraumStart = currHeizenergieZeitraumStart;

    changeEnergieData(currEnergie);
  }

  function changeHeizenergieZeitraumEnd(
    currHeizenergieZeitraumEnd: Date | undefined
  ) {
    const currEnergie: GebaeudeEnergiesteckbrief = createEnergieStateTemplate();
    currEnergie.heizenergieZeitraumEnd = currHeizenergieZeitraumEnd;

    changeEnergieData(currEnergie);
  }

  function changeHeizenergieEmission(
    currHeizenergieEmission: number | undefined
  ) {
    const currEnergie: GebaeudeEnergiesteckbrief = createEnergieStateTemplate();
    currEnergie.heizenergieEmissionCo2Kg = currHeizenergieEmission;

    changeEnergieData(currEnergie);
  }
  function changeHeizenergieNutzungsartenMix(
    currAnteilFernwaerme: number | undefined,
    currAnteilGas: number | undefined,
    currAnteilOel: number | undefined,
    currAnteilWaermepumpe: number | undefined,
    currAnteilHolz: number | undefined,
    currAnteilSonstiges: number | undefined
  ) {
    const currEnergie: GebaeudeEnergiesteckbrief = createEnergieStateTemplate();
    currEnergie.anteilFernwaerme = currAnteilFernwaerme;
    currEnergie.anteilGas = currAnteilGas;
    currEnergie.anteilOel = currAnteilOel;
    currEnergie.anteilWaermepumpe = currAnteilWaermepumpe;
    currEnergie.anteilHolz = currAnteilHolz;
    currEnergie.anteilSonstiges = currAnteilSonstiges;

    changeEnergieData(currEnergie);
  }
  function changeStromVerbrauch(currStromVerbrauch: number | undefined) {
    const currEnergie: GebaeudeEnergiesteckbrief = createEnergieStateTemplate();
    currEnergie.stromVerbrauchWhM2a = currStromVerbrauch;

    changeEnergieData(currEnergie);
  }
  function changeStromZeitraumStart(currStromZeitraumStart: Date | undefined) {
    const currEnergie: GebaeudeEnergiesteckbrief = createEnergieStateTemplate();
    currEnergie.stromZeitraumStart = currStromZeitraumStart;

    changeEnergieData(currEnergie);
  }

  function changeStromZeitraumEnd(currStromZeitraumEnd: Date | undefined) {
    const currEnergie: GebaeudeEnergiesteckbrief = createEnergieStateTemplate();
    currEnergie.stromZeitraumEnd = currStromZeitraumEnd;

    changeEnergieData(currEnergie);
  }
  function changeStromEmission(currStromEmission: number | undefined) {
    const currEnergie: GebaeudeEnergiesteckbrief = createEnergieStateTemplate();
    currEnergie.stromEmissionCo2Kg = currStromEmission;

    changeEnergieData(currEnergie);
  }
  function changeTyp(currTyp: string | undefined) {
    const currEnergie: GebaeudeEnergiesteckbrief = createEnergieStateTemplate();
    currEnergie.typ = currTyp;

    changeEnergieData(currEnergie);
  }

  function createEnergieStateTemplate() {
    return {
      heizenergieVerbrauchWhM2a: heizenergieVerbrauch,
      heizenergieZeitraumStart: heizenergieZeitraumStart,
      heizenergieZeitraumEnd: heizenergieZeitraumEnd,
      heizenergieEmissionCo2Kg: heizenergieEmission,
      anteilFernwaerme: anteilFernwaerme,
      anteilGas: anteilGas,
      anteilOel: anteilOel,
      anteilWaermepumpe: anteilWaermepumpe,
      anteilHolz: anteilHolz,
      anteilSonstiges: anteilSonstiges,
      stromVerbrauchWhM2a: stromVerbrauch,
      stromZeitraumStart: stromZeitraumStart,
      stromZeitraumEnd: stromZeitraumEnd,
      stromEmissionCo2Kg: stromEmission,
      typ: typ
    };
  }

  function renderHeadline() {
    return (
      <BeeContentHeadline
        label={'Energiesteckbrief'}
        headline={'h2'}
        type={'secondary'}
      />
    );
  }
  const renderHeizenergieVerbrauch = () => {
    const verbrauch = heizenergieVerbrauch
      ? heizenergieVerbrauch / 1000
      : undefined;

    return (
      <BeeValidatedNumberInput
        label={'Heizenergieverbrauch [kWh/m² a]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
        grouping={false}
        value={verbrauch}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setHeizenergieVerbrauch(
            e.target.value ? _.round(e.target.value * 1000) : undefined
          )
        }
        onFocusOut={(e) => {
          let newInt =
            e && e.target && e.target.value
              ? e.target.value.replaceAll('.', '')
              : undefined;
          newInt = newInt ? newInt.replace(',', '.') : undefined;

          newInt
            ? changeHeizenergieVerbrauch(_.round(parseFloat(newInt) * 1000))
            : changeHeizenergieVerbrauch(undefined);
        }}
      />
    );
  };

  function renderHeizenergieZeitraumStart() {
    return (
      <BeeValidatedDateInput
        label={'Heizenergieverbrauch Zeitraum Start'}
        value={
          heizenergieZeitraumStart ? new Date(heizenergieZeitraumStart) : null
        }
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e: any) => {
          setHeizenergieZeitraumStart(e.value.toISOString());
          changeHeizenergieZeitraumStart(e.value.toISOString());
        }}
      />
    );
  }

  function renderHeizenergieZeitraumEnd() {
    return (
      <BeeValidatedDateInput
        label={'Heizenergieverbrauch Zeitraum Ende'}
        value={heizenergieZeitraumEnd ? new Date(heizenergieZeitraumEnd) : null}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e: any) => {
          setHeizenergieZeitraumEnd(e.value.toISOString());
          changeHeizenergieZeitraumEnd(e.value.toISOString());
        }}
      />
    );
  }

  const renderHeizenergieEmission = () => {
    const emission = heizenergieEmission
      ? heizenergieEmission / 1000
      : undefined;

    return (
      <BeeValidatedNumberInput
        label={'Heizenergie-Emission [t CO₂/a]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
        grouping={false}
        value={emission}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setHeizenergieEmission(
            e.target.value ? _.round(e.target.value * 1000) : undefined
          )
        }
        onFocusOut={(e) => {
          let newInt =
            e && e.target && e.target.value
              ? e.target.value.replaceAll('.', '')
              : undefined;
          newInt = newInt ? newInt.replace(',', '.') : undefined;

          newInt
            ? changeHeizenergieEmission(_.round(parseFloat(newInt) * 1000))
            : changeHeizenergieEmission(undefined);
        }}
      />
    );
  };
  const renderNutzungsartenmixHeizenergie = () => {
    if (
      !anteilFernwaerme &&
      !anteilGas &&
      !anteilOel &&
      !anteilWaermepumpe &&
      !anteilHolz &&
      !anteilSonstiges
    ) {
      changeHeizenergieNutzungsartenMix(16, 16, 17, 17, 17, 17);
      setAnteilFernwaerme(16);
      setAnteilGas(16);
      setAnteilOel(17);
      setAnteilWaermepumpe(17);
      setAnteilHolz(17);
      setAnteilSonstiges(17);
    }
    return (
      <div>
        <BeeNutzungsartMixHeizenergie
          label={'Nutzungsart Heizenergie'}
          disabled={false}
          forceRerenderToggle={false} //fixme
          aFernwaerme={anteilFernwaerme ? anteilFernwaerme : 0}
          aGas={anteilGas ? anteilGas : 0}
          aOel={anteilOel ? anteilOel : 0}
          aWaermepumpe={anteilWaermepumpe ? anteilWaermepumpe : 0}
          aHolz={anteilHolz ? anteilHolz : 0}
          aSonstiges={anteilSonstiges ? anteilSonstiges : 0}
          type={'primary'}
          formstate={'none'}
          errorLabel={null}
          neutralLabel={null}
          validLabel={null}
          readOnly={readOnly}
          required={false}
          onChange={(e) => {
            changeHeizenergieNutzungsartenMix(
              e.aFernwaerme,
              e.aGas,
              e.aOel,
              e.aWaermepumpe,
              e.aHolz,
              e.aSonstiges
            );
            setAnteilFernwaerme(e.aFernwaerme);
            setAnteilGas(e.aGas);
            setAnteilOel(e.aOel);
            setAnteilWaermepumpe(e.aWaermepumpe);
            setAnteilHolz(e.aHolz);
            setAnteilSonstiges(e.aSonstiges);
          }}
        />
      </div>
    );
  };

  const renderStromverbrauch = () => {
    const strom = stromVerbrauch ? stromVerbrauch / 1000 : undefined;

    return (
      <BeeValidatedNumberInput
        label={'Stromverbrauch [kWh/m² a]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
        grouping={false}
        value={strom}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setStromVerbrauch(
            e.target.value ? _.round(e.target.value * 1000) : undefined
          )
        }
        onFocusOut={(e) => {
          let newInt =
            e && e.target && e.target.value
              ? e.target.value.replaceAll('.', '')
              : undefined;
          newInt = newInt ? newInt.replace(',', '.') : undefined;

          newInt
            ? changeStromVerbrauch(_.round(parseFloat(newInt) * 1000))
            : changeStromVerbrauch(undefined);
        }}
      />
    );
  };
  function renderStromZeutraumStart() {
    return (
      <BeeValidatedDateInput
        label={'Stromverbrauch Zeitraum Start'}
        value={stromZeitraumStart ? new Date(stromZeitraumStart) : null}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e: any) => {
          setStromZeitraumStart(e.value.toISOString());
          changeStromZeitraumStart(e.value.toISOString());
        }}
      />
    );
  }

  function renderStromZeitraumEnd() {
    return (
      <BeeValidatedDateInput
        label={'Stromverbrauch Zeitraum Ende'}
        value={stromZeitraumEnd ? new Date(stromZeitraumEnd) : null}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e: any) => {
          setStromZeitraumEnd(e.value.toISOString());
          changeStromZeitraumEnd(e.value.toISOString());
        }}
      />
    );
  }

  const renderStromEmission = () => {
    const emission = stromEmission ? stromEmission / 1000 : undefined;

    return (
      <BeeValidatedNumberInput
        label={'Stromemission [t CO₂/a]'}
        minFractionDigits={MIN_NUMBER_OF_DECIMALS}
        maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
        grouping={false}
        value={emission}
        required={false}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(e) =>
          setStromEmission(
            e.target.value ? _.round(e.target.value * 1000) : undefined
          )
        }
        onFocusOut={(e) => {
          let newInt =
            e && e.target && e.target.value
              ? e.target.value.replaceAll('.', '')
              : undefined;
          newInt = newInt ? newInt.replace(',', '.') : undefined;

          newInt
            ? changeStromEmission(_.round(parseFloat(newInt) * 1000))
            : changeStromEmission(undefined);
        }}
      />
    );
  };

  const renderType = () => {
    return (
      <BeeValidatedDropDown
        disabled={disabled}
        value={typ}
        options={energieTypenOptions}
        readOnly={readOnly}
        label={'Energietyp'}
        required={false}
        onChange={(e) => {
          setTyp(e);
          changeTyp(e);
        }}
      />
    );
  };

  return (
    <div className={'alpha-gebaeude-energie-form'}>
      {renderHeadline()}
      <div className={'grid'}>
        <div className={'col col-12 sm:col-12 md:col-12 lg:col-12'}>
          {renderType()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderHeizenergieEmission()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderHeizenergieVerbrauch()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderHeizenergieZeitraumStart()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderHeizenergieZeitraumEnd()}
        </div>

        <div className={'col col-12 sm:col-12 md:col-12 lg:col-12'}>
          {renderNutzungsartenmixHeizenergie()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderStromEmission()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderStromverbrauch()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderStromZeutraumStart()}
        </div>
        <div className={'col col-12 sm:col-6 md:col-6 lg:col-6'}>
          {renderStromZeitraumEnd()}
        </div>
      </div>
    </div>
  );
}
