export const ENTER = 13;
export const ESCAPE = 27;
export const SPACEBAR = 32;
export const LEFTARROW = 37;
export const UPARROW = 38;
export const RIGHTARROW = 39;
export const DOWNARROW = 40;

export const ENTER_KEY = 'Enter';
export const ESCAPE_KEY = 'Escape';
export const LEFTARROW_KEY = 'ArrowLeft';
export const UPARROW_KEY = 'ArrowUp';
export const RIGHTARROW_KEY = 'ArrowRight';
export const DOWNARROW_KEY = 'ArrowDown';
