import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { PATH_ROOT } from '../../Helper/Statics/Routes';
import Animation404 from '../../Style/Lottie-Animations/404-page.json';
import BeeContentHeadline from '../Atoms/BeeContentHeadline';
import BeeLinkButton from '../Atoms/BeeLinkButton';
import './Error404Page.scss';

function Error404Page() {
  let navigate = useNavigate();
  function onClickBack() {
    navigate(PATH_ROOT);
  }
  return (
    <div className={'error-page'}>
      <BeeContentHeadline
        label={'Die Seite konnte leider nicht gefunden werden'}
        headline={'h1'}
        type={'primary'}
      />
      <div className={'animation animation404'}>
        <Lottie animationData={Animation404} loop={true} autoplay={true} />
      </div>

      <BeeLinkButton
        label={
          <div className={'back-to-main-btn'}>
            <i className="pi pi-arrow-left"></i>
            <span>{'Zurück zur Startseite'}</span>
          </div>
        }
        disabled={false}
        type={'secondary'}
        onClick={() => {
          onClickBack();
        }}
      />
    </div>
  );
}

export default Error404Page;
