import { Card } from 'primereact';
import React, { useState } from 'react';
import './ForgotPwForm.scss';
import { VALIDATE_EMAIL_REGEX } from '../../../Helper/Statics/Constants';
import { ENTER } from '../../../Helper/Statics/KeyCodes';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeValidatedTextInput from '../../Atoms/BeeValidatedTextInput';
import BeeButton from '../../Atoms/BeeButton';
import BeeLinkButton from '../../Atoms/BeeLinkButton';

type ForgotPwProps = {
  showProgress: boolean;
  sendResetPwMailError: string;
  onSendResetPw: (email: string) => void;
  onRedirectToLogin: () => void;
};

function ForgotPwForm({
  showProgress,
  sendResetPwMailError,
  onSendResetPw,
  onRedirectToLogin
}: ForgotPwProps) {
  //component state
  const [emailSentMessage, setEmailSentMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const [isSendBtnActive, setIsSendBtnActive] = useState<boolean>(true);

  //label
  const successMessage =
    'Falls Sie mit dieser E-Mail-Adresse auf der Plattform registriert sind erhalten Sie in Kürze eine E-Mail. Eventuell müssen Sie den SPAM-Ordner prüfen oder die Adresse noReply@beestate.io als Absender zulassen. Bitte bestätigen Sie innerhalb der nächsten 24 Stunden das Zurücksetzen des Passworts, andernfalls verfällt der Link.';

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode === ENTER && !e.altKey) {
      sendResetPw();
    }
  }

  function redirectToLogin(): void {
    onRedirectToLogin();
  }

  function sendResetPw(): void {
    if (isInputValid()) {
      setEmailSentMessage(successMessage);
      setEmailError('');
      setIsSendBtnActive(false);
      onSendResetPw(email);
      toggleSendBtn();
    }
  }

  function isInputValid(): boolean {
    const isRegexConform = VALIDATE_EMAIL_REGEX.test(email);
    if (!email || !isRegexConform) {
      setEmailError('Bitte geben Sie eine valide E-Mail-Adresse ein.');
      return false;
    } else {
      setEmailError('');
      return true;
    }
  }

  function toggleSendBtn(): void {
    setTimeout(() => {
      setIsSendBtnActive(true);
    }, 5000);
  }

  function injectHeadline(): JSX.Element {
    return (
      <div
        className={'logo'}
        onClick={() => {
          redirectToLogin();
        }}
      />
    );
  }

  function injectSendEmailForm(): JSX.Element {
    return (
      <div>
        <div>
          <BeeContentHeadline
            label={'Passwort vergessen?'}
            headline={'h2'}
            type={'secondary'}
          />
        </div>
        <div className={'infotext'}>
          {
            'Wenn Sie ihr Passwort vergessen haben können Sie hier einen Link zum Zurücksetzen Ihres Passwortes anfordern.'
          }
        </div>

        <div
          className="field"
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
        >
          <BeeValidatedTextInput
            label={'E-Mail-Adresse'}
            errorLabel={emailError}
            neutralLabel={null}
            validLabel={null}
            value={email}
            disabled={false}
            formstate={emailError ? 'error' : 'none'}
            readOnly={false}
            required={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        {emailSentMessage ? (
          <div className={'successtext'}>
            {emailSentMessage}
            <i
              className={'pi pi-times'}
              onClick={() => setEmailSentMessage('')}
            ></i>
          </div>
        ) : null}
      </div>
    );
  }

  function injectActions(): JSX.Element {
    return (
      <>
        {sendResetPwMailError ? <div>{sendResetPwMailError}</div> : null}
        <div className="send-email-btn">
          <BeeButton
            label={emailSentMessage ? 'E-Mail erneut senden' : 'Abschicken'}
            disabled={!isSendBtnActive || !email}
            type={'primary'}
            onClick={() => sendResetPw()}
          />
        </div>
        <div className="back-to-login-btn">
          <BeeLinkButton
            label={'Zurück zum Login'}
            raised={false}
            disabled={false}
            type={'secondary'}
            onClick={() => redirectToLogin()}
          />
        </div>
      </>
    );
  }

  return (
    <div
      className={
        'o-forgot-pw-form form grid justify-content-center align-center'
      }
    >
      <div className="col-12 sm:col-10 md:col-8 lg:col-6 xl:col-4">
        <Card>
          {injectHeadline()}
          {injectSendEmailForm()}
          {injectActions()}
        </Card>
      </div>
    </div>
  );
}

export default ForgotPwForm;
