import Lottie from 'lottie-react';
import emptyResultAnimation from '../../Style/Lottie-Animations/empty-box.json';
import BeeLinkButton from '../Atoms/BeeLinkButton';
import './EmptySearchView.scss';

type EmptySearchViewProps = {
  searchTerm: string;
  filterCat: any;
  onResetSearch: () => void;
  onResetFilter: () => void;
};

function EmptySearchView({
  searchTerm,
  filterCat,
  onResetSearch,
  onResetFilter
}: EmptySearchViewProps) {
  function resetSearch() {
    if (onResetSearch) {
      onResetSearch();
    }
  }

  function resetFilter() {
    if (onResetFilter) {
      onResetFilter();
    }
  }

  function injectResetFilterButton() {
    return (
      <BeeLinkButton
        label={'Filter zurücksetzen'}
        disabled={false}
        type={'primary'}
        onClick={() => {
          resetFilter();
        }}
      />
    );
  }

  function injectResetSearchButton() {
    return (
      <BeeLinkButton
        label={'Suche zurücksetzen'}
        disabled={false}
        type={'primary'}
        onClick={() => {
          resetSearch();
        }}
      />
    );
  }

  function injectLottie() {
    return (
      <div className={'no-search-animation'}>
        <Lottie animationData={emptyResultAnimation} loop={true} />
      </div>
    );
  }

  return (
    <div className={'imag-no-search-result'}>
      {searchTerm && filterCat && filterCat.key ? (
        <>
          <h3>
            {"Es wurden leider keine Ergebnisse zu dem Suchbegriff '" +
              searchTerm +
              "' und der Filterkategorie '" +
              filterCat.label +
              "' gefunden"}
          </h3>
          {injectResetSearchButton()}
          {injectResetFilterButton()}
          {injectLottie()}
        </>
      ) : searchTerm && !filterCat ? (
        <>
          <h3>
            {"Es wurden leider keine Ergebnisse zu dem Suchbegriff '" +
              searchTerm +
              "' gefunden"}
          </h3>{' '}
          {injectResetSearchButton()}
          {injectLottie()}
        </>
      ) : !searchTerm && filterCat && filterCat.key ? (
        <>
          <h3>
            {"Es wurden leider keine Ergebnisse zu der Filterkategorie '" +
              filterCat.label +
              "' gefunden"}
          </h3>
          {injectResetFilterButton()}
          {injectLottie()}
        </>
      ) : (
        <>
          <h3>
            {
              'Es wurden leider keine Ergebnisse für Ihre Such- oder Filteranfrage gefunden.'
            }
          </h3>
          {injectLottie()}
        </>
      )}
    </div>
  );
}

export default EmptySearchView;
