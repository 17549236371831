import fileDownload from 'js-file-download';
import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { downloadDocumentFromServer } from '../../../Helper/ApiHelper/MediaNetworkHelper';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';
import { zipDocs } from '../../../Helper/StorageHelper/ZipHelper';
import { handlePromisesAndIgnoreErrors } from '../../../Helper/Util/PromiseHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeImageSlider from '../../Atoms/BeeImageSlider';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import { DocType, ImgType } from '../../Pages/DLPages/DLPriceInputPage';
import BeeDocumentTable from '../BeeDocumentTable';
import BeeFlatImageGallery from '../BeeFlatImageGallery';
import './AlphaMediaForm.scss';

type AlphaMediaFormProps = {
  images: ImgType[];
  documents: DocType[];
  downloadFolderName: string;
  readOnly: boolean;
  onDeleteImage?: (itemId: string) => void;
  onAddImage?: () => void;
  onDeleteDocument?: (itemId: string) => void;
  onAddDocument?: () => void;
};

export default function AlphaMediaForm({
  downloadFolderName,
  images,
  documents,
  readOnly,
  onDeleteImage,
  onAddImage,
  onDeleteDocument,
  onAddDocument
}: AlphaMediaFormProps) {
  const [isLoadingDownloadDocs, setIsLoadingDownloadDocs] =
    useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const gallery = useRef<any>(null);

  function deleteImage(id: string) {
    if (onDeleteImage) {
      onDeleteImage(id);
    }
  }
  function addImage() {
    if (onAddImage) {
      onAddImage();
    }
  }

  function deleteDocument(id: string) {
    if (onDeleteDocument) {
      onDeleteDocument(id);
    }
  }
  function addDocument() {
    if (onAddDocument) {
      onAddDocument();
    }
  }

  function clickImage(entryId: string) {
    if (images) {
      const entryIndex = _.findIndex(images, function (o: ImgType) {
        return o.id === entryId;
      });
      if (entryIndex >= 0) {
        gallery.current.showImage(entryIndex);
      }
      gallery.current.openGallery();
    }
  }

  function downloadDocById(entryId: string) {
    const entry: DocType | undefined = _.find(
      documents,
      function (doc: DocType) {
        return doc.id === entryId;
      }
    );

    if (entry) {
      downloadDocumentFromServer(entry.fileSrc, entry.fileKey)
        .then((result: any) => {
          if (entry.filename) {
            fileDownload(result, entry.filename);
          }
        })
        .catch((error) => {
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Download fehlgeschlagen ',
              detail:
                'Leider konnte das Dokument nicht heruntergeladen werden. Bitte versuchen Sie es später erneut.',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  function downloadDocumentsByIds(entryIds: string[]) {
    setIsLoadingDownloadDocs(true);
    let downloadDocsPromises = [];
    for (let i = 0; i < entryIds.length; i++) {
      const entry = _.find(documents, function (doc: DocType) {
        return doc.id === entryIds[i];
      });

      if (entry) {
        downloadDocsPromises.push(
          downloadDocumentFromServer(entry.fileSrc, entry.fileKey).then(
            (result) => {
              let filename = entry.filename;
              if (filename) {
                return { name: filename, blob: result };
              }
              //no filename
            }
          )
        );
      }
    }
    handlePromisesAndIgnoreErrors(downloadDocsPromises)
      .then((result: any) => {
        if (result.fulfilled && result.fulfilled.length > 0) {
          let docFiles = [];
          for (let j = 0; j < result.fulfilled.length; j++) {
            docFiles.push(result.fulfilled[j].value);
          }

          //zip data
          zipDocs(docFiles, downloadFolderName)
            .then(() => {
              //sucess
              console.log('success');
              if (toast && toast.current) {
                if (!result.rejected || result.rejected.length <= 0) {
                  toast.current?.show({
                    severity: 'success',
                    summary: 'Download erfolgreich abgeschlossen',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                } else {
                  toast.current?.show({
                    severity: 'warn',
                    summary:
                      'Der Download ist für einige Dokumente fehlgeschlagen',
                    detail:
                      'Der Download der Dokumente ist vereinzelt fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                    //fixme filenames
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              }
              setIsLoadingDownloadDocs(false);
            })
            .catch((error) => {
              //Fixme zip didnt work
              console.log(error);
              if (toast && toast.current) {
                toast.current?.show({
                  severity: 'error',
                  summary: 'Der Download ist fehlgeschlagen',
                  detail:
                    'Die Dokumente konnten nicht gezipped und heruntergeladen werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
              setIsLoadingDownloadDocs(false);
            });
        } else if (result.rejected && result.rejected.length > 0) {
          //download failed for all documents
          if (toast && toast.current) {
            toast.current?.show({
              severity: 'error',
              summary: 'Der Download ist für alle Dokumente fehlgeschlagen',
              detail:
                'Der Server für den Download der Dokumente konnte nicht erreicht werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
          setIsLoadingDownloadDocs(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (toast && toast.current) {
          toast.current?.show({
            severity: 'error',
            summary: 'Der Download ist fehlgeschlagen',
            detail:
              'Der Download der Dokumente ist leider fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
        setIsLoadingDownloadDocs(false);
      });
  }

  function injectGalleryHeadline() {
    return <h2>{'Bilder'}</h2>;
  }

  function injectImageGallery() {
    return (
      <>
        <BeeFlatImageGallery
          images={images}
          showAddOption={true}
          activateHover={true}
          readOnly={readOnly}
          onClick={(e) => clickImage(e)}
          onRemove={(e) => deleteImage(e)}
          onAdd={() => addImage()}
        />
      </>
    );
  }
  function injectDocumentHeadline() {
    return (
      <BeeContentHeadline
        label={'Dokumente'}
        headline={'h2'}
        type={'secondary'}
      />
    );
  }

  function injectDocuments() {
    return (
      <>
        <div className={''}>
          <BeeDocumentTable
            type={'dark'}
            data={documents}
            readOnly={readOnly}
            showDownload={true}
            showDelete={!readOnly}
            enableBulkDownload={true}
            isBulkDownloadLoading={isLoadingDownloadDocs}
            onDownload={(id) => downloadDocById(id)}
            onDownloadAll={(ids) => downloadDocumentsByIds(ids)}
            onDelete={(id) => deleteDocument(id)}
          />
          {!readOnly ? (
            <div className={'add-doc-btn'}>
              <BeeOutlinedButton
                label={'Dokument hinzufügen'}
                disabled={readOnly}
                type={'secondary'}
                onClick={() => addDocument()}
              />
            </div>
          ) : null}
        </div>
      </>
    );
  }

  const injectImageSlider = () => {
    return (
      <BeeImageSlider
        type={'primary'}
        ref={gallery}
        images={images ? images : []}
        activeIndex={0}
      />
    );
  };

  return (
    <div>
      <>
        {!(images && images.length > 0) && readOnly ? null : (
          <>
            <div>{injectGalleryHeadline()}</div>
            <div>{injectImageGallery()}</div>
          </>
        )}
      </>

      <>
        {!(documents && documents.length > 0) && readOnly ? null : (
          <>
            <div>{injectDocumentHeadline()}</div>
            <div>{injectDocuments()}</div>
          </>
        )}
      </>
      {/* gallery */}
      <>{injectImageSlider()}</>
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
