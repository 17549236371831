import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { useId } from 'react-id-generator';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import './BeeDropDown.scss';
import BeeTextInput from './BeeTextInput';

type BeeDropDownProps = {
  id?: string;
  label?: any;
  value: any;
  options: any;
  optionLabel?: string;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: string) => void;
  onFocusOut?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
};

export function BeeDropDown({
  id,
  label,
  value,
  options,
  optionLabel,
  disabled,
  formstate,
  readOnly,
  required,
  onChange,
  onFocusOut
}: BeeDropDownProps) {
  const genId = useId(1, 'bee-drop-down-')[0];
  const currentId = id ? id : genId;
  const inputId = currentId + 'input';

  //classNames
  const formstateClassnames =
    formstate === 'valid'
      ? 'bee-dropdown-valid'
      : formstate === 'error'
      ? 'bee-dropdown-error'
      : formstate === 'neutral'
      ? 'bee-dropdown-neutral'
      : 'bee-dropdown-neutral';

  let readOnlyClassnames = '';
  if (readOnly) {
    readOnlyClassnames = ' bee-dropdown-readOnly';
    disabled = true;
  }

  const classNames = formstateClassnames + readOnlyClassnames;
  const containerClassNames = 'p-float-label bee-dropdown-container';
  const requiredClassName = 'bee-dropdown-label-required';
  const requiredStarClassName = 'bee-dropdown-label-required-star';

  function change(item: any) {
    if (!disabled && onChange) {
      onChange(item);
    }
  }

  function focusOut(e: React.FocusEvent<HTMLInputElement, Element>) {
    if (!disabled && onFocusOut) {
      onFocusOut(e);
    }
  }
  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={required}
        onChange={() => {}}
      />
    );
  };
  return (
    <>
      {readOnly && !value ? (
        renderPlaceholder()
      ) : (
        <span id={currentId} className={containerClassNames}>
          <Dropdown
            id={inputId}
            value={value}
            options={options}
            disabled={disabled}
            className={classNames}
            onChange={(e) => change(e.value)}
            onBlur={(e) => focusOut(e)}
            optionLabel={optionLabel}
          />
          {label ? (
            <label htmlFor={inputId}>
              {label}
              {required && label ? (
                <span className={requiredClassName}>
                  <span className={requiredStarClassName}> *</span>
                </span>
              ) : null}
            </label>
          ) : null}
        </span>
      )}
    </>
  );
}

export default BeeDropDown;
