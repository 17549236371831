import _ from 'lodash';
import TreeNode from 'primereact/treenode';
import { TreeSelectSelectionKeys } from 'primereact/treeselect';
import {
  Tree,
  TreeCheckboxSelectionKeys,
  TreeSelectionKeys
} from 'primereact/tree';
import { useEffect, useState } from 'react';
import { useId } from 'react-id-generator';
import { v4 as uuidv4 } from 'uuid';
import { LiegenschaftsData } from '../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import BeeTextInput from './BeeTextInput';
import './BeeTreeLiegenschaften.scss';

type BeeTreeLiegenschaftenProps = {
  id?: string;
  label?: any;
  value: any;
  options?: LiegenschaftsData[];
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  onChange: (e: LiegenschaftsData[]) => void;
};

export function BeeTreeLiegenschaften({
  id,
  label,
  value,
  options,
  disabled,
  readOnly,
  onChange
}: BeeTreeLiegenschaftenProps) {
  const [data, setData] = useState<TreeNode[]>([]);
  const [propertyLookUp, setPropertyLookUp] = useState<Map<string, string[]>>();
  const [selectedValues, setSelectedValues] = useState<TreeSelectionKeys>({});
  const genId = useId(1, 'bee-drop-down-')[0];
  const currentId = id ? id : genId;
  const inputId = currentId + 'input';

  useEffect(() => {
    if (options) {
      const currData = reworkPayload(options);
      const currLookup = new Map<string, string[]>();
      generatePropLookup(currData[0], currLookup);
      setPropertyLookUp(currLookup);
      setData(currData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (value && propertyLookUp) {
      setSelectedValues(reworkIncomingValues(value, propertyLookUp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyLookUp, value]);

  function change(value: TreeSelectSelectionKeys) {
    if (!disabled && onChange) {
      const reworkedValues = reworkOutgoingValues(value);
      onChange(reworkedValues);
    }
  }

  function reworkOutgoingValues(values: TreeSelectSelectionKeys) {
    let outgoingProperties = [];
    if (values && _.isObject(values)) {
      for (const [key, value] of Object.entries(values)) {
        const prop = _.find(options, function (o) {
          return o.id === key;
        });
        //only return selected property
        if (value.checked && prop && _.isObject(prop)) {
          outgoingProperties.push(prop);
        }
      }
    }
    return outgoingProperties;
  }

  function reworkIncomingValues(
    properties: LiegenschaftsData[],
    lookup: Map<string, string[]> | undefined
  ) {
    let currValues: TreeCheckboxSelectionKeys = {};
    let propIds: string[] = [];
    if (properties && lookup) {
      for (let i = 0; i < properties.length; i++) {
        propIds.push(properties[i].id!);
      }
      lookup.forEach((value, key) => {
        const k: string = key;
        const intersection = _.intersection(value, propIds);
        if (intersection.length === 0) {
          //do nothing, hide knode
        } else if (intersection.length === value.length) {
          currValues = {
            ...currValues,
            [k]: { checked: true, partialChecked: false }
          };
        } else if (intersection.length < value.length) {
          currValues = {
            ...currValues,
            [k]: { checked: false, partialChecked: true }
          };
        }
      });
    }
    return currValues;
  }

  function generatePropLookup(node: TreeNode, lookup: Map<string, string[]>) {
    if (!node) {
      return;
    }

    if (node.id) {
      if (!node.children || node.children.length === 0) {
        // If the node is a leaf node, add its ID directly to the parent's array
        if (lookup.has(node.id)) {
          lookup.get(node.id)!.push(node.id);
        } else {
          lookup.set(node.id, [node.id]);
        }
      } else {
        // Initialize an array for this node to store its child leaf IDs
        lookup.set(node.id, []);

        // Recursively process child nodes and populate the map
        for (const child of node.children) {
          generatePropLookup(child, lookup);

          // Concatenate the leaf IDs of the child nodes to this node's array

          const childLeafIds = child.id ? lookup.get(child.id) : undefined;
          if (childLeafIds) {
            lookup.set(node.id, lookup.get(node.id)!.concat(childLeafIds));
          }
        }
      }
    }
  }

  function reworkPayload(options?: LiegenschaftsData[]) {
    if (options) {
      let ids: any = {};
      const pay: TreeNode[] = [];
      //sort array by land, region, city
      const sorted: LiegenschaftsData[] = _.sortBy(options, [
        'adresse.land',
        'adresse.region',
        'adresse.stadt',
        'nummer'
      ]);
      //loop and build tree
      for (let i = 0; i < sorted.length; i++) {
        const property: LiegenschaftsData = sorted[i];
        const countryName = property.adresse?.land;
        const regionName = property.adresse?.region;
        const cityName = property.adresse?.stadt;
        const propId = property.id ? property.id : '';
        if (pay.length === 0) {
          const countryId = uuidv4();
          const regionId = uuidv4();
          const cityId = uuidv4();
          ids[countryId] = { checked: true };
          ids[regionId] = { checked: true };
          ids[cityId] = { checked: true };
          ids[propId] = { checked: true };
          //create this country
          //create this region
          //create this city
          pay.push({
            id: countryId,
            key: countryId,
            label: countryName,
            data: countryName,
            children: [
              {
                id: regionId,
                key: regionId,
                label: regionName,
                data: regionName,
                children: [
                  {
                    id: cityId,
                    key: cityId,
                    label: cityName,
                    data: cityName,
                    children: [
                      {
                        id: property.id,
                        key: property.id,
                        label: property.nummer + ' ' + property.name,
                        data: property,
                        selectable: true
                      }
                    ]
                  }
                ]
              }
            ]
          });
        } else {
          const entry = _.find(pay, function (e: TreeNode) {
            return e.data === countryName;
          });
          if (entry) {
            //we found the country => test region
            const region = _.find(entry.children, function (e: TreeNode) {
              return e.data === regionName;
            });
            if (region) {
              //we found region => test city
              const city = _.find(region.children, function (e: TreeNode) {
                return e.data === cityName;
              });
              if (city) {
                //if property is not contained > create it
                if (
                  !_.find(city.children, function (e: TreeNode) {
                    return e.id === property.id;
                  })
                ) {
                  ids[propId] = { checked: true };
                  city.children?.push({
                    id: property.id,
                    key: property.id,
                    label: property.nummer + ' ' + property.name,
                    data: property,
                    selectable: true
                  });
                }
              } else {
                const cityId = uuidv4();
                ids[cityId] = { checked: true };
                ids[propId] = { checked: true };
                // add city to this region
                region.children?.push({
                  id: cityId,
                  key: cityId,
                  label: cityName,
                  data: cityName,
                  children: [
                    {
                      id: property.id,
                      key: property.id,
                      label: property.nummer + ' ' + property.name,
                      data: property,
                      selectable: true
                    }
                  ]
                });
              }
            } else {
              const regionId = uuidv4();
              const cityId = uuidv4();
              ids[regionId] = { checked: true };
              ids[cityId] = { checked: true };
              ids[propId] = { checked: true };
              // add region to this entry
              // add city to this entry
              entry.children?.push({
                id: regionId,
                key: regionId,
                label: regionName,
                data: regionName,
                children: [
                  {
                    id: cityId,
                    key: cityId,
                    label: cityName,
                    data: cityName,
                    children: [
                      {
                        id: property.id,
                        key: property.id,
                        label: property.nummer + ' ' + property.name,
                        data: property,
                        selectable: true
                      }
                    ]
                  }
                ]
              });
            }
          } else {
            const countryId = uuidv4();
            const regionId = uuidv4();
            const cityId = uuidv4();
            ids[countryId] = { checked: true };
            ids[regionId] = { checked: true };
            ids[cityId] = { checked: true };
            ids[propId] = { checked: true };
            //create this country
            //create this region
            //create this city
            pay.push({
              id: countryId,
              key: countryId,
              label: countryName,
              data: countryName,
              children: [
                {
                  id: regionId,
                  key: regionId,
                  label: regionName,
                  data: regionName,
                  children: [
                    {
                      id: cityId,
                      key: cityId,
                      label: cityName,
                      data: cityName,
                      children: [
                        {
                          id: property.id,
                          key: property.id,
                          label: property.nummer + ' ' + property.name,
                          data: property,
                          selectable: true
                        }
                      ]
                    }
                  ]
                }
              ]
            });
          }
        }
      }
      return pay;
    } else {
      return [];
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={false}
        onChange={() => {}}
      />
    );
  };

  return (
    <>
      {readOnly && !value ? (
        renderPlaceholder()
      ) : (
        <div
          id={currentId}
          className={'p-float-label bee-treeselect-liegenschaften'}
        >
          <>{console.log(data)}</>
          <Tree
            value={data}
            selectionKeys={selectedValues}
            selectionMode="checkbox"
            filter
            filterMode="lenient"
            filterBy="data"
            onSelectionChange={(e: any) => change(e.value)}
          />
          {label ? <label>{label}</label> : null}
        </div>
      )}
    </>
  );
}

export default BeeTreeLiegenschaften;
