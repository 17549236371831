import _ from 'lodash';
import { FileUploadType } from '../../UI/Pages/DLPages/DLPriceInputPage';
import { arrayToString } from '../ApiHelper/ErrorMessages';
import {
  excelDlEpCodeHeader,
  excelDlPriceHeader
} from './ExcelExportDlEpHelper';
import { LeistungsSlices } from '../Statics/Constants';
import { excelTpPropertyIdHeader } from './ExcelExportAlphaTargetPriceHelper';

const ExcelJS = require('exceljs');

export function importAlphaTargetPricesExcel(excel: FileUploadType) {
  return new Promise((resolve, reject) => {
    if (!excel || !excel.file) {
      reject(
        'Bitte importieren Sie eine valide Datei. Die Datei konnte nicht gelesen werden.'
      );
    } else {
      const workbook = new ExcelJS.Workbook();
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        const buffer =
          e && e.target && e.target.result ? e.target.result : null;
        workbook.xlsx
          .load(buffer)
          .then(async (wb: any) => {
            initData(wb)
              .then((data: any) => {
                resolve(data);
              })
              .catch((error: any) => reject(error));
          })
          .catch((error: any) => {
            reject(
              'Bitte importieren Sie eine valide Datei. Die Datei konnte nicht gelesen werden.'
            );
          });
      };

      fileReader.readAsArrayBuffer(new Blob([excel.file]));
    }
  });
}

function initData(wb: any) {
  return new Promise((resolve, reject) => {
    const sheetDlEp = wb.worksheets[0];

    //combine data
    if (sheetDlEp) {
      //parse data
      createMap(sheetDlEp)
        .then((values) => {
          resolve(values);
        })
        .catch((error) => reject(error));
    } else {
      reject(
        'Bitte importieren Sie eine valide Datei mit mindestens einem Blatt. Das erste Blatt der Excel wird eingelesen.'
      );
    }
  });
}

function createMap(sheet: any) {
  return new Promise((resolve, reject) => {
    let headlines: string[] = [];
    let pricesheet: any = [];
    let errorPriceNANRows: { row: number; col: number }[] = [];
    let errorPriceDecimalsRows: { row: number; col: number }[] = [];

    //read contained data
    sheet.eachRow((row: any, rowNumber: number) => {
      if (rowNumber === 1 || rowNumber === 2) {
        //skip
      } else if (rowNumber === 3) {
        headlines.push(...row.values);
      } else {
        let payload: any = { slices: {} };
        row.eachCell(
          { includeEmpty: true },
          function (cell: any, colNumber: number) {
            let cellVal = cell.value;
            //use results if there is any kind of formula
            cellVal =
              cellVal && (cellVal.result || cellVal.result === 0)
                ? cellVal.result
                : cell._value &&
                  (cell._value.result || cell._value.result === 0)
                ? cell._value.result
                : cellVal;

            //Save price epcode and rownumber
            let attrName = calcAttributeName(headlines, colNumber);
            if (attrName) {
              attrName = attrName.replace('mgmt', 'management');
            }
            const slice = _.find(LeistungsSlices, function (o) {
              return o.title === attrName;
            });
            //prices
            if (slice) {
              //check if value is a number
              if (
                cellVal !== null &&
                cellVal !== undefined &&
                typeof cellVal !== 'number'
              ) {
                errorPriceNANRows.push({ row: rowNumber, col: colNumber });
              } else {
                //check decimals are smaller than 3
                if (countDecimals(cellVal) > 3) {
                  errorPriceDecimalsRows.push({
                    row: rowNumber,
                    col: colNumber
                  });
                } else {
                  payload.slices[slice.id] = cellVal;
                  payload['row'] = rowNumber;
                }
              }
            } else if (
              calcAttributeName(headlines, colNumber) ===
              excelTpPropertyIdHeader
            ) {
              //propId
              payload['propId'] = cellVal;
              payload['row'] = rowNumber;
            }
          }
        );

        if (payload && Object.keys(payload).length > 0) {
          pricesheet.push(payload);
        }
      }
    });
    //errorhandling
    if (errorPriceNANRows && errorPriceNANRows.length > 0) {
      if (errorPriceNANRows.length === 1) {
        reject(
          'Der angegebene Preis in ' +
            excelArrayToString(errorPriceNANRows) +
            ' ist keine Zahl. Bitte geben Sie eine gültige Zahl ein und importieren Sie die Datei erneut.'
        );
      } else {
        reject(
          'Die angegebenen Preise in ' +
            excelArrayToString(errorPriceNANRows) +
            ' sind keine Zahl. Bitte geben Sie eine gültige Zahl ein und importieren Sie die Datei erneut.'
        );
      }
    }
    if (errorPriceDecimalsRows && errorPriceDecimalsRows.length > 0) {
      if (errorPriceDecimalsRows.length === 1) {
        reject(
          'Der angegebene Rahmenpreis in ' +
            excelArrayToString(errorPriceDecimalsRows) +
            ' hat mehr als 3 Nachkommastellen. Bitte geben Sie eine gültige Zahl mit 3 oder weniger Nachkommastellen ein (z.B. 1,426) und importieren Sie die Datei erneut.'
        );
      } else {
        reject(
          'Die angegebenen Rahmenpreise in ' +
            excelArrayToString(errorPriceDecimalsRows) +
            ' haben mehr als 3 Nachkommastellen. Bitte geben Sie eine gültige Zahl mit 3 oder weniger Nachkommastellen ein (z.B. 1,426) und importieren Sie die Datei erneut.'
        );
      }
    }
    if (pricesheet) {
      //check for uniqueness of propIds

      let propIds: any = _.mapValues(pricesheet, 'propIds');
      propIds = _.valuesIn(propIds);
      propIds = _.remove(propIds, function (n: string) {
        return n !== undefined && n !== null;
      });

      const uniqueCodes = _.uniq(propIds);

      if (propIds.length !== uniqueCodes.length) {
        const doublePropIds = propIds.reduce(function (
          acc: string[],
          el: string,
          i: number,
          arr: string[]
        ) {
          if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el);
          return acc;
        },
        []);

        reject(
          'Die Positionen mit den LiegenschaftIds: ' +
            doublePropIds.toString() +
            ' sind nicht eineindeutig. Jeder Liegenschaft/Liegenschaftsid darf nur ein mal vorkommen. Bitte korrigieren Sie die Excel-Tabelle und versuchen Sie es erneut.'
        );
      }

      resolve(pricesheet);
    }

    reject(
      'Die Datei konnte nicht gelesen werden. Bitte laden Sie eine valide Excel-Tabelle hoch.'
    );
  });
}

function calcAttributeName(headlines: string[], colNumber: number) {
  return headlines[colNumber];
}

function countDecimals(value: number) {
  if (value && value % 1 !== 0) {
    if (value.toString().split('.')[1]) {
      return value.toString().split('.')[1].length;
    }
  }

  return 0;
}

export function excelArrayToString(array: { row: number; col: number }[]) {
  let string = '';
  if (array && array.length > 0) {
    if (array[0]) {
      string = 'Zeile: ' + array[0].row + ', Spalte: ' + array[0].col;
    }
    if (array.length > 1) {
      for (let i = 1; i < array.length - 1; i++) {
        string =
          string + ', Zeile: ' + array[i].row + ', Spalte: ' + array[i].col;
      }
      string =
        string +
        ' und Zeile: ' +
        array[array.length - 1].row +
        ', Spalte: ' +
        array[array.length - 1].col;
    }
  }
  return string;
}
