import _ from 'lodash';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { errorMsgToString } from '../../../Helper/ApiHelper/ErrorMessages';
import {
  createNewPhase,
  deletePhaseById,
  Phase,
  readAllPhases,
  updatePhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeIconButton from '../../Atoms/BeeIconButton';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import AlphaPhaseDialog from '../../Molecules/Dialogs/AlphaPhaseDialog';
import BeeDeletionDialog from '../../Molecules/Dialogs/BeeDeletionDialog';
import './AlphaPhasesPage.scss';

export default function AlphaPhasesPage() {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(false);
  const [vPhaseDialog, setVPhaseDialog] = useState<boolean>(false);
  const [vDeletionDialog, setVDeletionDialog] = useState<boolean>(false);
  const [phaseDialogReadOnly, setPhaseDialogReadOnly] =
    useState<boolean>(false);
  const [currentEntry, setCurrentEntry] = useState<Phase>();
  const [phasen, setPhasen] = useState<Phase[]>([]);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    setDataIsLoading(true);
    readAllPhases()
      .then((allPhases: any) => {
        setPhasen(_.sortBy(allPhases, ['start', 'end', 'title']));
        setDataIsLoading(false);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Phasen konnten nicht geladen werden',
            detail:
              'Leider konnten die Phasen nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
        setDataIsLoading(false);
      });
  }, []);

  function createOrUpdatePhase(phase: Phase) {
    if (currentEntry) {
      if (phase && phase.id) {
        updatePhase(phase.id, phase)
          .then((data: any) => {
            const savedPhase: Phase = data;
            let adapted: Phase[] = _.cloneDeep(phasen);
            adapted = adapted ? adapted : [];
            const phaseToAdaptIndex = _.findIndex(
              adapted,
              function (currPhase: Phase) {
                return currPhase.id === phase.id;
              }
            );
            if (phaseToAdaptIndex !== -1) {
              adapted[phaseToAdaptIndex] = savedPhase;
            } else {
              if (toast && toast.current) {
                toast.current.show({
                  severity: 'error',
                  summary: 'Phase konnte nicht gespeichert werden',
                  detail:
                    'Leider konnten die Phase nicht gespeichert werden. Die upzudatende Phase konnte nicht gefunden werden. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
            }
            setPhasen(adapted);
            setVPhaseDialog(false);
            setCurrentEntry(undefined);
          })
          .catch((error: any) => {
            if (toast && toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Phase konnten nicht gespeichert werden',
                detail: errorMsgToString(error)
                  ? 'Leider konnte die Phase nicht gespeichert werden. ' +
                    errorMsgToString(error)
                  : 'Leider konnte die Phase nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          });
      } else {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Phase konnten nicht gespeichert werden',
            detail:
              'Leider konnte die Phase nicht gespeichert werden. Die upzudatende Phase konnte nicht gefunden werden. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      }
    } else {
      if (phase) {
        createNewPhase(phase)
          .then((savedPhase: any) => {
            let currPhase: Phase = savedPhase;
            let adapted: Phase[] = _.cloneDeep(phasen);
            adapted = adapted ? adapted : [];
            adapted.push(currPhase);
            console.log(adapted);
            setPhasen(adapted);
            setVPhaseDialog(false);
            setCurrentEntry(undefined);
          })
          .catch((error) => {
            if (toast && toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Phase konnten nicht gespeichert werden',
                detail: errorMsgToString(error)
                  ? 'Leider konnte die Phase nicht gespeichert werden. ' +
                    errorMsgToString(error)
                  : 'Leider konnte die Phase nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          });
      } else {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Phase konnten nicht gespeichert werden',
            detail:
              'Leider konnte die Phase nicht gespeichert werden. Bitte prüfen Sie Ihre Angaben auf Vollständigkeit und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      }
    }
  }

  function deletePhase(phaseId: string) {
    if (phaseId) {
      deletePhaseById(phaseId)
        .then(() => {
          let adapted: Phase[] = _.cloneDeep(phasen);
          adapted = adapted ? adapted : [];
          _.remove(adapted, function (currPhase: Phase) {
            return currPhase.id === phaseId;
          });
          if (toast && toast.current) {
            toast.current.show({
              severity: 'success',
              summary: 'Phase wurde erfolgreich gelöscht',
              detail:
                'Die Phase wurde mit all Ihren verbundenen Daten erfolgreich gelöscht',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
          setPhasen(adapted);
          setVPhaseDialog(false);
          setCurrentEntry(undefined);
        })
        .catch((error) => {
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Phase konnten nicht gelöscht werden',
              detail: errorMsgToString(error)
                ? 'Leider konnte die Phase nicht gelöscht werden. ' +
                  errorMsgToString(error)
                : 'Leider konnte die Phase nicht gelöscht werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    } else {
      if (toast && toast.current) {
        toast.current.show({
          severity: 'error',
          summary: 'Phase konnten nicht gelöscht werden',
          detail:
            'Leider konnte die Phase nicht gelöscht werden, da sie nicht gefunden wurde. Bitte aktualisieren Sie Ihre Seite. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
          sticky: true,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        });
      }
    }
  }

  function isPhaseDeleteable(phase: Phase) {
    //only if it hasnt started
    if (phase && phase.start) {
      return new Date(phase.start) >= new Date();
    }
    return false;
  }

  function isPhaseUpdateable(phase: Phase) {
    //only if it hasnt ended yet
    if (phase && phase.end) {
      return new Date(phase.end) >= new Date();
    }
    return false;
  }

  const actionTemplate = (rowData: Phase) => {
    return (
      <div>
        {isPhaseUpdateable(rowData) ? (
          <BeeIconButton
            iconClass={'pi pi-pencil'}
            rounded={false}
            disabled={false}
            type={'primary'}
            onClick={() => {
              setCurrentEntry(rowData);
              setVPhaseDialog(true);
            }}
          />
        ) : (
          <BeeIconButton
            iconClass={'pi pi-eye'}
            rounded={false}
            disabled={false}
            type={'primary'}
            onClick={() => {
              setPhaseDialogReadOnly(true);
              setCurrentEntry(rowData);
              setVPhaseDialog(true);
            }}
          />
        )}
        {isPhaseDeleteable(rowData) ? (
          <BeeIconButton
            iconClass={'pi pi-trash'}
            rounded={false}
            disabled={false}
            type={'danger'}
            onClick={() => {
              setCurrentEntry(rowData);
              setVDeletionDialog(true);
            }}
          />
        ) : null}
      </div>
    );
  };

  function injectPhaseDialog() {
    return (
      <AlphaPhaseDialog
        data={currentEntry}
        phasen={phasen}
        visible={vPhaseDialog}
        readOnly={phaseDialogReadOnly}
        onHide={() => {
          setCurrentEntry(undefined);
          setVPhaseDialog(false);
          setPhaseDialogReadOnly(false);
        }}
        onAdapt={(data: Phase) => {
          createOrUpdatePhase(data);
        }}
      />
    );
  }

  function injectDeletionConfirmationDialog() {
    return (
      <BeeDeletionDialog
        header={'Phase löschen'}
        message={
          'Sind Sie sicher, dass Sie die Phase unwiderruflich löschen möchten?'
        }
        acceptLabel={'Löschen'}
        rejectLabel={'Abbrechen'}
        type={'primary'}
        visible={vDeletionDialog}
        onReject={() => {
          setCurrentEntry(undefined);
          setVDeletionDialog(false);
        }}
        onHide={() => {
          setCurrentEntry(undefined);
          setVDeletionDialog(false);
        }}
        onAccept={() => {
          currentEntry ? deletePhase(currentEntry.id) : deletePhase('');
        }}
      />
    );
  }

  function phaseStartBodyTemplate(rowData: Phase) {
    const date = rowData.start ? new Date(rowData.start) : null;
    return date ? date.toLocaleString('de-DE') : null;
  }

  function phaseEndBodyTemplate(rowData: Phase) {
    const date = rowData.end ? new Date(rowData.end) : null;
    return date ? date.toLocaleString('de-DE') : null;
  }

  function injectPhaseTable() {
    return (
      <div className={'alpha-options-phasen-table'}>
        <DataTable
          value={phasen}
          responsiveLayout="stack"
          breakpoint="640px"
          emptyMessage={'Keine Phasen vorhanden'}
        >
          <Column field="title" header="Name" className={'title-col'} />
          <Column
            field="start"
            header="Start"
            body={phaseStartBodyTemplate}
            className={'date-start-col'}
          />
          <Column
            field="end"
            header="Ende"
            body={phaseEndBodyTemplate}
            className={'date-end-col'}
          />
          <Column header="" body={actionTemplate} className={'action-col'} />
        </DataTable>
      </div>
    );
  }

  function injectButton() {
    return (
      <div className="mt-2">
        <BeeOutlinedButton
          label={'Phase hinzufügen'}
          disabled={false}
          type={'secondary'}
          onClick={() => {
            setVPhaseDialog(true);
          }}
        />
      </div>
    );
  }

  function injectHeadline() {
    return (
      <div className={'overview-header'}>
        <BeeContentHeadline
          label={'Phasenverwaltung'}
          headline={'h1'}
          type={'primary'}
        />
        {dataIsLoading ? <>{injectLoadingSpinner()}</> : null}
      </div>
    );
  }

  function injectDescription() {
    return (
      <div className="mb-2">
        Bitte legen Sie in folgender Übersicht die Angebotsphasen an. Phasen
        dürfen sich dabei nicht überschneiden. Das bedeutet eine Phase muss
        abgeschlossen sein, bevor die Nächste beginnt. Phasen können nur
        gelöscht werden, wenn sie noch nicht begonnen haben und nur geupdated
        werden, solange sie noch nicht abgelaufen sind.
      </div>
    );
  }

  function injectLoadingSpinner() {
    return <BeeLoadingSpinner strokeWidth={'3'} type={'secondary'} />;
  }

  return (
    <div className={'alpha-phases-page m-4 pt-2 justify-content-start'}>
      {injectHeadline()}
      {injectDescription()}
      {injectPhaseTable()}
      {injectButton()}
      {vPhaseDialog ? injectPhaseDialog() : null}
      {vDeletionDialog ? injectDeletionConfirmationDialog() : null}
    </div>
  );
}
