import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import './AlphaTabPrincing.scss';
import AlphaAnalysisFactorTable from './AlphaAnalysisFactorTable';
import AlphaAnalysisEPTable from './AlphaAnalysisEPTable';
import BeeListbox from '../../Atoms/BeeListbox';
import {
  COUNTRIES,
  DURATION_NOTIFICATION_ERROR_LONG,
  LeistungsSlices
} from '../../../Helper/Statics/Constants';
import { exportAlphaPricingExcel } from '../../../Helper/StorageHelper/ExcelExportAlphaPricing';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import _ from 'lodash';

type AlphaTabPrincingProps = {
  phase: TimelineStep;
  properties: LiegenschaftsData[];
  serviceProvider: AuthUserType[];
  keyValues: any;
  epTree: any;
};

export default function AlphaTabPrincing({
  phase,
  properties,
  serviceProvider,
  keyValues,
  epTree
}: AlphaTabPrincingProps) {
  const [selectedProvider, setSelectedProvider] = useState<AuthUserType[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [blStructure, setBlStructure] = useState<any>(setupBlData());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lsStructure, setLsStructure] = useState<any>(setupLsData());
  const [exportPricesIsLoading, setExportPricesIsLoading] =
    useState<boolean>(false);
  const toast = useRef<Toast>(null);

  ///////////////////////
  //////// LOGIC ////////
  ///////////////////////

  function setupLsData() {
    let pay: any = [];
    let allContainedRegions: string[] = [];
    const regions: Map<string, LiegenschaftsData[]> = new Map();
    for (let i = 0; i < properties.length; i++) {
      if (properties[i] && properties[i].adresse) {
        allContainedRegions.push(properties[i].adresse!.region);
        let data = regions.get(properties[i].adresse!.region);
        data = data ? data : [];
        data.push(properties[i]);
        regions.set(properties[i].adresse!.region, data);
      }
    }
    //minimize allContainedRegions and sort alphabetically
    allContainedRegions = _.sortBy(_.uniq(allContainedRegions));
    allContainedRegions.forEach((r) => {
      let rObj: any = {
        key: r,
        data: {
          type: 'region',
          title: r
        },
        children: []
      };
      let prArray = regions.get(r);
      prArray?.forEach((pr) => {
        let prObj: any = {
          key: pr.id,
          data: {
            type: 'property',
            title: pr.name
          },
          children: []
        };
        LeistungsSlices.forEach((sl) => {
          let curr: any = _.cloneDeep(sl);
          curr.propertyId = pr.id;
          curr.type = 'pos';
          prObj.children.push({
            key: sl.id,
            data: curr
          });
          if (sl.sustainability === true) {
            let currSus: any = _.cloneDeep(sl);
            currSus.propertyId = pr.id;
            currSus.title = currSus.title + ' > Nachhaltigkeit';
            currSus.type = 'pos';
            prObj.children.push({
              key: sl.id + '_sus',
              data: currSus
            });
          }
        });
        rObj.children.push(prObj);
      });
      pay.push(rObj);
    });
    console.log(pay);
    return pay;
  }

  function setupBlData() {
    let pay: any = [];
    COUNTRIES.forEach((country) => {
      let cObj: any = {
        key: country.id,
        data: {
          type: 'cat_1',
          title: country.country
        },
        children: []
      };
      country.regions.forEach((r) => {
        let rObj: any = {
          key: r.id,
          data: {
            type: 'cat_2',
            title: r.region
          },
          children: []
        };
        LeistungsSlices.forEach((sl) => {
          let curr: any = _.cloneDeep(sl);
          curr.regionId = r.id;
          curr.type = 'pos';
          rObj.children.push({
            key: sl.id,
            data: curr
          });
        });
        cObj.children.push(rObj);
      });
      pay.push(cObj);
    });
    return pay;
  }
  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  const providerTemplate = (provider: any) => {
    const orga = provider.organisation;
    const name = provider.firstname + ' ' + provider.lastname;
    return (
      <div>
        {orga ? <span>{orga + ' - '}</span> : null}
        {name ? <span>{name}</span> : null}
      </div>
    );
  };

  function injectDLSelection() {
    return (
      <BeeListbox
        selectedValues={selectedProvider}
        grouped={false}
        options={serviceProvider}
        label={'Dienstleiser'}
        filterLabel={'organisation'}
        optionRenderTemplate={providerTemplate}
        required={false}
        disabled={false}
        readOnly={false}
        onChangeSelectedOptions={(e: any) => setSelectedProvider(e)}
      />
    );
  }
  function injectExportBtn() {
    return (
      <BeeOutlinedButton
        label={
          selectedProvider.length <= 1
            ? 'Übersicht herunterladen'
            : 'Preisspiegel herunterladen'
        }
        type={'secondary'}
        isLoading={exportPricesIsLoading}
        onClick={() => {
          setExportPricesIsLoading(true);
          exportAlphaPricingExcel(
            epTree,
            selectedProvider,
            keyValues,
            blStructure,
            lsStructure,
            phase
          )
            .catch((e) => {
              console.log(e);

              if (toast.current) {
                toast.current.show({
                  severity: 'error',
                  summary: 'Export fehlgeschlagen',
                  detail:
                    'Beim Exportieren der Excel ist etwas fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                  sticky: false,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
            })
            .finally(() => setExportPricesIsLoading(false));
        }}
      />
    );
  }

  return (
    <div className={'alpha-tab-pricing m-2'}>
      <div className="flex justify-content-between align-items-end ">
        {injectDLSelection()}
        {injectExportBtn()}
      </div>
      <AlphaAnalysisFactorTable
        phase={phase}
        blStructure={blStructure}
        lsStructure={lsStructure}
        valueLookup={keyValues}
        selectedProvider={selectedProvider}
      />
      <AlphaAnalysisEPTable
        phase={phase}
        epTree={epTree}
        valueLookup={keyValues}
        selectedProvider={selectedProvider}
      />
      <div className="flex justify-content-end">{injectExportBtn()}</div>
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
