import _ from 'lodash';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { errorToMsg } from '../../../Helper/ApiHelper/ErrorMessages';
import {
  LiegenschaftsData,
  readAllLiegenschaften
} from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  EP,
  EpPosition,
  generateEpTreeFromPositions,
  generateLVTreeFromEPPositions,
  LV,
  readAllLvCatalogsForPhase,
  readEpCatalogForPhase,
  removeAllHiddenCategoriesFromEpTree
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  translateImageToData,
  translateMediaIds
} from '../../../Helper/ApiHelper/MediaNetworkHelper';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import NoProperties from '../../Molecules/DL/NoProperties';
import DLTabOverview from '../../Organisms/DL/DLTabOverview';
import DLTabProperty from '../../Organisms/DL/DLTabProperty';
import { ImageType } from '../AlphaPages/AlphaLiegenschaftVerwaltung';
import './DLOverviewTabView.scss';
import { AppDispatch, RootState } from '../../../Redux/store';
import { connect } from 'react-redux';

type DLOverviewTabViewProps = {
  phase: TimelineStep;
  readOnly: boolean;
  userParticipation: boolean;
};

export function DLOverviewTabView({
  phase,
  readOnly,
  userParticipation
}: DLOverviewTabViewProps) {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [noPropertyInPhase, setNoPropertyInPhase] = useState<boolean>(false);
  const [properties, setProperties] = useState<LiegenschaftsData[]>([]);
  const [lvs, setLvs] = useState<LV[]>([]);
  const [imgLookup, setImgLookup] = useState<Map<string, ImageType>>(new Map());
  const [epLookup, setEpLookup] = useState<Map<string, EpPosition>>(new Map());
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentEpCatalog, setCurrentEpCatalog] = useState<EP>();
  const [epTree, setEpTree] = useState<any>();
  const [shortenLvTree, setShortenLvTree] = useState<any>();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (phase) {
      if (phase.liegenschaftIds && phase.liegenschaftIds.length > 0) {
        setDataIsLoading(true);
        const propertyIds: string[] = phase.liegenschaftIds;
        readAllLiegenschaften()
          .then((props: any) => {
            let properties = _.filter(props, (entry) =>
              _.includes(propertyIds, entry.id)
            );
            readAllLvCatalogsForPhase(phase.id)
              .then((dataLvs: any) => {
                const allLvs: LV[] = _.filter(dataLvs.data, (entry) =>
                  _.includes(propertyIds, entry.info.propertyId)
                );
                if (allLvs.length !== propertyIds.length) {
                  toast.current?.show({
                    severity: 'info',
                    summary: 'Liegenschaften ohne Leistungsverzeichnis',
                    detail:
                      'Leider wurden noch nicht zu allen Liegenschaften Leistungsverzeichnisse hinterlegt. Dementsprechend werden darauf basierende Rechnungen noch nicht vollständig angezeigt.',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
                readEpCatalogForPhase(phase.id)
                  .then((ep: any) => {
                    const catalog: EP = ep;
                    let epTree: any = generateEpTreeFromPositions(catalog.data);
                    epTree = epTree ? epTree : [];
                    epTree = removeAllHiddenCategoriesFromEpTree(epTree, false);

                    //load images
                    let mediaIds = [];
                    for (let i = 0; i < properties.length; i++) {
                      if (properties[i].hauptBildId) {
                        mediaIds.push(properties[i].hauptBildId);
                      }
                    }
                    translateMediaIds(mediaIds, extractUserId())
                      .then((data) => {
                        setShortenLvTree(
                          generateLVTreeFromEPPositions(catalog.data)
                        );
                        setupMediaLookups(data);
                        setCurrentEpCatalog(catalog);
                        setProperties(properties);
                        setDataIsLoading(false);
                        setLvs(allLvs);
                        setupEpLookup(catalog);
                        setEpTree(epTree);
                      })
                      .catch((error) => {
                        if (toast && toast.current) {
                          toast.current.show({
                            severity: 'error',
                            summary:
                              'Liegenschaftsbilder konnten nicht geladen werden ' +
                              errorToMsg(error),
                            detail:
                              'Leider konnten die Liegenschaftsbilder nicht vollständig geladen werden. Bitte aktualisieren Sie die Seite. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                            sticky: false,
                            closable: true,
                            life: DURATION_NOTIFICATION_ERROR_LONG
                          });
                        }
                      });
                  })
                  .catch(() => {
                    setCurrentEpCatalog(undefined);
                    toast.current?.show({
                      severity: 'error',
                      summary: 'EP-Katalog konnte nicht geladen werden.',
                      detail:
                        'Das Laden des EP-Katalogs ist schiefgelaufen. Die Berechnung von Preisen für einzelne Leistungsverzeichnisse ist deshalb unmöglich. Bitte aktualisieren Sie die Seite. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice. ',
                      sticky: true,
                      closable: true,
                      life: DURATION_NOTIFICATION_ERROR_LONG
                    });
                  });
              })
              .catch((error) => {
                if (toast && toast.current) {
                  toast.current.show({
                    severity: 'error',
                    summary:
                      'Leistungsverzeichnisse nicht geladen ' +
                      errorToMsg(error),
                    detail:
                      'Leider ist beim Laden der Leistungsverzeichnisse ein Fehler aufgetreten. Bitte aktualisieren Sie die Seite. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              });
          })
          .catch((error) => {
            toast.current?.show({
              severity: 'error',
              summary: 'Liegenschaften konnten nicht geladen werden.',
              detail:
                'Leider konnten die Liegenschaften nicht vollständig geladen werden. Bitte aktualisieren Sie die Seite. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice. ',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          });
      } else {
        setNoPropertyInPhase(true);
      }
    }
  }, [phase]);

  useEffect(() => {
    const propertyId = searchParams.get('lId');
    //select Tab
    if (propertyId) {
      if (activeIndex !== 1) {
        setActiveIndex(1);
      }
    } else {
      if (activeIndex !== 0) {
        setActiveIndex(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  ///////////////////////
  /////// HELPER ////////
  ///////////////////////

  function setupEpLookup(catalog: EP) {
    if (catalog && catalog.data) {
      const lookup = new Map();
      catalog.data.forEach((e) => {
        if (e.epCode) {
          lookup.set(e.epCode, e);
        }
      });
      setEpLookup(lookup);
    }
  }

  /* media */
  function setupMediaLookups(mediaData: any) {
    const imgLookup = new Map();
    if (mediaData) {
      mediaData.forEach((entry: any) => {
        if (entry.image) {
          const image = translateImageToData(entry.image);
          if (image) {
            imgLookup.set(image.id, image);
          }
        }
      });
    }
    setImgLookup(imgLookup);
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  function injectPayload() {
    if (!phase || dataIsLoading) {
      return (
        <div className={'tabview-spinner'}>
          <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
        </div>
      );
    }
    if (noPropertyInPhase) {
      return (
        <div className={userParticipation ? '' : 'disabled'}>
          <NoProperties />
        </div>
      );
    }
    return (
      <div className={userParticipation ? '' : 'disabled'}>
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => {
            if (e.index === 0) {
              searchParams.delete('lId');
              setSearchParams(searchParams);
            } else if (e.index === 1) {
              //autoselect first property if selected tab is 1
              if (properties && properties.length > 0) {
                const lId = properties[0].id ? properties[0].id : 'no-prop';
                searchParams.set('lId', lId);
                setSearchParams(searchParams);
              }
            } else {
              setActiveIndex(e.index);
            }
          }}
        >
          <TabPanel header="Portfolioübersicht" leftIcon="icon-chart">
            {phase ? (
              <DLTabOverview
                phase={phase}
                properties={properties}
                imageLookup={imgLookup}
                epLookup={epLookup}
                epTree={epTree}
                lvs={lvs}
              />
            ) : null}
          </TabPanel>
          <TabPanel
            header="Nach Liegenschaften"
            disabled={properties && currentEpCatalog ? false : true}
            leftIcon="icon-company"
          >
            {phase && lvs && shortenLvTree ? (
              <DLTabProperty
                phase={phase}
                properties={properties}
                readOnly={readOnly}
                imageLookup={imgLookup}
                epLookup={epLookup}
                lvs={lvs}
                shortenLvTree={shortenLvTree}
              />
            ) : null}
          </TabPanel>
        </TabView>
      </div>
    );
  }

  return (
    <div className={'dl-overview-tab-view mt-5 '}>
      {injectPayload()}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}

const mapState = (state: RootState) => {
  return {
    userParticipation: state.app.userParticipation
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {};
};
export default connect(mapState, mapDispatchToProps)(DLOverviewTabView);
