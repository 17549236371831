import { PATH_DATENSCHUTZ, PATH_IMPRESSUM } from '../../Helper/Statics/Routes';
import './Footer.scss';

function Footer() {
  return (
    <div className={'footer'}>
      <a
        className={'bee-link bee-link-secondary'}
        href={PATH_IMPRESSUM}
        target="_blank"
        rel="noreferrer"
      >
        {'Impressum'}
      </a>
      <a
        className={'bee-link bee-link-secondary'}
        href={PATH_DATENSCHUTZ}
        target="_blank"
        rel="noreferrer"
      >
        {'Datenschutz'}
      </a>
    </div>
  );
}

export default Footer;
