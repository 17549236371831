// LOGIN
export const PATH_ROOT: string = '/';
export const PATH_LOGIN: string = '/login';
export const PATH_TWO_FACTOR: string = 'twofactor';
export const PATH_FORGOTPW: string = '/resetpw';
export const PATH_FORGOTPW_EMAIL: string = 'email';
export const PATH_FORGOTPW_PW: string = 'pw';

// WEITERE ÖFFENTLICHEN PAGES
export const PATH_DATENSCHUTZ: string = '/datenschutz';
export const PATH_IMPRESSUM: string = '/impressum';

//SECURED PATHS
export const PATH_DL_OVERVIEW: string = '/dl-overview';
export const PATH_DL_PRICEINPUT: string = '/dl-priceinput';
export const PATH_ALPHA_ANALYSIS: string = '/analysis';
export const PATH_ALPHA_PROPERTY: string = '/liegenschaften';
export const PATH_ALPHA_OPTIONS: string = '/options';
export const PATH_ALPHA_USER: string = '/user';
export const PATH_ALPHA_PROVIDER_ANALYSIS: string = '/provider';
export const PATH_ALPHA_PLACING: string = '/placing';
export const PATH_ALPHA_PHASES: string = '/phases';

export const PATH_INVALID_TYPE_ROOT: string = '/notype';

export const PATH_DETAIL_LIEGENSCHAFT: string = '/liegenschaften/detail';
