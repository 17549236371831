import _ from 'lodash';
import { TimelineStep } from '../../UI/Atoms/BeeTimeline';
import { extractUserId, isUserFromAlpha } from './JwtHelper';

export const STEP_IS_ACTIVE = 'ACTIVE';
export const STEP_WILL_BE_ACTIVE = 'STEP_WILL_BE_ACTIVE';
export const STEP_WAS_ACTIVE = 'STEP_WAS_ACTIVE';

export function compareTime(time1: any, time2: any) {
  return new Date(time1) < new Date(time2); // true if time1 is before
}

export function isBefore(time1: any, time2: Date) {
  return time2 < new Date(time1);
}

export function checkStepActivation(item: TimelineStep) {
  const currDate: any = new Date();
  if (compareTime(currDate, new Date(item.start))) {
    /* if current date is before startdate-> will be activated in future*/
    return STEP_WILL_BE_ACTIVE;
  } else {
    if (compareTime(new Date(item.end), currDate)) {
      /* abgelaufen */
      return STEP_WAS_ACTIVE;
    } else {
      return STEP_IS_ACTIVE;
    }
  }
}

export function checkUserParticipation(item: TimelineStep) {
  const userId = extractUserId();
  const isAlpha = isUserFromAlpha();
  if (isAlpha) {
    return true;
  } else {
    if (item.userIds && _.includes(item.userIds!, userId)) {
      return true;
    }
  }
  return false;
}
