import _ from 'lodash';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { useEffect, useState } from 'react';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  COUNTRIES,
  DEFAULT_CALCULATION_MULTIPLIER,
  DEFAULT_FACTOR,
  LeistungsSlices
} from '../../../Helper/Statics/Constants';
import {
  generateParticipationKey,
  simpleGenerateBlFactorId,
  simpleGenerateLsFactorId,
  simpleGenerateLsInMarktgebietId
} from '../../../Helper/Util/IdGeneratorHelper';
import BeeArrowDiff from '../../Atoms/BeeArrowDiff';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import { calculateDiff } from './AlphaDLVerlaufEPTable';
import './AlphaDLVerlaufFaktorTable.scss';
import BeeNumberInput from '../../Atoms/BeeNumberInput';

type AlphaDLVerlaufFaktorTableProps = {
  dataLoading: boolean;
  phases: TimelineStep[];
  properties: LiegenschaftsData[];
  valueLookup: Map<string, any>;
  providerId: string;
};

export default function AlphaDLVerlaufFaktorTable({
  dataLoading,
  phases,
  properties,
  valueLookup,
  providerId
}: AlphaDLVerlaufFaktorTableProps) {
  const [setupComplete, setSetupComplete] = useState<boolean>(false);
  const [blStructure, setBlStructure] = useState<any>(setupBlData());
  const [lsStructure, setLsStructure] = useState<any>(setupLsData());
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    setSetupComplete(dataLoading);
  }, [dataLoading]);

  useEffect(() => {
    let cols: any = [];
    if (phases) {
      phases.forEach((p: TimelineStep) => {
        cols.push({
          id: p.id,
          header: p.title,
          period:
            new Date(p.start).toLocaleDateString('de-DE') +
            ' - ' +
            new Date(p.end).toLocaleDateString('de-DE')
        });
      });
    }
    setColumns(cols);
  }, [phases]);

  ///////////////////////
  //////// LOGIC ////////
  ///////////////////////

  function setupLsData() {
    let pay: any = [];
    let allContainedRegions: string[] = [];
    const regions: Map<string, LiegenschaftsData[]> = new Map();
    for (let i = 0; i < properties.length; i++) {
      if (properties[i] && properties[i].adresse) {
        allContainedRegions.push(properties[i].adresse!.region);
        let data = regions.get(properties[i].adresse!.region);
        data = data ? data : [];
        data.push(properties[i]);
        regions.set(properties[i].adresse!.region, data);
      }
    }
    //minimize allContainedRegions and sort alphabetically
    allContainedRegions = _.sortBy(_.uniq(allContainedRegions));
    allContainedRegions.forEach((r) => {
      let rObj: any = {
        key: r,
        data: {
          type: 'region',
          title: r
        },
        children: []
      };
      let prArray = regions.get(r);
      prArray?.forEach((pr) => {
        let prObj: any = {
          key: pr.id,
          data: {
            type: 'property',
            title: pr.name
          },
          children: []
        };
        LeistungsSlices.forEach((sl) => {
          let curr: any = _.cloneDeep(sl);
          curr.propertyId = pr.id;
          curr.type = 'pos';
          prObj.children.push({
            key: sl.id,
            data: curr
          });
          if (sl.sustainability === true) {
            let currSus: any = _.cloneDeep(sl);
            console.log(currSus);
            currSus.propertyId = pr.id;
            currSus.title = currSus.title + ' > Nachhaltigkeit';
            currSus.type = 'pos';
            prObj.children.push({
              key: sl.id + '_sus',
              data: currSus
            });
          }
        });
        rObj.children.push(prObj);
      });
      pay.push(rObj);
    });
    return pay;
  }

  function setupBlData() {
    let pay: any = [];
    COUNTRIES.forEach((country) => {
      let cObj: any = {
        key: country.id,
        data: {
          type: 'cat_1',
          title: country.country
        },
        children: []
      };
      country.regions.forEach((r) => {
        let rObj: any = {
          key: r.id,
          data: {
            type: 'cat_2',
            title: r.region
          },
          children: []
        };
        LeistungsSlices.forEach((sl) => {
          let curr: any = _.cloneDeep(sl);
          curr.regionId = r.id;
          curr.type = 'pos';
          rObj.children.push({
            key: sl.id,
            data: curr
          });
        });
        cObj.children.push(rObj);
      });
      pay.push(cObj);
    });
    return pay;
  }

  function createBlFactorKey(node: any, phaseId: string) {
    if (node.data && node.data.type === 'pos') {
      const regionId: String = node.data.regionId;
      const sliceId: String = node.data.id;
      return simpleGenerateBlFactorId(phaseId, regionId, sliceId);
    }
    return '';
  }

  function createPrFactorKey(node: any, phaseId: String) {
    if (node.data && node.data.type === 'pos') {
      const propertyId: String = node.data.propertyId;
      const sliceId: String = node.data.id;
      if (_.endsWith(node.key, '_sus')) {
        return simpleGenerateLsFactorId(phaseId, propertyId, true, sliceId);
      }
      return simpleGenerateLsFactorId(phaseId, propertyId, false, sliceId);
    }
    return '';
  }

  function search(lookupKey: string) {
    return valueLookup.get(lookupKey);
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  const blFactorValueTemplate = (node: any, column: any) => {
    if (!setupComplete) {
      return <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />;
    }
    const phaseId: string = column.props.header.key;
    const phase: TimelineStep | undefined = _.find(phases, function (p) {
      return p.id === phaseId;
    });
    if (node.data) {
      if (node.data.type === 'pos' || node.data.type === 'cat_2') {
        //test user participation
        if (!phase || !_.includes(phase.userIds, providerId)) {
          return <div>Keine Teilnahme</div>;
        }
        //test offer rejected
        if (!search(generateParticipationKey(phaseId))) {
          return <div>Keine Abgabe</div>;
        } else if ('false' === search(generateParticipationKey(phaseId))) {
          return <div>Angebot zurückgezogen</div>;
        }
        //if position
        if (node.data.type === 'pos') {
          const v = search(createBlFactorKey(node, phaseId));
          let value = v ? parseInt(v) : DEFAULT_FACTOR;
          value = value ? value : DEFAULT_FACTOR;
          let rootValue = -1;
          let rootPId = null;
          let rootPTitle = null;
          for (let i = 0; i < columns.length; i++) {
            //search first valid participation
            const p: TimelineStep | undefined = _.find(phases, function (p) {
              return p.id === columns[i].id;
            });
            if (p && _.includes(p.userIds, providerId)) {
              if (search(generateParticipationKey(columns[i].id))) {
                if (
                  !('false' === search(generateParticipationKey(columns[i].id)))
                ) {
                  const tmp = search(createBlFactorKey(node, columns[i].id));
                  rootValue = tmp ? parseInt(tmp) : DEFAULT_FACTOR;
                  rootValue = rootValue ? rootValue : DEFAULT_FACTOR;
                  rootPId = columns[i].id;
                  rootPTitle = columns[i].header;
                  break;
                }
              }
            }
          }
          return (
            <div className={'alpha-dl-factorOverTime-blFac-value'}>
              <div className={'alpha-dl-factorOverTime-blFac-num'}>
                <BeeNumberInput
                  value={
                    value
                      ? _.divide(value, DEFAULT_CALCULATION_MULTIPLIER)
                      : value === 0
                      ? 0
                      : undefined
                  }
                  grouping={false}
                  readOnly={true}
                />
              </div>
              {rootPId ? (
                <BeeArrowDiff
                  value={calculateDiff(rootValue, value)}
                  tooltip={'In Bezug auf Phase: ' + rootPTitle}
                  disabled={false}
                  isRoot={phaseId === rootPId}
                />
              ) : null}
            </div>
          );
        } else if (node.data.type === 'cat_2') {
          let value: number = 0;
          let rootValue = 0;
          let rootPId = null;
          let rootPTitle = null;
          if (node.children) {
            let pointer = -1;
            //search first valid participation
            for (let i = 0; i < columns.length; i++) {
              const p: TimelineStep | undefined = _.find(phases, function (p) {
                return p.id === columns[i].id;
              });
              //search first valid participation
              if (p && _.includes(p.userIds, providerId)) {
                if (search(generateParticipationKey(columns[i].id))) {
                  if (
                    !(
                      'false' ===
                      search(generateParticipationKey(columns[i].id))
                    )
                  ) {
                    pointer = i;
                    break;
                  }
                }
              }
            }
            node.children.forEach((child: any) => {
              let val = search(createBlFactorKey(child, phaseId));
              value += val && parseInt(val) ? parseInt(val) : DEFAULT_FACTOR;
              if (pointer !== -1) {
                let val = search(createBlFactorKey(child, columns[pointer].id));
                rootValue +=
                  val && parseInt(val) ? parseInt(val) : DEFAULT_FACTOR;
                rootPId = columns[pointer].id;
                rootPTitle = columns[pointer].header;
              }
            });
          }
          return (
            <div className={'alpha-dl-factorOverTime-blFac-value'}>
              <div className={'alpha-dl-factorOverTime-blFac-num'}>
                <BeeNumberInput
                  value={
                    value
                      ? _.divide(
                          _.round(value / node.children.length),
                          DEFAULT_CALCULATION_MULTIPLIER
                        )
                      : value === 0
                      ? 0
                      : undefined
                  }
                  grouping={false}
                  readOnly={true}
                />
              </div>
              {rootPId ? (
                <BeeArrowDiff
                  value={calculateDiff(rootValue, value)}
                  tooltip={'In Bezug auf Phase: ' + rootPTitle}
                  disabled={false}
                  isRoot={phaseId === rootPId}
                />
              ) : null}
            </div>
          );
        }
      }
    }
    return <div />;
  };

  const propertyFactorValueTemplate = (node: any, column: any) => {
    if (!setupComplete) {
      return <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />;
    }
    const phaseId: string = column.props.header.key;
    const phase: TimelineStep | undefined = _.find(phases, function (p) {
      return p.id === phaseId;
    });
    if (node.data) {
      if (node.data.type === 'pos' || node.data.type === 'property') {
        //test property phase participation
        const propId =
          node.data.type === 'pos' ? node.data.propertyId : node.key;
        if (!phase || !_.includes(phase.liegenschaftIds, propId)) {
          return <div>Nicht in Phase</div>;
        }
        //test user participation
        if (!phase || !_.includes(phase.userIds, providerId)) {
          return <div>Keine Teilnahme</div>;
        }
        //test offer rejected
        if (!search(generateParticipationKey(phaseId))) {
          return <div>Keine Abgabe</div>;
        } else if ('false' === search(generateParticipationKey(phaseId))) {
          return <div>Angebot zurückgezogen</div>;
        }
        //test property marketArea
        if (
          valueLookup.get(simpleGenerateLsInMarktgebietId(phaseId, propId)) ===
          'false'
        ) {
          return <div>Nicht in Marktgebiet</div>;
        }
        if (node.data.type === 'pos') {
          const v = search(createPrFactorKey(node, phaseId));
          let value = v ? parseInt(v) : DEFAULT_FACTOR;
          value = value ? value : DEFAULT_FACTOR;
          let rootValue = -1;
          let rootPId = null;
          let rootPTitle = null;
          for (let i = 0; i < columns.length; i++) {
            //search first valid participation
            const p: TimelineStep | undefined = _.find(phases, function (p) {
              return p.id === columns[i].id;
            });
            if (p && _.includes(p.liegenschaftIds, propId)) {
              if (p && _.includes(p.userIds, providerId)) {
                if (search(generateParticipationKey(columns[i].id))) {
                  if (
                    !(
                      'false' ===
                      search(generateParticipationKey(columns[i].id))
                    )
                  ) {
                    if (
                      !(
                        valueLookup.get(
                          simpleGenerateLsInMarktgebietId(columns[i].id, propId)
                        ) === 'false'
                      )
                    ) {
                      const tmp = search(
                        createPrFactorKey(node, columns[i].id)
                      );
                      rootValue = tmp ? parseInt(tmp) : DEFAULT_FACTOR;
                      rootValue = rootValue ? rootValue : DEFAULT_FACTOR;
                      rootPId = columns[i].id;
                      rootPTitle = columns[i].header;
                      break;
                    }
                  }
                }
              }
            }
          }
          return (
            <div className={'alpha-dl-factorOverTime-blProp-value'}>
              <div className={'alpha-dl-factorOverTime-blProp-num'}>
                <BeeNumberInput
                  value={
                    value
                      ? _.divide(value, DEFAULT_CALCULATION_MULTIPLIER)
                      : value === 0
                      ? 0
                      : undefined
                  }
                  grouping={false}
                  readOnly={true}
                />
              </div>
              {rootPId ? (
                <BeeArrowDiff
                  value={calculateDiff(rootValue, value)}
                  tooltip={'In Bezug auf Phase: ' + rootPTitle}
                  disabled={false}
                  isRoot={phaseId === rootPId}
                />
              ) : null}
            </div>
          );
        } else if (node.data.type === 'property') {
          let value: number = 0;
          let rootValue = 0;
          let rootPId = null;
          let rootPTitle = null;
          if (node.children) {
            let pointer = -1;
            for (let i = 0; i < columns.length; i++) {
              //search first valid participation
              const p: TimelineStep | undefined = _.find(phases, function (p) {
                return p.id === columns[i].id;
              });
              if (p && _.includes(p.liegenschaftIds, propId)) {
                if (p && _.includes(p.userIds, providerId)) {
                  if (search(generateParticipationKey(columns[i].id))) {
                    if (
                      !(
                        'false' ===
                        search(generateParticipationKey(columns[i].id))
                      )
                    ) {
                      if (
                        !(
                          valueLookup.get(
                            simpleGenerateLsInMarktgebietId(
                              columns[i].id,
                              propId
                            )
                          ) === 'false'
                        )
                      ) {
                        pointer = i;
                        break;
                      }
                    }
                  }
                }
              }
            }
            node.children.forEach((child: any) => {
              let val = search(createPrFactorKey(child, phaseId));
              value += val && parseInt(val) ? parseInt(val) : DEFAULT_FACTOR;
              if (pointer !== -1) {
                let val = search(createPrFactorKey(child, columns[pointer].id));
                rootValue +=
                  val && parseInt(val) ? parseInt(val) : DEFAULT_FACTOR;
                rootPId = columns[pointer].id;
                rootPTitle = columns[pointer].header;
              }
            });
          }
          return (
            <div className={'alpha-dl-factorOverTime-blProp-value '}>
              <div className={'alpha-dl-factorOverTime-blProp-num'}>
                <BeeNumberInput
                  value={
                    value
                      ? _.divide(
                          _.round(value / node.children.length),
                          DEFAULT_CALCULATION_MULTIPLIER
                        )
                      : value === 0
                      ? 0
                      : undefined
                  }
                  grouping={false}
                  readOnly={true}
                />
              </div>
              {rootPId ? (
                <BeeArrowDiff
                  value={calculateDiff(rootValue, value)}
                  tooltip={'In Bezug auf Phase: ' + rootPTitle}
                  disabled={false}
                  isRoot={phaseId === rootPId}
                />
              ) : null}
            </div>
          );
        }
      }
    }
    return <div />;
  };

  const dynamicRegionColumns = columns
    ? columns.map((col: any, i: any) => {
        return (
          <Column
            key={col.id + '_' + col.header}
            style={{ width: '9em' }}
            className={'alpha-dl-factorOverTime-blFac-col'}
            body={blFactorValueTemplate}
            header={
              <div key={col.id}>
                <div>{col.header}</div>
                <div className={'phase-period'}>{col.period}</div>
              </div>
            }
          />
        );
      })
    : null;

  const dynamicPropertyColumns = columns
    ? columns.map((col: any, i: any) => {
        return (
          <Column
            key={col.id + '_' + col.header}
            style={{ width: '9em' }}
            className={'alpha-dl-factorOverTime-blProp-col'}
            body={propertyFactorValueTemplate}
            header={
              <div key={col.id}>
                {' '}
                <div>{col.header}</div>
                <div className={'phase-period'}>{col.period}</div>
              </div>
            }
          />
        );
      })
    : null;

  return (
    <div className={'alpha-dl-factorOverTime'}>
      <div className="mt-5">
        <BeeContentHeadline
          label={'Bundesländer'}
          headline={'h2'}
          type={'secondary'}
        />
        <div className="mb-3">
          Folgende Übersicht zeigt die Entwicklung der Bundeslandfaktoren des
          ausgewählten Dienstleisters über die Angebotsphasen hinweg. Von dem
          Dienstleister nicht angegebene Faktoren werden mit 1,0 (100%)
          ausgewertet.
        </div>

        <TreeTable
          value={blStructure}
          scrollable
          style={{ width: '100%' }}
          className={'alpha-dl-factorOverTime-table'}
        >
          <Column
            field="title"
            header="Bundesland"
            className={'alpha-dl-factorOverTime-bl-col frozen-col'}
            expander
            style={{ width: '20em' }}
          />
          {dynamicRegionColumns}
        </TreeTable>
      </div>
      <div className="mt-6">
        <BeeContentHeadline
          label={'Liegenschaften'}
          headline={'h2'}
          type={'secondary'}
        />
        <div className="mb-3">
          Folgende Übersicht zeigt die Entwicklung der Liegenschaftsfaktoren des
          ausgewählten Dienstleisters über die Angebotsphasen hinweg. Von dem
          Dienstleister nicht angegebene Faktoren werden mit 1,0 (100%)
          ausgewertet.
        </div>
        <TreeTable
          value={lsStructure}
          scrollable
          style={{ width: '100%' }}
          className={'alpha-dl-factorOverTime-prop-table'}
        >
          <Column
            field="title"
            header="Liegenschaft"
            expander
            style={{ width: '20em' }}
            className={'alpha-dl-factorOverTime-prop-col frozen-col'}
          />
          {dynamicPropertyColumns}
        </TreeTable>
      </div>
    </div>
  );
}
