import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  callResetPw,
  callSendForgotPwMail
} from '../../Helper/ApiHelper/LoginNetworkHelper';
import { pwChangedToast } from '../../Helper/Statics/Constants';
import {
  PATH_FORGOTPW_EMAIL,
  PATH_FORGOTPW_PW,
  PATH_LOGIN
} from '../../Helper/Statics/Routes';

import ForgotPwForm from '../Organisms/Login/ForgotPwForm';
import ResetPwForm from '../Organisms/Login/ResetPwForm';

import './ForgotPwPage.scss';

function ForgotPwPage() {
  //component state
  const [sendForgotPwEmailPending, setSendForgotPwEmailPending] =
    useState(false);
  const [sendForgotPwEmailError, setSendForgotPwEmailError] = useState('');

  const [resetPwPending, setResetPwPending] = useState(false);
  const [resetPwError, setResetPwError] = useState('');

  //routing
  let navigate = useNavigate();
  const { route } = useParams();

  function redirectToLogin(): void {
    navigate(PATH_LOGIN);
  }

  function injectLogin(): JSX.Element {
    return <Navigate to={PATH_LOGIN} />;
  }
  function sendResetPwMail(email: string): void {
    setSendForgotPwEmailError('');
    setSendForgotPwEmailPending(true);

    callSendForgotPwMail(email)
      .then(() => {
        setSendForgotPwEmailPending(false);
      })
      .catch((error: string) => {
        setSendForgotPwEmailError(error);
        setSendForgotPwEmailPending(false);
      });
  }

  function resetPw(token: string, password: string): void {
    setResetPwError('');
    setResetPwPending(true);

    callResetPw(token, password)
      .then(() => {
        setResetPwPending(false);
        //navigate to login and show toast
        navigate(PATH_LOGIN, { state: { showToast: pwChangedToast } });
      })
      .catch((error: string) => {
        setResetPwError(error);
        setResetPwPending(false);
      });
  }

  return (
    <>
      <div className="forgot-pw-page">
        {route === PATH_FORGOTPW_EMAIL ? (
          <ForgotPwForm
            showProgress={sendForgotPwEmailPending}
            sendResetPwMailError={sendForgotPwEmailError}
            onSendResetPw={(email) => sendResetPwMail(email)}
            onRedirectToLogin={() => redirectToLogin()}
          />
        ) : route === PATH_FORGOTPW_PW ? (
          <ResetPwForm
            showProgress={resetPwPending}
            resetPwError={resetPwError}
            onResetPw={(token, password) => {
              resetPw(token, password);
            }}
            onRedirectToLogin={() => redirectToLogin()}
          />
        ) : (
          injectLogin()
        )}
      </div>
    </>
  );
}

export default ForgotPwPage;
