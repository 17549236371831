import { Route, Routes } from 'react-router-dom';
import {
  PATH_ALPHA_ANALYSIS,
  PATH_ALPHA_OPTIONS,
  PATH_ALPHA_PHASES,
  PATH_ALPHA_PLACING,
  PATH_ALPHA_PROPERTY,
  PATH_ALPHA_PROVIDER_ANALYSIS,
  PATH_ALPHA_USER,
  PATH_DATENSCHUTZ,
  PATH_DL_OVERVIEW,
  PATH_DL_PRICEINPUT,
  PATH_FORGOTPW,
  PATH_IMPRESSUM,
  PATH_INVALID_TYPE_ROOT,
  PATH_LOGIN,
  PATH_ROOT
} from '../Helper/Statics/Routes';
import Footer from '../UI/Organisms/Footer';
import AlphaOverviewPage from '../UI/Pages/AlphaPages/AlphaOverviewPage';
import Datenschutz from '../UI/Pages/Datenschutz';
import DLOverviewPage from '../UI/Pages/DLPages/DLOverviewPage';
import DLPriceInputPage from '../UI/Pages/DLPages/DLPriceInputPage';
import Error404Page from '../UI/Pages/Error404Page';
import ForgotPwPage from '../UI/Pages/ForgotPwPage';
import Impressum from '../UI/Pages/Impressum';
import InvalidUserTypePage from '../UI/Pages/InvalidUserTypePage';
import LoginPage from '../UI/Pages/LoginPage';
import MainPage from '../UI/Pages/MainPage';

import AlphaDlZeitverlaufPage from '../UI/Pages/AlphaPages/AlphaDlZeitverlaufPage';
import AlphaLiegenschaftPage from '../UI/Pages/AlphaPages/AlphaLiegenschaftPage';
import AlphaLiegenschaftVerwaltung from '../UI/Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import AlphaOptionsPage from '../UI/Pages/AlphaPages/AlphaOptionsPage';
import AlphaPhasesPage from '../UI/Pages/AlphaPages/AlphaPhasesPage';
import AlphaUserPage from '../UI/Pages/AlphaPages/AlphaUserPage';
import AlphaVergabeAnsicht from '../UI/Pages/AlphaPages/AlphaVergabeAnsicht';
import './App.scss';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Login + Twofactor */}
        <Route path={PATH_LOGIN} element={<LoginPage />}>
          <Route path={':route'} element={<LoginPage />} />
        </Route>
        {/* Send forgot pw link + reset pw */}
        <Route path={PATH_FORGOTPW} element={<ForgotPwPage />}>
          <Route path={':route'} element={<ForgotPwPage />} />
        </Route>

        {/* Impressum + Datenschutz */}
        <Route path={PATH_IMPRESSUM} element={<Impressum />} />
        <Route path={PATH_DATENSCHUTZ} element={<Datenschutz />} />
        {/* securedRoutes */}
        <Route path={PATH_ROOT} element={<MainPage />}>
          {/* dl routes */}
          <Route path={PATH_DL_PRICEINPUT} element={<DLPriceInputPage />} />
          <Route path={PATH_DL_OVERVIEW} element={<DLOverviewPage />} />
          {/* alpha routes */}
          <Route path={PATH_ALPHA_ANALYSIS} element={<AlphaOverviewPage />} />
          <Route path={PATH_ALPHA_OPTIONS} element={<AlphaOptionsPage />} />
          <Route path={PATH_ALPHA_USER} element={<AlphaUserPage />} />
          <Route path={PATH_ALPHA_PHASES} element={<AlphaPhasesPage />} />
          <Route
            path={PATH_ALPHA_PROPERTY}
            /*  element={<AlphaLiegenschaftVerwaltung />} */
          >
            <Route
              path={'/liegenschaften/'}
              element={<AlphaLiegenschaftVerwaltung />}
            />
            <Route path={':route'} element={<AlphaLiegenschaftPage />} />
          </Route>
          {/*   <Route
            path={PATH_DETAIL_LIEGENSCHAFT}
            element={<AlphaLiegenschaftPage />}
          >
            <Route path={':route'} element={<AlphaLiegenschaftPage />} />
          </Route> */}
          <Route
            path={PATH_ALPHA_PROVIDER_ANALYSIS}
            element={<AlphaDlZeitverlaufPage />}
          />
          <Route path={PATH_ALPHA_PLACING} element={<AlphaVergabeAnsicht />} />

          {/* invalid type page */}
          <Route
            path={PATH_INVALID_TYPE_ROOT}
            element={<InvalidUserTypePage />}
          />
        </Route>
        {/* fallback */}

        <Route path="*" element={<Error404Page />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
