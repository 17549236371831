import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { errorToMsg } from '../../../Helper/ApiHelper/ErrorMessages';
import {
  downloadAvailableBaualtersstufen,
  downloadAvailableEnergietypen,
  downloadAvailableGebaeudetypen,
  downloadAvailableHauptbetriebstage,
  downloadAvailableKlimatisierungen,
  downloadAvailableTechnisierungsgrade,
  GebaeudeData,
  LiegenschaftsData,
  readGebaeudeById
} from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  extractAllMediaIds,
  translateAlphaMediaIds,
  translateMediaIds
} from '../../../Helper/ApiHelper/MediaNetworkHelper';
import {
  DEFAULT_IMAGE_SIZE,
  DURATION_NOTIFICATION_ERROR_LONG,
  URL_TO_MEDIA_SERVER
} from '../../../Helper/Statics/Constants';
import { ENTER_KEY, ESCAPE_KEY } from '../../../Helper/Statics/KeyCodes';
import { extractUserId, isUserFromAlpha } from '../../../Helper/Util/JwtHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import { DocType, ImgType } from '../../Pages/DLPages/DLPriceInputPage';

import _ from 'lodash';

import BeeButton from '../../Atoms/BeeButton';
import { GebaeudeImgs } from '../../Pages/AlphaPages/AlphaLiegenschaftPage';
import './LiegenschaftDialog.scss';

import { generateZipName } from '../../../Helper/StorageHelper/FilenameHelper';
import AlphaGebaeudeForm from '../../Organisms/Alpha/AlphaGebaeudeForm';
import AlphaAdressForm from '../Alpha/AlphaAdressForm';
import AlphaLiegenschaftGDForm, {
  LiegenschaftsGrunddatenType
} from '../Alpha/AlphaLiegenschaftGDForm';
import AlphaMediaForm from '../Alpha/AlphaMediaForm';

type LiegenschaftDialogProps = {
  property?: LiegenschaftsData;
  visible: boolean;
  onHide: () => void;
};

export default function LiegenschaftDialog({
  property,
  visible,
  onHide
}: LiegenschaftDialogProps) {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [gebaeudeListe, setGebaeudeListe] = useState<GebaeudeData[]>();

  //lookups images, documents
  const [imgLookup, setImgLookup] = useState(new Map());
  const [docLookup, setDocLookup] = useState(new Map());
  const [liegenschaftImgs, setLiegenschaftImgs] = useState<ImgType[] | null>();
  const [liegenschaftDocs, setLiegenschaftDocs] = useState<DocType[] | null>();
  const [gebaeudeImgs, setGebaeudeImgs] = useState<GebaeudeImgs[] | null>();
  const [gebaeudeDocs, setGebaeudeDocs] = useState<any>();
  // const [gebaeudeDocs, setGebaeudeDocs] = useState<GebaeudeDocs[] | null>();

  //Dropdowns
  const [allBaualtersstufen, setAllBaualtersstufen] = useState<any>();
  const [allHauptbetriebstage, setAllHauptbetriebstage] = useState<any>();
  const [allTechnisierungsgrade, setAllTechnisierungsgrade] = useState<any>();
  const [allKlimatisierungen, setAllKlimatisierungen] = useState<any>();
  const [allGebaeudetypen, setAllGebaeudetypen] = useState<any>();
  const [allEnergiesteckbriefTypen, setAllEnergiesteckbriefTypen] =
    useState<any>();

  //refs
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (property) {
      if (
        property.id &&
        property.gebaeudeIds &&
        property.gebaeudeIds.length > 0
      ) {
        let gebaeude: any[] = [];
        for (let i = 0; i < property.gebaeudeIds.length; i++) {
          gebaeude.push(readGebaeudeById(property.id, property.gebaeudeIds[i]));
        }
        Promise.all(gebaeude)
          .then((values) => {
            setGebaeudeListe(values);
            //call all mediaIds
            const mediaIds = extractAllMediaIds(property, values);
            translateMediaIds(mediaIds, extractUserId())
              .then((data) => {
                setupMediaLookups(data);
              })
              .catch((error) => {
                if (toast && toast.current) {
                  toast.current.show({
                    severity: 'error',
                    summary:
                      'Bilder konnten nicht geladen werden ' +
                      errorToMsg(error),
                    detail:
                      'Leider konnten die Bilder nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              });
            console.log('setdataisloading false');
            setDataIsLoading(false);
          })
          .catch((error) => {
            //not all geb loaded
            console.log('setdataisloading false');
            setDataIsLoading(false);
            if (toast && toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Nicht alle Gebäude konnten geladen werden ',
                detail:
                  'Leider konnten die Gebäude nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          });
      } else {
        //call all mediaIds
        const mediaIds = extractAllMediaIds(property, null);
        translateMediaIds(mediaIds, extractUserId())
          .then((data) => {
            setupMediaLookups(data);
          })
          .catch((error) => {
            if (toast && toast.current) {
              toast.current.show({
                severity: 'error',
                summary:
                  'Bilder konnten nicht geladen werden ' + errorToMsg(error),
                detail:
                  'Leider konnten die Bilder nicht vollständig geladen werden. Bitte versuchen Sie es später erneut.',
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          });
        setDataIsLoading(false);
      }
    } else {
      console.log('setdataisloading false');
      setDataIsLoading(false);
      if (toast && toast.current) {
        toast.current.show({
          severity: 'error',
          summary: 'Die Liegenschaft konnte nicht geladen werden ',
          detail:
            'Leider konnten die Liegenschaft nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und aktualisieren Sie die Seite. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        });
      }
    }

    downloadAvailableBaualtersstufen()
      .then((data) => {
        const baualtersstufenSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            baualtersstufenSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllBaualtersstufen(baualtersstufenSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Baualtersstufen konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Baualtersstufen nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    downloadAvailableHauptbetriebstage()
      .then((data) => {
        const hauptBestriebstageSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            hauptBestriebstageSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllHauptbetriebstage(hauptBestriebstageSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Hauptbetriebstage konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Hauptbetriebstage nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    downloadAvailableTechnisierungsgrade()
      .then((data) => {
        const technisierungsgradeSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            technisierungsgradeSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllTechnisierungsgrade(technisierungsgradeSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Technisierungsgrade konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Technisierungsgrade nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    downloadAvailableKlimatisierungen()
      .then((data) => {
        const klimatisierungenSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            klimatisierungenSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllKlimatisierungen(klimatisierungenSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Klimatisierungen konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Klimatisierungen nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    downloadAvailableGebaeudetypen()
      .then((data) => {
        const gebaeudetypenSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            gebaeudetypenSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllGebaeudetypen(gebaeudetypenSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Gebäudetypen konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Gebäudetypen nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    downloadAvailableEnergietypen()
      .then((data) => {
        const energietypenSelect = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            energietypenSelect.push({
              label: data[i],
              value: data[i]
            });
          }
        }
        setAllEnergiesteckbriefTypen(energietypenSelect);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Die Energietypen konnte nicht geladen werden',
            detail:
              'Leider konnten die verfügbaren Energietypen nicht vom Server geladen werden. Bitte prüfen Sie Ihre Internetverbindung. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
  }, []);

  function hide() {
    if (onHide) {
      onHide();
    }
  }

  function onKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if ((e.key === ESCAPE_KEY && visible) || (e.key === ENTER_KEY && visible)) {
      hide();
    }
  }

  /* media */
  function setupMediaLookups(mediaData: any) {
    const imgLookup = new Map();
    const docLookup = new Map();
    if (mediaData) {
      mediaData.forEach((entry: any) => {
        if (entry.image) {
          const image = entry.image;
          imgLookup.set(image.id, image);
        } else if (entry.document) {
          const doc = entry.document;
          docLookup.set(doc.id, doc);
        }
      });
    }

    setImgLookup(imgLookup);
    setDocLookup(docLookup);
    //reset media caches
    setLiegenschaftImgs(null);
    setLiegenschaftDocs(null);
    setGebaeudeImgs(null);
    setGebaeudeDocs(null);
  }

  function getLiegenschaftImgs() {
    if (liegenschaftImgs) {
      return liegenschaftImgs;
    } else if (property && imgLookup) {
      const imgArray = [] as ImgType[];
      //search all images
      const imgIds = [];
      if (property) {
        if (property.hauptBildId) {
          imgIds.push(property.hauptBildId);
        }
        if (property.mediaIds) {
          property.mediaIds.forEach((entry: string) => {
            if (imgLookup.has(entry)) {
              imgIds.push(entry);
            }
          });
        }
        //convert into images
        imgIds.forEach(function (imageId) {
          const entry = imgLookup.get(imageId);
          let version = null;
          if (entry) {
            for (let i = 0; i < entry.versions.length; i++) {
              const v = entry.versions[i];
              //default to first version
              if (!version) {
                version = v;
              }
              //search for medium pic
              if (v && v.vLabel) {
                if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                  version = v;
                }
              }
            }
            if (version) {
              imgArray.push({
                id: entry.id,
                url: URL_TO_MEDIA_SERVER + version.url,
                key: version.access,
                alt: '',
                title: entry.title,
                copyright: entry.copyright,
                secured: version.access ? true : false,
                showPlaceholder: false
              });
            }
          }
        });
      }
      setLiegenschaftImgs(imgArray);
      return imgArray;
    } else {
      return [];
    }
  }

  function getLiegenschaftDocs() {
    if (liegenschaftDocs) {
      const currLiegenschaftenDocs = _.sortBy(liegenschaftDocs, [
        function (o: DocType) {
          return o.timestamp;
        }
      ]);
      return currLiegenschaftenDocs;
    } else if (property && docLookup) {
      let docArray = [] as DocType[];
      //search all documents
      const docIds = [];
      if (property) {
        if (property.hauptPlanId) {
          docIds.push(property.hauptPlanId);
        }
        if (property.mediaIds) {
          property.mediaIds.forEach((entry: string) => {
            if (docLookup.has(entry)) {
              docIds.push(entry);
            }
          });
        }
        //convert to documents
        docIds.forEach(function (docId) {
          const entry = docLookup.get(docId);
          if (entry) {
            docArray.push({
              id: entry.id,
              title: entry.title,
              filename: entry.filename,
              fileSrc: URL_TO_MEDIA_SERVER + entry.url,
              timestamp: new Date(entry.createdAt),
              size: entry.fileSize,
              type: entry.type,
              copyright: entry.copyright,
              secured: entry.access ? true : false,
              fileKey: entry.access
            });
          }
        });
      }

      docArray = _.sortBy(docArray, [
        function (o: DocType) {
          return o.timestamp;
        }
      ]);
      setLiegenschaftDocs(docArray);
      return docArray;
    } else {
      return [];
    }
  }

  function getGebaeudeImgs(gebaeude: GebaeudeData) {
    if (gebaeudeImgs) {
      let indexId = null;
      let indexTempId = null;
      indexId = _.findIndex(gebaeudeImgs, function (geb: GebaeudeImgs) {
        return geb.id === gebaeude.id;
      });
      indexTempId = _.findIndex(gebaeudeImgs, function (geb: GebaeudeImgs) {
        return geb.tempId === gebaeude.tempId;
      });

      if (indexId >= 0) {
        return gebaeudeImgs[indexId].images;
      } else if (indexTempId >= 0) {
        return gebaeudeImgs[indexTempId].images;
      } else if (gebaeude && imgLookup) {
        let imgArray = {
          id: gebaeude.id ? gebaeude.id : null,
          tempId: gebaeude.tempId ? gebaeude.tempId : null,
          images: buildImgArrayForGeb(gebaeude)
        };

        const currGebImages: any = gebaeudeImgs
          ? _.cloneDeep(gebaeudeImgs)
          : [];
        currGebImages.push(imgArray);

        setGebaeudeImgs(currGebImages);
        return imgArray;
      } else {
        return [];
      }
    } else if (gebaeude && imgLookup) {
      let imgArray = {
        id: gebaeude.id ? gebaeude.id : null,
        tempId: gebaeude.tempId ? gebaeude.tempId : null,
        images: buildImgArrayForGeb(gebaeude)
      };

      const currGebImages: any = gebaeudeImgs ? _.cloneDeep(gebaeudeImgs) : [];
      currGebImages.push(imgArray);

      setGebaeudeImgs(currGebImages);
      return imgArray;
    } else {
      return [];
    }
  }

  function buildImgArrayForGeb(gebaeude: GebaeudeData) {
    const imgArray = [] as ImgType[];
    //search all images
    const imgIds = [];
    if (gebaeude) {
      if (gebaeude.hauptBildId) {
        imgIds.push(gebaeude.hauptBildId);
      }
      if (gebaeude.mediaIds) {
        gebaeude.mediaIds.forEach((entry) => {
          if (imgLookup.has(entry)) {
            imgIds.push(entry);
          }
        });
      }
      //convert into images
      imgIds.forEach(function (imageId) {
        const entry = imgLookup.get(imageId);
        let version = null;
        if (entry) {
          for (let i = 0; i < entry.versions.length; i++) {
            const v = entry.versions[i];
            //default to first version
            if (!version) {
              version = v;
            }
            //search for medium pic
            if (v && v.vLabel) {
              if (v.vLabel === DEFAULT_IMAGE_SIZE) {
                version = v;
              }
            }
          }
          if (version) {
            imgArray.push({
              id: entry.id,
              url: URL_TO_MEDIA_SERVER + version.url,
              key: version.access,
              alt: '',
              title: entry.title,
              copyright: entry.copyright,
              secured: version.access ? true : false,
              showPlaceholder: false
            });
          }
        }
      });
    }
    return imgArray;
  }

  function getGebaeudeDocs(gebaeude: GebaeudeData) {
    if (gebaeudeDocs) {
      let indexId = null;
      let indexTempId = null;
      indexId = _.findIndex(
        gebaeudeDocs,
        function (geb: { id?: string; tempId?: string; documents?: DocType }) {
          return geb.id === gebaeude.id;
        }
      );
      indexTempId = _.findIndex(
        gebaeudeDocs,
        function (geb: { id?: string; tempId?: string; documents?: DocType }) {
          return geb.tempId === gebaeude.tempId;
        }
      );

      if (indexId >= 0) {
        const currGebDocs = _.sortBy(gebaeudeDocs[indexId].documents, [
          function (o: DocType) {
            return o.timestamp;
          }
        ]);
        return currGebDocs;
      } else if (indexTempId >= 0) {
        const currGebDocs = _.sortBy(gebaeudeDocs[indexTempId].documents, [
          function (o: DocType) {
            return o.timestamp;
          }
        ]);
        return currGebDocs;
      } else if (gebaeude && docLookup) {
        let docArray = {
          id: gebaeude.id ? gebaeude.id : null,
          tempId: gebaeude.tempId ? gebaeude.tempId : null,
          documents: createDocArrayForGeb(gebaeude)
        };

        const currGebDocs: any = gebaeudeDocs ? _.cloneDeep(gebaeudeDocs) : [];
        currGebDocs.push(docArray);

        setGebaeudeDocs(currGebDocs);
        return docArray.documents;
      } else {
        return [];
      }
    } else if (gebaeude && docLookup) {
      let docArray = {
        id: gebaeude.id ? gebaeude.id : null,
        tempId: gebaeude.tempId ? gebaeude.tempId : null,
        documents: createDocArrayForGeb(gebaeude)
      };

      const currGebDocs: any = gebaeudeDocs ? _.cloneDeep(gebaeudeDocs) : [];
      currGebDocs.push(docArray);

      setGebaeudeDocs(currGebDocs);
      return docArray.documents;
    } else {
      return [];
    }
  }

  function createDocArrayForGeb(gebaeude: GebaeudeData) {
    const docArray = [] as DocType[];
    //search all documents
    const docIds = [];
    if (gebaeude) {
      if (gebaeude.hauptPlanId) {
        docIds.push(gebaeude.hauptPlanId);
      }
      if (gebaeude.mediaIds) {
        gebaeude.mediaIds.forEach((entry) => {
          if (docLookup.has(entry)) {
            docIds.push(entry);
          }
        });
      }
      //convert to documents
      docIds.forEach(function (docId) {
        const entry = docLookup.get(docId);
        if (entry) {
          docArray.push({
            id: entry.id,
            title: entry.title,
            filename: entry.filename,
            fileSrc: URL_TO_MEDIA_SERVER + entry.url,
            timestamp: new Date(entry.createdAt),
            size: entry.fileSize,
            type: entry.type,
            copyright: entry.copyright,
            secured: entry.access ? true : false,
            fileKey: entry.access
          });
        }
      });
    }
    return docArray;
  }

  /* View components */

  function renderLiegenschaftHeadline() {
    return (
      <div className={'headline'}>
        <BeeContentHeadline
          label={property ? 'Liegenschaft: ' + property.name : 'Liegenschaft'}
          headline={'h1'}
          type={'primary'}
        />

        <div className={dataIsLoading ? 'visibleSpinner' : 'invisibleSpinner'}>
          <BeeLoadingSpinner strokeWidth={'3'} type={'secondary'} />
        </div>
      </div>
    );
  }

  function renderLiegenschaftGrunddaten() {
    let liegenschaftGD = {} as LiegenschaftsGrunddatenType;
    if (property) {
      liegenschaftGD = {
        nummer: property.nummer ? property.nummer : '',
        name: property.name ? property.name : '',
        beschreibung: property.beschreibung ? property.beschreibung : '',
        anmerkung: property.anmerkung ? property.anmerkung : '',
        eigentuemer: property.eigentuemer ? property.eigentuemer : '',
        verantwortlicher: property.verantwortlicher
          ? property.verantwortlicher
          : ''
      };
    }
    return (
      <>
        <AlphaLiegenschaftGDForm
          data={liegenschaftGD}
          disabled={true}
          readOnly={true}
        />
      </>
    );
  }

  function renderLiegenschaftMedia() {
    return (
      <>
        <AlphaMediaForm
          images={getLiegenschaftImgs()}
          documents={getLiegenschaftDocs()}
          readOnly={true}
          downloadFolderName={
            property && property.name
              ? generateZipName('Dokumente_' + property.name)
              : 'Dokumente'
          }
        />
      </>
    );
  }

  function renderLiegenschaftAdresse() {
    return (
      <>
        <AlphaAdressForm
          data={property && property.adresse ? property.adresse : undefined}
          disabled={true}
          readOnly={true}
        />
      </>
    );
  }

  function renderGebaeudeHeadline() {
    return (
      <BeeContentHeadline label={'Gebäude'} headline={'h1'} type={'primary'} />
    );
  }

  function renderAccordionTab(gebaeude: any, index: number) {
    let gebId = gebaeude ? gebaeude.id : null;

    return (
      <AccordionTab
        className={'geb'}
        header={gebaeude && gebaeude.name ? gebaeude.name : 'Neues Gebäude '}
        key={'lsDialog_geb_' + gebId + '_' + index}
      >
        <AlphaGebaeudeForm
          data={gebaeude}
          imagesGebaeude={gebaeude ? getGebaeudeImgs(gebaeude) : []} //fixme
          documentsGebaeude={getGebaeudeDocs(gebaeude)} //fixme
          baualtersstufenOptions={allBaualtersstufen}
          betriebstageOptions={allHauptbetriebstage}
          technisierungsgradeOptions={allTechnisierungsgrade}
          klimatisierungenOptions={allKlimatisierungen}
          gebaeudetypenOptions={allGebaeudetypen}
          energieTypenOptions={allEnergiesteckbriefTypen}
          disabled={true}
          readOnly={true}
        ></AlphaGebaeudeForm>
      </AccordionTab>
    );
  }

  function renderGebaeudeAkkordeon() {
    const accTabs =
      gebaeudeListe && gebaeudeListe.length > 0
        ? gebaeudeListe.map((gebaeude: any, i: number) =>
            renderAccordionTab(gebaeude, i)
          )
        : null;
    return (
      <>
        {gebaeudeListe && gebaeudeListe.length > 0 ? (
          <Accordion multiple>{accTabs}</Accordion>
        ) : (
          <div>Keine Gebäude vorhanden.</div>
        )}
      </>
    );
  }

  const injectButtonRow = () => {
    return (
      <div className={'lsDialog-btns'}>
        <BeeButton
          label={'Schließen'}
          disabled={false}
          type={'primary'}
          onClick={() => hide()}
        />
      </div>
    );
  };

  return (
    <div onKeyDown={(e) => onKeyPress(e)}>
      <Dialog
        header={renderLiegenschaftHeadline()}
        visible={visible}
        className={'dl-liegenschaft-dialog'}
        modal={true}
        onHide={() => hide()}
      >
        <div>
          <div className={'grid'}>
            <div
              className={'col-12 sm:col-12 md:col-12 lg:col-12 xl:col-6 lsGd'}
            >
              {renderLiegenschaftGrunddaten()}
            </div>

            <div
              className={
                'col-12 sm:col-12 md:col-12 lg:col-12 xl:col-6 lsAdresse'
              }
            >
              {renderLiegenschaftAdresse()}
            </div>
          </div>
          <div className={'lsMedia'}>{renderLiegenschaftMedia()}</div>
          <div className={'liegenschaft-gebaeude-form'}>
            <div>{renderGebaeudeHeadline()}</div>
            <>{renderGebaeudeAkkordeon()}</>
          </div>
          <div>{injectButtonRow()}</div>
        </div>
      </Dialog>
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
