import { useEffect } from 'react';
import {
  Adresse,
  GebaeudeData,
  GebaeudeEnergiesteckbrief
} from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';

import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';

import './AlphaGebaeudeForm.scss';

import { generateZipName } from '../../../Helper/StorageHelper/FilenameHelper';
import AlphaAdressForm from '../../Molecules/Alpha/AlphaAdressForm';
import AlphaGebaeudeEnergieForm from '../../Molecules/Alpha/AlphaGebaeudeEnergieForm';
import AlphaGebaeudeGDForm, {
  GebaeudeGrunddaten
} from '../../Molecules/Alpha/AlphaGebaeudeGDForm';
import AlphaGebaeudeGebDatenForm, {
  GebaeudeGebData
} from '../../Molecules/Alpha/AlphaGebaeudeGebDatenForm';
import AlphaMediaForm from '../../Molecules/Alpha/AlphaMediaForm';

type AlphaGebaeudeGDFormProps = {
  data: GebaeudeData;
  isErrorGebaeudeName?: boolean;
  isErrorGebaeudeNummer?: boolean;
  isErrorGebaeudeStrasse?: boolean;
  isErrorGebaeudeHausnr?: boolean;
  isErrorGebaeudePlz?: boolean;
  isErrorGebaeudeStadt?: boolean;
  isErrorGebaeudeRegion?: boolean;
  isErrorGebaeudeLand?: boolean;
  imagesGebaeude: any; //Fixme
  documentsGebaeude: any; //Fixme
  baualtersstufenOptions: Dropdown;
  betriebstageOptions: Dropdown;
  technisierungsgradeOptions: Dropdown;
  klimatisierungenOptions: Dropdown;
  gebaeudetypenOptions: Dropdown;
  energieTypenOptions: Dropdown;
  disabled: boolean;
  readOnly: boolean;
  onDeleteImage?: (itemId: string) => void;
  onAddImage?: () => void;
  onDeleteDocument?: (itemId: string) => void;
  onAddDocument?: () => void;
  onChangeGebaeude?: (dataset: any) => void;
  onDeleteGebaeude?: () => void;
};

export type Dropdown = { label: string; value: string; newLabel?: string }[];

function AlphaGebaeudeForm({
  data, //fullgeb
  isErrorGebaeudeName,
  isErrorGebaeudeNummer,
  isErrorGebaeudeStrasse,
  isErrorGebaeudeHausnr,
  isErrorGebaeudePlz,
  isErrorGebaeudeStadt,
  isErrorGebaeudeRegion,
  isErrorGebaeudeLand,
  imagesGebaeude,
  documentsGebaeude,
  baualtersstufenOptions,
  betriebstageOptions,
  technisierungsgradeOptions,
  klimatisierungenOptions,
  gebaeudetypenOptions,
  energieTypenOptions,
  disabled,
  readOnly,
  onDeleteImage,
  onAddImage,
  onDeleteDocument,
  onAddDocument,
  onChangeGebaeude,
  onDeleteGebaeude
}: AlphaGebaeudeGDFormProps) {
  let gebaeudeDataToSave = {} as GebaeudeData;
  let newGebaeudeGD: GebaeudeGrunddaten | undefined = {} as GebaeudeGrunddaten;
  let newGebaeudeAdresse: Adresse | undefined = {} as Adresse;
  let newGebaeudeMedia: any = {};
  let newGebaeudeGebData: GebaeudeGebData | undefined = {} as GebaeudeGebData;
  let newEnergiesteckbrief: GebaeudeEnergiesteckbrief | undefined =
    {} as GebaeudeEnergiesteckbrief;

  useEffect(() => {
    if (data) {
      gebaeudeDataToSave = data;
      newGebaeudeGD = undefined;
      newGebaeudeAdresse = undefined;
      newGebaeudeMedia = undefined;
      newGebaeudeGebData = undefined;
      newEnergiesteckbrief = undefined;
    }
  }, [data]);

  function deleteImage(id: string) {
    if (onDeleteImage) {
      onDeleteImage(id);
    }
  }
  function addImage() {
    if (onAddImage) {
      onAddImage();
    }
  }

  function deleteDocument(id: string) {
    if (onDeleteDocument) {
      onDeleteDocument(id);
    }
  }
  function addDocument() {
    if (onAddDocument) {
      onAddDocument();
    }
  }

  function deleteGebaeude() {
    if (onDeleteGebaeude) {
      onDeleteGebaeude();
    }
  }
  function changeGebaeude(data: any) {
    if (onChangeGebaeude) {
      if (data && data.tempId) {
        onChangeGebaeude({ tempId: data.tempId, ...data });
      } else {
        onChangeGebaeude(data);
      }
    }
  }

  function changeGebaeudeGD(gebGD: GebaeudeGrunddaten) {
    newGebaeudeGD = gebGD;

    let gebaeudeDataSet = createGebaeudeTemplateToSave() as GebaeudeData;

    gebaeudeDataToSave = gebaeudeDataSet;
    changeGebaeude(gebaeudeDataToSave);
  }

  function changeAdresse(adresse: Adresse) {
    newGebaeudeAdresse = adresse;

    let gebaeudeDataSet = createGebaeudeTemplateToSave() as GebaeudeData;

    gebaeudeDataToSave = gebaeudeDataSet;
    changeGebaeude(gebaeudeDataToSave);
  }

  function changeGebaeudeGebData(gebData: GebaeudeGebData) {
    newGebaeudeGebData = gebData;

    let gebaeudeDataSet = createGebaeudeTemplateToSave() as GebaeudeData;

    gebaeudeDataToSave = gebaeudeDataSet;
    changeGebaeude(gebaeudeDataToSave);
  }

  function changeEnergieData(energieData: GebaeudeEnergiesteckbrief) {
    newEnergiesteckbrief = energieData;

    let gebaeudeDataSet = createGebaeudeTemplateToSave() as GebaeudeData;

    gebaeudeDataToSave = gebaeudeDataSet;
    changeGebaeude(gebaeudeDataToSave);
  }

  function createGebaeudeTemplateToSave() {
    let gebaeudeDataSet = {} as GebaeudeData;
    //if gebaeude already existed use metainfo
    if (data && Object.keys(data).length > 0) {
      if (data.tempId) {
        gebaeudeDataSet.tempId = data.tempId;
      }
      if (data.id) {
        gebaeudeDataSet.id = data.id;
      }
      if (gebaeudeDataSet.createdAt) {
        gebaeudeDataSet.createdAt = data.createdAt;
      }
      if (gebaeudeDataSet.liegenschaftId) {
        gebaeudeDataSet.createdAt = data.createdAt;
      }
    }
    //add gebaeudeGD
    if (newGebaeudeGD && Object.keys(newGebaeudeGD).length > 0) {
      gebaeudeDataSet.nummer = newGebaeudeGD.nummer ? newGebaeudeGD.nummer : '';
      gebaeudeDataSet.name = newGebaeudeGD.name ? newGebaeudeGD.name : '';
      gebaeudeDataSet.beschreibung = newGebaeudeGD.beschreibung
        ? newGebaeudeGD.beschreibung
        : '';
      gebaeudeDataSet.anmerkung = newGebaeudeGD.anmerkung
        ? newGebaeudeGD.anmerkung
        : '';
      gebaeudeDataSet.eigentuemer = newGebaeudeGD.eigentuemer
        ? newGebaeudeGD.eigentuemer
        : '';
      gebaeudeDataSet.verantwortlicher = newGebaeudeGD.verantwortlicher
        ? newGebaeudeGD.verantwortlicher
        : '';
    } else if (data) {
      gebaeudeDataSet.nummer = data.nummer ? data.nummer : '';
      gebaeudeDataSet.name = data.name ? data.name : '';
      gebaeudeDataSet.beschreibung = data.beschreibung ? data.beschreibung : '';
      gebaeudeDataSet.anmerkung = data.anmerkung ? data.anmerkung : '';
      gebaeudeDataSet.eigentuemer = data.eigentuemer ? data.eigentuemer : '';
      gebaeudeDataSet.verantwortlicher = data.verantwortlicher
        ? data.verantwortlicher
        : '';
    }

    //add adress
    if (newGebaeudeAdresse && Object.keys(newGebaeudeAdresse).length > 0) {
      gebaeudeDataSet.adresse = {} as Adresse;

      gebaeudeDataSet.adresse.strasse = newGebaeudeAdresse.strasse
        ? newGebaeudeAdresse.strasse
        : '';
      gebaeudeDataSet.adresse.hausnummer = newGebaeudeAdresse.hausnummer
        ? newGebaeudeAdresse.hausnummer
        : '';
      gebaeudeDataSet.adresse.postleitzahl = newGebaeudeAdresse.postleitzahl
        ? newGebaeudeAdresse.postleitzahl
        : '';
      gebaeudeDataSet.adresse.stadt = newGebaeudeAdresse.stadt
        ? newGebaeudeAdresse.stadt
        : '';
      gebaeudeDataSet.adresse.region = newGebaeudeAdresse.region
        ? newGebaeudeAdresse.region
        : '';
      gebaeudeDataSet.adresse.land = newGebaeudeAdresse.land
        ? newGebaeudeAdresse.land
        : '';
    } else if (data) {
      gebaeudeDataSet.adresse = data.adresse ? data.adresse : undefined;
    }

    if (newGebaeudeMedia && Object.keys(newGebaeudeMedia).length > 0) {
    } else if (data) {
      gebaeudeDataSet.hauptBildId = data.hauptBildId ? data.hauptBildId : '';
      gebaeudeDataSet.hauptPlanId = data.hauptPlanId ? data.hauptPlanId : '';
      gebaeudeDataSet.mediaIds = data.mediaIds ? data.mediaIds : [];
    }

    //add gebdata
    if (newGebaeudeGebData && Object.keys(newGebaeudeGebData).length > 0) {
      gebaeudeDataSet.baujahr = newGebaeudeGebData.baujahr
        ? newGebaeudeGebData.baujahr
        : undefined;
      gebaeudeDataSet.anzahlEtagen = newGebaeudeGebData.anzahlEtagen
        ? newGebaeudeGebData.anzahlEtagen
        : undefined;
      gebaeudeDataSet.anzahlEtagenOberirdisch =
        newGebaeudeGebData.anzahlEtagenOberirdisch
          ? newGebaeudeGebData.anzahlEtagenOberirdisch
          : undefined;
      gebaeudeDataSet.anzahlEtagenUnterirdisch =
        newGebaeudeGebData.anzahlEtagenUnterirdisch
          ? newGebaeudeGebData.anzahlEtagenUnterirdisch
          : undefined;
      gebaeudeDataSet.anzahlTreppenhaeuser =
        newGebaeudeGebData.anzahlTreppenhaeuser
          ? newGebaeudeGebData.anzahlTreppenhaeuser
          : undefined;
      gebaeudeDataSet.anzahlAufzuege = newGebaeudeGebData.anzahlAufzuege
        ? newGebaeudeGebData.anzahlAufzuege
        : undefined;
      gebaeudeDataSet.anzahlStellplaetze = newGebaeudeGebData.anzahlStellplaetze
        ? newGebaeudeGebData.anzahlStellplaetze
        : undefined;
      gebaeudeDataSet.anzahlNutzerPersonen =
        newGebaeudeGebData.anzahlNutzerPersonen
          ? newGebaeudeGebData.anzahlNutzerPersonen
          : undefined;
      gebaeudeDataSet.anzahlBauteile = newGebaeudeGebData.anzahlBauteile
        ? newGebaeudeGebData.anzahlBauteile
        : undefined;
      gebaeudeDataSet.kostenNettoFmProJahr =
        newGebaeudeGebData.kostenNettoFmProJahr
          ? newGebaeudeGebData.kostenNettoFmProJahr
          : undefined;
      gebaeudeDataSet.jahresMieteinnahmen =
        newGebaeudeGebData.jahresMieteinnahmen
          ? newGebaeudeGebData.jahresMieteinnahmen
          : undefined;
      gebaeudeDataSet.nutzungsanteilOffice =
        newGebaeudeGebData.nutzungsanteilOffice
          ? newGebaeudeGebData.nutzungsanteilOffice
          : undefined;
      gebaeudeDataSet.nutzungsanteilRetail =
        newGebaeudeGebData.nutzungsanteilRetail
          ? newGebaeudeGebData.nutzungsanteilRetail
          : undefined;
      gebaeudeDataSet.nutzungsanteilWohnen =
        newGebaeudeGebData.nutzungsanteilWohnen
          ? newGebaeudeGebData.nutzungsanteilWohnen
          : undefined;
      gebaeudeDataSet.nutzungsanteilLogistik =
        newGebaeudeGebData.nutzungsanteilLogistik
          ? newGebaeudeGebData.nutzungsanteilLogistik
          : undefined;
      gebaeudeDataSet.nutzungsanteilSonstige =
        newGebaeudeGebData.nutzungsanteilSonstige
          ? newGebaeudeGebData.nutzungsanteilSonstige
          : undefined;
      gebaeudeDataSet.bruttoGrundflaeche = newGebaeudeGebData.bruttoGrundflaeche
        ? newGebaeudeGebData.bruttoGrundflaeche
        : undefined;
      gebaeudeDataSet.nettoRaumflaeche = newGebaeudeGebData.nettoRaumflaeche
        ? newGebaeudeGebData.nettoRaumflaeche
        : undefined;
      gebaeudeDataSet.nutzungsflaeche = newGebaeudeGebData.nutzungsflaeche
        ? newGebaeudeGebData.nutzungsflaeche
        : undefined;
      gebaeudeDataSet.technikflaeche = newGebaeudeGebData.technikflaeche
        ? newGebaeudeGebData.technikflaeche
        : undefined;
      gebaeudeDataSet.verkehrsflaeche = newGebaeudeGebData.verkehrsflaeche
        ? newGebaeudeGebData.verkehrsflaeche
        : undefined;
      gebaeudeDataSet.grundstuecksflaeche =
        newGebaeudeGebData.grundstuecksflaeche
          ? newGebaeudeGebData.grundstuecksflaeche
          : undefined;
      gebaeudeDataSet.aussenanlagenflaeche =
        newGebaeudeGebData.aussenanlagenflaeche
          ? newGebaeudeGebData.aussenanlagenflaeche
          : undefined;
      gebaeudeDataSet.flaecheLeerstand = newGebaeudeGebData.flaecheLeerstand
        ? newGebaeudeGebData.flaecheLeerstand
        : undefined;
      gebaeudeDataSet.zertifiziert = newGebaeudeGebData.zertifiziert;
      gebaeudeDataSet.zertifiziertInfo = newGebaeudeGebData.zertifiziertInfo
        ? newGebaeudeGebData.zertifiziertInfo
        : undefined;
      gebaeudeDataSet.tiefgarage = newGebaeudeGebData.tiefgarage;
      gebaeudeDataSet.fassadenbefahranlage =
        newGebaeudeGebData.fassadenbefahranlage;
      gebaeudeDataSet.denkmalschutz = newGebaeudeGebData.denkmalschutz;
      gebaeudeDataSet.hauptbetriebszeitVon =
        newGebaeudeGebData.hauptbetriebszeitVon
          ? newGebaeudeGebData.hauptbetriebszeitVon
          : undefined;
      gebaeudeDataSet.hauptbetriebszeitBis =
        newGebaeudeGebData.hauptbetriebszeitBis
          ? newGebaeudeGebData.hauptbetriebszeitBis
          : undefined;
      gebaeudeDataSet.hauptbetriebsTage = newGebaeudeGebData.hauptbetriebsTage
        ? newGebaeudeGebData.hauptbetriebsTage
        : undefined;
      gebaeudeDataSet.baualtersstufe = newGebaeudeGebData.baualtersstufe
        ? newGebaeudeGebData.baualtersstufe
        : undefined;
      gebaeudeDataSet.technisierungsgrad = newGebaeudeGebData.technisierungsgrad
        ? newGebaeudeGebData.technisierungsgrad
        : undefined;
      gebaeudeDataSet.klimatisierung = newGebaeudeGebData.klimatisierung
        ? newGebaeudeGebData.klimatisierung
        : undefined;
      gebaeudeDataSet.gebaeudeTyp = newGebaeudeGebData.gebaeudeTyp
        ? newGebaeudeGebData.gebaeudeTyp
        : undefined;
    } else if (data) {
      gebaeudeDataSet.baujahr = data.baujahr ? data.baujahr : undefined;
      gebaeudeDataSet.anzahlEtagen = data.anzahlEtagen
        ? data.anzahlEtagen
        : undefined;
      gebaeudeDataSet.anzahlEtagenOberirdisch = data.anzahlEtagenOberirdisch
        ? data.anzahlEtagenOberirdisch
        : undefined;
      gebaeudeDataSet.anzahlEtagenUnterirdisch = data.anzahlEtagenUnterirdisch
        ? data.anzahlEtagenUnterirdisch
        : undefined;
      gebaeudeDataSet.anzahlTreppenhaeuser = data.anzahlTreppenhaeuser
        ? data.anzahlTreppenhaeuser
        : undefined;
      gebaeudeDataSet.anzahlAufzuege = data.anzahlAufzuege
        ? data.anzahlAufzuege
        : undefined;
      gebaeudeDataSet.anzahlStellplaetze = data.anzahlStellplaetze
        ? data.anzahlStellplaetze
        : undefined;
      gebaeudeDataSet.anzahlNutzerPersonen = data.anzahlNutzerPersonen
        ? data.anzahlNutzerPersonen
        : undefined;
      gebaeudeDataSet.anzahlBauteile = data.anzahlBauteile
        ? data.anzahlBauteile
        : undefined;
      gebaeudeDataSet.kostenNettoFmProJahr = data.kostenNettoFmProJahr
        ? data.kostenNettoFmProJahr
        : undefined;
      gebaeudeDataSet.jahresMieteinnahmen = data.jahresMieteinnahmen
        ? data.jahresMieteinnahmen
        : undefined;
      gebaeudeDataSet.nutzungsanteilOffice = data.nutzungsanteilOffice
        ? data.nutzungsanteilOffice
        : undefined;
      gebaeudeDataSet.nutzungsanteilRetail = data.nutzungsanteilRetail
        ? data.nutzungsanteilRetail
        : undefined;
      gebaeudeDataSet.nutzungsanteilWohnen = data.nutzungsanteilWohnen
        ? data.nutzungsanteilWohnen
        : undefined;
      gebaeudeDataSet.nutzungsanteilLogistik = data.nutzungsanteilLogistik
        ? data.nutzungsanteilLogistik
        : undefined;
      gebaeudeDataSet.nutzungsanteilSonstige = data.nutzungsanteilSonstige
        ? data.nutzungsanteilSonstige
        : undefined;
      gebaeudeDataSet.bruttoGrundflaeche = data.bruttoGrundflaeche
        ? data.bruttoGrundflaeche
        : undefined;
      gebaeudeDataSet.nettoRaumflaeche = data.nettoRaumflaeche
        ? data.nettoRaumflaeche
        : undefined;
      gebaeudeDataSet.nutzungsflaeche = data.nutzungsflaeche
        ? data.nutzungsflaeche
        : undefined;
      gebaeudeDataSet.technikflaeche = data.technikflaeche
        ? data.technikflaeche
        : undefined;
      gebaeudeDataSet.verkehrsflaeche = data.verkehrsflaeche
        ? data.verkehrsflaeche
        : undefined;
      gebaeudeDataSet.grundstuecksflaeche = data.grundstuecksflaeche
        ? data.grundstuecksflaeche
        : undefined;
      gebaeudeDataSet.aussenanlagenflaeche = data.aussenanlagenflaeche
        ? data.aussenanlagenflaeche
        : undefined;
      gebaeudeDataSet.flaecheLeerstand = data.flaecheLeerstand
        ? data.flaecheLeerstand
        : undefined;
      gebaeudeDataSet.zertifiziert = data.zertifiziert;

      gebaeudeDataSet.zertifiziertInfo = data.zertifiziertInfo
        ? data.zertifiziertInfo
        : undefined;
      gebaeudeDataSet.tiefgarage = data.tiefgarage;

      gebaeudeDataSet.fassadenbefahranlage = data.fassadenbefahranlage;

      gebaeudeDataSet.denkmalschutz = data.denkmalschutz;

      gebaeudeDataSet.hauptbetriebszeitVon = data.hauptbetriebszeitVon
        ? data.hauptbetriebszeitVon
        : undefined;
      gebaeudeDataSet.hauptbetriebszeitBis = data.hauptbetriebszeitBis
        ? data.hauptbetriebszeitBis
        : undefined;
      gebaeudeDataSet.hauptbetriebsTage = data.hauptbetriebsTage
        ? data.hauptbetriebsTage
        : undefined;
      gebaeudeDataSet.baualtersstufe = data.baualtersstufe
        ? data.baualtersstufe
        : undefined;
      gebaeudeDataSet.technisierungsgrad = data.technisierungsgrad
        ? data.technisierungsgrad
        : undefined;
      gebaeudeDataSet.klimatisierung = data.klimatisierung
        ? data.klimatisierung
        : undefined;
      gebaeudeDataSet.gebaeudeTyp = data.gebaeudeTyp
        ? data.gebaeudeTyp
        : undefined;
    }

    // add Energiesteckbrief
    if (newEnergiesteckbrief && Object.keys(newEnergiesteckbrief).length > 0) {
      gebaeudeDataSet.energiesteckbrief = {} as GebaeudeEnergiesteckbrief;
      //Add id
      if (data && data.energiesteckbrief) {
        gebaeudeDataSet.energiesteckbrief.id = data.energiesteckbrief.id;
      }

      gebaeudeDataSet.energiesteckbrief.heizenergieVerbrauchWhM2a =
        newEnergiesteckbrief.heizenergieVerbrauchWhM2a
          ? newEnergiesteckbrief.heizenergieVerbrauchWhM2a
          : undefined;
      gebaeudeDataSet.energiesteckbrief.heizenergieZeitraumStart =
        newEnergiesteckbrief.heizenergieZeitraumStart
          ? newEnergiesteckbrief.heizenergieZeitraumStart
          : undefined;
      gebaeudeDataSet.energiesteckbrief.heizenergieZeitraumEnd =
        newEnergiesteckbrief.heizenergieZeitraumEnd
          ? newEnergiesteckbrief.heizenergieZeitraumEnd
          : undefined;
      gebaeudeDataSet.energiesteckbrief.heizenergieEmissionCo2Kg =
        newEnergiesteckbrief.heizenergieEmissionCo2Kg
          ? newEnergiesteckbrief.heizenergieEmissionCo2Kg
          : undefined;
      gebaeudeDataSet.energiesteckbrief.anteilFernwaerme =
        newEnergiesteckbrief.anteilFernwaerme
          ? newEnergiesteckbrief.anteilFernwaerme
          : undefined;
      gebaeudeDataSet.energiesteckbrief.anteilGas =
        newEnergiesteckbrief.anteilGas
          ? newEnergiesteckbrief.anteilGas
          : undefined;
      gebaeudeDataSet.energiesteckbrief.anteilOel =
        newEnergiesteckbrief.anteilOel
          ? newEnergiesteckbrief.anteilOel
          : undefined;
      gebaeudeDataSet.energiesteckbrief.anteilWaermepumpe =
        newEnergiesteckbrief.anteilWaermepumpe
          ? newEnergiesteckbrief.anteilWaermepumpe
          : undefined;
      gebaeudeDataSet.energiesteckbrief.anteilHolz =
        newEnergiesteckbrief.anteilHolz
          ? newEnergiesteckbrief.anteilHolz
          : undefined;
      gebaeudeDataSet.energiesteckbrief.anteilSonstiges =
        newEnergiesteckbrief.anteilSonstiges
          ? newEnergiesteckbrief.anteilSonstiges
          : undefined;
      gebaeudeDataSet.energiesteckbrief.stromVerbrauchWhM2a =
        newEnergiesteckbrief.stromVerbrauchWhM2a
          ? newEnergiesteckbrief.stromVerbrauchWhM2a
          : undefined;
      gebaeudeDataSet.energiesteckbrief.stromZeitraumStart =
        newEnergiesteckbrief.stromZeitraumStart
          ? newEnergiesteckbrief.stromZeitraumStart
          : undefined;
      gebaeudeDataSet.energiesteckbrief.stromZeitraumEnd =
        newEnergiesteckbrief.stromZeitraumEnd
          ? newEnergiesteckbrief.stromZeitraumEnd
          : undefined;
      gebaeudeDataSet.energiesteckbrief.stromEmissionCo2Kg =
        newEnergiesteckbrief.stromEmissionCo2Kg
          ? newEnergiesteckbrief.stromEmissionCo2Kg
          : undefined;
      gebaeudeDataSet.energiesteckbrief.typ = newEnergiesteckbrief.typ
        ? newEnergiesteckbrief.typ
        : undefined;
    } else if (data) {
      gebaeudeDataSet.energiesteckbrief = data.energiesteckbrief
        ? data.energiesteckbrief
        : {};
    }

    return gebaeudeDataSet;
  }

  function injectGebaeudeGd() {
    return (
      <>
        <AlphaGebaeudeGDForm
          data={data}
          errorName={isErrorGebaeudeName ? isErrorGebaeudeName : false}
          errorNummer={isErrorGebaeudeNummer ? isErrorGebaeudeNummer : false}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(gebGD) => changeGebaeudeGD(gebGD)}
        />
      </>
    );
  }
  function injectGebaeudeAdresse() {
    return (
      <>
        <AlphaAdressForm
          data={data && data.adresse ? data.adresse : undefined}
          errorStrasse={isErrorGebaeudeStrasse}
          errorHausnr={isErrorGebaeudeHausnr}
          errorPlz={isErrorGebaeudePlz}
          errorStadt={isErrorGebaeudeStadt}
          errorRegion={isErrorGebaeudeRegion}
          errorLand={isErrorGebaeudeLand}
          disabled={disabled}
          readOnly={readOnly}
          onChangeAdress={(adresse) => changeAdresse(adresse)}
        />
      </>
    );
  }

  function injectGebaeudeMedia() {
    return (
      <>
        <AlphaMediaForm
          images={imagesGebaeude}
          documents={documentsGebaeude}
          readOnly={readOnly}
          downloadFolderName={
            data && data.name
              ? generateZipName('Dokumente_' + data.name)
              : 'Dokumente'
          }
          onDeleteImage={(id) => deleteImage(id)}
          onAddImage={() => addImage()}
          onDeleteDocument={(id) => deleteDocument(id)}
          onAddDocument={() => addDocument()}
        />
      </>
    );
  }
  function injectGebaeudeDaten() {
    return (
      <>
        <AlphaGebaeudeGebDatenForm
          data={data}
          baualtersstufenOptions={baualtersstufenOptions}
          betriebstageOptions={betriebstageOptions}
          technisierungsgradeOptions={technisierungsgradeOptions}
          klimatisierungenOptions={klimatisierungenOptions}
          gebaeudetypenOptions={gebaeudetypenOptions}
          disabled={disabled}
          readOnly={readOnly}
          onChangeGebData={(gebData) => changeGebaeudeGebData(gebData)}
        />
      </>
    );
  }
  function injectGebaeudeEnergie() {
    return (
      <>
        <AlphaGebaeudeEnergieForm
          data={
            data && data.energiesteckbrief ? data.energiesteckbrief : undefined
          }
          energieTypenOptions={energieTypenOptions}
          disabled={disabled}
          readOnly={readOnly}
          onChangeEnergie={(energieData) => {
            changeEnergieData(energieData);
          }}
        />
      </>
    );
  }
  function injectDeleteGebaeudeBtn() {
    return (
      <>
        <BeeOutlinedButton
          label={'Gebäude löschen'}
          disabled={false}
          type={'danger'}
          onClick={() => deleteGebaeude()}
        />
      </>
    );
  }

  return (
    <div className={'grid alpha-gebaeude'}>
      {/*  <div className={'col col-12 sm:col-12 md:col-12 lg:col-12 xl:col-6'}> */}
      <div className={'col col-12 sm:col-12 md:col-12 lg:col-12 xl:col-6'}>
        {injectGebaeudeGd()}
      </div>

      <div className={'col col-12 sm:col-12 md:col-12 lg:col-12 xl:col-6'}>
        {injectGebaeudeAdresse()}
      </div>
      <div className={'col col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12'}>
        {injectGebaeudeMedia()}
      </div>
      {/*       </div> */}
      {/*   <div className={'col col-12 sm:col-12 md:col-12 lg:col-12 xl:col-6'}> */}
      <div className={'col col-12 sm:col-12 md:col-12 lg:col-12 xl:col-6'}>
        {injectGebaeudeDaten()}
      </div>
      <div className={'col col-12 sm:col-12 md:col-12 lg:col-12 xl:col-6'}>
        {injectGebaeudeEnergie()}
      </div>
      {/*       </div> */}
      {!readOnly ? (
        <div className={'gebaeude-form-delete'}>
          {injectDeleteGebaeudeBtn()}
        </div>
      ) : null}
    </div>
  );
}

export default AlphaGebaeudeForm;
