import { useEffect, useState } from 'react';

import { Adresse } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { COUNTRIES } from '../../../Helper/Statics/Constants';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeValidatedDropDown from '../../Atoms/BeeValidatedDropDown';
import BeeValidatedTextInput from '../../Atoms/BeeValidatedTextInput';
import './AlphaAdressForm.scss';

type AlphaAdressFormProps = {
  data?: Adresse | undefined;
  errorStrasse?: boolean;
  errorHausnr?: boolean;
  errorPlz?: boolean;
  errorStadt?: boolean;
  errorRegion?: boolean;
  errorLand?: boolean;
  disabled: boolean;
  readOnly: boolean;
  onChangeAdress?: (adresse: Adresse) => void;
};

export default function AlphaAdressForm({
  data,
  errorStrasse,
  errorHausnr,
  errorPlz,
  errorStadt,
  errorRegion,
  errorLand,
  disabled,
  readOnly,
  onChangeAdress
}: AlphaAdressFormProps) {
  const [strasse, setStrasse] = useState<string>(
    data && data.strasse ? data.strasse : ''
  );
  const [hausnr, setHausnr] = useState<string>(
    data && data.hausnummer ? data.hausnummer : ''
  );
  const [plz, setPlz] = useState<string>(
    data && data.postleitzahl ? data.postleitzahl : ''
  );
  const [stadt, setStadt] = useState<string>(
    data && data.stadt ? data.stadt : ''
  );
  const [region, setRegion] = useState<any>();
  const [land, setLand] = useState<any>();
  const [isAdaptedStrasse, setIsAdaptedStrasse] = useState<boolean>(false);
  const [isAdaptedHausnr, setIsAdaptedHausnr] = useState<boolean>(false);
  const [isAdaptedPlz, setIsAdaptedPlz] = useState<boolean>(false);
  const [isAdaptedStadt, setIsAdaptedStadt] = useState<boolean>(false);
  const [isAdaptedRegion, setIsAdaptedRegion] = useState<boolean>(false);
  const [isAdaptedLand, setIsAdaptedLand] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setStrasse(data.strasse ? data.strasse : '');
      setHausnr(data.hausnummer ? data.hausnummer : '');
      setPlz(data.postleitzahl ? data.postleitzahl : '');
      setStadt(data.stadt ? data.stadt : '');

      //find entry for Region
      for (let i = 0; i < COUNTRIES.length; i++) {
        if (COUNTRIES[i] && COUNTRIES[i].regions) {
          for (let j = 0; j < COUNTRIES[i].regions.length; j++) {
            if (data.region === COUNTRIES[i].regions[j].region) {
              setRegion(COUNTRIES[i].regions[j]);
            }
          }
        }
      }
      //find dropdown entry for land
      for (let i = 0; i < COUNTRIES.length; i++) {
        if (COUNTRIES[i] && COUNTRIES[i].country === data.land) {
          setLand(COUNTRIES[i]);
        }
      }
    }
  }, [data]);

  function change(currAdresse: Adresse) {
    if (onChangeAdress) {
      onChangeAdress(currAdresse);
    }
  }

  function changeStrasse(currStrasse: string) {
    const currAdresse: Adresse = {
      strasse: currStrasse,
      hausnummer: hausnr,
      postleitzahl: plz,
      stadt: stadt,
      region: region && region.region ? region.region : '',
      land: land && land.country ? land.country : ''
    };

    change(currAdresse);
  }

  function changeHausnr(currHausnr: string) {
    const currAdresse: Adresse = {
      strasse: strasse,
      hausnummer: currHausnr,
      postleitzahl: plz,
      stadt: stadt,
      region: region && region.region ? region.region : '',
      land: land && land.country ? land.country : ''
    };

    change(currAdresse);
  }
  function changePlz(currPlz: string) {
    const currAdresse: Adresse = {
      strasse: strasse,
      hausnummer: hausnr,
      postleitzahl: currPlz,
      stadt: stadt,
      region: region && region.region ? region.region : '',
      land: land && land.country ? land.country : ''
    };

    change(currAdresse);
  }
  function changeStadt(currStadt: string) {
    const currAdresse: Adresse = {
      strasse: strasse,
      hausnummer: hausnr,
      postleitzahl: plz,
      stadt: currStadt,
      region: region && region.region ? region.region : '',
      land: land && land.country ? land.country : ''
    };

    change(currAdresse);
  }

  function changeRegion(currRegion: string) {
    const currAdresse: Adresse = {
      strasse: strasse,
      hausnummer: hausnr,
      postleitzahl: plz,
      stadt: stadt,
      region: currRegion,
      land: land && land.country ? land.country : ''
    };

    change(currAdresse);
  }

  function changeLand(currLand: string) {
    const currAdresse: Adresse = {
      strasse: strasse,
      hausnummer: hausnr,
      postleitzahl: plz,
      stadt: stadt,
      region: region && region.region ? region.region : '',
      land: currLand
    };

    change(currAdresse);
  }

  function renderHeadline() {
    return (
      <BeeContentHeadline
        label={'Adresse'}
        headline={'h2'}
        type={'secondary'}
      />
    );
  }

  const inputStraße = () => {
    const errorLabel = 'Bitte geben Sie eine Straße an.';

    return (
      <BeeValidatedTextInput
        label={'Straße'}
        errorLabel={errorStrasse && !isAdaptedStrasse ? errorLabel : null}
        neutralLabel={errorStrasse && isAdaptedStrasse ? errorLabel : null}
        value={strasse}
        disabled={disabled}
        formstate={
          errorStrasse && isAdaptedStrasse
            ? 'neutral'
            : errorStrasse
            ? 'error'
            : 'none'
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => {
          setStrasse(e.target.value);
          if (errorStrasse) {
            setIsAdaptedStrasse(true);
          }
        }}
        onFocusOut={(e) => {
          changeStrasse(e.target.value);
        }}
      />
    );
  };

  const inputStreetNumber = () => {
    const errorLabel = 'Bitte geben Sie eine Hausnr. an.';

    return (
      <BeeValidatedTextInput
        label={'Hausnr.'}
        errorLabel={errorHausnr && !isAdaptedHausnr ? errorLabel : null}
        neutralLabel={errorHausnr && isAdaptedHausnr ? errorLabel : null}
        value={hausnr}
        disabled={disabled}
        formstate={
          errorHausnr && isAdaptedHausnr
            ? 'neutral'
            : errorHausnr
            ? 'error'
            : 'none'
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => {
          setHausnr(e.target.value);
          if (errorHausnr) {
            setIsAdaptedHausnr(true);
          }
        }}
        onFocusOut={(e) => {
          changeHausnr(e.target.value);
        }}
      />
    );
  };

  const inputZip = () => {
    const errorLabel = 'Bitte geben Sie eine Postleitzahl an.';

    return (
      <BeeValidatedTextInput
        label={'PLZ'}
        errorLabel={errorPlz && !isAdaptedPlz ? errorLabel : null}
        neutralLabel={errorPlz && isAdaptedPlz ? errorLabel : null}
        value={plz}
        disabled={disabled}
        formstate={
          errorPlz && isAdaptedPlz ? 'neutral' : errorPlz ? 'error' : 'none'
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => {
          setPlz(e.target.value);
          if (errorPlz) {
            setIsAdaptedPlz(true);
          }
        }}
        onFocusOut={(e) => {
          changePlz(e.target.value);
        }}
      />
    );
  };

  const inputCity = () => {
    const errorLabel = 'Bitte geben Sie eine Stadt an.';

    return (
      <BeeValidatedTextInput
        label={'Stadt'}
        errorLabel={errorStadt && !isAdaptedStadt ? errorLabel : null}
        neutralLabel={errorStadt && isAdaptedStadt ? errorLabel : null}
        value={stadt}
        disabled={disabled}
        formstate={
          errorStadt && isAdaptedStadt
            ? 'neutral'
            : errorStadt
            ? 'error'
            : 'none'
        }
        readOnly={readOnly}
        required={true}
        onChange={(e) => {
          setStadt(e.target.value);
          if (errorStadt) {
            setIsAdaptedStadt(true);
          }
        }}
        onFocusOut={(e) => {
          changeStadt(e.target.value);
        }}
      />
    );
  };

  const selectRegion = () => {
    const errorLabel = 'Bitte geben Sie eine Region an.';

    return (
      <BeeValidatedDropDown
        label={'Region'}
        errorLabel={errorRegion && !isAdaptedRegion ? errorLabel : null}
        neutralLabel={errorRegion && isAdaptedRegion ? errorLabel : null}
        disabled={land === null || disabled}
        value={region}
        formstate={
          errorRegion && isAdaptedRegion
            ? 'neutral'
            : errorRegion
            ? 'error'
            : 'none'
        }
        readOnly={readOnly}
        required={true}
        options={
          COUNTRIES && COUNTRIES[0] && COUNTRIES[0].regions
            ? COUNTRIES[0].regions
            : null
        }
        optionLabel={'region'}
        onChange={(value: any) => {
          setRegion(value);

          changeRegion(value.region);
          if (errorRegion) {
            setIsAdaptedRegion(true);
          }
        }}
      />
    );
  };

  const selectCountry = () => {
    const errorLabel = 'Bitte geben Sie ein Land an.';

    return (
      <BeeValidatedDropDown
        label={'Land'}
        errorLabel={errorLand && !isAdaptedLand ? errorLabel : null}
        neutralLabel={errorLand && isAdaptedLand ? errorLabel : null}
        value={land}
        formstate={
          errorLand && isAdaptedLand ? 'neutral' : errorLand ? 'error' : 'none'
        }
        options={COUNTRIES}
        optionLabel={'country'}
        disabled={COUNTRIES === null || disabled}
        readOnly={readOnly}
        required={true}
        onChange={(e: any) => {
          setLand(e);
          changeLand(e.country);
          if (errorLand) {
            setIsAdaptedLand(true);
          }
        }}
      />
    );
  };

  return (
    <div className={'alpha-adress-form'}>
      {renderHeadline()}
      <div className={'grid'}>
        <div className={'col-12 sm:col-9 md:col-9 lg:col-9'}>
          {inputStraße()}
        </div>
        <div className={'col-12 sm:col-3 md:col-3 lg:col-3'}>
          {inputStreetNumber()}
        </div>
        <div className={'col-12 sm:col-3 md:col-3 lg:col-3'}>{inputZip()}</div>
        <div className={'col-12 sm:col-9 md:col-9 lg:col-9'}>{inputCity()}</div>
        <div className={'col-12 sm:col-6 md:col-6 lg:col-6'}>
          {selectCountry()}
        </div>
        <div className={'col-12 sm:col-6 md:col-6 lg:col-6'}>
          {selectRegion()}
        </div>
      </div>
    </div>
  );
}
