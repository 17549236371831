import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown-simple';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  KeyValEntry,
  readAllStoredKeyValuesForOrgaAndPhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import {
  EP,
  readEpCatalogForPhase
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';
import {
  extractUserId,
  hasUserWriteRights
} from '../../../Helper/Util/JwtHelper';
import {
  checkStepActivation,
  checkUserParticipation,
  STEP_IS_ACTIVE,
  STEP_WILL_BE_ACTIVE
} from '../../../Helper/Util/TimelineStepHelper';
import { AppDispatch, RootState } from '../../../Redux/store';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeTimeline, { TimelineStep } from '../../Atoms/BeeTimeline';
import DLRahmenpreiseingabe from '../../Organisms/DL/DLRahmenpreiseingabe';
import './DLPriceInputPage.scss';

export type DocType = {
  id: string;
  title: string;
  filename?: string;
  fileSrc: string;
  timestamp: Date;
  size?: number;
  type: string;
  fileKey?: string;
  copyright: string;
  secured: boolean;
};

export type ImgType = {
  id: string;
  url: string;
  key: string;
  alt: string;
  title: string;
  copyright: string;
  secured: boolean;
  showPlaceholder: boolean;
};

type DLPriceInputPageProps = {
  dlPhasen: any;
  userParticipation: boolean;
};

export type FileUploadType = {
  file: File | null | undefined;
  name: string | null;
  copyright: string | null;
  originalFileName: string | null | undefined;
};

export function DLPriceInputPage(props: DLPriceInputPageProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [phasen, setPhasen] = useState<TimelineStep[]>([]);
  const [currentEpCatalog, setCurrentEpCatalog] = useState<EP>();
  const [selectedPhase, setSelectedPhase] = useState<TimelineStep>();
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [fatalError, setFatalError] = useState<boolean>(false);
  const [initialUserInputs, setInitialUserInputs] = useState<KeyValEntry[]>();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (!phasen) {
      setInitialUserInputs(undefined);
      setCurrentEpCatalog(undefined);
    }
    if (props.dlPhasen) {
      let steps: TimelineStep[] = [...props.dlPhasen];
      if (!_.isEqual(phasen, steps)) {
        //nur zurücksetzen wenn sich wirklich was ändert
        setInitialUserInputs(undefined);
        setCurrentEpCatalog(undefined);
        setPhasen(steps);
        let activePhase: any;
        let stepFound = false;
        for (let i = 0; i < steps.length; i++) {
          if (checkStepActivation(steps[i]) === STEP_IS_ACTIVE) {
            activePhase = steps[i];
            if (checkUserParticipation(activePhase)) {
              stepFound = true;
              if (searchParams.get('pId') !== activePhase.id) {
                searchParams.set('pId', activePhase.id);
                setSearchParams(searchParams);
              }
            } else {
              while (i > 0) {
                if (checkUserParticipation(steps[i])) {
                  stepFound = true;
                  if (searchParams.get('pId') !== steps[i].id) {
                    searchParams.set('pId', steps[i].id);
                    setSearchParams(searchParams);
                    break;
                  }
                }
                i = i - 1;
              }
              //if user is in no phase he will be shown the first one
            }
            break;
          }
        }
        if (!stepFound) {
          if (steps.length > 0) {
            if (searchParams.get('pId') !== steps[0]?.id) {
              searchParams.set('pId', steps[0]?.id);
              setSearchParams(searchParams);
            }
          } else {
            toast.current?.show({
              severity: 'error',
              summary: 'Keine Phasen vorhanden',
              detail:
                'Aktuell wurden von der Administration noch keine Projektphasen hinterlegt. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut. ',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dlPhasen]);

  useEffect(() => {
    if (phasen) {
      const phaseId = searchParams.get('pId');
      if (phaseId) {
        //if phase changed
        if (!(selectedPhase && selectedPhase.id === phaseId)) {
          setInitialUserInputs(undefined);
          setCurrentEpCatalog(undefined);
          const currPhase = _.find(phasen, { id: phaseId });
          if (currPhase) {
            setReadOnly(
              !(
                checkStepActivation(currPhase) === STEP_IS_ACTIVE &&
                checkUserParticipation(currPhase) &&
                hasUserWriteRights()
              )
            );
            getInitialUserInput(currPhase);
            setSelectedPhase(currPhase);
          } else {
            setSelectedPhase(phasen[0]);
          }
        }
      } else {
        setInitialUserInputs(undefined);
        setCurrentEpCatalog(undefined);
      }
    } else {
      setInitialUserInputs(undefined);
      setCurrentEpCatalog(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, phasen]);

  function getInitialUserInput(selectedPhase: any) {
    if (selectedPhase) {
      setFatalError(false);
      let userId = extractUserId();
      userId = userId ? userId : 'no_user';
      readAllStoredKeyValuesForOrgaAndPhase(userId, selectedPhase.id)
        .then((data: any) => {
          readEpCatalogForPhase(selectedPhase.id)
            .then((ep: any) => {
              const catalog: EP = ep;
              setCurrentEpCatalog(catalog);
              console.log(catalog);
              const keyValues = [...data];
              setInitialUserInputs(keyValues);
            })
            .catch(() => {
              setFatalError(true);
              toast.current?.show({
                severity: 'error',
                summary: 'EP-Katalog konnte nicht geladen werden.',
                detail:
                  'Das Laden des zu bepreisenden EP-Katalogs ist schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice. ',
                sticky: false,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            });
        })
        .catch(() => {
          setFatalError(true);
          toast.current?.show({
            severity: 'error',
            summary: 'Ihre Daten können nicht geladen werden.',
            detail:
              'Das Laden der von Ihnen eingegebenen Preise und Faktoren ist schiefgelaufen. Bitte aktualisieren Sie die Seite. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        });
    }
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  function injectHeadline(): JSX.Element {
    return (
      <div className={'priceInput-header'}>
        <BeeContentHeadline
          label={'Rahmenpreiseingabe'}
          headline={'h1'}
          type={'primary'}
        />
        {selectedPhase ? (
          <span className={'phase-countdown '}>
            <span className={'bold'}>{'Phase endet in:'}</span>
            <br />
            {renderCountdown(selectedPhase)}
          </span>
        ) : null}
      </div>
    );
  }

  function renderCountdown(item: TimelineStep): JSX.Element {
    const activation: string = checkStepActivation(item);
    if (activation === STEP_WILL_BE_ACTIVE) {
      return <div></div>;
    } else {
      /* is active */
      if (activation === STEP_IS_ACTIVE) {
        return <Countdown targetDate={item.end} renderer={countDownRenderer} />;
      } else {
        /* abgelaufen */
        return <div>{'Phase beendet'}</div>;
      }
    }
  }

  const countDownRenderer = ({
    days,
    hours,
    minutes,
    seconds
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    return (
      <div className={'countdown'}>
        <div className={'countdownBlock'}>
          <div className={'countdownNumber'}>{days}</div>
          <div className={'countdownLabel'}>Tage</div>{' '}
        </div>
        <div className={'countdownBlock'}>
          <div className={'countdownNumber'}>{hours}</div>
          <div className={'countdownLabel'}>Std</div>
        </div>
        <div className={'countdownBlock'}>
          <div className={'countdownNumber'}>{minutes}</div>
          <div className={'countdownLabel'}>Min</div>
        </div>
        <div className={'countdownBlock'}>
          <div className={'countdownNumber'}>{seconds}</div>
          <div className={'countdownLabel'}>Sek</div>
        </div>
        <br />
      </div>
    );
  };

  return (
    <>
      <div className={'dl-priceInput-page'}>
        {injectHeadline()}
        {phasen && selectedPhase ? (
          <BeeTimeline steps={phasen} currentPhase={selectedPhase} />
        ) : (
          <span className={'loader-page'}>
            <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
          </span>
        )}
        <div className={props.userParticipation ? '' : 'disabled'}>
          {selectedPhase && !fatalError ? (
            <>
              {initialUserInputs ? (
                <DLRahmenpreiseingabe
                  readOnly={readOnly}
                  phase={selectedPhase}
                  keyValues={initialUserInputs}
                  epCatalog={currentEpCatalog}
                />
              ) : (
                <span className={'loader-page'}>
                  <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
                </span>
              )}
            </>
          ) : fatalError ? (
            //fixme
            <div className={'priceInput-fatal-error-msg'}>
              <i className="pi pi-exclamation-triangle mr-2 error"></i>
              <h1 className="error">Fehler</h1>
              <div>
                <span className="error">
                  Der EP-Katalog und Ihre bisher eigegebenen Daten konnten nicht
                  vom Server geladen werden.
                </span>
                <span className="error">
                  Bitte aktualisieren Sie die Seite. Sollte das Problem
                  weiterhin bestehen wenden Sie sich bitte an den Kundenservice.
                </span>
              </div>
            </div>
          ) : (
            <span className={'loader-page'}>
              <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
            </span>
          )}
        </div>
      </div>
      <Toast ref={toast} position={'top-right'} />
    </>
  );
}

const mapState = (state: RootState) => {
  return {
    dlPhasen: state.app.dlPhasen,
    userParticipation: state.app.userParticipation
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {};
};

export default connect(mapState, mapDispatchToProps)(DLPriceInputPage);
