import { useId } from 'react-id-generator';
import BeeAddEntryCard from './BeeAddEntryCard';
import './BeeGalleryImage.scss';
import BeeIconButton from './BeeIconButton';
import BeeImage from './BeeImage';

type BeeGalleryImageProps = {
  imageUrl: string | null;
  imageKey: string | null;
  imageAlt: string | null;
  imageTitle: string | null;
  imageCopyright: string | null;
  isImageSecured: boolean;
  showPlaceHolder: boolean;
  activateHover: boolean;
  readOnly: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onRemove?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAdd?: () => void;
};

export function BeeGalleryImage({
  imageUrl,
  imageKey,
  imageAlt,
  imageTitle,
  imageCopyright,
  isImageSecured,
  showPlaceHolder,
  activateHover,
  readOnly,
  onClick,
  onRemove,
  onAdd
}: BeeGalleryImageProps) {
  const currentId = useId(1, 'bee-gallery-img-')[0];

  // classNames
  const readOnlyClassNames = readOnly ? 'bee-gallery-img-readOnly' : '';
  const activateHoverClassNames = activateHover
    ? ' bee-gallery-img-hover'
    : ' bee-gallery-img-noHover';
  const imgPrevClassNames = 'bee-gallery-img-close-button';
  const imgClassNames = 'bee-gallery-img-content';
  const addButtonClassNames = 'bee-gallery-img-add-button';
  const placeholderClassNames = ' bee-gallery-img-placeholder';
  const classNames =
    'bee-gallery-img ' +
    readOnlyClassNames +
    activateHoverClassNames +
    placeholderClassNames;

  // icons
  const closeBtnIcon = 'pi pi-times';

  function removeImage(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (onRemove) {
      onRemove(e);
    }
  }

  function addImage() {
    if (onAdd) {
      onAdd();
    }
  }

  function clicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (onClick) {
      onClick(e);
    }
  }

  const injectImagePreview = () => {
    return (
      <div>
        {!readOnly ? (
          <div className={imgPrevClassNames}>
            <BeeIconButton
              iconClass={closeBtnIcon}
              rounded={true}
              disabled={false}
              type={'secondary'}
              onClick={(e) => removeImage(e)}
            />
          </div>
        ) : null}
        <div className={imgClassNames}>
          <BeeImage
            img_url={imageUrl}
            img_key={imageKey}
            alt={imageAlt}
            title={imageTitle}
            titlePosition="left"
            copyright={imageCopyright}
            isSecured={isImageSecured}
            showPlaceholder={showPlaceHolder}
            showDefault={false}
            onClick={(e) => clicked(e)}
          />
        </div>
      </div>
    );
  };

  const injectPlaceholderAdd = () => {
    return (
      <div className={addButtonClassNames}>
        <BeeAddEntryCard
          disabled={readOnly ? true : false}
          onClick={(e) => addImage()}
        />
      </div>
    );
  };

  return (
    <div id={currentId} className={classNames}>
      {imageUrl ? injectImagePreview() : injectPlaceholderAdd()}
    </div>
  );
}

export default BeeGalleryImage;
