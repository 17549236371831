import { Link } from 'react-router-dom';
import BeeContentHeadline from '../Atoms/BeeContentHeadline';
import Header from '../Organisms/Header';
import './Impressum.scss';

export function Impressum() {
  return (
    <>
      <Header />
      <div className="impressum">
        <BeeContentHeadline
          label={'Impressum'}
          headline={'h1'}
          type={'primary'}
        />

        <div className={'impressum-content'}>
          <div className={'bold'}>{`SERTHORO GmbH`}</div>
          <div>{`Herzog-Max-Str. 44`}</div>
          <div className={'paragraph-end'}>{`96047 Bamberg, Deutschland`}</div>
          <div className={'bold'}>{`Vertreten durch:`}</div>
          <div
            className={'paragraph-end'}
          >{`Geschäftsführer: Sebastian Hölzlein, Roger Krieg`}</div>
          <div className={'bold'}>{`Kontakt:`}</div>
          <div>{`Tel: +49 951 994 3360`}</div>
          <div>{`Fax: +49 951 917 68340`}</div>
          <div className={'paragraph-end'}>
            <span>{`E-Mail: `}</span>
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = 'mailto:info@serthoro.de';
                e.preventDefault();
              }}
            >
              {'info@serthoro.de'}
            </Link>
          </div>
          <div>{`USt-Id Nr.: DE 325 295 657`}</div>
          <div>{`Handelsregisterdaten: HRB 9606 ∙ Amtsgericht Bamberg`}</div>
          <div>{`Verantwortlich für Inhalte gem. § 55 RStV ist: Sebastian Hölzlein`}</div>

          <BeeContentHeadline
            label={' VERANTWORTLICH FÜR INHALT, URHEBERRECHT UND MARKENSCHUTZ'}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`Copyright 2022. Serthoro GmbH. Alle Rechte vorbehalten. Alle Texte, Bilder, Grafiken, Multimediadateien sowie das Layout unterliegen dem Urheberrecht und den Gesetzen zum Schutz geistigen Eigentums. Eine Weiterverwertung bedarf der ausdrücklichen Zustimmung der Serthoro GmbH.`}</div>

          <BeeContentHeadline
            label={'BRANDING, DESIGN UND TECHNISCHE UMSETZUNG'}
            headline={'h3'}
            type={'primary'}
          />
          <div>{`SERTHORO GmbH`}</div>

          <BeeContentHeadline
            label={'RECHTSHINWEIS'}
            headline={'h3'}
            type={'primary'}
          />
          <div className={'bold'}>{`Inhalte`}</div>
          <div
            className={'paragraph-end'}
          >{`Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt erstellt. SERTHORO GmbH übernimmt jedoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte. Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung von SERTHORO GmbH wieder.`}</div>
          <div
            className={'paragraph-end'}
          >{`Die Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen oder Bildmaterial, bedarf der vorherigen Zustimmung der SERTHORO GmbH. Insbesondere dürfen Nachdruck, Aufnahme in Online-Dienste, Internet und Vervielfältigung auf Datenträger wie CD-ROM, DVD-ROM, etc., auch auszugsweise, nur nach vorheriger schriftlicher Zustimmung durch die SERTHORO GmbH erfolgen.`}</div>
          <div
            className={'paragraph-end'}
          >{`Inhalt und Struktur der SERTHORO GmbH Webseiten sind urheberrechtlich geschützt. Die Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen oder Bildmaterial, bedarf der vorherigen Zustimmung der SERTHORO GmbH.`}</div>
          <div className={'bold'}>{`Bild- und Videomaterial`}</div>

          <div
            className={'paragraph-end'}
          >{`Bilder oder Filmmaterial wurden (soweit zutreffend) unter Lizenz von Shutterstock.com, Unsplash.com und Pexels.com verwendet.`}</div>
          <div className={'bold'}>{`Haftung für fremde Inhalte`}</div>

          <div
            className={'paragraph-end'}
          >{`Sofern SERTHORO GmbH direkt oder indirekt auf fremde Internetseiten verweist („Links“), haftet SERTHORO GmbH nur, wenn sie von den Inhalten genaue Kenntnis hat und es ihr technisch möglich und zumutbar ist, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.`}</div>

          <div
            className={'paragraph-end'}
          >{`SERTHORO GmbH erklärt ausdrücklich, dass zum Zeitpunkt der Linksetzung die verlinkten Seiten keine illegalen Inhalte enthalten haben. SERTHORO GmbH besitzt keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung der verlinkten Seiten. SERTHORO GmbH distanziert sich ausdrücklich von allen inhaltlichen Änderungen, die nach der Linkssetzung auf den verlinkten Seiten vorgenommen werden.`}</div>
          <div
            className={'paragraph-end'}
          >{`SERTHORO GmbH ist nicht verantwortlich für den Inhalt, die Verfügbarkeit und Richtigkeit der verlinkten Seiten, deren Angebote, Links oder Werbeanzeigen. SERTHORO GmbH haftet nicht für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die durch Nutzung oder Nichtnutzung der auf den verlinkten Seiten angebotenen Informationen entstehen.`}</div>

          <div className={'bold'}>{`Rechtswirksamkeit`}</div>
          <div>{`Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit hiervon unberührt.`}</div>
        </div>
      </div>
    </>
  );
}

export default Impressum;
