import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import {
  Phase,
  readAllPhases
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import AlphaPhaseDocTable from '../../Molecules/Alpha/AlphaPhaseDocTable';
import NoPhases from '../../Molecules/Alpha/NoPhases';
import './AlphaSetupTabPhaseToMedia.scss';

export function AlphaSetupTabPhaseToMedia() {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [phasen, setPhasen] = useState<Phase[]>([]);
  //refs
  const toast = useRef<Toast>(null);

  ///////////
  // SETUP //
  ///////////

  useEffect(() => {
    setDataIsLoading(true);
    readAllPhases()
      .then((phases: any) => {
        setPhasen(_.sortBy(phases, ['start', 'end', 'title']));
        setDataIsLoading(false);
      })
      .catch((error) => {
        if (toast && toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Phasen konnten nicht geladen werden',
            detail:
              'Leider konnten die Phasen nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
        setDataIsLoading(false);
      });
  }, []);

  ////////////////
  // UI METHODS //
  ////////////////

  function injectPayload() {
    if (phasen) {
      return phasen.map((p) => <AlphaPhaseDocTable phase={p} />);
    }
    return <NoPhases />;
  }

  function injectDescription() {
    return (
      <div>
        Bitte laden Sie in folgender Übersicht die ausschreibungsrelevanten
        Dokumente pro Phase hoch. Diese Dokumente werden den Dienstleistern als
        zusätzliche Informationen bei der Rahmenpreiseingabe bereitgestellt.
        <br /> <br /> Ist eine Phase bereits beendet können die bereitgestellten
        Dokumente nicht mehr geändert werden. Hat eine Phase bereits begonnen,
        ist es nicht mehr möglich Dokumente zu löschen. Liegt eine Phase in der
        Zukunft können Dokumente nach Belieben hinzugefügt und gelöscht werden.
      </div>
    );
  }
  return (
    <div className={'alpha-lv-verwaltung m-2 mt-4 ml-1 p-2'}>
      {injectDescription()}

      {!dataIsLoading ? (
        injectPayload()
      ) : (
        <BeeLoadingSpinner strokeWidth={'3'} type={'secondary'} />
      )}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
