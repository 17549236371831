export function numberToExcelLetter(columnIndex: number) {
  let columnName = '';
  while (columnIndex > 0) {
    const remainder = (columnIndex - 1) % 26; // 26 letters in the alphabet
    columnName = String.fromCharCode(65 + remainder) + columnName; // A is ASCII 65
    columnIndex = Math.floor((columnIndex - 1) / 26);
  }
  return columnName;
}

export function calculateTreeDepth(tree: any) {
  const depth =
    1 +
    Math.max(
      0,
      ...tree.map(({ children = [] }) => calculateTreeDepth(children))
    );

  return depth;
}

export function shadeColor(color: string, percent: number) {
  //correct percentage so it starts at a light color
  const correctedPercentage = percent + 50;

  let R = parseInt(color.substring(0, 2), 16);
  let G = parseInt(color.substring(2, 4), 16);
  let B = parseInt(color.substring(4, 6), 16);

  R = (R * (100 + correctedPercentage)) / 100;
  G = (G * (100 + correctedPercentage)) / 100;
  B = (B * (100 + correctedPercentage)) / 100;

  //make color more grey
  R = R && R < 255 ? R : 255;
  G = G && G < 255 ? G : 255;
  B = B && B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return RR + GG + BB;
}
