import {
  InputNumber,
  InputNumberValueChangeParams
} from 'primereact/inputnumber';
import { useId } from 'react-id-generator';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import './BeeNumberInput.scss';
import BeeTextInput from './BeeTextInput';

type BeeNumberInputProps = {
  id?: string;
  label?: string;
  value?: number;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  disabled?: boolean;
  grouping?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange?: (e: InputNumberValueChangeParams) => void;
  onFocusOut?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
};

export function BeeNumberInput({
  id,
  label,
  value,
  autoFocus,
  min,
  max,
  minFractionDigits,
  maxFractionDigits,
  disabled,
  grouping,
  formstate,
  readOnly,
  required,
  onChange,
  onFocusOut
}: BeeNumberInputProps) {
  const currentId = useId(1, 'bee-num-input')[0];

  //classNames
  const formStateClassnames =
    formstate === 'valid'
      ? 'bee-num-input-valid'
      : formstate === 'error'
      ? 'bee-num-input-error'
      : formstate === 'neutral'
      ? 'bee-num-input-neutral'
      : 'bee-num-input-neutral';

  let readOnlyClassnames = '';
  if (readOnly) {
    readOnlyClassnames = ' bee-num-input-readOnly';
    disabled = true;
  }

  const inputClassnames =
    'bee-num-input ' + formStateClassnames + readOnlyClassnames;
  const containerClassnames = 'p-float-label bee-num-input-container';

  //label
  const labelClassNames = 'bee-num-input-label';

  function change(e: InputNumberValueChangeParams) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }
  function focusOut(e: React.FocusEvent<HTMLInputElement, Element>) {
    if (!disabled && onFocusOut) {
      onFocusOut(e);
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={required}
        onChange={() => {}}
      />
    );
  };

  return (
    <>
      {readOnly && !value && value !== 0 ? (
        renderPlaceholder()
      ) : (
        <span id={currentId} className={containerClassnames}>
          <InputNumber
            id={currentId + 'input'}
            value={value}
            disabled={disabled}
            mode="decimal"
            autoFocus={autoFocus}
            useGrouping={grouping}
            min={min}
            max={max}
            minFractionDigits={minFractionDigits}
            maxFractionDigits={maxFractionDigits}
            inputClassName={inputClassnames}
            onValueChange={(e) => change(e)}
            onBlur={(e) => focusOut(e)}
          />
          {label ? (
            <label htmlFor={currentId + 'input'} className={labelClassNames}>
              {label}
              {required && label ? (
                <span className="bee-num-input-label-required">
                  <span className="bee-num-input-label-required-star"> *</span>
                </span>
              ) : null}
            </label>
          ) : null}
        </span>
      )}
    </>
  );
}

export default BeeNumberInput;
