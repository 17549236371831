export async function handlePromisesAndIgnoreErrors(promises: Promise<any>[]) {
  try {
    const results = await Promise.allSettled(promises);
    const fulfilled: any[] = [];
    const rejected: any[] = [];
    results.forEach((result) => {
      if (result.status === 'fulfilled') {
        fulfilled.push(result);
      } else {
        rejected.push(result);
      }
    });
    return { fulfilled, rejected };
  } catch (error) {
    throw error;
  }
}

// export async function handlePromisesAndIgnoreErrors(promises: Promise<any>[]) {
//   const batchSize = 2; // Maximum number of promises to execute concurrently
//   try {
//     let results: PromiseSettledResult<any>[] = [];
//     const fulfilled: any[] = [];
//     const rejected: any[] = [];
//     // Process promises in batches
//     for (let i = 0; i < promises.length; i += batchSize) {
//       const batchPromises = promises.slice(i, i + batchSize);
//       results = await Promise.allSettled(batchPromises);
//       results.forEach((result) => {
//         if (result.status === 'fulfilled') {
//           fulfilled.push(result.value);
//         } else {
//           rejected.push(result.reason);
//         }
//       });
//     }
//     return { fulfilled, rejected };
//   } catch (error) {
//     throw error;
//   }
// }
