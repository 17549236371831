export const STORE_REFRESH_TOKEN = 'STORE_REFRESH_TOKEN';
export const STORE_ACCESS_TOKEN = 'STORE_ACCESS_TOKEN';
export const STORE_TWOFAC_TOKEN = 'STORE_TWOFAC_TOKEN';
export const STORE_USERNAME = 'STORE_USERNAME';
export const LOGOUT_USER = 'LOGOUT_USER';

export const STORE_STATIC_SETUP = 'STORE_STATIC_SETUP';
export const STORE_DL_PHASEN = 'STORE_DL_PHASEN';

export const CHANGE_USER_PARTICIPATION = 'CHANGE_USER_PARTICIPATION';

export type ReducerProps = {
  refreshToken?: string;
  accessToken?: string;
  twoFactorToken?: string;
  username?: string;
  dlPhasen?: any | null;
  userParticipation?: boolean | null;
};

export type Action = {
  type: string;
  payload?: ReducerProps;
};
