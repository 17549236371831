import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { readAllPhases } from '../../Helper/ApiHelper/KeyValueNetworkHelper';
import {
  DEV_MODE,
  DEV_TYPE,
  DURATION_NOTIFICATION_ERROR_LONG
} from '../../Helper/Statics/Constants';
import {
  PATH_ALPHA_ANALYSIS,
  PATH_ALPHA_OPTIONS,
  PATH_ALPHA_PHASES,
  PATH_ALPHA_PLACING,
  PATH_ALPHA_PROPERTY,
  PATH_ALPHA_PROVIDER_ANALYSIS,
  PATH_ALPHA_USER,
  PATH_DL_OVERVIEW,
  PATH_DL_PRICEINPUT,
  PATH_INVALID_TYPE_ROOT,
  PATH_LOGIN,
  PATH_ROOT
} from '../../Helper/Statics/Routes';
import { isUserDL, isUserFromAlpha } from '../../Helper/Util/JwtHelper';

import { storeDlPhasen } from '../../Redux/Authentication/authActions';
import { AppDispatch, RootState } from '../../Redux/store';
import NavBar, { navPointType } from '../Molecules/NavBar';
import Header from '../Organisms/Header';
import './MainPage.scss';

type MainPageProps = {
  refreshToken: string;
  storeDlPhasen: any;
};

export function MainPage(props: MainPageProps) {
  const [navExpanded, setNavExpanded] = useState(false);
  const [navItems, setItems] = useState<navPointType[]>([]);
  const toast = useRef<Toast>(null);

  let navigate = useNavigate();
  const location = useLocation();
  const jwt = props.refreshToken;
  const label_DL_eingabe = 'Rahmenpreiseingabe';
  const label_DL_auswertung = 'Preisübersicht';
  const label_A_liegenschaft = 'Liegenschaften';
  const label_A_user = 'Nutzerverwaltung';
  const label_A_phases = 'Phasenverwaltung';
  const label_A_options = 'Phasen-Setup';
  const label_A_analyse = 'Analyse';
  const label_A_dlPerformance = 'DL Zeitverlauf';
  const label_A_vergabe = 'Vergabeansicht';

  useEffect(() => {
    readAllPhases()
      .then((json: any) => {
        props.storeDlPhasen(json);
      })
      .catch((error) => {
        toast.current?.show({
          severity: 'error',
          summary: 'Fehler beim Laden benötigter Daten',
          detail:
            'Leider konnten die Phasen nicht geladen werden. Bitte versuchen Sie es später erneut. ' +
            error,
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        });
      });
    if (DEV_MODE) {
      if (DEV_TYPE === 'alpha') {
        const data: navPointType[] = [
          { label: label_A_liegenschaft, iconName: 'icon-company' },
          { label: label_A_user, iconName: 'icon-user' },
          { label: label_A_phases, iconName: 'icon-schedule' },
          { label: label_A_options, iconName: 'icon-settings' },
          { label: label_A_analyse, iconName: 'icon-chart' },
          { label: label_A_dlPerformance, iconName: 'icon-timer' },
          { label: label_A_vergabe, iconName: 'icon-euro' }
        ];
        setItems(data);
        if (location.pathname === PATH_ROOT) {
          navigate(PATH_ALPHA_PROPERTY);
        }
      } else {
        const data: navPointType[] = [
          { label: label_DL_eingabe, iconName: 'icon-euro' },
          { label: label_DL_auswertung, iconName: 'icon-chart' }
        ];
        setItems(data);
        if (location.pathname === PATH_ROOT) {
          navigate(PATH_DL_PRICEINPUT);
        }
      }
    } else {
      if (isUserFromAlpha()) {
        const data: navPointType[] = [
          { label: label_A_liegenschaft, iconName: 'icon-company' },
          { label: label_A_user, iconName: 'icon-user' },
          { label: label_A_phases, iconName: 'icon-schedule' },
          { label: label_A_options, iconName: 'icon-settings' },
          { label: label_A_analyse, iconName: 'icon-chart' },
          { label: label_A_dlPerformance, iconName: 'icon-timer' },
          { label: label_A_vergabe, iconName: 'icon-euro' }
        ];
        setItems(data);
        if (location.pathname === PATH_ROOT) {
          navigate(PATH_ALPHA_PROPERTY);
        }
      } else if (isUserDL()) {
        const data: navPointType[] = [
          { label: label_DL_eingabe, iconName: 'icon-euro' },
          { label: label_DL_auswertung, iconName: 'icon-chart' }
        ];
        setItems(data);
        if (location.pathname === PATH_ROOT) {
          navigate(PATH_DL_PRICEINPUT);
        }
      } else {
        if (location.pathname === PATH_ROOT) {
          navigate(PATH_INVALID_TYPE_ROOT);
        }
      }
    }
  }, []);

  function clickNavPoint(navPoint: navPointType) {
    if (navPoint.label === label_DL_eingabe) {
      if (location.pathname !== PATH_DL_PRICEINPUT) {
        navigate(PATH_DL_PRICEINPUT);
      }
    } else if (navPoint.label === label_A_user) {
      if (location.pathname !== PATH_ALPHA_USER) {
        navigate(PATH_ALPHA_USER);
      }
    } else if (navPoint.label === label_DL_auswertung) {
      if (location.pathname !== PATH_DL_OVERVIEW) {
        navigate(PATH_DL_OVERVIEW);
      }
    } else if (navPoint.label === label_A_liegenschaft) {
      if (location.pathname !== PATH_ALPHA_PROPERTY) {
        navigate(PATH_ALPHA_PROPERTY);
      }
    } else if (navPoint.label === label_A_options) {
      if (location.pathname !== PATH_ALPHA_OPTIONS) {
        navigate(PATH_ALPHA_OPTIONS);
      }
    } else if (navPoint.label === label_A_analyse) {
      if (location.pathname !== PATH_ALPHA_ANALYSIS) {
        navigate(PATH_ALPHA_ANALYSIS);
      }
    } else if (navPoint.label === label_A_dlPerformance) {
      if (location.pathname !== PATH_ALPHA_PROVIDER_ANALYSIS) {
        navigate(PATH_ALPHA_PROVIDER_ANALYSIS);
      }
    } else if (navPoint.label === label_A_vergabe) {
      if (location.pathname !== PATH_ALPHA_PLACING) {
        navigate(PATH_ALPHA_PLACING);
      }
    } else if (navPoint.label === label_A_phases) {
      if (location.pathname !== PATH_ALPHA_PHASES) {
        navigate(PATH_ALPHA_PHASES);
      }
    } else {
      if (location.pathname !== PATH_ALPHA_ANALYSIS) {
        navigate(PATH_ALPHA_ANALYSIS);
      }
    }
    setNavExpanded(false);
  }

  if (jwt !== '' && jwt !== null && jwt !== undefined) {
    return (
      <div className="main-page">
        <Header />
        <NavBar
          navPoints={navItems}
          expanded={navExpanded}
          onClickLabel={(navPoint) => clickNavPoint(navPoint)}
          onClickExpand={() => setNavExpanded(!navExpanded)}
        />
        <div
          className={'main-page-content'}
          onClick={() => setNavExpanded(false)}
        >
          <Outlet />
        </div>
        <Toast ref={toast} position={'top-right'} />
      </div>
    );
  } else {
    console.log('redirect');
    return <Navigate to={PATH_LOGIN} replace state={{ from: location }} />;
  }
}

const mapState = (state: RootState) => {
  return {
    refreshToken: state.app.refreshToken
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    storeDlPhasen: (json: any) => dispatch(storeDlPhasen(json))
  };
};

export default connect(mapState, mapDispatchToProps)(MainPage);
