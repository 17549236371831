import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { Phase } from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import {
  createNewEpCatalogForPropertyFromExcel,
  deleteEpCatalogForPhase,
  replaceEpCatalogForPhaseFromExcel
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  ACCEPTED_FORMATS_EXCEL,
  DURATION_NOTIFICATION_ERROR_LONG
} from '../../../Helper/Statics/Constants';
import { isBefore } from '../../../Helper/Util/TimelineStepHelper';
import BeeLinkButton from '../../Atoms/BeeLinkButton';
import { FileUploadType } from '../../Pages/DLPages/DLPriceInputPage';
import BeeDeletionDialog from '../Dialogs/BeeDeletionDialog';
import BeeUploadDialog from '../Dialogs/BeeUploadDialog';
import './EpLvPhase.scss';
import AlphaConfigEpTreeDialog from '../Dialogs/AlphaConfigEpTreeDialog';
import { errorMsgToString } from '../../../Helper/ApiHelper/ErrorMessages';

type EpLvPhaseProps = {
  phase?: Phase;
  epTree: any;
  onAdaptData: (headline: string, message: string) => void;
};

export function EpLvPhase({ phase, epTree, onAdaptData }: EpLvPhaseProps) {
  //states
  const [uploadVisible, setUploadVisible] = useState<boolean>(false);
  const [uploadReplaceVisible, setUploadReplaceVisible] =
    useState<boolean>(false);
  const [uploadProgressVisible, setUploadProgressVisible] =
    useState<boolean>(false);
  const [uploadReplaceProgressVisible, setUploadReplaceProgressVisible] =
    useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | undefined>();
  const [uploadReplaceError, setUploadReplaceError] = useState<
    string | undefined
  >();
  const [deletionVisible, setDeletionVisible] = useState<boolean>(false);
  const [currEpTreeVisible, setcurrEpTreeVisible] = useState<boolean>(false);
  //refs
  const toast = useRef<Toast>(null);

  /////////////
  // DIALOGS //
  /////////////

  function injectFileUploadDialog() {
    if (phase) {
      return (
        <BeeUploadDialog
          type={'primary'}
          visible={uploadVisible}
          disabled={false}
          locale={'de-DE'}
          header={'EP-Katalog hochladen'}
          info={''}
          titleLabel={''}
          titleVisible={false}
          copyrightLabel={''}
          copyrightVisible={false}
          dropzoneTitle={'Upload - EP-Katalog'}
          dropzoneDescription={
            'Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen'
          }
          dropzoneAddLabel={'Klicken zum Hinzufügen'}
          dropzoneFormats={ACCEPTED_FORMATS_EXCEL}
          progressVisible={uploadProgressVisible}
          progressMessage={'EP-Katalog wird hochgeladen'}
          errorVisible={uploadError ? true : false}
          errorHeadline={uploadError}
          errorDescription={uploadError}
          onHide={() => {
            setUploadError(undefined);
            setUploadVisible(false);
          }}
          onUpload={(data) => uploadEpCatalog(phase.id, data)}
        />
      );
    } else {
      return null;
    }
  }

  function injectFileReplaceDialog() {
    if (phase) {
      return (
        <BeeUploadDialog
          type={'primary'}
          visible={uploadReplaceVisible}
          disabled={false}
          locale={'de-DE'}
          header={'EP-Katalog hochladen'}
          info={''}
          titleLabel={''}
          titleVisible={false}
          copyrightLabel={''}
          copyrightVisible={false}
          dropzoneTitle={'Upload - EP-Katalog'}
          dropzoneDescription={
            'Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen'
          }
          dropzoneAddLabel={'Klicken zum Hinzufügen'}
          dropzoneFormats={ACCEPTED_FORMATS_EXCEL}
          progressVisible={uploadReplaceProgressVisible}
          progressMessage={'EP-Katalog wird hochgeladen'}
          errorVisible={uploadReplaceError ? true : false}
          errorHeadline={
            'Der EP-Katalog kann leider nicht hochgeladen werden. '
          }
          errorDescription={uploadReplaceError}
          onHide={() => {
            setUploadReplaceError(undefined);
            setUploadReplaceVisible(false);
          }}
          onUpload={(data) => uploadReplaceEpCatalog(phase.id, data)}
        />
      );
    } else {
      return null;
    }
  }

  function injectDeletionConfirmationDialog() {
    if (phase && phase.id && phase.ep && phase.ep.id) {
      const catalogId = phase.ep.id;
      return (
        <BeeDeletionDialog
          visible={deletionVisible}
          message={
            'Möchten Sie den EP-Katalog dieser Phase wirklich löschen? Hierdurch werden alle hinterlegten Gebäude-LVs dieser Phase ebenfalls gelöscht. '
          }
          messageIcon={'pi pi-exclamation-triangle'}
          acceptLabel={'Löschen'}
          rejectLabel={'Abbrechen'}
          header={'EP-Katalog löschen'}
          type={'primary'}
          onAccept={() => deleteEpCatalog(phase.id, catalogId)}
          onReject={() => setDeletionVisible(false)}
          onHide={() => setDeletionVisible(false)}
        />
      );
    } else {
      return null;
    }
  }
  function injectCurrentEpTreeDialog() {
    if (phase && phase.id && phase.ep && phase.ep.id) {
      return (
        <AlphaConfigEpTreeDialog
          visible={currEpTreeVisible}
          phase={phase}
          data={epTree}
          readOnly={false}
          onDelete={() => setDeletionVisible(true)}
          onReplace={() => setUploadReplaceVisible(true)} //fixme
          onHide={() => setcurrEpTreeVisible(false)}
          onDownload={() => downloadEpCatalog(phase.id)}
        />
      );
    } else {
      return null;
    }
  }

  ///////////////
  // UI HELPER //
  ///////////////

  function adaptData(headline: string, message: string) {
    if (onAdaptData) {
      onAdaptData(headline, message);
    }
  }

  function downloadEpCatalog(phaseId: String) {
    toast.current?.show({
      severity: 'info',
      summary: 'Download noch nicht implementiert',
      detail: 'Diese Funktion ist leider noch nicht implementiert',
      sticky: false,
      closable: true,
      life: DURATION_NOTIFICATION_ERROR_LONG
    });
    // readEpCatalogAsExcelForProperty(phaseId).catch((error) => {
    //   toast.current?.show({
    //     severity: 'error',
    //     summary: 'Download nicht möglich',
    //     detail:
    //       'Der EP-Katalog kann leider nicht heruntergeladen werden. ' + error,
    //     sticky: false,
    //     closable: true,
    //     life: DURATION_NOTIFICATION_ERROR_LONG
    //   });
    // });
  }

  function uploadEpCatalog(phaseId: String, file: FileUploadType) {
    setUploadProgressVisible(true);
    createNewEpCatalogForPropertyFromExcel(phaseId, file.file)
      .then(() => {
        setUploadProgressVisible(false);
        setUploadVisible(false);
        const headline = 'EP-Katalog angepast';
        const message =
          'Der EP-Katalog wurde erfolgreich hochgeladen. Sie können nun die LVs der Liegenschaften hochladen.';
        adaptData(headline, message);
      })
      .catch((error) => {
        setUploadProgressVisible(false);
        setUploadError('Der EP-Katalog kann leider nicht hochgeladen werden.');
        toast.current?.show({
          severity: 'error',
          summary: 'Upload nicht möglich',
          detail:
            'Der EP-Katalog kann leider nicht hochgeladen werden. ' + error,
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        });
      });
  }
  function uploadReplaceEpCatalog(phaseId: String, file: FileUploadType) {
    setUploadReplaceProgressVisible(true);
    replaceEpCatalogForPhaseFromExcel(phaseId, file.file)
      .then(() => {
        setUploadReplaceProgressVisible(false);
        setUploadReplaceVisible(false);
        const headline = 'EP-Katalog ersetzt';
        const message = 'Der EP-Katalog wurde erfolgreich ersetzt.';
        adaptData(headline, message);
      })
      .catch((error) => {
        setUploadReplaceProgressVisible(false);
        setUploadReplaceError(error);
      });
  }

  function deleteEpCatalog(phaseId: String, catalogId: String) {
    deleteEpCatalogForPhase(phaseId, catalogId)
      .then(() => {
        const headline = 'EP-Katalog gelöscht';
        const message =
          'Der EP-Katalog wurde erfolgreich gelöscht. Um die Bepreisung der Dienstleister zu ermöglichen, laden Sie bitte einen neuen EP Katalog hoch.';
        adaptData(headline, message);
        if (currEpTreeVisible) {
          setcurrEpTreeVisible(false);
        }
      })
      .catch((error) => {
        toast.current?.show({
          severity: 'error',
          summary: 'Löschen fehlgeschlagen',
          detail:
            'Der EP-Katalog kann leider nicht gelöscht werden. Bitte versuchen Sie es später erneut. ' +
            error,
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        });
      });
  }

  ////////////////
  // UI METHODS //
  ////////////////

  const injectUI = () => {
    if (phase) {
      if (phase.end && isBefore(phase.end, new Date())) {
        // future
        if (phase.ep && phase.ep.id) {
          return injectPhaseNotDoneWithData(phase);
        } else {
          return injectPhaseNotDoneNoData(phase);
        }
      } else {
        return injectPhaseDone(phase);
      }
    } // render empty otherwise!!
  };

  function injectPhaseDone(phase: Phase) {
    const start = phase && phase.start ? new Date(phase.start) : null;
    const end = phase && phase.end ? new Date(phase.end) : null;
    const filename: any =
      phase.ep && phase.ep.filename ? phase.ep.filename : null;
    return (
      <div>
        <div>{phase.title}</div>
        <div className={'ep-lv-phase-date flex'}>
          <span>
            {start ? start.toLocaleDateString('de-DE') + ' - ' : null}
          </span>{' '}
          <span>{end ? ' ' + end.toLocaleDateString('de-DE') : null}</span>
        </div>
        {phase.ep && phase.ep.id ? (
          <div className={'ep-lv-phase-epBtn download'}>
            <BeeLinkButton
              label={filename}
              type={'secondary'}
              onClick={() => setcurrEpTreeVisible(true)}
            />
          </div>
        ) : null}
      </div>
    );
  }

  function injectPhaseNotDoneNoData(phase: Phase) {
    const start = phase && phase.start ? new Date(phase.start) : null;
    const end = phase && phase.end ? new Date(phase.end) : null;
    return (
      <div>
        <div>{phase.title}</div>
        <div className={'ep-lv-phase-date flex'}>
          <span>
            {start ? start.toLocaleDateString('de-DE') + ' - ' : null}
          </span>{' '}
          <span>{end ? ' ' + end.toLocaleDateString('de-DE') : null}</span>
        </div>
        {!(phase.ep && phase.ep.id) ? (
          <div className={'ep-lv-phase-epBtn'}>
            <BeeLinkButton
              label={
                <>
                  <i className={'pi pi-upload'}></i>
                  <span>{'EP-Katalog hochladen'}</span>
                </>
              }
              type={'secondary'}
              onClick={() => setUploadVisible(true)}
            />{' '}
          </div>
        ) : null}
      </div>
    );
  }

  function injectPhaseNotDoneWithData(phase: Phase) {
    const start = phase && phase.start ? new Date(phase.start) : null;
    const phaseStarted: boolean =
      start && isBefore(new Date(), new Date(start)) ? true : false;
    const end = phase && phase.end ? new Date(phase.end) : null;
    const filename: any =
      phase.ep && phase.ep.filename ? phase.ep.filename : null;
    return (
      <div>
        <div>{phase.title}</div>
        <div className={'ep-lv-phase-date flex'}>
          <span>
            {start ? start.toLocaleDateString('de-DE') + ' - ' : null}
          </span>{' '}
          <span>{end ? ' ' + end.toLocaleDateString('de-DE') : null}</span>
        </div>
        {phase.ep && phase.ep.id ? (
          <div className={'ep-lv-phase-epBtn grid download'}>
            <div className={'col download-btn'}>
              <BeeLinkButton
                label={filename}
                type={'secondary'}
                onClick={() => setcurrEpTreeVisible(true)}
              />
            </div>{' '}
            {!phaseStarted ? (
              <div className={'col col-fixed delete-btn'}>
                <BeeLinkButton
                  label={<i className={'pi pi-trash'}></i>}
                  type={'danger'}
                  onClick={() => setDeletionVisible(true)}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <Toast ref={toast} position={'top-right'} />
      {injectUI()}
      {uploadVisible ? injectFileUploadDialog() : null}
      {uploadReplaceVisible ? injectFileReplaceDialog() : null}
      {deletionVisible ? injectDeletionConfirmationDialog() : null}
      {currEpTreeVisible ? injectCurrentEpTreeDialog() : null}
    </div>
  );
}

export default EpLvPhase;
