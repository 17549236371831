import _ from 'lodash';
import { useState } from 'react';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import { EnlargedLvPosition } from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import './AlphaTabCluster.scss';
import BeeMultiListbox, {
  BeeClusterFilter
} from '../../Molecules/Alpha/BeeMultiListbox';

import AlphaAnalysisClusterTable from './AlphaAnalysisClusterTable';
import {
  prefixActive,
  prefixInactive
} from '../../../Helper/Statics/Constants';

type AlphaTabClusterProps = {
  phase: TimelineStep;
  properties: LiegenschaftsData[];
  epTree: any;
  serviceProvider: AuthUserType[];
  keyValues: any;
  lvLookup: Map<string, EnlargedLvPosition[]>;
};

export default function AlphaTabCluster({
  phase,
  properties,
  epTree,
  serviceProvider,
  keyValues,
  lvLookup
}: AlphaTabClusterProps) {
  const [currentEpTree, setCurrentEpTree] = useState<any>();
  const [selectedProvider, setSelectedProvider] = useState<AuthUserType[]>([]);
  const [epCodeLookup, setEpCodeLookup] = useState<
    Map<string, EnlargedLvPosition[]>
  >(new Map());

  ///////////////////////
  /////// HELPER ////////
  ///////////////////////

  function adaptData(filter: BeeClusterFilter) {
    //filter selected ServiceProvider
    if (serviceProvider) {
      if (filter.serviceProviderIds) {
        setSelectedProvider(
          _.filter(serviceProvider, (sp) =>
            _.includes(filter.serviceProviderIds, sp.id)
          )
        );
      }
    }
    //filter LVs by liegenschaften
    let combinedLvPositions: EnlargedLvPosition[] = [];
    if (filter.propertyIds) {
      filter.propertyIds.forEach((id) => {
        const posArray = lvLookup.get(id);
        if (posArray) {
          combinedLvPositions.push(...posArray);
        }
      });
    }
    //filter these positions using provided tags
    if (filter.sliceTags) {
      combinedLvPositions = _.filter(combinedLvPositions, (pos) =>
        _.includes(filter.sliceTags, pos.sliceTag)
      );
    }
    //generate ep-based map
    let containedEpCodes: string[] = [];
    const epCodeMap = new Map<string, EnlargedLvPosition[]>();
    combinedLvPositions.forEach((e) => {
      let prefix = '';
      if (e.optionalPosition && !e.optionalPositionActive) {
        prefix = 'inaktivBedarf_';
      } else if (e.optionalPosition && e.optionalPositionActive) {
        prefix = 'aktivBedarf_';
      }
      const epCode = prefix + e.epCode;
      let array = epCodeMap.get(epCode);
      array = array ? array : [];
      array.push(e);
      epCodeMap.set(epCode, array);
      containedEpCodes.push(epCode);
    });
    //enlarge epTree by triple positions
    let tree = _.cloneDeep(epTree);
    tree = tripleAllLeafNodes(tree);
    //unique codes
    containedEpCodes = _.uniq(containedEpCodes);
    setEpCodeLookup(epCodeMap);
    setCurrentEpTree(filterArray(tree, containedEpCodes));
  }

  function tripleAllLeafNodes(epTree: any) {
    if (epTree && epTree.length > 0) {
      for (let i = 0; i < epTree.length; i++) {
        adaptLeafNodes(epTree[i]);
      }
    }
    return epTree;
  }

  function adaptLeafNodes(parentNode: any) {
    if (parentNode) {
      //if parentnode has children without children themselfes
      if (parentNode.children && parentNode.children.length > 0) {
        //adapt optPos & active
        for (const child of parentNode.children) {
          if (
            child.className === 'p' &&
            !_.startsWith(child.key, prefixInactive) &&
            !_.startsWith(child.key, prefixActive)
          ) {
            const optPos = _.cloneDeep(child);
            const optPosActive = _.cloneDeep(child);
            optPos.key = prefixInactive + optPos.key;
            optPos.data.epCode = prefixInactive + optPos.data.epCode;
            optPosActive.key = prefixActive + optPosActive.key;
            optPosActive.data.epCode = prefixActive + optPosActive.data.epCode;
            //push them
            parentNode.children.push(optPosActive);
            parentNode.children.push(optPos);
          } else if (child.children && child.children.length > 0) {
            adaptLeafNodes(child);
          }
          parentNode.children = _.sortBy(parentNode.children, ['data.number']);
        }
      }
    }
  }

  function filterArray(array: any, epCodes: any) {
    const getChildren = (result: any, object: any) => {
      const epCode =
        object.data && object.data.epCode ? object.data.epCode : 'no_ep_code';
      if (_.includes(epCodes, epCode)) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.children)) {
        const children = object.children.reduce(getChildren, []);
        if (children.length) result.push({ ...object, children });
      }
      return result;
    };
    return array.reduce(getChildren, []);
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////
  return (
    <div className={'dl-tab-overview m-2'}>
      <div className="mb-5">
        Bitte wählen Sie aus folgenden Filtern die Liegenschaften, Dienstleister
        und Leistungsgruppen aus, für welche Sie die Angebote der Dienstleister
        vergleichen möchten.
      </div>
      <BeeMultiListbox
        phaseId={phase.id}
        properties={properties}
        serviceProvider={serviceProvider}
        onAdaptSelection={(filter: BeeClusterFilter) => adaptData(filter)}
      />
      <AlphaAnalysisClusterTable
        phase={phase}
        epTree={currentEpTree}
        valueLookup={keyValues}
        selectedProvider={selectedProvider}
        epCodeLookup={epCodeLookup}
      />
    </div>
  );
}
