import _ from 'lodash';
import { MiniKeyValEntry } from '../ApiHelper/KeyValueNetworkHelper';
import { LiegenschaftsData } from '../ApiHelper/LiegenschaftenNetworkHelper';
import { EnlargedLvPosition } from '../ApiHelper/LvEpNetworkHelper';
import { AuthUserType } from '../ApiHelper/LoginNetworkHelper';

export const generateExportKeyVals = (
  data: MiniKeyValEntry[],
  awardees: String[],
  phaseId: string
) => {
  return new Promise((resolve, reject) => {
    const filter = _.filter(data, (obj) =>
      awardees.includes(obj.organisationId)
    );
    const cleaned: any = [];
    filter.forEach((e) => {
      cleaned.push({
        organisationId: e.organisationId,
        key: e.key.replace(phaseId + '_', ''),
        value: e.value
      });
    });
    const json = JSON.stringify(cleaned, null, 2);
    resolve({
      name: 'Export_KeyValues.json',
      blob: new Blob([json], { type: 'application/json' })
    });
  });
};

export const generateExportPropContract = (
  prop: LiegenschaftsData,
  payload: (EnlargedLvPosition & { price: number })[],
  provider: AuthUserType
) => {
  return new Promise((resolve, reject) => {
    const data = {
      property: prop,
      lvPositions: payload,
      provider: provider
    };
    const json = JSON.stringify(data, null, 2);
    let filename =
      'Export_AuftragsLV_' +
      _.camelCase(provider.organisation) +
      '_[' +
      _.camelCase(prop.nummer) +
      ']_' +
      _.kebabCase(prop.name) +
      '.json';
    resolve({
      name: filename,
      blob: new Blob([json], { type: 'application/json' })
    });
  });
};
