import { InputNumberValueChangeParams } from 'primereact/inputnumber';
import { useId } from 'react-id-generator';
import BeeErrorLabel from './BeeErrorLabel';
import BeeInfoLabel from './BeeInfoLabel';
import BeeNumberSelect from './BeeNumberSelect';

import './BeeValidatedNumberSelect.scss';

type BeeValidatedNumberSelectProps = {
  label?: string;
  errorLabel?: string | null;
  neutralLabel?: string | null;
  validLabel?: string | null;
  value?: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  stepSize: number;
  disabled?: boolean;
  grouping?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: InputNumberValueChangeParams) => void;
  onFocusOut?: (e: number | undefined) => void;
};

export function BeeValidatedNumberSelect({
  label,
  errorLabel,
  neutralLabel,
  validLabel,
  value,
  minFractionDigits,
  maxFractionDigits,
  stepSize,
  disabled,
  grouping,
  formstate,
  readOnly,
  required,
  onChange,
  onFocusOut
}: BeeValidatedNumberSelectProps) {
  const currentId = useId(1, 'bee-val-num-select-')[0];

  //classNames
  const labelClassNames = 'bee-validated-number-select';

  const textSize = 'medium';
  const inputFormstate = formstate === 'none' ? 'neutral' : formstate;

  function change(e: InputNumberValueChangeParams) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }
  function focusOut(e: number | undefined) {
    if (!disabled && onFocusOut) {
      onFocusOut(e);
    }
  }

  const renderLabel = () => {
    return (
      <div className={labelClassNames}>
        {formstate === 'error' && errorLabel ? (
          <BeeErrorLabel size={textSize} label={errorLabel} visible={true} />
        ) : formstate === 'valid' && validLabel ? (
          <BeeInfoLabel
            size={textSize}
            label={validLabel}
            type={'valid'}
            visible={true}
          />
        ) : formstate === 'neutral' && neutralLabel ? (
          <BeeInfoLabel
            size={textSize}
            label={neutralLabel}
            type={'neutral'}
            visible={true}
          />
        ) : null}
      </div>
    );
  };
  return (
    <div id={currentId}>
      <BeeNumberSelect
        label={label}
        value={value}
        minFractionDigits={minFractionDigits}
        maxFractionDigits={maxFractionDigits}
        stepSize={stepSize}
        disabled={disabled}
        grouping={grouping}
        formstate={inputFormstate}
        required={required}
        readOnly={readOnly}
        onChange={(e) => change(e)}
        onFocusOut={(e) => focusOut(e)}
      />
      {formstate !== 'none' && (errorLabel || neutralLabel || validLabel)
        ? renderLabel()
        : null}
    </div>
  );
}

export default BeeValidatedNumberSelect;
