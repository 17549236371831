import _ from 'lodash';
import { Column } from 'primereact/column';
import TreeNode from 'primereact/treenode';
import { TreeTable, TreeTableExpandedKeysType } from 'primereact/treetable';
import { useEffect, useRef, useState } from 'react';
import {
  EnlargedLvPosition,
  EpPosition
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import './AlphaAnalysisClusterTable.scss';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {
  DEFAULT_CALCULATION_MULTIPLIER,
  DEFAULT_FACTOR,
  DURATION_NOTIFICATION_ERROR_LONG,
  MAX_NUMBER_OF_DECIMALS,
  prefixActive,
  prefixInactive
} from '../../../Helper/Statics/Constants';
import { calculateGP } from '../../../Helper/Util/LvCalculator';
import BeeCurrencyInput from '../../Atoms/BeeCurrencyInput';
import { ReactComponent as MarktgebietIncompleteSVG } from '../../../Style/Icons/svgs/icons8-country-warning.svg';
import { ReactComponent as PriceInputIncompleteSVG } from '../../../Style/Icons/svgs/icons8-incompletePrice.svg';
import { Tooltip } from 'primereact/tooltip';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import { Toast } from 'primereact/toast';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import { exportAlphaClusterExcel } from '../../../Helper/StorageHelper/ExcelExportAlphaCluster';

type AlphaAnalysisClusterTableProps = {
  phase: TimelineStep;
  epTree: any;
  valueLookup: Map<string, any>;
  selectedProvider: AuthUserType[];
  epCodeLookup: Map<string, EnlargedLvPosition[]>;
};

export default function AlphaAnalysisClusterTable({
  phase,
  epTree,
  valueLookup,
  selectedProvider,
  epCodeLookup
}: AlphaAnalysisClusterTableProps) {
  const [columns, setColumns] = useState<any>([]);
  const [expanded, setExpanded] = useState<TreeTableExpandedKeysType>();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    let cols: any = [];
    if (selectedProvider) {
      selectedProvider.forEach((p: AuthUserType) => {
        cols.push({ id: p.id, header: p.organisation });
      });
    }
    setColumns(cols);
  }, [selectedProvider]);

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  function calculateRowClassName(rowData: TreeNode) {
    return {
      'priceInput-row-category-tabIndex ': rowData.data.type === 'category',
      'priceInput-row-position-noTabIndex': rowData.data.type === 'position',
      'disabled-cluster':
        rowData.className === 'p' &&
        _.startsWith('' + rowData.key, prefixInactive),
      'grey-background':
        rowData.className === 'p' &&
        _.startsWith('' + rowData.key, prefixInactive)
    };
  }

  const titleTemplate = (node: any) => {
    const epEntry: EpPosition = node.data;
    const currEpCode = _.startsWith(node.key, prefixInactive)
      ? _.trimStart(epEntry.epCode, prefixInactive)
      : _.startsWith(node.key, prefixActive)
      ? _.trimStart(epEntry.epCode, prefixActive)
      : epEntry.epCode;

    return (
      <div className={'analysisCluster-table-title'}>
        <div className={'analysisCluster-ep-code'}>{currEpCode}</div>

        <div>{`[${epEntry.number}] ${epEntry.title}`}</div>
      </div>
    );
  };

  const typeTemplate = (node: any, column: any) => {
    const typeClassName = 'analysisCluster-type';
    if (node && node.data) {
      if (node.className !== 'p') {
        return <span></span>;
      } else {
        if (
          _.startsWith(node.key, prefixInactive) ||
          _.startsWith(node.key, prefixActive)
        ) {
          return (
            <>
              <div className={typeClassName}>{'Bedarfspos.'}</div>
              <div>
                {_.startsWith(node.key, prefixActive) ? 'Aktiv' : 'Inaktiv'}
              </div>
            </>
          );
        } else {
          return <span></span>;
        }
      }
    }
  };

  const amountTemplate = (node: any) => {
    if (node && node.data && node.data.epCode) {
      const entries: any = epCodeLookup.get(node.data.epCode);
      if (entries) {
        let amount: number = 0;
        entries.forEach((e: EnlargedLvPosition) => {
          amount += e.amount!;
        });
        return (
          <>
            <div className="analysisCluster-unit ">{node.data.unit}</div>
            <div>{amount}</div>
          </>
        );
      }
    }
    return '';
  };

  const epValueTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node && node.data && node.data.epCode) {
      const allContainedEpCodes = getEpCodesFromNodes(node);
      let gpSum: number = 0;
      let amountSum: number = 0;
      let notAllPrices: boolean = false;
      let notAllPropsInArea: boolean = false;
      if (allContainedEpCodes) {
        for (let i = 0; i < allContainedEpCodes.length; i++) {
          //load all data for this provider
          const ep = allContainedEpCodes[i];
          const epPositions = epCodeLookup.get(ep);
          if (epPositions && valueLookup) {
            for (let j = 0; j < epPositions.length; j++) {
              const pos = epPositions[j];
              const amount = pos.amount ? pos.amount : 0;
              amountSum += amount;
              const priceRaw =
                valueLookup.get(pos.epPriceId) &&
                valueLookup.get(pos.epPriceId)[providerId]
                  ? valueLookup.get(pos.epPriceId)[providerId]
                  : null;
              let priceVal = 0;
              if (!priceRaw || !_.isNumber(parseInt(priceRaw))) {
                notAllPrices = true;
              } else {
                priceVal = parseInt(priceRaw);
              }
              const blFac = parseInt(
                valueLookup.get(pos.blFactorId) &&
                  valueLookup.get(pos.blFactorId)[providerId]
                  ? valueLookup.get(pos.blFactorId)[providerId]
                  : DEFAULT_FACTOR
              );
              const lsFac = parseInt(
                valueLookup.get(pos.lsFactorId) &&
                  valueLookup.get(pos.lsFactorId)[providerId]
                  ? valueLookup.get(pos.lsFactorId)[providerId]
                  : DEFAULT_FACTOR
              );
              const nFac = parseInt(
                valueLookup.get(pos.nFactorId) &&
                  valueLookup.get(pos.nFactorId)[providerId]
                  ? valueLookup.get(pos.nFactorId)[providerId]
                  : DEFAULT_FACTOR
              );
              const mVal: boolean =
                valueLookup.get(pos.mFactorId) &&
                valueLookup.get(pos.mFactorId)[providerId] === 'false'
                  ? false
                  : true;
              if (!mVal) {
                notAllPropsInArea = true;
              }
              const blVal = blFac ? blFac : DEFAULT_FACTOR;
              const lsVal = lsFac ? lsFac : DEFAULT_FACTOR;
              const nVal = nFac ? nFac : DEFAULT_FACTOR;

              gpSum = gpSum + calculateGP(amount, priceVal, blVal, lsVal, nVal);
            }
          }
        }
      }
      return (
        <div className={notAllPrices || notAllPropsInArea ? 'error-color' : ''}>
          <BeeCurrencyInput
            value={
              gpSum
                ? _.divide(
                    _.divide(gpSum, amountSum),
                    DEFAULT_CALCULATION_MULTIPLIER
                  )
                : gpSum === 0
                ? 0
                : null
            }
            disabled={false}
            minFractionDigits={MAX_NUMBER_OF_DECIMALS}
            maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
            formstate={'neutral'}
            readOnly={true}
            required={false}
          />
        </div>
      );
    }
    return '';
  };

  const gpValueTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node) {
      const allContainedEpCodes = getEpCodesFromNodes(node);
      let gpSum: number = 0;
      let notAllPrices: boolean = false;
      let notAllPropsInArea: boolean = false;
      if (allContainedEpCodes) {
        for (let i = 0; i < allContainedEpCodes.length; i++) {
          //load all data for this provider
          const ep = allContainedEpCodes[i];
          const epPositions = epCodeLookup.get(ep);

          if (epPositions && valueLookup) {
            for (let j = 0; j < epPositions.length; j++) {
              const pos = epPositions[j];
              const amount = pos.amount ? pos.amount : 0;
              const priceRaw =
                valueLookup.get(pos.epPriceId) &&
                valueLookup.get(pos.epPriceId)[providerId]
                  ? valueLookup.get(pos.epPriceId)[providerId]
                  : null;
              let priceVal = 0;
              if (!priceRaw || !_.isNumber(parseInt(priceRaw))) {
                notAllPrices = true;
              } else {
                priceVal = parseInt(priceRaw);
              }
              const blFac = parseInt(
                valueLookup.get(pos.blFactorId) &&
                  valueLookup.get(pos.blFactorId)[providerId]
                  ? valueLookup.get(pos.blFactorId)[providerId]
                  : DEFAULT_FACTOR
              );
              const lsFac = parseInt(
                valueLookup.get(pos.lsFactorId) &&
                  valueLookup.get(pos.lsFactorId)[providerId]
                  ? valueLookup.get(pos.lsFactorId)[providerId]
                  : DEFAULT_FACTOR
              );
              const nFac = parseInt(
                valueLookup.get(pos.nFactorId) &&
                  valueLookup.get(pos.nFactorId)[providerId]
                  ? valueLookup.get(pos.nFactorId)[providerId]
                  : DEFAULT_FACTOR
              );
              const mVal: boolean =
                valueLookup.get(pos.mFactorId) &&
                valueLookup.get(pos.mFactorId)[providerId] === 'false'
                  ? false
                  : true;
              if (!mVal) {
                notAllPropsInArea = true;
              }
              const blVal = blFac ? blFac : DEFAULT_FACTOR;
              const lsVal = lsFac ? lsFac : DEFAULT_FACTOR;
              const nVal = nFac ? nFac : DEFAULT_FACTOR;
              if (
                !pos.optionalPosition ||
                (pos.optionalPosition && pos.optionalPositionActive)
              ) {
                gpSum =
                  gpSum + calculateGP(amount, priceVal, blVal, lsVal, nVal);
              }
            }
          }
        }
      }

      if (
        node.className === 'p' &&
        _.startsWith(node.data.epCode, prefixInactive)
      ) {
        return (
          <BeeCurrencyInput
            value={null}
            disabled={false}
            minFractionDigits={MAX_NUMBER_OF_DECIMALS}
            maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
            formstate={'neutral'}
            readOnly={true}
            required={false}
          />
        );
      } else {
        return (
          <div
            className={notAllPrices || notAllPropsInArea ? 'error-color' : ''}
          >
            <BeeCurrencyInput
              value={
                gpSum
                  ? _.divide(gpSum, DEFAULT_CALCULATION_MULTIPLIER)
                  : gpSum === 0
                  ? 0
                  : null
              }
              disabled={false}
              minFractionDigits={MAX_NUMBER_OF_DECIMALS}
              maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
              formstate={'neutral'}
              readOnly={true}
              required={false}
            />
          </div>
        );
      }
    }
  };

  const epDiffTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node) {
      const allContainedEpCodes = getEpCodesFromNodes(node);
      let compare = [];
      for (let i = 0; i < selectedProvider.length; i++) {
        const pId = selectedProvider[i].id;
        let gpSum: number = 0;
        let notAllPrices: boolean = false;
        let notAllPropsInArea: boolean = false;
        if (allContainedEpCodes) {
          for (let i = 0; i < allContainedEpCodes.length; i++) {
            //load all data for this provider
            const ep = allContainedEpCodes[i];
            const epPositions = epCodeLookup.get(ep);
            if (epPositions && valueLookup) {
              for (let j = 0; j < epPositions.length; j++) {
                const pos = epPositions[j];
                const amount = pos.amount ? pos.amount : 0;
                const priceRaw =
                  valueLookup.get(pos.epPriceId) &&
                  valueLookup.get(pos.epPriceId)[pId]
                    ? valueLookup.get(pos.epPriceId)[pId]
                    : null;
                let priceVal = 0;
                if (!priceRaw || !_.isNumber(parseInt(priceRaw))) {
                  notAllPrices = true;
                } else {
                  priceVal = parseInt(priceRaw);
                }
                const blFac = parseInt(
                  valueLookup.get(pos.blFactorId) &&
                    valueLookup.get(pos.blFactorId)[pId]
                    ? valueLookup.get(pos.blFactorId)[pId]
                    : DEFAULT_FACTOR
                );
                const lsFac = parseInt(
                  valueLookup.get(pos.lsFactorId) &&
                    valueLookup.get(pos.lsFactorId)[pId]
                    ? valueLookup.get(pos.lsFactorId)[pId]
                    : DEFAULT_FACTOR
                );
                const nFac = parseInt(
                  valueLookup.get(pos.nFactorId) &&
                    valueLookup.get(pos.nFactorId)[pId]
                    ? valueLookup.get(pos.nFactorId)[pId]
                    : DEFAULT_FACTOR
                );
                const mVal: boolean =
                  valueLookup.get(pos.mFactorId) &&
                  valueLookup.get(pos.mFactorId)[pId] === 'false'
                    ? false
                    : true;
                if (!mVal) {
                  notAllPropsInArea = true;
                }
                const blVal = blFac ? blFac : DEFAULT_FACTOR;
                const lsVal = lsFac ? lsFac : DEFAULT_FACTOR;
                const nVal = nFac ? nFac : DEFAULT_FACTOR;
                //fixme
                if (
                  !pos.optionalPosition ||
                  (pos.optionalPosition && pos.optionalPositionActive)
                ) {
                  gpSum =
                    gpSum + calculateGP(amount, priceVal, blVal, lsVal, nVal);
                }
              }
            }
          }
        }
        //fixme styling
        //fixme icons anzeigen
        compare.push({
          id: pId,
          sum: gpSum,
          pricingComplete: !notAllPrices,
          marketComplete: !notAllPropsInArea
        });
      }
      //compare by sum
      compare = _.sortBy(compare, ['sum']);
      //search the lowest complete offer
      let benchmarkPrice = undefined;
      for (let x = 0; x < compare.length; x++) {
        if (compare[x].pricingComplete && compare[x].marketComplete) {
          benchmarkPrice = compare[x].sum;
          break;
        }
      }
      const providerEntry = _.find(compare, function (o) {
        return o.id === providerId;
      });
      if (providerEntry) {
        if (
          !_.isNumber(benchmarkPrice) ||
          !providerEntry.pricingComplete ||
          !providerEntry.marketComplete
        ) {
          return (
            <div className="error-background">
              {!providerEntry.pricingComplete ? (
                <div>
                  <PriceInputIncompleteSVG className={'priceInputIncomplete'} />
                  <Tooltip target=".priceInputIncomplete">
                    unvollständige Preiseingabe
                  </Tooltip>
                </div>
              ) : null}
              {!providerEntry.marketComplete ? (
                !providerEntry.pricingComplete ? (
                  <div>
                    <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                    <Tooltip target=".marktgebietIncomplete">
                      Nicht im Marktgebiet
                    </Tooltip>
                  </div>
                ) : (
                  <>
                    <div className=" visibility-hidden">
                      <svg />
                    </div>
                    <div>
                      <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                      <Tooltip target=".marktgebietIncomplete">
                        Nicht im Marktgebiet
                      </Tooltip>
                    </div>
                  </>
                )
              ) : null}
            </div>
          );
        } else {
          const calcValue =
            providerEntry.sum === 0
              ? 100
              : _.round((providerEntry.sum / benchmarkPrice) * 100);
          const className =
            calcValue === 100 ? 'valid-background' : 'error-background';

          if (!_.startsWith('' + node.key, prefixInactive)) {
            return (
              <div className={className}>
                <span>{calcValue + ' %'}</span>
              </div>
            );
          }
        }
      }
    }
    return '';
  };

  function getEpCodesFromNodes(node: any) {
    const result: any = [];
    function traverse(node: any) {
      const code = node && node.data ? node.data.epCode : undefined;
      if (code) {
        result.push(code);
      }
      if (_.isArray(node.children)) {
        _.each(node.children, traverse);
      }
    }
    traverse(node);
    return result;
  }

  const dynamicColumns = columns.map((col: any, i: any) => {
    const epUI = {
      className: 'aAnalysisCluster-epPrice-col',
      key: col.id + '_' + col.header + '_ep',
      body: epValueTemplate,
      width: '12em'
    };
    const gpUI = {
      className: 'aAnalysisCluster-gpPrice-col',
      key: col.id + '_' + col.header + '_gp',
      body: gpValueTemplate,
      width: '12em'
    };
    const diffUI = {
      className: 'aAnalysisCluster-diff-col',
      key: col.id + '_' + col.header + '_diff',
      body: epDiffTemplate,
      width: '7em'
    };
    const values = [epUI, gpUI, diffUI];
    return values.map((e, index) => {
      return (
        <Column
          key={e && e.key ? e.key : 'aAnalysisCluster_dynamicCol_' + index}
          className={e.className}
          body={e.body}
          header={<div key={col.id}>{col.header}</div>}
          style={{ width: e.width }}
        />
      );
    });
  });

  const headerGroup = (
    <ColumnGroup>
      <Row key="aAnalysis-cluster-headerrow-first">
        <Column
          header="Position"
          rowSpan={2}
          colSpan={1}
          style={{ width: '30em' }}
          className={'propertyLv-title-col frozen-col'}
        />
        <Column
          header="Typ"
          rowSpan={2}
          colSpan={1}
          style={{ width: '7em' }}
          className={'clusterLv-type-col frozen-col'}
        />
        <Column
          header="Menge"
          rowSpan={2}
          colSpan={1}
          style={{ width: '6em' }}
          className={'clusterLv-amount-col frozen-col'}
        />
        {columns.map((col: any) => {
          return [1, 2].map((e) => {
            return e === 1 ? (
              <Column
                header={col.header}
                colSpan={2}
                key={
                  col && col.id
                    ? 'aAnalysisCluster-col-dl_' + col.id.replaceAll(' ', '_')
                    : 'aAnalysisCluster-col-dl_' + e
                }
                style={{ width: '24em' }}
                className={'propertyLv-epGpDiff-col'}
              />
            ) : (
              <Column
                colSpan={1}
                style={{ width: '7em' }}
                className={'propertyLv-Diff-col'}
                key={
                  col && col.id
                    ? 'aAnalysisCluster-col-dl_' + col.id.replaceAll(' ', '_')
                    : 'aAnalysisCluster-col-dl_' + e
                }
              />
            );
          });
        })}
      </Row>
      <Row key="aAnalysis-cluster-headerrow-second">
        {columns.map((col: any) => {
          const values = [
            { title: '⌀ EP', width: '12em' },
            { title: 'GP', width: '12em' },
            { title: '', width: '7em' }
          ];
          return values.map((e, index) => {
            return e.title === 'Diff' ? (
              <Column
                header={e.title}
                key={
                  col && col.id
                    ? 'aAnalysisCluster-col-' +
                      e.title +
                      '_' +
                      col.id.replaceAll(' ', '_')
                    : 'aAnalysisCluster-col-' + e.title + index
                }
                style={{ width: e.width }}
              />
            ) : (
              <Column
                header={e.title}
                key={
                  col && col.id
                    ? 'aAnalysisCluster-col-' +
                      e.title +
                      '_' +
                      col.id.replaceAll(' ', '_')
                    : 'aAnalysisCluster-col-' + e.title
                }
                style={{ width: e.width }}
              />
            );
          });
        })}
      </Row>
    </ColumnGroup>
  );

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Summe:"
          colSpan={2}
          className={'clusterLv-title-col frozen-col '}
          style={{ width: '43em' }}
        />
        {columns.map((col: any) => {
          const values = ['', 'GP', 'DIFF'];
          return values.map((e, index) => {
            if (e === '') {
              return (
                <Column
                  footer={''}
                  colSpan={1}
                  key={
                    col && col.id
                      ? 'aClusterLv-footer-' +
                        index +
                        '_' +
                        col.id.replaceAll(' ', '_')
                      : 'aClusterLv-footer-' + index
                  }
                  className={'aClusterLv-footer-ep'}
                  style={{ width: '12em' }}
                />
              );
            } else if (e === 'GP') {
              return (
                <Column
                  footer={gpValueTemplate(
                    { children: epTree },
                    { props: { header: { key: col.id } } }
                  )}
                  colSpan={1}
                  key={
                    col && col.id
                      ? 'aClusterLv-footer-' +
                        e +
                        '_' +
                        col.id.replaceAll(' ', '_')
                      : 'aClusterLv-footer-' + e + '_' + index
                  }
                  className={'aClusterLv-footer-gp'}
                  style={{ width: '12em' }}
                />
              );
            } else {
              return (
                <Column
                  footer={epDiffTemplate(
                    { children: epTree },
                    { props: { header: { key: col.id } } }
                  )}
                  colSpan={1}
                  key={'aClusterLv-footer-' + e + '_' + col.pId}
                  className={'aClusterLv-footer-diff'}
                  style={{ width: '7em' }}
                />
              );
            }
          });
        })}
      </Row>
    </ColumnGroup>
  );

  return (
    <div className={'alpha-analysis-cluster-lv pt-4'}>
      <BeeContentHeadline
        label={'Kombinierte LVs'}
        headline={'h2'}
        type={'secondary'}
      />
      <p>
        Die folgende Übersicht zeigt Ihnen auf Basis obiger Filterkonfiguration
        kombinierte Leistungsverzeichnisse an. Die EPs der angezeigten
        Dienstleister entsprechen deshalb gemittelten gewichteten Preisen. Das
        bedeutet, da für eine Leistungsposition unterschiedliche Mengen pro
        Liegenschaft aufkumuliert werden und diese unterschiedliche EPs haben
        können, wird der faktorisierte EP einer Liegenschaft mit der Menge
        dieser im Verhältnis zur Gesamtmenge der angezeigten Position gewichtet.
      </p>
      <TreeTable
        value={epTree}
        expandedKeys={expanded}
        rowClassName={(rowData: TreeNode) => calculateRowClassName(rowData)}
        headerColumnGroup={headerGroup}
        footerColumnGroup={footerGroup}
        emptyMessage={'Keine Position gefunden'}
        className={'alpha-analysis-cluster-table'}
        onToggle={(e) => setExpanded(e.value)}
        scrollable
        style={{ width: '100%' }}
      >
        <Column
          field={'title'}
          columnKey={'priceinput-title-col'}
          body={titleTemplate}
          className={'clusterLv-position-col frozen-col '}
          expander
          style={{ width: '30em' }}
        />
        <Column
          columnKey={'clusterLv-type-col'}
          className={'clusterLv-type-col frozen-col '}
          body={typeTemplate}
          header={'Typ'}
          style={{ width: '7em' }}
        />
        <Column
          field={'amount'}
          columnKey={'clusterLv-amount-col'}
          body={amountTemplate}
          className={'clusterLv-amount-col frozen-col '}
          style={{ width: '6em' }}
        />
        {dynamicColumns}
      </TreeTable>
      <div className="flex justify-content-end">
        <BeeOutlinedButton
          label={
            selectedProvider && selectedProvider.length > 0
              ? 'Preisübersicht exportieren'
              : 'Leistungsübersicht exportieren'
          }
          type={'secondary'}
          disabled={!epTree}
          onClick={async () => {
            try {
              await exportAlphaClusterExcel(
                epTree,
                selectedProvider,
                epCodeLookup,
                valueLookup,
                phase
              );
            } catch (e) {
              if (toast.current) {
                toast.current.show({
                  severity: 'error',
                  summary: 'Export fehlgeschlagen',
                  detail:
                    'Beim Exportieren der Excel ist etwas fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                  sticky: false,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
            }
          }}
        />
      </div>
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
