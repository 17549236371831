import React from 'react';

import { useId } from 'react-id-generator';
import BeeErrorLabel from './BeeErrorLabel';
import BeeInfoLabel from './BeeInfoLabel';
import BeeTextArea from './BeeTextArea';

import './BeeValidatedTextArea.scss';

type BeeValidatedTextAreaProps = {
  label?: string;
  errorLabel?: string | null;
  neutralLabel?: string | null;
  validLabel?: string | null;
  value?: string;
  rows: number;
  autoResize: boolean;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocusOut?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export function BeeValidatedTextArea({
  label,
  errorLabel,
  neutralLabel,
  validLabel,
  value,
  rows,
  disabled,
  autoResize,
  formstate,
  readOnly,
  required,
  onChange,
  onFocusOut
}: BeeValidatedTextAreaProps) {
  const currentId = useId(1, 'bee-val-txtarea-')[0];

  //classNames
  const labelClassNames = 'bee-validated-text-area';

  const textSize = 'medium';
  const inputFormstate = formstate === 'none' ? 'neutral' : formstate;

  function change(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }
  function focusOut(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (!disabled && onFocusOut) {
      onFocusOut(e);
    }
  }

  const renderLabel = () => {
    return (
      <div className={labelClassNames}>
        {formstate === 'error' && errorLabel ? (
          <BeeErrorLabel size={textSize} label={errorLabel} visible={true} />
        ) : formstate === 'valid' && validLabel ? (
          <BeeInfoLabel
            size={textSize}
            label={validLabel}
            type={'valid'}
            visible={true}
          />
        ) : formstate === 'neutral' && neutralLabel ? (
          <BeeInfoLabel
            size={textSize}
            label={neutralLabel}
            type={'neutral'}
            visible={true}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div id={currentId}>
      <BeeTextArea
        label={label}
        value={value}
        rows={rows}
        disabled={disabled}
        autoResize={autoResize}
        formstate={inputFormstate}
        readOnly={readOnly}
        required={required}
        onChange={(e) => change(e)}
        onFocusOut={(e) => focusOut(e)}
      />
      {formstate !== 'none' && (errorLabel || neutralLabel || validLabel)
        ? renderLabel()
        : null}
    </div>
  );
}

export default BeeValidatedTextArea;
