import { Galleria } from 'primereact/galleria';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useId } from 'react-id-generator';
import { ImageType } from '../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import BeeContentHeadline from './BeeContentHeadline';
import BeeImage from './BeeImage';

type BeeImageSliderProps = {
  id?: string;
  type: string;
  // images: ImageType[];
  images: any;
  activeIndex: number;
};

export const BeeImageSlider = React.forwardRef(
  ({ id, type, images, activeIndex }: BeeImageSliderProps, ref) => {
    const currentId = useId(1, 'bee-img-detail-slider-')[0];
    const [index, setIndex] = useState(activeIndex);
    let gallery: any = useRef(null);

    useImperativeHandle(ref, () => ({
      openGallery,
      showImage
    }));

    const typeClassNames =
      type === 'primary'
        ? 'bee-img-detail-slider-primary'
        : type === 'secondary'
        ? 'bee-img-detail-slider-secondary'
        : 'bee-img-detail-slider-primary';

    const classNames = 'card bee-img-detail-slider';
    const headlineClassNames = 'bee-img-slider-title';
    const copyrightClassNames = 'bee-img-slider-copyright';

    function openGallery() {
      if (gallery) {
        gallery.show();
      }
    }

    function showImage(index: number) {
      setIndex(index);
    }

    const itemTemplate = (item: ImageType) => {
      return (
        <BeeImage
          img_url={item ? item.url : null}
          img_key={item ? item.key : null}
          alt={item ? item.alt : null}
          title={null}
          copyright={item && item.copyright ? item.copyright : null}
          isSecured={item && item.secured ? item.secured : false}
          showDefault={false}
          showPlaceholder={item ? item.showPlaceholder : true}
        />
      );
    };
    const thumbnailTemplate = (item: ImageType) => {
      return (
        <BeeImage
          img_url={item ? item.url : null}
          img_key={item ? item.key : null}
          alt={item ? item.alt : null}
          title={item ? item.title : null}
          copyright={item && item.copyright ? item.copyright : null}
          isSecured={item ? item.secured : false}
          showDefault={false}
          showPlaceholder={item ? item.showPlaceholder : true}
        />
      );
    };
    const caption = (item: ImageType) => {
      if (item.title || item.copyright) {
        return (
          <div>
            {item.title ? (
              <div className={headlineClassNames}>
                <BeeContentHeadline
                  label={item.title}
                  headline={'h3'}
                  type="secondary"
                />
              </div>
            ) : null}

            {/* FIXME Import imgages have string null as copyright
                 {item.copyright ? (
              <div className={copyrightClassNames}>
                <BeeContentParagraph
                  text={'© ' + item.copyright}
                  size={'xSmall'}
                />
              </div>
            ) : null} */}
          </div>
        );
      } else {
        return null;
      }
    };

    return (
      <div id={currentId} className={classNames}>
        <Galleria
          ref={(el) => (gallery = el)}
          value={images}
          className={typeClassNames}
          activeIndex={index}
          onItemChange={(e) => setIndex(e.index)}
          numVisible={7}
          circular
          fullScreen
          showItemNavigators
          showThumbnails={false}
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
          caption={caption}
        />
      </div>
    );
  }
);

export default BeeImageSlider;
