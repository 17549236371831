import { ProgressSpinner } from 'primereact/progressspinner';
import { useId } from 'react-id-generator';
import './BeeLoadingSpinner.scss';

type BeeLoadingSpinnerProps = {
  id?: string;
  strokeWidth?: string;
  type?: string;
};

export function BeeLoadingSpinner({
  id,
  strokeWidth,
  type
}: BeeLoadingSpinnerProps) {
  const genId = useId(1, 'bee-loading-spinner-')[0];
  const currentId = id ? id : genId;

  const animationDuration = '4s';

  //classNames
  const classNames =
    type === 'primary'
      ? 'bee-loading-spinner bee-loading-spinner-primary'
      : type === 'secondary'
      ? 'bee-loading-spinner bee-loading-spinner-secondary'
      : type === 'mixed'
      ? 'bee-loading-spinner bee-loading-spinner-mixed'
      : 'bee-loading-spinner bee-loading-spinner-primary';

  return (
    <ProgressSpinner
      className={classNames}
      id={currentId}
      strokeWidth={strokeWidth}
      animationDuration={animationDuration}
    />
  );
}

export default BeeLoadingSpinner;
