import { Card } from 'primereact/card';
import React, { useEffect, useState } from 'react';
import { useId } from 'react-id-generator';
//theme
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  LeistungsSlices,
  MAX_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';
import BeeIconButton from '../../Atoms/BeeIconButton';
import BeeImage from '../../Atoms/BeeImage';
import BeeValidatedCurrencyInput from '../../Atoms/BeeValidatedCurrencyInput';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import './DLLiegenschaftCard.scss';

type DLLiegenschaftCardProps = {
  property: LiegenschaftsData;
  image: ImageType | null;
  lvData?: Map<string, number>;
  activateHover: boolean;
  isInMarketArea: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClickInfo: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type ServiceGroupPrice = {
  serviceGroup: string;
  price: number;
};

export function DLLiegenschaftCard({
  property,
  image,
  lvData,
  activateHover,
  isInMarketArea,
  onClick,
  onClickInfo
}: DLLiegenschaftCardProps) {
  const currentId = useId(1, 'dl-liegenschaft-card-')[0];
  const [serviceGroupPrices, setServiceGroupPrices] =
    useState<ServiceGroupPrice[]>();

  //classNames
  const activateHoverClassNames = activateHover
    ? 'dl-liegenschaft-prev-hover'
    : 'dl-liegenschaft-prev-noHover';
  const orientationClassName = 'dl-liegenschaft-prev-card ';
  const classNames =
    'dl-liegenschaft-prev ' + orientationClassName + activateHoverClassNames;

  useEffect(() => {
    if (property) {
      const currServicegroupPrices = [];
      // leere map --> kein hinterlegtes LV
      if (lvData && lvData.size > 0) {
        for (let i = 0; i < LeistungsSlices.length; i++) {
          const tags = LeistungsSlices[i].tag ? LeistungsSlices[i].tag : [];
          let share = 0;
          for (let j = 0; j < tags.length; j++) {
            const currTag = tags[j];
            share = lvData.get(currTag) ? share + lvData.get(currTag)! : share;
          }
          currServicegroupPrices.push({
            serviceGroup: shortenServiceGroup(LeistungsSlices[i].title),
            price: share
          });
        }
        setServiceGroupPrices(currServicegroupPrices);
      }
    }
  }, [property, lvData]);

  function shortenServiceGroup(string: string) {
    return string.replace('management', 'mgmt');
  }

  function clicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (onClick) {
      onClick(e);
    }
  }

  function clickInfo(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (onClickInfo) {
      onClickInfo(e);
    }
  }

  const injectHeadline = () => {
    return (
      <>
        {property && property.adresse && property.adresse.stadt ? (
          <div>{property.adresse.stadt}</div>
        ) : (
          <div />
        )}
        <h2>{property.name}</h2>
        {property.nummer ? <div>{property.nummer}</div> : <div />}
      </>
    );
  };

  const injectImage = () => {
    if (image && property.hauptBildId) {
      return (
        <BeeImage
          img_url={image.url}
          img_key={image.key}
          alt={image.alt}
          title={image.title}
          copyright={image.copyright}
          isSecured={image.secured ? image.secured : false}
          showDefault={false}
          showPlaceholder={
            image.showPlaceholder ? image.showPlaceholder : false
          }
        />
      );
    } else if (property.hauptBildId) {
      //show loader
      return (
        <BeeImage
          img_url={''}
          img_key={''}
          alt={''}
          title={''}
          copyright={''}
          isSecured={false}
          showDefault={false}
          showPlaceholder={true}
        />
      );
    } else {
      //show default property image
      return (
        <BeeImage
          img_url={''}
          img_key={''}
          alt={''}
          title={''}
          copyright={''}
          isSecured={false}
          showDefault={true}
          showPlaceholder={false}
        />
      );
    }
  };

  function injectTableRows(disabled: boolean, property: LiegenschaftsData) {
    let sum = 0;
    const rows = serviceGroupPrices ? (
      <>
        {serviceGroupPrices.map((sg) => {
          sum = sg.price ? sum + sg.price : sum;
          return (
            <tr
              key={
                'dlLiegenschaftCardRow_' + property.id! + '_' + sg.serviceGroup
              }
            >
              <td>{sg.serviceGroup}</td>
              <td className={'card-table-price'}>
                <BeeValidatedCurrencyInput
                  label={''}
                  value={sg.price}
                  minFractionDigits={MAX_NUMBER_OF_DECIMALS}
                  maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
                  disabled={true}
                  readOnly={true}
                  required={false}
                />
              </td>
            </tr>
          );
        })}
      </>
    ) : (
      <></>
    );
    const sumRow = (
      <tr className="card-table-sum-row">
        <td>Summe</td>
        <td className={'card-table-price'}>
          <BeeValidatedCurrencyInput
            label={''}
            value={sum}
            minFractionDigits={MAX_NUMBER_OF_DECIMALS}
            maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
            disabled={true}
            readOnly={true}
            required={false}
          />
        </td>
      </tr>
    );
    return (
      <div className={disabled ? 'disabled' : ''}>
        <table role="table" className={'dl-liegenschaft-prev-card-table'}>
          <tbody>
            {lvData && lvData.size > 0 ? (
              <>
                <>{rows}</>
                <>{sumRow}</>
              </>
            ) : (
              <tr>
                <td>{'Kein LV hinterlegt'}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  function injectInfoButton() {
    return (
      <BeeIconButton
        iconClass={'icon-search'}
        rounded={true}
        disabled={false}
        type={'primary'}
        onClick={(e) => clickInfo(e)}
      />
    );
  }

  return (
    <Card id={currentId} className={classNames}>
      <div className={'dl-liegenschaft-prev-card-info'}>
        {injectInfoButton()}
      </div>
      <div onClick={(e) => clicked(e)}>
        <div
          className={
            isInMarketArea
              ? 'dl-liegenschaft-prev-card-headline'
              : 'dl-liegenschaft-prev-card-headline disabled'
          }
        >
          {injectHeadline()}
        </div>
        <div className={'dl-liegenschaft-prev-card-content'}>
          <div
            className={
              isInMarketArea
                ? 'dl-liegenschaft-prev-card-img'
                : 'dl-liegenschaft-prev-card-img disabled'
            }
          >
            {injectImage()}
          </div>
          {injectTableRows(!isInMarketArea, property)}
          {isInMarketArea ? null : (
            <div className="card-notInMarketArea">
              <span>{'Nicht im Marktgebiet'}</span>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

export default DLLiegenschaftCard;
