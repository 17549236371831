import React from 'react';
import { useId } from 'react-id-generator';
import DEFAULT_PROPERTY_IMAGE from '../../Style/Images/beestate_digital.jpg';
import './BeeImage.scss';
import BeeSkeleton from './BeeSkeleton';
import {
  generatePublicMediaAccessJWT,
  testMediaTokenExpired
} from '../../Helper/Util/JwtHelper';

type BeeImageProps = {
  id?: string;
  img_key: string | null;
  img_url: string | null;
  showPlaceholder: boolean;
  showDefault: boolean;
  isSecured: boolean;
  alt: string | null;
  title: string | null;
  titlePosition?: string;
  copyright: string | null;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export function BeeImage({
  id,
  img_key,
  img_url,
  showPlaceholder,
  showDefault,
  isSecured,
  alt,
  title,
  titlePosition,
  copyright,
  onClick
}: BeeImageProps) {
  const currentId = useId(1, 'bee-img-')[0];
  const htmlImgId = currentId + '-pay';

  //classNames
  const copyrightClassNames = 'bee-img-copyright';
  const titleClassNames =
    titlePosition === 'left'
      ? ' bee-img-title bee-img-title-left'
      : titlePosition === 'center'
      ? ' bee-img-title bee-img-title-center'
      : titlePosition === 'right'
      ? ' bee-img-title bee-img-title-right'
      : ' bee-img-title bee-img-title-left';
  const classNames = 'bee-img';

  //sizes
  const imgWidth = '100%';
  const imgHeight = '100%';
  const skeletonWidth = '100%';
  const skeletonHeight = '100px';

  function clicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (onClick) {
      onClick(e);
    }
  }
  //FIXME Axios?
  function fetchWithAuthentication(url: string, authToken: string) {
    let headers = new Headers();
    headers.set('x-bee-secure', authToken);
    headers.set('authorization', 'Bearer ' + sessionStorage.getItem('aT'));
    return fetch(url, { headers });
  }

  function fetchWithoutAuthentication(url: string) {
    let headers = new Headers();
    let mediaToken = sessionStorage.getItem('mT');
    if (!mediaToken || testMediaTokenExpired(mediaToken)) {
      //generate new one
      mediaToken = generatePublicMediaAccessJWT();
      sessionStorage.setItem('mT', '' + mediaToken);
    }
    headers.set('authorization', 'System ' + mediaToken);
    return fetch(url, { headers });
  }

  async function displayProtectedImage(
    imageId: string,
    imageUrl: string,
    authenticated: boolean
  ) {
    if (img_key && authenticated) {
      let response = await fetchWithAuthentication(imageUrl, img_key);
      // Create an object URL from the data.
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob); // Update the source of the image.
      const imageElement = document.getElementById(imageId) as HTMLImageElement;
      if (imageElement) {
        imageElement.src = objectUrl;
      }
    } else {
      let response = await fetchWithoutAuthentication(imageUrl);
      // Create an object URL from the data.
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob); // Update the source of the image.
      const imageElement = document.getElementById(imageId) as HTMLImageElement;
      if (imageElement) {
        imageElement.src = objectUrl;
      }
    }
    // imageElement.onload = () => URL.revokeObjectUrl(objectUrl); //FIXME maybe activate
  }

  const injectImage = () => {
    if (showPlaceholder) {
      return (
        <BeeSkeleton
          shape={'rectangle'}
          animated={true}
          width={skeletonWidth}
          height={skeletonHeight}
        />
      );
    } else if (showDefault) {
      return (
        <img
          id={htmlImgId}
          src={DEFAULT_PROPERTY_IMAGE}
          alt={alt ? alt : ''}
          width={imgWidth}
          height={imgHeight}
        />
      );
    } else {
      if (isSecured && img_url) {
        //return async loaded - secured image
        displayProtectedImage(htmlImgId, img_url, true);
      } else if (img_url) {
        //return image by url
        displayProtectedImage(htmlImgId, img_url, false);
      } else {
        return (
          <img
            id={htmlImgId}
            src={img_url ? img_url : ''}
            alt={alt ? alt : ''}
            width={imgWidth}
            height={imgHeight}
          />
        );
      }
      return (
        <img
          id={htmlImgId}
          src={''}
          alt={alt ? alt : ''}
          width={imgWidth}
          height={imgHeight}
        />
      );
    }
  };

  return (
    <div id={currentId} onClick={(e) => clicked(e)} className={classNames}>
      {title ? <div className={titleClassNames}>{title}</div> : null}
      {injectImage()}
      {copyright ? (
        <footer className={copyrightClassNames}>
          <small>{copyright}</small>
        </footer>
      ) : null}
    </div>
  );
}

export default BeeImage;
