import _ from 'lodash';
import { LiegenschaftsData } from '../ApiHelper/LiegenschaftenNetworkHelper';
import { AuthUserType } from '../ApiHelper/LoginNetworkHelper';
import {
  AwardCategory,
  AwardPropertyInfo,
  AwardRequest,
  AwardServiceProvider
} from '../ApiHelper/LvEpNetworkHelper';
import {
  ALPHA_DL_NOT_IN_MARKETAREA,
  ALPHA_DL_NO_PRICE
} from '../Statics/Constants';
import { calculateFakEp } from './LvCalculator';

export function generateAwardRequest(
  property: LiegenschaftsData,
  provider: AuthUserType[],
  lvTree: any
) {
  const awardRequest: AwardRequest = {
    liegenschaft: generateAwardPropertyInfo(property),
    serviceProvider: generateAwardServiceProvider(provider, lvTree),
    rootCategories: generateRootCategories(lvTree)
  };
  return awardRequest;
}

export function generateAwardDlRequest(
  property: LiegenschaftsData,
  lvTree: any
) {
  const awardRequest: AwardRequest = {
    liegenschaft: generateAwardPropertyInfo(property),
    serviceProvider: generateAwardDL(lvTree),
    rootCategories: generateRootCategories(lvTree)
  };
  return awardRequest;
}

function generateRootCategories(lvTree: any) {
  const categories: AwardCategory[] = [];
  if (lvTree) {
    for (let i = 0; i < lvTree.length; i++) {
      categories.push(transformNode(lvTree[i]));
    }
  }
  return categories;
}

function transformNode(node: any) {
  const transformedNode: AwardCategory = {
    categories: [],
    epCatalogId: node.key,
    lvPositionIds: []
  };
  for (let i = 0; i < node.children.length; i++) {
    if (node.children[i].className === 'lv-pos') {
      transformedNode.lvPositionIds!.push(node.children[i].key);
    } else {
      transformedNode.categories!.push(transformNode(node.children[i]));
    }
  }
  return transformedNode;
}

function collectPosLeafs(node: any, result: any) {
  if (!node.children || node.children.length === 0) {
    if (node.className === 'lv-pos') {
      result.push(node);
    }
  } else {
    for (let i = 0; i < node.children.length; i++) {
      collectPosLeafs(node.children[i], result);
    }
  }
}

function generateAwardDL(lvTree: any) {
  const provider: AwardServiceProvider[] = [];
  const positions: any[] = [];
  if (lvTree) {
    for (let i = 0; i < lvTree.length; i++) {
      collectPosLeafs(lvTree[i], positions);
    }
    const ASP: AwardServiceProvider = {
      organisationName: '', //this is for himself
      positions: []
    };
    positions.forEach((p) => {
      const ep = p.data
        ? calculateFakEp(
            p.data.ep, //rawPrice is stored in ep here
            p.data.blFactor,
            p.data.lsFactor,
            p.data.nFactor
          )
        : null;
      ASP.positions.push({
        id: p.key,
        amount: p.data ? p.data.amount : 0,
        ep: ep ? ep : null,
        gp: p.data ? p.data.gp : null,
        //default false because if user can trigger this he needs to have this property in marketarea
        notInMarketArea: false,
        noPriceGiven: p.data.noPriceGiven
      });
    });
    provider.push(ASP);
  }
  return provider;
}

function generateAwardServiceProvider(user: AuthUserType[], lvTree: any) {
  const provider: AwardServiceProvider[] = [];
  const positions: any[] = [];
  if (lvTree && user) {
    for (let i = 0; i < lvTree.length; i++) {
      collectPosLeafs(lvTree[i], positions);
    }
    for (let j = 0; j < user.length; j++) {
      const ASP: AwardServiceProvider = {
        organisationName: user[j].organisation ? user[j].organisation! : '',
        positions: []
      };
      const provId = user[j].id;
      positions.forEach((p) => {
        const val = p.data && p.data.values ? p.data.values[provId] : null;
        ASP.positions.push({
          id: p.key,
          amount: p.data ? p.data.amount : 0,
          ep: val && val.ep ? val.ep : null,
          gp: val && val.gp ? val.gp : null,
          notInMarketArea:
            val && val === ALPHA_DL_NOT_IN_MARKETAREA ? true : false,
          noPriceGiven: val && val === ALPHA_DL_NO_PRICE ? true : false
        });
      });
      provider.push(ASP);
    }
  }
  return provider;
}

function generateAwardPropertyInfo(property: LiegenschaftsData) {
  const propInfo: AwardPropertyInfo = {
    id: property.id ? property.id : '',
    nummer: property.nummer ? property.nummer : '',
    name: property.name ? property.name : '',
    beschreibung: property.beschreibung ? property.beschreibung : '',
    anmerkung: property.anmerkung ? property.anmerkung : '',
    eigentuemer: property.eigentuemer ? property.eigentuemer : '',
    verantwortlicher: property.verantwortlicher
      ? property.verantwortlicher
      : '',
    adresse: {
      strasse: property.adresse ? property.adresse.strasse : '',
      hausnummer: property.adresse ? property.adresse.hausnummer : '',
      postleitzahl: property.adresse ? property.adresse.postleitzahl : '',
      stadt: property.adresse ? property.adresse.stadt : '',
      region: property.adresse ? property.adresse.region : '',
      land: property.adresse ? property.adresse.land : ''
    }
  };
  return propInfo;
}
