import { useId } from 'react-id-generator';
import './BeeContentParagraph.scss';

type BeeContentParagraphProps = {
  id?: string;
  text: string;
  size?: string;
  type?: string;
};

export function BeeContentParagraph({
  id,
  text,
  size,
  type
}: BeeContentParagraphProps) {
  const genId = useId(1, 'bee-content-paragraph-')[0];
  const currentId = id ? id : genId;

  //classNames
  const typeClassnames =
    type === 'primary'
      ? ' bee-content-paragraph-primary'
      : type === 'secondary'
      ? ' bee-content-paragraph-secondary'
      : type === 'default'
      ? ''
      : '';
  const sizeClassnames =
    size === 'xSmall'
      ? ' bee-content-paragraph-xsmall'
      : size === 'small'
      ? ' bee-content-paragraph-small'
      : size === 'medium'
      ? ' bee-content-paragraph-medium'
      : size === 'large'
      ? ' bee-content-paragraph-large'
      : size === 'xLarge'
      ? ' bee-content-paragraph-xlarge'
      : ' bee-content-paragraph-medium';
  const classNames = 'bee-content-paragraph' + sizeClassnames + typeClassnames;

  return (
    <p id={currentId} className={classNames}>
      {text}
    </p>
  );
}

export default BeeContentParagraph;
