import _ from 'lodash';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { ENTER_KEY, ESCAPE_KEY } from '../../../Helper/Statics/KeyCodes';
import './AlphaUserDialog.scss';

import { User, UserRight } from '../../../Helper/ApiHelper/AuthServiceHelper';
import BeeButton from '../../Atoms/BeeButton';
import BeeTextInput from '../../Atoms/BeeTextInput';
import BeeValidatedCheckboxTriState from '../../Atoms/BeeValidatedCheckboxTriState';

import {
  USER_RIGHT_DLREAD,
  USER_RIGHT_DLWRITE_KEYVAL,
  USER_RIGHT_DLWRITE_MEDIA,
  VALIDATE_EMAIL_REGEX
} from '../../../Helper/Statics/Constants';
import BeeValidatedTextInput from '../../Atoms/BeeValidatedTextInput';

type AlphaPhaseDialogProps = {
  data?: User;
  visible: boolean;
  readOnly: boolean;
  onHide: () => void;
  onCreate: (data: User) => void;
};

export default function AlphaPhaseDialog({
  data,
  visible,
  readOnly,
  onHide,
  onCreate
}: AlphaPhaseDialogProps) {
  const [firstName, setFirstName] = useState<string>('');
  const [errorFirstName, setErrorFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [errorLastName, setErrorLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [organisation, setOrganisation] = useState<string>('');
  const [errorOrganisation, setErrorOrganisation] = useState<string>('');
  const [organisationLogo, setOrganisationLogo] = useState<string>('');
  const [rightRead, setRightRead] = useState<boolean>(true);
  const [rightWrite, setRightWrite] = useState<boolean>(true);

  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (data) {
      setFirstName(data.firstname);
      setLastName(data.lastname);
      setEmail(data.username);
      setTelephone(data.phone ? data.phone : '');
      setOrganisation(data.organisation ? data.organisation : '');
      // setrights
      const rights = data.grantedRights;
      const writeRights = _.filter(rights, function (right: UserRight) {
        return (
          right.title === USER_RIGHT_DLWRITE_KEYVAL ||
          right.title === USER_RIGHT_DLWRITE_MEDIA
        );
      });
      const userHasWriteRight =
        writeRights && writeRights.length > 1 ? true : false;

      const readRights = _.filter(rights, function (right: UserRight) {
        return right.title === USER_RIGHT_DLREAD;
      });

      const userHasReadRight =
        readRights && readRights.length > 0 ? true : false;

      setRightRead(userHasReadRight);
      setRightWrite(userHasWriteRight);
    }
  }, [data]);

  function hide() {
    if (onHide) {
      onHide();
    }
  }

  function onKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if ((e.key === ESCAPE_KEY && visible) || (e.key === ENTER_KEY && visible)) {
      hide();
    }
  }
  function createUser() {
    setErrorFirstName('');
    setErrorLastName('');
    setErrorEmail('');
    if (inputValid()) {
      if (onCreate) {
        let userRights: UserRight[] = [];
        if (rightRead) {
          userRights.push({ title: USER_RIGHT_DLREAD });
        }
        if (rightWrite) {
          userRights.push({ title: USER_RIGHT_DLWRITE_KEYVAL });
          userRights.push({ title: USER_RIGHT_DLWRITE_MEDIA });
        }
        onCreate({
          password: 'test',
          username: email,
          firstname: firstName,
          lastname: lastName,
          phone: telephone,
          organisation: organisation,
          organisationLogo: organisationLogo,
          type: 'OTHER',
          valid: true,
          registration: new Date(),
          expire: new Date(
            new Date().setFullYear(new Date().getFullYear() + 2)
          ),
          grantedRights: userRights
        });
      }
    }
  }
  function inputValid() {
    const emailIsRegexConform = VALIDATE_EMAIL_REGEX.test(email);

    if (!firstName) {
      setErrorFirstName('Bitte geben Sie einen Vornamen an.');
    }
    if (!lastName) {
      setErrorLastName('Bitte geben Sie einen Nachnamen an.');
    }
    if (!organisation) {
      setErrorOrganisation('Bitte geben Sie eine Organisation an.');
    }
    if (!email || !emailIsRegexConform) {
      setErrorEmail('Bitte geben Sie eine valide E-Mail-Adresse an.');
    }

    if (firstName && lastName && organisation && email && emailIsRegexConform) {
      return true;
    }
    return false;
  }

  function injectButtonRow() {
    return (
      <div className={'user-btns flex justify-content-between'}>
        <BeeButton
          label={readOnly ? 'Schließen' : 'Abbrechen'}
          disabled={false}
          type={'secondary'}
          isLoading={false}
          onClick={() => hide()}
        />
        {!readOnly ? (
          <BeeButton
            label={'Nutzer anlegen'}
            disabled={false} //FIXME validate
            type={'primary'}
            isLoading={false}
            onClick={() => createUser()}
          />
        ) : null}
      </div>
    );
  }

  function injectFirstName() {
    return (
      <div>
        <BeeValidatedTextInput
          label={'Vorname'}
          value={firstName}
          disabled={false}
          errorLabel={errorFirstName}
          formstate={errorFirstName ? 'error' : 'neutral'}
          readOnly={readOnly}
          required={true}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
    );
  }
  function injectLastName() {
    return (
      <div>
        <BeeValidatedTextInput
          label={'Nachname'}
          value={lastName}
          disabled={false}
          errorLabel={errorLastName}
          formstate={errorLastName ? 'error' : 'neutral'}
          readOnly={readOnly}
          required={true}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
    );
  }
  function injectEmail() {
    return (
      <div>
        <BeeValidatedTextInput
          label={'E-Mail'}
          value={email}
          disabled={false}
          errorLabel={errorEmail}
          formstate={errorEmail ? 'error' : 'neutral'}
          readOnly={readOnly}
          required={true}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
    );
  }

  function injectTelephone() {
    return (
      <div>
        <BeeTextInput
          label={'Telefonnummer'}
          value={telephone}
          disabled={false}
          formstate={'neutral'}
          readOnly={readOnly}
          required={false}
          onChange={(e) => setTelephone(e.target.value)}
        />
      </div>
    );
  }

  function injectOrganisation() {
    return (
      <div>
        <BeeValidatedTextInput
          label={'Organisation'}
          value={organisation}
          disabled={false}
          errorLabel={errorOrganisation}
          formstate={errorOrganisation ? 'error' : 'neutral'}
          readOnly={readOnly}
          required={true}
          onChange={(e) => setOrganisation(e.target.value)}
        />
      </div>
    );
  }
  function injectRightsRead() {
    return (
      <div>
        <BeeValidatedCheckboxTriState
          label={''}
          value={rightRead}
          stateNull={'Leserechte'}
          stateFalse={'Leserechte'}
          stateTrue={'Leserechte'}
          disabled={false}
          formstate={'neutral'}
          readOnly={true}
          required={true}
          onChange={(e) => setRightRead(e.target.value)}
        />
      </div>
    );
  }
  function injecRightsWrite() {
    return (
      <div>
        <BeeValidatedCheckboxTriState
          label={''}
          value={rightWrite}
          stateNull={'Schreibrechte'}
          stateFalse={'Schreibrechte'}
          stateTrue={'Schreibrechte'}
          disabled={false}
          formstate={'neutral'}
          readOnly={true}
          required={true}
          onChange={(e) => setRightWrite(e.target.value)}
        />
      </div>
    );
  }

  return (
    <div onKeyDown={(e) => onKeyPress(e)}>
      <Dialog
        header={'Nutzer anlegen'}
        visible={visible}
        className={'alpha-user-dialog'}
        modal={true}
        onHide={() => hide()}
      >
        <>
          {' '}
          <div className="grid">
            <div className="col-6"> {injectFirstName()}</div>
            <div className="col-6"> {injectLastName()}</div>{' '}
            <div className="col-12"> {injectEmail()}</div>
            <div className="col-12"> {injectOrganisation()}</div>
            <div className="col-12"> {injectTelephone()}</div>
            <div className="col-6"> {injectRightsRead()}</div>
            <div className="col-6"> {injecRightsWrite()}</div>
          </div>{' '}
          {injectButtonRow()}
        </>
      </Dialog>
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
