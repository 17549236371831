import Germany from '@svg-maps/germany';
import _ from 'lodash';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';
import { SVGMap } from 'react-svg-map';
import {
  createNewKeyValueForOrgaAndPhase,
  KeyValEntry,
  updateKeyValueForOrgaAndPhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import {
  COUNTRY,
  DEFAULT_CALCULATION_MULTIPLIER,
  DEFAULT_FACTOR,
  LEISTUNGSART,
  LeistungsSlices,
  MAX_NUMBER_OF_DECIMALS,
  MAX_VALUE_FACTOR,
  MIN_NUMBER_OF_DECIMALS,
  MIN_VALUE_FACTOR,
  NUMBER_OF_FACTORS,
  SERVER_RESPONSE_ERROR,
  SERVER_RESPONSE_PENDING,
  SERVER_RESPONSE_SUCCESS
} from '../../../Helper/Statics/Constants';
import { generateBlFactorId } from '../../../Helper/Util/IdGeneratorHelper';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeIcon from '../../Atoms/BeeIcon';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import BeeNumberInput from '../../Atoms/BeeNumberInput';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import './DlBundeslandfaktoren.scss';
import BeeSlider from '../../Atoms/BeeSlider';
import BeeSwitch from '../../Atoms/BeeSwitch';
import { checkUserParticipation } from '../../../Helper/Util/TimelineStepHelper';

type DlBundeslandfaktorenPageProps = {
  readOnly: boolean;
  countries: COUNTRY[];
  phase: TimelineStep;
  keyValues: KeyValEntry[];
};

type BlFaktorenLookUpObject = {
  id: string;
  region: string;
  slices: LEISTUNGSART[];
};

export type FaktorKeyValueStore = {
  id: string;
  value: any;
  process: string | null;
};

function DlBundeslandfaktoren({
  readOnly,
  countries,
  phase,
  keyValues
}: DlBundeslandfaktorenPageProps) {
  const [blLookUp, setBlLookUp] = useState<BlFaktorenLookUpObject[]>();
  const [keyValueStore, setKeyValueStore] = useState<{
    [key: string]: FaktorKeyValueStore;
  }>({});

  let userId: string = 'no_user';
  const extracted = extractUserId();
  userId = extracted ? extracted : userId;

  useEffect(() => {
    if (phase && keyValues) {
      let allValues: string[] = [];
      let dataArray: BlFaktorenLookUpObject[] = [];
      countries.forEach((country) =>
        country.regions.forEach((r) => {
          let pay: BlFaktorenLookUpObject = {
            id: r.id,
            region: r.region,
            slices: LeistungsSlices
          };
          dataArray.push(pay);
          //adapt allValues
          LeistungsSlices.forEach((s) =>
            allValues.push(generateBlFactorId(phase, r.id, s))
          );
        })
      );
      setBlLookUp(dataArray);
      const values: any = {};
      const promises: any = [];
      allValues.forEach((e: string) => {
        const valObj = _.find(keyValues, function (o: any) {
          return o.key === e;
        });
        if (valObj) {
          //hier habe ich daten aus dem keyValue server für diesen eintrag
          values[e] = {};
          values[e].id = valObj.id;
          values[e].value = parseInt(valObj.value);
          values[e].process = null;
        } else {
          //send change to server
          if (readOnly) {
            values[e] = {};
            values[e].id = null;
            values[e].value = null;
            values[e].process = SERVER_RESPONSE_SUCCESS;
          } else {
            promises.push(
              createNewKeyValueForOrgaAndPhase(
                userId,
                phase.id,
                e,
                '' + DEFAULT_FACTOR
              )
            );
          }
        }
      });
      if (promises.length > 0) {
        Promise.all(promises)
          .then((data: any) => {
            data.forEach((serverKeyVal: KeyValEntry) => {
              values[serverKeyVal.key] = {};
              values[serverKeyVal.key].id = serverKeyVal.id;
              values[serverKeyVal.key].value = parseInt(serverKeyVal.value);
              values[serverKeyVal.key].process = SERVER_RESPONSE_SUCCESS;
            });
            setKeyValueStore(values);
          })
          .catch(() => {
            //FIXME!!
            //show error to user
          });
      } else {
        setKeyValueStore(values);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase, keyValues]);

  ////////////////
  //// HELPER ////
  ////////////////

  function changeCellInput(val: number | null, valueKey: string) {
    let currValue = val ? val : 0;
    const dbId = keyValueStore[valueKey].id;
    if (
      keyValueStore[valueKey] &&
      (keyValueStore[valueKey].value !== currValue ||
        keyValueStore[valueKey].process === SERVER_RESPONSE_ERROR)
    ) {
      setKeyValueStore(
        (previousInputs: { [key: string]: FaktorKeyValueStore }) => ({
          ...previousInputs,
          [valueKey]: {
            id: dbId,
            value: currValue,
            process: SERVER_RESPONSE_PENDING
          }
        })
      );
      updateKeyValueForOrgaAndPhase(userId, phase.id, dbId, valueKey, '' + val)
        .then((responseValue) =>
          updateKeyValStore(dbId, valueKey, currValue, SERVER_RESPONSE_SUCCESS)
        )
        .catch(() =>
          updateKeyValStore(dbId, valueKey, currValue, SERVER_RESPONSE_ERROR)
        );
    }
  }

  function updateKeyValStore(
    id: string,
    valueKey: string,
    val: number | null,
    progress: string
  ) {
    setKeyValueStore((previousInputs: any) => ({
      ...previousInputs,
      [valueKey]: { id: id, value: val, process: progress }
    }));
  }

  function calculateColorDepth(node?: BlFaktorenLookUpObject) {
    let factorSum: number = 0;
    if (node) {
      for (let i = 0; i < node.slices.length; i++) {
        const entryId = generateBlFactorId(phase, node.id, node.slices[i]);
        factorSum +=
          keyValueStore[entryId] && keyValueStore[entryId].value
            ? keyValueStore[entryId].value
            : 0;
      }
      if (factorSum === 0) {
        //no values given/everything is 0
        return 0.1;
      }
      const diff =
        (MAX_VALUE_FACTOR - MIN_VALUE_FACTOR) * DEFAULT_CALCULATION_MULTIPLIER;
      const max = MAX_VALUE_FACTOR * DEFAULT_CALCULATION_MULTIPLIER;
      const min = MIN_VALUE_FACTOR * DEFAULT_CALCULATION_MULTIPLIER;
      const meanFactor =
        Math.round((factorSum / NUMBER_OF_FACTORS) * 100) / 100;
      if (diff >= 0) {
        const anteil = Math.round(((meanFactor - min) / (max - min)) * 10) / 10;

        if (anteil > 1) {
          return 1;
        } else if (anteil < 0 || anteil === 0) {
          return 0.0;
        } else {
          return anteil;
        }
      }
    }
    return 0;
  }

  function getLocationClassName(location: any, index: number) {
    let opacity;
    if (blLookUp) {
      const tree = _.cloneDeep(blLookUp);
      switch (location.name) {
        case 'Baden-Wurttemberg':
          const currNode_BW = _.find(tree, { id: 'BW' });
          opacity = calculateColorDepth(currNode_BW);
          break;
        case 'Bavaria':
          const currNode_B = _.find(tree, { id: 'BY' });
          opacity = calculateColorDepth(currNode_B);
          break;
        case 'Berlin':
          const currNode_Ber = _.find(tree, { id: 'BE' });
          opacity = calculateColorDepth(currNode_Ber);
          break;
        case 'Brandenburg':
          const currNode_Bra = _.find(tree, { id: 'BB' });
          opacity = calculateColorDepth(currNode_Bra);
          break;
        case 'Bremen':
          const currNode_Bre = _.find(tree, { id: 'HB' });
          opacity = calculateColorDepth(currNode_Bre);
          break;
        case 'Hamburg':
          const currNode_Ha = _.find(tree, { id: 'HH' });
          opacity = calculateColorDepth(currNode_Ha);
          break;
        case 'Hesse':
          const currNode_He = _.find(tree, { id: 'HE' });
          opacity = calculateColorDepth(currNode_He);
          break;
        case 'Mecklenburg-Vorpommern':
          const currNode_MV = _.find(tree, { id: 'MV' });
          opacity = calculateColorDepth(currNode_MV);
          break;
        case 'Lower Saxony':
          const currNode_NS = _.find(tree, { id: 'NI' });
          opacity = calculateColorDepth(currNode_NS);
          break;
        case 'North Rhine-Westphalia':
          const currNode_NW = _.find(tree, { id: 'NW' });
          opacity = calculateColorDepth(currNode_NW);
          break;
        case 'Rhineland-Palatinate':
          const currNode_RP = _.find(tree, { id: 'RP' });
          opacity = calculateColorDepth(currNode_RP);
          break;
        case 'Saarland':
          const currNode_Sa = _.find(tree, { id: 'SL' });
          opacity = calculateColorDepth(currNode_Sa);
          break;
        case 'Saxony':
          const currNode_Sach = _.find(tree, { id: 'SN' });
          opacity = calculateColorDepth(currNode_Sach);
          break;
        case 'Saxony-Anhalt':
          const currNode_SA = _.find(tree, { id: 'ST' });
          opacity = calculateColorDepth(currNode_SA);
          break;
        case 'Schleswig-Holstein':
          const currNode_SH = _.find(tree, { id: 'SH' });
          opacity = calculateColorDepth(currNode_SH);
          break;
        case 'Thuringia':
          const currNode_T = _.find(tree, { id: 'TH' });
          opacity = calculateColorDepth(currNode_T);
          break;
      }
    }
    return `svgMap_BL opacity_${
      opacity || opacity === 0 || opacity === 0.0
        ? opacity.toString().replace('.', '-')
        : ''
    } ${Germany.locations[index].name}`;
  }

  ////////////////
  // VIEW LOGIC //
  ////////////////

  function renderNumberInput(rowData: string) {
    const valueId = rowData;
    return keyValueStore && keyValueStore[valueId] ? (
      <div className={'bl-col-editor'}>
        <BeeNumberInput
          value={
            keyValueStore[valueId] && keyValueStore[valueId].value
              ? _.divide(
                  keyValueStore[valueId].value,
                  DEFAULT_CALCULATION_MULTIPLIER
                )
              : undefined
          }
          autoFocus={false}
          min={MIN_VALUE_FACTOR}
          max={MAX_VALUE_FACTOR}
          minFractionDigits={MIN_NUMBER_OF_DECIMALS}
          maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
          disabled={false}
          grouping={false}
          formstate={'neutral'}
          readOnly={false}
          required={false}
          onChange={(e) => {
            if (e && e.target && e.target.value) {
              changeCellInput(
                _.round(
                  _.multiply(e.target.value, DEFAULT_CALCULATION_MULTIPLIER)
                ),
                valueId
              );
            }
          }}
        />
      </div>
    ) : (
      injectLoadingSpinner()
    );
  }

  function injectHeadline() {
    return (
      <div>
        <BeeContentHeadline
          label={'Bundeslandfaktoren'}
          headline={'h2'}
          type={'primary'}
        />
      </div>
    );
  }

  const blBodyTemplate = (rowData: BlFaktorenLookUpObject) => {
    return <span>{rowData.region}</span>;
  };

  function injectLoadingSpinner() {
    return (
      <span className={'load-factor pending'}>
        <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
      </span>
    );
  }

  const renderBodyTemplate = (valueId: string) => {
    if (
      keyValueStore &&
      keyValueStore[valueId] &&
      (keyValueStore[valueId].value || keyValueStore[valueId].value === 0)
    ) {
      const classNames =
        keyValueStore[valueId].process &&
        keyValueStore[valueId].process === SERVER_RESPONSE_ERROR
          ? 'bl-col tooltip-factor-bl'
          : 'bl-col-body';

      return (
        <>
          <span className={readOnly ? classNames + ' disabled' : classNames}>
            <BeeNumberInput
              value={_.divide(
                keyValueStore[valueId].value,
                DEFAULT_CALCULATION_MULTIPLIER
              )}
              grouping={false}
              readOnly={true}
            />
          </span>
          {keyValueStore[valueId].process === SERVER_RESPONSE_PENDING ? (
            <span className={'status-factorChange pending'}>
              <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
            </span>
          ) : keyValueStore[valueId].process === SERVER_RESPONSE_ERROR ? (
            <>
              <span className={'status-factorChange error  tooltip-factor-bl'}>
                <BeeIcon
                  iconClass={'pi pi-exclamation-triangle'}
                  type={'primary'}
                />
                <span>{'Fehler'}</span>
              </span>
              <Tooltip
                target=".tooltip-factor-bl"
                mouseTrack
                mouseTrackLeft={10}
              >
                {
                  'Die Änderung konnte nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung.'
                }
              </Tooltip>
            </>
          ) : null}
        </>
      );
    } else {
      return <div>-</div>;
    }
  };

  function injectInputFields() {
    return (
      <DataTable value={blLookUp} breakpoint="500px" responsiveLayout="stack">
        <Column
          className={'dl-bundeslandfaktoren-bl-col'}
          field="code"
          header="Bundesland"
          body={blBodyTemplate}
        />
        {LeistungsSlices.map((slice, i) => {
          return (
            <Column
              key={slice.id}
              header={
                <span>
                  {' '}
                  <i className={slice.icon}></i>
                  <span>{slice.title}</span>
                </span>
              }
              body={(rowData: BlFaktorenLookUpObject) =>
                renderBodyTemplate(generateBlFactorId(phase, rowData.id, slice))
              }
              editor={(col: ColumnEditorOptions) => {
                return !readOnly &&
                  !(
                    keyValueStore &&
                    keyValueStore[
                      generateBlFactorId(phase, col.rowData.id, slice)
                    ]?.process === SERVER_RESPONSE_PENDING
                  )
                  ? renderNumberInput(
                      generateBlFactorId(phase, col.rowData.id, slice)
                    )
                  : renderBodyTemplate(
                      generateBlFactorId(phase, col.rowData.id, slice)
                    );
              }}
            />
          );
        })}
      </DataTable>
    );
  }

  function injectMap() {
    //https://www.npmjs.com/package/react-svg-map
    return <SVGMap map={Germany} locationClassName={getLocationClassName} />;
  }

  function injectDescription() {
    return (
      <div className={'bundesland-descr'}>
        {readOnly ? (
          <div className={'readOnly'}>
            <span>
              {
                'Sie können derzeit keine Änderungen vornehmen, da die Phase entweder bereits abgelaufen ist oder Sie nicht die benötigten Rechte besitzen. '
              }
            </span>
          </div>
        ) : (
          <div>
            <span>
              Bitte geben Sie pro Leistungsgruppe den Faktor an, mit dem der
              Rahmenpreis in einem Bundesland faktorisiert werden soll. Sie
              können Faktoren zwischen
            </span>{' '}
            <b>{MIN_VALUE_FACTOR}</b> <span> und </span>{' '}
            <b>{MAX_VALUE_FACTOR}</b> <span>angeben.</span>
            <br />
            <b> Hinweis: </b>
            <span>
              {' '}
              Bitte achten Sie auf die Einhaltung der Mindestlöhne bei der
              Wirkung der Faktoren. Zum Bearbeiten der Tabelle klicken Sie auf
              den gewünschten Faktor.
            </span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={'dl-bundeslandfaktoren '}>
      <div>{injectHeadline()}</div>
      <div>{injectDescription()}</div>
      <div className="grid">
        <div className="col-12 sm:col-12 md:col-12 lg:col-8 xl:col-9">
          <>{injectInputFields()}</>
        </div>
        {/*  //fixme styling karte */}
        <div className="dl-bl-map-col col-12 sm:col-12 md:col-12 lg:col-4 xl:col-3">
          {injectMap()}
        </div>
      </div>
    </div>
  );
}

export default DlBundeslandfaktoren;
