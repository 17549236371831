import { debounce } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { useId } from 'react-id-generator';
import BeeIconButton from './BeeIconButton';
import BeeTextInput from './BeeTextInput';

import './BeeSearchBar.scss';

type BeeSearchBarProps = {
  id?: string;
  label?: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  formstate?: string;
  onSearch: (e: string) => void;
};

export function BeeSearchBar({
  id,
  label,
  value,
  type,
  disabled,
  readOnly,
  required,
  formstate,
  onSearch
}: BeeSearchBarProps) {
  const currentId = useId(1, 'bee-searchbar-')[0];
  const reference = useRef<HTMLButtonElement>(null);
  const [term, setTerm] = useState(value);

  // classNames
  const searchBtnClassnames = 'col-fixed bee-search-bar-button-col';
  const classNames = 'bee-search-bar grid';
  const searchColClassNames = 'col bee-search-bar-input-col';
  // Icons
  const searchIcon = 'pi pi-search';
  const cancelIcon = 'pi pi-times-circle';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(() => {
      if (reference.current) {
        reference.current.click();
      }
    }, 1000),
    []
  );

  const userInputChanged = (value: string) => {
    setTerm(value);
    debouncedSearch();
  };

  const triggerSearch = () => {
    if (!disabled) {
      search(term);
    }
  };

  const search = (term: string | undefined) => {
    const currTerm = term ? term : '';
    if (onSearch) {
      onSearch(currTerm);
    }
  };

  const cancelDebounced = () => {
    if (debouncedSearch) {
      debouncedSearch.cancel();
    }
  };

  const enterPressed = () => {
    cancelDebounced();
    triggerSearch();
  };

  return (
    <div
      id={currentId}
      className={classNames}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          enterPressed();
        }
      }}
    >
      <div className={searchColClassNames}>
        <BeeTextInput
          id={currentId + '_search_in'}
          value={term}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          formstate={formstate}
          label={label}
          onChange={(e) => userInputChanged(e.target.value)}
        />
      </div>
      {readOnly ? null : (
        <div className={searchBtnClassnames}>
          <BeeIconButton
            iconClass={searchIcon}
            rounded={false}
            disabled={disabled}
            type={type}
            onClick={() => enterPressed()}
          />
          <button
            ref={reference}
            type="button"
            onClick={() => enterPressed()}
            style={{ display: 'none' }}
          />
        </div>
      )}
      {!term || term === '' ? null : (
        <div className={'search-cancel'}>
          <BeeIconButton
            iconClass={cancelIcon}
            rounded={true}
            disabled={disabled}
            type={'light'}
            onClick={(e) => {
              cancelDebounced();
              setTerm('');
              search('');
            }}
          />
        </div>
      )}
    </div>
  );
}

export default BeeSearchBar;
