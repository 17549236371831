import { useId } from 'react-id-generator';
import './BeeErrorLabel.scss';

type BeeErrorLabelProps = {
  id?: string;
  size?: string;
  label: string;
  visible?: boolean;
};

export function BeeErrorLabel({
  id,
  size,
  label,
  visible
}: BeeErrorLabelProps) {
  const genId = useId(1, 'bee-error-label-')[0];
  const currentId = id ? id : genId;

  //classNames
  const sizeClassnames =
    size === 'small'
      ? ' bee-error-label-small'
      : size === 'medium'
      ? ' bee-error-label-medium'
      : size === 'large'
      ? ' bee-error-label-large'
      : ' bee-error-label-medium';
  const classNames = 'bee-error-label ' + sizeClassnames;

  return visible ? (
    <span id={currentId} className={classNames}>
      {label}
    </span>
  ) : null;
}

export default BeeErrorLabel;
