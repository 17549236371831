import {
  STORE_ACCESS_TOKEN,
  STORE_REFRESH_TOKEN,
  STORE_TWOFAC_TOKEN,
  STORE_USERNAME,
  LOGOUT_USER,
  STORE_DL_PHASEN,
  CHANGE_USER_PARTICIPATION
} from './authActionTypes';

export const storeRefreshToken = (token: string) => {
  return {
    type: STORE_REFRESH_TOKEN,
    payload: {
      refreshToken: token
    }
  };
};

export const storeAccessToken = (token: string) => {
  return {
    type: STORE_ACCESS_TOKEN,
    payload: { accessToken: token }
  };
};

export const storeTwoFactorToken = (token: string) => {
  return {
    type: STORE_TWOFAC_TOKEN,
    payload: { twoFactorToken: token }
  };
};

export const storeUsername = (username: string) => {
  return {
    type: STORE_USERNAME,
    payload: { username: username }
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER
  };
};

export const storeDlPhasen = (json: any) => {
  return {
    type: STORE_DL_PHASEN,
    payload: { dlPhasen: json }
  };
};

export const changeUserParticipation = (flag: boolean|null) => {
  return {
    type: CHANGE_USER_PARTICIPATION,
    payload: { userParticipation: flag }
  };
};
