import { Chip } from 'primereact/chip';
import React from 'react';
import { useId } from 'react-id-generator';
import './BeeChip.scss';

type BeeChipProps = {
  id?: string;
  label?: string;
  iconClass?: string;
  removable?: boolean;
  type?: string | null;
  onRemove?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export function BeeChip({
  id,
  label,
  iconClass,
  removable,
  type,
  onRemove,
  onClick
}: BeeChipProps) {
  const genId = useId(1, 'bee-chip-')[0];
  const currentId = id ? id : genId;

  //classNames
  const typeClassNames =
    type === 'primary'
      ? 'bee-chip bee-chip-primary'
      : type === 'secondary'
      ? 'bee-chip bee-chip-secondary'
      : 'bee-chip bee-chip-primary';

  const clickClassNames = onClick ? 'bee-chip-clickable ' : '';

  const classNames = clickClassNames + typeClassNames;

  function remove(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (onRemove) {
      onRemove(e);
    }
  }

  function click(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (onClick) {
      onClick(e);
    }
  }

  return (
    <div className={classNames}>
      {removable ? (
        <div
          className={'bee-chip-remove-btn'}
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            remove(e)
          }
        >
          <i className={'pi pi-times-circle'}></i>
        </div>
      ) : null}
      <div
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => click(e)}
      >
        <Chip
          id={currentId}
          label={label}
          icon={iconClass}
          className={'bee-chip-content'}
          removable={removable}
        />
      </div>
    </div>
  );
}

export default BeeChip;
