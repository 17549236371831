import './DLRahmenpreiseingabe.scss';
import { COUNTRIES } from '../../../Helper/Statics/Constants';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import { KeyValEntry } from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import DLAusschrUnterlagen from '../../Molecules/DL/DLAusschrUnterlagen';
import DlBundeslandfaktoren from '../../Molecules/DL/DlBundeslandfaktoren';
import DLPriceInput from '../../Molecules/DL/DLPriceInput';
import DLUploadDocuments from '../../Molecules/DL/DLUploadDocuments';
import { EP } from '../../../Helper/ApiHelper/LvEpNetworkHelper';

type DLRahmenpreiseingabeProps = {
  readOnly: boolean;
  phase?: TimelineStep;
  keyValues: KeyValEntry[];
  epCatalog?: EP;
};

export function DLRahmenpreiseingabe({
  readOnly,
  phase,
  keyValues,
  epCatalog
}: DLRahmenpreiseingabeProps) {
  
  //ViewHelper for injecting Bundeslandfaktoren if phase is set
  function injectBundeslandFaktoren() {
    if (phase && keyValues) {
      return (
        <DlBundeslandfaktoren
          readOnly={readOnly}
          countries={COUNTRIES}
          keyValues={keyValues}
          phase={phase}
        />
      );
    } else {
      return null;
    }
  }

  function injectPriceInput() {
    if (phase && keyValues && epCatalog) {
      return (
        <DLPriceInput
          readOnly={readOnly}
          phase={phase}
          keyValues={keyValues}
          epCatalog={epCatalog}
        />
      );
    } else {
      return null;
    }
  }

  function injectUnterlagen() {
    return (
      <DLAusschrUnterlagen
        staticMediaIds={
          phase && phase.publicMediaIds ? phase.publicMediaIds : []
        }
        readOnly={readOnly}
      />
    );
  }

  function injectDocumentUpload() {
    if (phase && keyValues) {
      return (
        <DLUploadDocuments
          keyValues={keyValues}
          phase={phase}
          readOnly={readOnly}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <div className="dl-rahmenpreiseingabe">
      {injectBundeslandFaktoren()}
      {injectPriceInput()}
      <div className={'grid'}>
        <div className={'col-12 lg:col-12 xl:col-12'}>{injectUnterlagen()}</div>
        <div className={'col-12  lg:col-12 xl:col-12'}>
          {injectDocumentUpload()}
        </div>
      </div>
    </div>
  );
}

export default DLRahmenpreiseingabe;
