import { useEffect, useRef, useState } from 'react';
import './AlphaVergabeAnsicht.scss';
import { Toast } from 'primereact/toast';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import {
  LiegenschaftsData,
  readAllLiegenschaften
} from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import {
  EP,
  EnlargedLvPosition,
  LV,
  generateLVTreeFromEPPositions,
  readAllLvCatalogsForPhase,
  readEpCatalogForPhase
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import { useSearchParams } from 'react-router-dom';
import {
  KeyValEntry,
  MiniKeyValEntry,
  deleteKeyValueForOrgaAndPhaseForAlpha,
  readAllKeyValuesForPhaseByAlpha,
  readAllPhases,
  readAllStoredKeyValuesForOrgaAndPhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import {
  COUNTRIES,
  DEFAULT_FACTOR,
  DURATION_NOTIFICATION_ERROR_LONG,
  LEISTUNGSART,
  LeistungsSlices,
  REGION
} from '../../../Helper/Statics/Constants';
import {
  STEP_IS_ACTIVE,
  checkStepActivation
} from '../../../Helper/Util/TimelineStepHelper';
import _ from 'lodash';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeDropDown from '../../Atoms/BeeDropDown';
import {
  calculateGP,
  generateEnlargedLV
} from '../../../Helper/Util/LvCalculator';
import {
  AuthUserType,
  readAllServiceProvider
} from '../../../Helper/ApiHelper/LoginNetworkHelper';
import BeeMultiListbox, {
  BeeClusterFilter
} from '../../Molecules/Alpha/BeeMultiListbox';
import AlphaTargetPricing from '../../Molecules/Alpha/AlphaTargetPricing';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import {
  generateTargetPriceKey,
  isKeyAwardingKey,
  isKeyParticipationKey
} from '../../../Helper/Util/IdGeneratorHelper';
import AlphaVergabeTable from '../../Molecules/Alpha/AlphaVergabeTable';
import AlphaVergabeProviderTable from '../../Molecules/Alpha/AlphaVergabeProviderTable';

export default function AlphaVergabeAnsicht() {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [dataLoadingError, setDataLoadingError] = useState<string>();
  const [phasen, setPhasen] = useState<TimelineStep[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<TimelineStep>();
  const [keyValLookup, setKeyValLookup] = useState<any>();
  const [awarding, setAwarding] = useState<Map<string, KeyValEntry>>(new Map());
  const [lvLookup, setLvLookup] = useState<Map<string, EnlargedLvPosition>>(
    new Map()
  );
  const [currentLvTree, setCurrentLvTree] = useState<any>();
  const [targetPriceId, setTargetPriceId] = useState<string>('');
  const [targetPriceLookup, setTargetPriceLookup] = useState<Map<string, any>>(
    new Map()
  );
  const [properties, setProperties] = useState<LiegenschaftsData[]>([]);
  const [currentServiceProvider, setCurrentServiceProvider] = useState<
    AuthUserType[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useRef<Toast>(null);
  //selectedStuff
  const [selectedProperties, setSelectedProperties] = useState<
    LiegenschaftsData[]
  >([]);
  const [selectedProvider, setSelectedProvider] = useState<AuthUserType[]>([]);
  const [selectedSlices, setSelectedSlices] = useState<LEISTUNGSART[]>([]);
  const [lookup, setLookup] = useState<any>();
  const [kvRaw, setKvRaw] = useState<MiniKeyValEntry[]>();


  /////////////////////
  /// STATE EFFECTS ///
  /////////////////////

  useEffect(() => {
    readAllPhases()
      .then((json: any) => {
        let steps: TimelineStep[] = [...json];
        setPhasen(steps);
        let activePhase: any;
        for (let i = 0; i < steps.length; i++) {
          if (checkStepActivation(steps[i]) === STEP_IS_ACTIVE) {
            activePhase = steps[i];
            if (searchParams.get('pId') !== activePhase.id) {
              searchParams.set('pId', activePhase.id);
              searchParams.set('cId', 'new');
              setSearchParams(searchParams);
            }
            break;
          }
        }
        if (!activePhase) {
          if (steps.length > 0) {
            if (searchParams.get('pId') !== steps[0]?.id) {
              searchParams.set('pId', steps[0]?.id);
              searchParams.set('cId', 'new');
              setSearchParams(searchParams);
            }
          } else {
            toast.current?.show({
              severity: 'error',
              summary: 'Keine Phasen vorhanden',
              detail:
                'Aktuell wurden noch keine Projektphasen hinterlegt. Bitte erstellen Sie zunächst die Projektphasen und versuchen es später erneut. ',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        }
      })
      .catch((error) => {
        toast.current?.show({
          severity: 'error',
          summary: 'Fehler beim Laden benötigter Daten',
          detail:
            'Leider konnten die Phasen nicht geladen werden. Bitte versuchen Sie es später erneut. ' +
            error,
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataLoadingError('');
    if (phasen) {
      const phaseId = searchParams.get('pId');
      //if phase changed
      if (!(selectedPhase && selectedPhase.id === phaseId)) {
        const currPhase: any = _.find(phasen, { id: phaseId });
        if (currPhase) {
          setSelectedPhase(currPhase);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, phasen]);

  useEffect(() => {
    if (selectedPhase) {
      setDataLoadingError('');
      setDataIsLoading(true);
      const propertyIds: string[] = selectedPhase.liegenschaftIds
        ? selectedPhase.liegenschaftIds
        : [];
      const providerIds: string[] = selectedPhase.userIds
        ? selectedPhase.userIds
        : [];
      readAllServiceProvider()
        .then((userEntries: any) => {
          let prov = _.filter(userEntries, (entry) =>
            _.includes(providerIds, entry.id)
          );
          prov = _.sortBy(prov, ['organisation', 'username']);
          readEpCatalogForPhase(selectedPhase.id)
            .then((epData: any) => {
              const epLookup: any = setupEpLookup(epData);
              let lvTree = generateLVTreeFromEPPositions(epData.data);
              readAllLiegenschaften()
                .then((props: any) => {
                  const properties = _.filter(props, (entry) =>
                    _.includes(propertyIds, entry.id)
                  );
                  readAllLvCatalogsForPhase(selectedPhase.id).then(
                    (dataLvs: any) => {
                      const allLvs: LV[] = _.filter(dataLvs.data, (entry) =>
                        _.includes(propertyIds, entry.info.propertyId)
                      );
                      if (allLvs.length !== propertyIds.length) {
                        toast.current?.show({
                          severity: 'info',
                          summary: 'Liegenschaften ohne Leistungsverzeichnis',
                          detail:
                            'Leider wurden noch nicht zu allen Liegenschaften Leistungsverzeichnisse hinterlegt. Bitte vervollständigt die LVs über den entsprechenden Tab um aussagekräftige Ergebisse zu erhalten.',
                          sticky: false,
                          closable: true,
                          life: DURATION_NOTIFICATION_ERROR_LONG
                        });
                      }
                      let lvLook = new Map<string, any>();
                      allLvs.forEach((lv: LV) => {
                        if (lv && lv.data && lv.info) {
                          //init enlarged LV for each property
                          const property = _.find(
                            properties,
                            function (pr: LiegenschaftsData) {
                              return pr.id === lv.info.propertyId;
                            }
                          );
                          if (property) {
                            //search bundesland
                            let region = null;
                            for (let i = 0; i < COUNTRIES.length; i++) {
                              const c = COUNTRIES[i];
                              const val = _.find(
                                c.regions,
                                function (reg: REGION) {
                                  return (
                                    reg.region === property.adresse!.region
                                  );
                                }
                              );
                              region = val ? val : region;
                            }
                            const blID = region ? region.id : '';
                            const enlargedLvPos = generateEnlargedLV(
                              lv.data,
                              property,
                              blID,
                              selectedPhase,
                              epLookup
                            );
                            lvLook.set(lv.info.propertyId, enlargedLvPos);
                          }
                        }
                      });
                      readAllKeyValuesForPhaseByAlpha(selectedPhase.id)
                        .then((keyvals: any) => {
                          let participatingProviderId: string[] = [];
                          let valueLookup: any = new Map();
                          if (keyvals && keyvals.length > 0) {
                            keyvals.forEach((kv: MiniKeyValEntry) => {
                              let entry = valueLookup.get(kv.key);
                              entry = entry ? entry : {};
                              entry[kv.organisationId] = kv.value;
                              valueLookup.set(kv.key, entry);
                              //create rejectedOffers
                              if (isKeyParticipationKey(kv.key)) {
                                if (kv.value === 'true') {
                                  participatingProviderId.push(
                                    kv.organisationId
                                  );
                                }
                              }
                            });
                          }
                          let userId = extractUserId();
                          userId = userId ? userId : '';
                          readAllStoredKeyValuesForOrgaAndPhase(
                            userId,
                            selectedPhase.id
                          )
                            .then((values: any) => {
                              //filter provider by rejection
                              prov = _.filter(prov, (entry) =>
                                _.includes(participatingProviderId, entry.id)
                              );
                              setupAlphaLookup(values, selectedPhase.id, prov);
                              calculatePositionLookup(
                                properties,
                                LeistungsSlices,
                                valueLookup,
                                prov,
                                lvLook
                              );
                              setCurrentLvTree(lvTree);
                              setLvLookup(lvLook);
                              setProperties(properties);
                              setCurrentServiceProvider(prov);
                              setDataIsLoading(false);
                              setKeyValLookup(valueLookup);
                              setKvRaw(keyvals);
                            })
                            .catch(() => {
                              setDataIsLoading(false);
                              setDataLoadingError(
                                'Beim Laden Ihrer Daten vom Server ist etwas schiefgelaufen. Die Vergabedaten und Zielpreise konnten nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.'
                              );
                              if (toast.current) {
                                toast.current.show({
                                  severity: 'error',
                                  summary: 'Laden der Daten fehlgeschlagen',
                                  detail:
                                    'Beim Laden Ihrer Daten vom Server ist etwas schiefgelaufen. Die Vergabedaten und Zielpreise konnten nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                                  sticky: true,
                                  closable: true,
                                  life: DURATION_NOTIFICATION_ERROR_LONG
                                });
                              }
                            });
                        })
                        .catch(() => {
                          setDataIsLoading(false);
                          setDataLoadingError(
                            'Das Laden der Dienstleisterdaten vom Server ist schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.'
                          );

                          if (toast.current) {
                            toast.current.show({
                              severity: 'error',
                              summary: 'Laden der Daten fehlgeschlagen',
                              detail:
                                'Das Laden der Dienstleisterdaten vom Server ist schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                              sticky: true,
                              closable: true,
                              life: DURATION_NOTIFICATION_ERROR_LONG
                            });
                          }
                        });
                    }
                  );
                })
                .catch(() => {
                  setDataIsLoading(false);
                  setDataLoadingError(
                    'Das Laden der LVs vom Server ist schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.'
                  );
                  if (toast.current) {
                    toast.current.show({
                      severity: 'error',
                      summary: 'Laden der Daten fehlgeschlagen',
                      detail:
                        'Das Laden der LVs vom Server ist schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                      sticky: true,
                      closable: true,
                      life: DURATION_NOTIFICATION_ERROR_LONG
                    });
                  }
                })
                .catch(() => {
                  setDataIsLoading(false);
                  setDataLoadingError(
                    'Das Laden der Liegenschaften vom Server ist schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.'
                  );
                  if (toast.current) {
                    toast.current.show({
                      severity: 'error',
                      summary: 'Laden der Daten fehlgeschlagen',
                      detail:
                        'Das Laden der Liegenschaften vom Server ist schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                      sticky: true,
                      closable: true,
                      life: DURATION_NOTIFICATION_ERROR_LONG
                    });
                  }
                });
            })
            .catch(() => {
              setDataIsLoading(false);
              setDataLoadingError(
                'Das Laden des EP-Katalogs vom Server ist schiefgelaufen. Bitte stellen Sie sicher, dass ein EP-Katalog für diese Phase hinterlegt ist und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.'
              );
              if (toast.current) {
                toast.current.show({
                  severity: 'error',
                  summary: 'Laden der Daten fehlgeschlagen',
                  detail:
                    'Das Laden des EP-Katalogs vom Server ist schiefgelaufen. Bitte stellen Sie sicher, dass ein EP-Katalog für diese Phase hinterlegt ist und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
            });
        })
        .catch(() => {
          setDataIsLoading(false);
          setDataLoadingError(
            'Das Laden der Dienstleister vom Server ist schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.'
          );
          if (toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Laden der Daten fehlgeschlagen',
              detail:
                'Das Laden der Dienstleister vom Server ist schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhase]);

  function setupEpLookup(catalog: EP) {
    if (catalog && catalog.data) {
      const lookup = new Map();
      catalog.data.forEach((e) => {
        if (e.epCode) {
          lookup.set(e.epCode, e);
        }
      });
      return lookup;
    }
  }

  function setupAlphaLookup(
    values: KeyValEntry[],
    phaseId: string,
    validProvider: AuthUserType[]
  ) {
    //setup targetprices & awarding-lookup
    const search = generateTargetPriceKey(phaseId);
    if (values) {
      const entry: KeyValEntry | undefined = _.find(
        values,
        function (o: KeyValEntry) {
          return o.key === search;
        }
      );
      if (entry) {
        setTargetPriceId(entry.id);
        if (entry.value) {
          let tmp: any = JSON.parse(entry.value);
          const map: any = new Map(tmp.map((obj: any) => [obj.key, obj.value]));
          setTargetPriceLookup(map);
        }
      }
    }
    const deleteAwardingIds: string[] = [];
    const vProviderIds: string[] = [];
    validProvider.forEach((p: AuthUserType) => vProviderIds.push(p.id));
    //awarding-lookup
    let award = new Map<string, KeyValEntry>();
    values.forEach((v: KeyValEntry) => {
      if (isKeyAwardingKey(v.key)) {
        if (_.includes(vProviderIds, v.value)) {
          award.set(v.key, v);
        } else {
          //provider rejected his offer
          deleteAwardingIds.push(v.id);
        }
      }
    });
    setAwarding(award);
    //if deletion is necessary
    if (deleteAwardingIds.length > 0 && selectedPhase) {
      let userId = extractUserId();
      userId = userId ? userId : '';
      const promiseArray: any[] = [];
      deleteAwardingIds.forEach((id: string) => {
        promiseArray.push(
          deleteKeyValueForOrgaAndPhaseForAlpha(userId!, selectedPhase.id, id)
        );
      });
      Promise.all(promiseArray)
        .then(() => {
          if (toast.current) {
            toast.current.show({
              severity: 'info',
              summary: 'Vergaben wurden angepasst',
              detail:
                'Ausgewählte Pakete waren an einen Dienstleister vergeben, der sein Angebot zurückgezogen hat. Diese Auswahl wurde erfolgreich zurückgesetzt.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        })
        .catch(() => {
          if (toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Vergabe an nicht vorhandenen Dienstleister',
              detail:
                'Es wurden Pakete an Dienstleister vergeben, die ihr Angebot zurückgezogen haben. Diese Auswahl konnte nicht automatisch behoben und an den Server übermittelt werden. Bitte laden Sie die Seite neu.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  //////////////////////
  /// BUSINESS LOGIC ///
  //////////////////////

  function calculatePositionLookup(
    properties: LiegenschaftsData[],
    slices: LEISTUNGSART[],
    kvLookup: Map<string, any>,
    provider: AuthUserType[],
    lvLookup: Map<string, EnlargedLvPosition[]>
  ) {
    let pl: any = {};
    properties.forEach((p: LiegenschaftsData) => {
      let pr: any = {};
      const lv: any = lvLookup.get(p.id!);
      slices.forEach((s: LEISTUNGSART) => {
        let ls: any = {};
        //filter lv pos
        let filteredLv = lv ? lv : [];
        if (s.tag) {
          filteredLv = _.filter(filteredLv, (pos) =>
            _.includes(s.tag, pos.sliceTag)
          );
        }
        provider.forEach((sp: AuthUserType) => {
          let spr: any = {};
          //calculate values for each provider/slice/property
          let gpSum: number = 0;
          let notAllPrices: boolean = false;
          let notInArea: boolean = false;
          //Fixme
          for (let i = 0; i < filteredLv.length; i++) {
            const pos = filteredLv[i];
            const amount = pos.amount ? pos.amount : 0;
            const priceRaw = kvLookup.get(pos.epPriceId)
              ? kvLookup.get(pos.epPriceId)[sp.id]
              : null;
            let priceVal = 0;
            if (!priceRaw) {
              notAllPrices = true;
            } else {
              priceVal = parseInt(priceRaw);
            }
            const blFac = parseInt(
              kvLookup.get(pos.blFactorId) &&
                kvLookup.get(pos.blFactorId)[sp.id]
                ? kvLookup.get(pos.blFactorId)[sp.id]
                : DEFAULT_FACTOR
            );
            const lsFac = parseInt(
              kvLookup.get(pos.lsFactorId) &&
                kvLookup.get(pos.lsFactorId)[sp.id]
                ? kvLookup.get(pos.lsFactorId)[sp.id]
                : DEFAULT_FACTOR
            );
            const nFac = parseInt(
              kvLookup.get(pos.nFactorId) && kvLookup.get(pos.nFactorId)[sp.id]
                ? kvLookup.get(pos.nFactorId)[sp.id]
                : DEFAULT_FACTOR
            );
            const mVal: boolean =
              kvLookup.get(pos.mFactorId) &&
              kvLookup.get(pos.mFactorId)[sp.id] === 'false'
                ? false
                : true;
            if (!mVal) {
              notInArea = true;
            }
            const blVal = blFac ? blFac : DEFAULT_FACTOR;
            const lsVal = lsFac ? lsFac : DEFAULT_FACTOR;
            const nVal = nFac ? nFac : DEFAULT_FACTOR;

            if (
              !pos.optionalPosition ||
              (pos.optionalPosition && pos.optionalPositionActive)
            ) {
              gpSum += calculateGP(amount, priceVal, blVal, lsVal, nVal);
            }
          }
          //use calculated data
          spr = {
            sum: gpSum,
            inComplete: notAllPrices,
            notInMarket: notInArea
          };
          ls[sp.id] = spr;
        });
        pr[s.id] = ls;
      });
      pl[p.id!] = pr;
    });
    setLookup(pl);
  }

  function adaptData(filter: BeeClusterFilter) {
    //filter selected ServiceProvider
    if (currentServiceProvider) {
      if (filter.serviceProviderIds) {
        setSelectedProvider(
          _.filter(currentServiceProvider, (sp) =>
            _.includes(filter.serviceProviderIds, sp.id)
          )
        );
      }
    }
    //filter selected properties
    if (properties) {
      if (filter.propertyIds) {
        setSelectedProperties(
          _.filter(properties, (pr) => _.includes(filter.propertyIds, pr.id))
        );
      }
    }
    if (LeistungsSlices) {
      if (filter.sliceTags) {
        const slices: any = _.filter(
          LeistungsSlices,
          function (sl: LEISTUNGSART) {
            return _.intersection(filter.sliceTags, sl.tag).length > 0;
          }
        );
        setSelectedSlices(slices);
      }
    }
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  const injectPayload = () => {
    if (dataIsLoading) {
      return (
        <div className={'bee-page-spinner'}>
          <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
        </div>
      );
    }
    if (selectedPhase) {
      return (
        <div>
          <BeeMultiListbox
            phaseId={selectedPhase.id}
            properties={properties}
            serviceProvider={currentServiceProvider}
            onAdaptSelection={(filter: BeeClusterFilter) => adaptData(filter)}
          />
          <div className={'alpha-vergabe-area mt-6'}>
            <AlphaTargetPricing
              phaseId={selectedPhase.id}
              properties={properties}
              targetPriceLookup={targetPriceLookup}
              targetPriceId={targetPriceId}
              onAdaptTarget={(payload: any) => setTargetPriceLookup(payload)}
            />
            <AlphaVergabeTable
              phaseId={selectedPhase.id}
              selectedProperties={selectedProperties}
              selectedProvider={selectedProvider}
              selectedSlices={selectedSlices}
              allProperties={properties}
              allProvider={currentServiceProvider}
              allValues={keyValLookup}
              lookup={lookup}
              awarding={awarding}
              targetPriceLookup={targetPriceLookup}
              lvLookup={lvLookup}
              lvTree={currentLvTree}
              kvRaw={kvRaw!}
              onUpdateAwarding={(entry: KeyValEntry, key: string) => {
                let newAward = _.cloneDeep(awarding);
                newAward.set(key, entry);
                setAwarding(newAward);
              }}
            />
          </div>
          <div className={'mt-6'}>
            <AlphaVergabeProviderTable
              phaseId={selectedPhase.id}
              selectedProperties={selectedProperties}
              selectedProvider={selectedProvider}
              selectedSlices={selectedSlices}
              lookup={lookup}
              awarding={awarding}
            />
          </div>
        </div>
      );
    }
  };

  const injectHeadline = () => {
    return (
      <div>
        <div className="alpha-vergabe-headline flex align-items-center justify-content-between">
          <BeeContentHeadline
            label={'Vergabeansicht'}
            headline={'h1'}
            type={'primary'}
          />
          <BeeDropDown
            label={'Ausgewählte Phase'}
            value={selectedPhase}
            options={phasen}
            optionLabel={'title'}
            disabled={false}
            formstate={'neutral'}
            readOnly={false}
            required={false}
            onChange={(e: any) => {
              searchParams.set('pId', e.id);
              searchParams.set('cId', 'new');
              setSearchParams(searchParams);
            }}
          />
        </div>
        {!dataLoadingError ? (
          <div className="mb-5">
            <span>
              Folgende Ansicht ermöglicht die Vergabe der Leistungen. Die Filter
              beeinflussen die angezeigten Liegenschaften und Leistungsgruppen.
              Die Einschränkung der Dienstleister wird bei der Vergabe einer
              Leistungsgruppe kenntlich gemacht.
            </span>
            <br /> <br />
            <b>
              Achtung: Das Durchführen der Vergabe macht erst nach Ablauf der
              letzten Angebotsphase Sinn. Achten Sie zudem darauf über das
              Dropdown die letzte Angebotsphase auszuwählen, um die finalen
              Angebote der Dienstleister zu vergleichen.
            </b>
          </div>
        ) : (
          <div className="error-color">{dataLoadingError}</div>
        )}
      </div>
    );
  };

  //return <ErrorNoDataView />;
  return (
    <div className={'alpha-vergabe-ansicht m-4 pt-3 justify-content-start'}>
      {injectHeadline()}
      {!dataLoadingError ? injectPayload() : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
