import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import { useId } from 'react-id-generator';

import './BeeBreadcrumb.scss';
type BeeBreadcrumbProps = {
  id?: string;
  border?: boolean;
  items?: MenuItem[];
  home: MenuItem;
  type: string;
};

export function BeeBreadcrumb({
  id,
  border,
  items,
  home,
  type
}: BeeBreadcrumbProps) {
  const currentId = useId(1, 'bee-breadcrumb-')[0];

  //classNames
  const typeClassNames =
    type === 'primary'
      ? 'bee-breadcrumb-primary'
      : type === 'secondary'
      ? 'bee-breadcrumb-secondary'
      : type === 'default'
      ? 'bee-breadcrumb-default'
      : 'bee-breadcrumb-default';
  const borderClassNames = border ? '' : ' bee-breadcrumb-no-border';
  const classNames = 'bee-breadcrumb ' + typeClassNames + borderClassNames;

  return (
    <div id={currentId} className={classNames}>
      <BreadCrumb model={items} home={home} />
    </div>
  );
}

export default BeeBreadcrumb;
