import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { useId } from 'react-id-generator';

import './BeeCheckboxTriState.scss';
import BeeIcon from './BeeIcon';
type BeeCheckboxTriStateProps = {
  label?: string;
  stateNull?: string;
  stateFalse?: string;
  stateTrue?: string;
  value?: boolean | null | undefined;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: any) => void;
};
export function BeeCheckboxTriState({
  label,
  stateNull,
  stateFalse,
  stateTrue,
  value,
  disabled,
  required,
  formstate,
  readOnly,
  onChange
}: BeeCheckboxTriStateProps) {
  const currentId = useId(1, 'bee-tri-checkbox-')[0];
  const inputId = currentId + 'input';

  //classNames
  const formStateClassnames =
    formstate === 'valid'
      ? ' bee-tri-state-valid'
      : formstate === 'error'
      ? ' bee-tri-state-error'
      : formstate === 'neutral'
      ? ' bee-tri-state-neutral'
      : ' bee-tri-state-neutral';
  let readOnlyClassnames = '';
  if (readOnly) {
    readOnlyClassnames = ' bee-tri-state-readOnly';
    disabled = true;
  }
  const disabledClassNames = disabled ? ' bee-tri-state-disabled' : '';
  const classNames =
    'bee-tri-state ' +
    formStateClassnames +
    readOnlyClassnames +
    disabledClassNames;
  const inputClassNames = 'bee-tri-state-input';
  const labelClassNames = label ? 'bee-tri-state-label' : '';
  const requiredClassName = 'bee-tri-state-label-required';
  const requiredStarClassName = 'bee-tri-state-label-required-star';
  const stateLabelClassName = 'bee-tri-state-value';

  //placeholder
  const checkPlaceholder = 'pi pi-check';
  const timesPlaceholder = 'pi pi-times';
  const questionPlaceholder = 'pi pi-question';
  const placeholderType = 'primary';
  const placeholderSize = 'large';

  function change(e: any) {
    console.log(e);
    if (!disabled && onChange) {
      onChange(e);
    }
  }

  function changeValueOnKeyDown(event: any) {
    if (event.keyCode === 13) {
      if (value === true) {
        change(false);
      } else if (value === false) {
        change(null);
      } else if (value === null || value === undefined) {
        change(true);
      }
    }
  }

  const renderCheckPlaceholder = () => {
    return (
      <BeeIcon
        iconClass={checkPlaceholder}
        type={placeholderType}
        size={placeholderSize}
      />
    );
  };

  const renderTimesPlaceholder = () => {
    return (
      <BeeIcon
        iconClass={timesPlaceholder}
        type={placeholderType}
        size={placeholderSize}
      />
    );
  };

  const renderQuestionPlaceholder = () => {
    return (
      <BeeIcon
        iconClass={questionPlaceholder}
        type={placeholderType}
        size={placeholderSize}
      />
    );
  };

  return (
    <div
      id={currentId}
      className={classNames}
      onKeyDown={(e) => {
        changeValueOnKeyDown(e);
      }}
    >
      <div className={inputClassNames}>
        {readOnly ? (
          <>
            {value === true
              ? renderCheckPlaceholder()
              : value === false
              ? renderTimesPlaceholder()
              : renderQuestionPlaceholder()}
          </>
        ) : (
          <>
            <TriStateCheckbox
              value={value}
              onChange={(e) => {
                console.log(e);
                change(e.value);
              }}
            />
          </>
        )}
        <label className={stateLabelClassName} htmlFor={inputId}>
          {value === true
            ? stateTrue
            : value === false
            ? stateFalse
            : stateNull}
        </label>
      </div>
      {label ? (
        <label htmlFor={inputId} className={labelClassNames}>
          {label}
          {required && label ? (
            <span className={requiredClassName}>
              <span className={requiredStarClassName}> *</span>
            </span>
          ) : null}
        </label>
      ) : null}
    </div>
  );
}

export default BeeCheckboxTriState;
