import './AlphaSaveFilterDialog.scss';
import { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { ESCAPE_KEY } from '../../../Helper/Statics/KeyCodes';
import { Dialog } from 'primereact/dialog';
import BeeTextInput from '../../Atoms/BeeTextInput';
import { BeeClusterFilter } from '../Alpha/BeeMultiListbox';
import BeeButton from '../../Atoms/BeeButton';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import { generateAlphaFilterId } from '../../../Helper/Util/IdGeneratorHelper';
import _ from 'lodash';
import { createNewKeyValueForOrgaAndPhaseForAlpha, updateKeyValueForOrgaAndPhaseForAlpha } from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';

type AlphaSaveFilterDialogProps = {
  visible: boolean;
  phaseId: string;
  currentFilter: BeeClusterFilter;
  knownFilter: BeeClusterFilter[];
  storageId?: string;
  onAccept: (data: any) => void;
  onHide: () => void;
};

export function AlphaSaveFilterDialog({
  visible,
  phaseId,
  currentFilter,
  knownFilter,
  storageId,
  onAccept,
  onHide
}: AlphaSaveFilterDialogProps) {
  const [title, setTitle] = useState<string>('');
  const [progress, setProgress] = useState<boolean>(false);
  const toast = useRef<Toast>(null);

  function accept() {
    if (onAccept) {
      setProgress(true);
      const userId = extractUserId();
      const filterKey = generateAlphaFilterId(phaseId);
      let values: any = _.cloneDeep(knownFilter);
      let currFilter = _.cloneDeep(currentFilter);
      currFilter.title = title;
      values.push(currFilter);
      const val = JSON.stringify(values);
      if (storageId) {
        updateKeyValueForOrgaAndPhaseForAlpha(
          userId!,
          phaseId,
          storageId,
          filterKey,
          val
        )
          .then((data: any) => {
            onAccept(data);
            setProgress(false);
          })
          .catch(() => {
            if (toast && toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Filter konnten nicht gespeichert werden',
                detail:
                  'Der gewünschte Filter konnte leider nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
            setProgress(false);
          });
      } else {
        createNewKeyValueForOrgaAndPhaseForAlpha(userId!, phaseId, filterKey, val)
          .then((data: any) => {
            onAccept(data);
            setProgress(false);
          })
          .catch(() => {
            if (toast && toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Filter konnten nicht gespeichert werden',
                detail:
                  'Der gewünschte Filter konnte leider nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
            setProgress(false);
          });
      }
    }
  }

  function onKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === ESCAPE_KEY && visible) {
      onHide();
    }
  }

  return (
    <div onKeyDown={(e) => onKeyPress(e)}>
      <Dialog
        header={'Filter speichern'}
        visible={visible}
        modal={true}
        onHide={() => onHide()}
        className={'alpha-save-filter-dialog'}
      >
        <div>
          <BeeTextInput
            label={'Titel'}
            value={title}
            disabled={false}
            formstate={'neutral'}
            readOnly={false}
            required={false}
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className={'flex justify-content-between'}>
            <BeeButton
              label={'Abbrechen'}
              disabled={false}
              type={'primary'}
              isLoading={false}
              onClick={() => onHide()}
            />
            <BeeButton
              label={'Speichern'}
              disabled={!title}
              type={'secondary'}
              isLoading={progress}
              onClick={() => accept()}
            />
          </div>
        </div>
      </Dialog>
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}

export default AlphaSaveFilterDialog;
