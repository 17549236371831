import { CalendarChangeParams } from 'primereact/calendar';
import { useId } from 'react-id-generator';
import BeeDateInput from './BeeDateInput';
import BeeErrorLabel from './BeeErrorLabel';
import BeeInfoLabel from './BeeInfoLabel';
import './BeeValidatedDateInput.scss';

type BeeValidatedDateInputProps = {
  id?: string;
  label?: string;
  errorLabel?: string | null;
  neutralLabel?: string | null;
  validLabel?: string | null;
  value?: Date | null;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: CalendarChangeParams) => void;
};

export function BeeValidatedDateInput({
  id,
  label,
  errorLabel,
  neutralLabel,
  validLabel,
  value,
  disabled,
  formstate,
  readOnly,
  required,
  onChange
}: BeeValidatedDateInputProps) {
  const currentId = useId(1, 'bee-val-date-input-')[0];

  // classNames
  const labelClassNames = 'bee-validated-date-input';

  const textSize = 'medium';
  const inputFormstate = formstate === 'none' ? 'neutral' : formstate;

  function change(e: CalendarChangeParams) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }

  const renderLabel = () => {
    return (
      <div className={labelClassNames}>
        {formstate === 'error' && errorLabel ? (
          <BeeErrorLabel size={textSize} label={errorLabel} visible={true} />
        ) : formstate === 'valid' && validLabel ? (
          <BeeInfoLabel
            size={textSize}
            label={validLabel}
            type={'valid'}
            visible={true}
          />
        ) : formstate === 'neutral' && neutralLabel ? (
          <BeeInfoLabel
            size={textSize}
            label={neutralLabel}
            type={'neutral'}
            visible={true}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div id={currentId}>
      <BeeDateInput
        label={label}
        value={value}
        disabled={disabled}
        formstate={inputFormstate}
        readOnly={readOnly}
        required={required}
        onChange={(e) => change(e)}
      />
      {formstate !== 'none' && (errorLabel || neutralLabel || validLabel)
        ? renderLabel()
        : null}
    </div>
  );
}

export default BeeValidatedDateInput;
