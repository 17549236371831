import React from 'react';
import { useId } from 'react-id-generator';
import BeeDropDown from './BeeDropDown';
import BeeErrorLabel from './BeeErrorLabel';
import BeeInfoLabel from './BeeInfoLabel';
import './BeeValidatedDropDown.scss';

type BeeValidatedDropDownProps = {
  id?: string;
  label?: string;
  errorLabel?: string | null;
  neutralLabel?: string | null;
  validLabel?: string | null;
  value?: string;
  options: any;
  optionLabel?: string;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: string) => void;
  onFocusOut?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
export function BeeValidatedDropDown({
  id,
  label,
  errorLabel,
  neutralLabel,
  validLabel,
  value,
  options,
  optionLabel,
  disabled,
  formstate,
  readOnly,
  required,
  onChange,
  onFocusOut
}: BeeValidatedDropDownProps) {
  const currentId = useId(1, 'bee-val-dropdown-')[0];

  //classNames
  const labelClassNames = 'bee-validated-dropdown-label';

  const textSize = 'medium';
  const inputFormstate = formstate === 'none' ? 'neutral' : formstate;

  function change(e: string) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }
  function focusOut(e: React.ChangeEvent<HTMLInputElement>) {
    if (!disabled && onFocusOut) {
      onFocusOut(e);
    }
  }

  const renderLabel = () => {
    return (
      <div className={labelClassNames}>
        {formstate === 'error' && errorLabel ? (
          <BeeErrorLabel size={textSize} label={errorLabel} visible={true} />
        ) : formstate === 'valid' && validLabel ? (
          <BeeInfoLabel
            size={textSize}
            label={validLabel}
            type={'valid'}
            visible={true}
          />
        ) : formstate === 'neutral' && neutralLabel ? (
          <BeeInfoLabel
            size={textSize}
            label={neutralLabel}
            type={'neutral'}
            visible={true}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div id={currentId}>
      <BeeDropDown
        label={label}
        value={value}
        options={options}
        optionLabel={optionLabel}
        disabled={disabled}
        formstate={inputFormstate}
        required={required}
        readOnly={readOnly}
        onChange={(e: string) => change(e)}
        onFocusOut={(e: React.ChangeEvent<HTMLInputElement>) => focusOut(e)}
      />
      {formstate !== 'none' && (errorLabel || neutralLabel || validLabel)
        ? renderLabel()
        : null}
    </div>
  );
}

export default BeeValidatedDropDown;
