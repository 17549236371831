import _ from 'lodash';
import { DataView } from 'primereact/dataview';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import { ImageType } from '../../Pages/AlphaPages/AlphaLiegenschaftVerwaltung';
import LiegenschaftDialog from '../Dialogs/LiegenschaftDialog';
import DLLiegenschaftCard from './DLLiegenschaftCard';
import './DLOverviewProperties.scss';

type DLOverviewPropertyProps = {
  properties?: LiegenschaftsData[];
  imageLookup?: Map<string, ImageType>;
  sliceLookup?: Map<string, Map<string, number>>;
  marketAreaLookup?: Map<string, boolean>;
  disabled: boolean;
};

export default function DLOverviewProperty({
  properties,
  imageLookup,
  sliceLookup,
  marketAreaLookup,
  disabled
}: DLOverviewPropertyProps) {
  const [currProperty, setCurrProperty] = useState<LiegenschaftsData>();
  const [vDialog, setVDialog] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  function renderDataViewtemplate(property: LiegenschaftsData) {
    const propId: string = property?.id ? property.id : 'no-id';
    let image = null;
    if (property.hauptBildId) {
      image = imageLookup?.get(property.hauptBildId);
    }
    const inMarket: boolean = marketAreaLookup?.get(propId)
      ? marketAreaLookup.get(propId)!
      : false;
    return (
      <div className={'dl-liegenschaft-select-card '}>
        <DLLiegenschaftCard
          property={property}
          image={image ? image : null}
          lvData={sliceLookup?.get(propId)}
          activateHover={!disabled}
          isInMarketArea={
            marketAreaLookup && marketAreaLookup.size > 0 ? inMarket : true
          }
          onClick={() => {
            if (!disabled && searchParams.get('lId') !== propId) {
              searchParams.set('lId', propId);
              setSearchParams(searchParams);
            }
          }}
          onClickInfo={() => {
            setCurrProperty(property);
            setVDialog(true);
          }}
        />
      </div>
    );
  }

  const injectRegionSlice = (
    region: string,
    properties?: LiegenschaftsData[]
  ) => {
    return (
      <div key={'dlOverviewProp_' + region}>
        <h2>BUNDESLAND: {region}</h2>
        <div>
          <DataView
            value={properties}
            itemTemplate={renderDataViewtemplate}
            header={null}
            emptyMessage={'Keine Liegenschaften verfügbar!'}
          />
        </div>
      </div>
    );
  };

  function injectSlices(properties: LiegenschaftsData[]) {
    let allContainedRegions: string[] = [];
    const regions: Map<string, LiegenschaftsData[]> = new Map();
    for (let i = 0; i < properties.length; i++) {
      if (properties[i] && properties[i].adresse) {
        allContainedRegions.push(properties[i].adresse!.region);
        let data = regions.get(properties[i].adresse!.region);
        data = data ? data : [];
        data.push(properties[i]);
        regions.set(properties[i].adresse!.region, data);
      }
    }
    //minimize allContainedRegions and sort alphabetically
    allContainedRegions = _.sortBy(_.uniq(allContainedRegions));
    return allContainedRegions.map((region) => {
      return injectRegionSlice(region, regions.get(region));
    });
  }

  function injectLiegenschaftsdialog() {
    return (
      <LiegenschaftDialog
        property={currProperty}
        visible={vDialog}
        onHide={() => setVDialog(false)}
      />
    );
  }

  return (
    <div className={'dl-overview-properties'}>
      {properties ? injectSlices(properties) : null}
      {vDialog ? injectLiegenschaftsdialog() : null}
    </div>
  );
}
