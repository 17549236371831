import Lottie from 'lottie-react';
import { connect } from 'react-redux';
import { logoutUser } from '../../Redux/Authentication/authActions';
import { AppDispatch, RootState } from '../../Redux/store';
import AnimationLocked from '../../Style/Lottie-Animations/locked.json';
import BeeContentHeadline from '../Atoms/BeeContentHeadline';
import BeeLinkButton from '../Atoms/BeeLinkButton';
import './InvalidUserTypePage.scss';

type InvalidUserTypePageProps = {
  logoutUser: any;
};

function InvalidUserTypePage(props: InvalidUserTypePageProps) {
  function logoutUser() {
    props.logoutUser();
  }

  return (
    <div className={'invalid-usertype-page'}>
      <BeeContentHeadline
        label={'Fehlerhafte Berechtigungen'}
        headline={'h1'}
        type={'primary'}
      />
      <div className={'animation animationLocked'}>
        <Lottie animationData={AnimationLocked} loop={true} autoplay={true} />
      </div>
      <BeeLinkButton
        label={
          <div className={'lockout-btn'}>
            <i className="pi pi-arrow-left"></i>
            <span>{'Zurück zur Startseite'}</span>
          </div>
        }
        disabled={false}
        type={'secondary'}
        onClick={() => logoutUser()}
      />
    </div>
  );
}

const mapState = (state: RootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    logoutUser: (email: string) => dispatch(logoutUser())
  };
};

export default connect(mapState, mapDispatchToProps)(InvalidUserTypePage);
