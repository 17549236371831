import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { readAllPhases } from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';
import {
  checkStepActivation,
  STEP_IS_ACTIVE
} from '../../../Helper/Util/TimelineStepHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeLoadingSpinner from '../../Atoms/BeeLoadingSpinner';
import { TimelineStep } from '../../Atoms/BeeTimeline';
import AlphaAnalysisTabView from '../../Organisms/Alpha/AlphaAnalysisTabView';
import './AlphaOverviewPage.scss';
import BeeDropDown from '../../Atoms/BeeDropDown';
import { errorToMsg } from '../../../Helper/ApiHelper/ErrorMessages';

export default function AlphaOverviewPage() {
  const [phasen, setPhasen] = useState<TimelineStep[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<TimelineStep>();
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    readAllPhases()
      .then((json: any) => {
        let steps: TimelineStep[] = [...json];
        setPhasen(steps);
        let activePhase: any;
        for (let i = 0; i < steps.length; i++) {
          if (checkStepActivation(steps[i]) === STEP_IS_ACTIVE) {
            activePhase = steps[i];
            if (searchParams.get('pId') !== activePhase.id) {
              searchParams.set('pId', activePhase.id);
              setSearchParams(searchParams);
            }
            break;
          }
        }
        if (!activePhase) {
          if (steps.length > 0) {
            if (searchParams.get('pId') !== steps[0]?.id) {
              searchParams.set('pId', steps[0]?.id);
              setSearchParams(searchParams);
            }
          } else {
            if (toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Keine Phasen vorhanden',
                detail:
                  'Aktuell wurden noch keine Phasen hinterlegt. Bitte erstellen Sie zunächst Angebotsphasen und versuchen es anschließend erneut.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          }
        }
      })
      .catch((error) => {
        if (toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Fehler beim Laden der Daten',
            detail: error
              ? errorToMsg(error)
              : 'Leider konnten die Phasen nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice. ',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (phasen) {
      const phaseId = searchParams.get('pId');
      if (!(selectedPhase && selectedPhase.id === phaseId)) {
        const currPhase: any = _.find(phasen, { id: phaseId });
        if (currPhase) {
          setSelectedPhase(currPhase);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, phasen]);

  return (
    <div className={'dl-analysis-page m-4 pt-2 justify-content-start'}>
      <div className={'overview-header '}>
        <BeeContentHeadline
          label={'Analyse'}
          headline={'h1'}
          type={'primary'}
        />
        {phasen ? (
          <BeeDropDown
            label={'Ausgewählte Phase'}
            value={selectedPhase ? selectedPhase : phasen[0]}
            options={phasen}
            optionLabel={'title'}
            disabled={false}
            formstate={'neutral'}
            readOnly={false}
            required={false}
            onChange={(e: any) => {
              searchParams.set('pId', e.id);
              searchParams.set('cId', 'new');
              setSearchParams(searchParams);
            }}
          />
        ) : (
          <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
        )}
      </div>
      {selectedPhase ? (
        <AlphaAnalysisTabView phase={selectedPhase} />
      ) : (
        <BeeLoadingSpinner strokeWidth={'3'} type={'primary'} />
      )}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
