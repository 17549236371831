import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import { useId } from 'react-id-generator';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import './BeeDateInput.scss';
import BeeTextInput from './BeeTextInput';

type BeeDateInputProps = {
  label?: string;
  value: Date | null | undefined;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange: (e: CalendarChangeParams) => void;
};

export function BeeDateInput({
  label,
  value,
  disabled,
  formstate,
  readOnly,
  required,
  onChange
}: BeeDateInputProps) {
  const currentId = useId(1, 'bee-date-input-')[0];

  //classNames
  const formStateClassnames =
    formstate === 'valid'
      ? ' bee-date-input-valid'
      : formstate === 'error'
      ? ' bee-date-input-error'
      : formstate === 'neutral'
      ? ' bee-date-input-neutral'
      : ' bee-date-input-neutral';

  let readOnlyClassNames = '';

  if (readOnly) {
    readOnlyClassNames = ' bee-date-input-readOnly';
    disabled = true;
  }
  const classnames =
    'bee-date-input' + formStateClassnames + readOnlyClassNames;
  const containerClassNames = 'p-float-label bee-date-input-container';

  //label
  const labelClassNames = 'bee-date-input-label';

  function change(e: CalendarChangeParams) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={true}
        required={required}
      />
    );
  };

  return (
    <>
      {readOnly && !value ? (
        renderPlaceholder()
      ) : (
        <span id={currentId} className={containerClassNames}>
          <Calendar
            id={currentId + 'input'}
            value={value}
            disabled={disabled}
            className={classnames}
            showIcon
            readOnlyInput
            dateFormat="dd.mm.yy"
            onChange={(e) => change(e)}
          />
          {label ? (
            <label htmlFor={currentId + 'input'} className={labelClassNames}>
              {label}
              {required && label ? (
                <span className="bee-date-input-label-required">
                  <span className="bee-date-input-label-required-star"> *</span>
                </span>
              ) : null}
            </label>
          ) : null}
        </span>
      )}
    </>
  );
}

export default BeeDateInput;
