import './AlphaTargetPricing.scss';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import AlphaTargetPricesDialog from '../Dialogs/AlphaTargetPricesDialog';
import { exportAlphaTpExcel } from '../../../Helper/StorageHelper/ExcelExportAlphaTargetPriceHelper';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../../Helper/Statics/Constants';

type AlphaTargetPricingProps = {
  phaseId: string;
  properties: LiegenschaftsData[];
  targetPriceLookup: Map<string, any>;
  targetPriceId: string;
  onAdaptTarget: (data: Map<string, any>) => void;
};

export default function AlphaTargetPricing({
  phaseId,
  properties,
  targetPriceLookup,
  targetPriceId,
  onAdaptTarget
}: AlphaTargetPricingProps) {
  const [vInputDialog, setVInputDialog] = useState<boolean>(false);
  const toast = useRef<Toast>(null);

  ///////////////////////
  /////// HELPER ////////
  ///////////////////////

  function downloadCurrentTargetPricesAsExcel() {
    exportAlphaTpExcel(properties, targetPriceLookup, phaseId, null).catch(
      (e) => {
        if (toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Export fehlgeschlagen',
            detail:
              'Beim Exportieren der Excel ist etwas fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
      }
    );
  }

  //////////////////////
  ////  UI METHODS  ////
  //////////////////////

  const injectInputDialog = () => {
    return (
      <AlphaTargetPricesDialog
        phaseId={phaseId}
        properties={properties}
        targetPriceId={targetPriceId}
        targetPriceLookup={targetPriceLookup}
        visible={vInputDialog}
        onHide={() => setVInputDialog(false)}
        onSaved={(data) => {
          setVInputDialog(false);
          onAdaptTarget(data);
        }}
      />
    );
  };

  const injectInputButton = () => {
    return (
      <BeeOutlinedButton
        label={'Zielpreise eingeben'}
        disabled={false}
        type={'secondary'}
        onClick={() => setVInputDialog(true)}
      />
    );
  };

  const injectDownloadButton = () => {
    return (
      <BeeOutlinedButton
        label={'Zielpreise herunterladen'}
        disabled={false}
        type={'primary'}
        onClick={() => downloadCurrentTargetPricesAsExcel()}
      />
    );
  };

  return (
    <div className={'alpha-target-pricing'}>
      <div className="m-1 "> {injectInputButton()}</div>
      <div className="mt-1 mb-1 ml-1"> {injectDownloadButton()}</div>

      {vInputDialog ? injectInputDialog() : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
