import { Card } from 'primereact';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENTER } from '../../../Helper/Statics/KeyCodes';
import { PATH_ROOT } from '../../../Helper/Statics/Routes';

import BeeButton from '../../Atoms/BeeButton';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeValidatedTextInput from '../../Atoms/BeeValidatedTextInput';
import './TwoFactorForm.scss';

type TwoFactorProps = {
  showProgress: boolean;
  twoFactorError: string;
  onValidateTwoFactor: (code: string, token: string | null) => void;
};

function TwoFactorForm({
  showProgress,
  twoFactorError,
  onValidateTwoFactor
}: TwoFactorProps) {
  //component state
  const [code, setCode] = useState<string>('');

  let navigate = useNavigate();

  //Get token from url
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('t');

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.keyCode === ENTER && !e.altKey) {
      validateTwoFactor();
    }
  }

  function validateTwoFactor(): void {
    //no input validation in front end
    onValidateTwoFactor(code, token);
  }

  function clickLogo() {
    navigate(PATH_ROOT);
  }

  function injectHeadline(): JSX.Element {
    return (
      <div
        className={'logo'}
        onClick={() => {
          clickLogo();
        }}
      />
    );
  }

  function injectResetPwForm(): JSX.Element {
    return (
      <div>
        <div>
          <BeeContentHeadline
            label={'Zwei-Faktor-Authentifizierung'}
            headline={'h2'}
            type={'secondary'}
          />
        </div>
        <div className={'infotext'}>
          {
            'Bitte geben Sie den Authentifizierungscode ein, den wir an Ihre E-Mail-Adresse gesendet haben. Bitte prüfen Sie auch Ihren SPAM-Ordner, falls Sie in den nächsten Minuten keine Bestätigung erhalten haben.'
          }
        </div>
        <div
          className="field code-input"
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
        >
          <BeeValidatedTextInput
            label={'Authentifizierungscode'}
            errorLabel={twoFactorError ? twoFactorError : null}
            value={code}
            disabled={false}
            readOnly={false}
            required={false}
            formstate={twoFactorError ? 'error' : 'none'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCode(e.target.value)
            }
          />
        </div>
      </div>
    );
  }

  function injectActions(): JSX.Element {
    return (
      <div className="auth-btn">
        <BeeButton
          label={'Bestätigen'}
          disabled={!code}
          type={'primary'}
          onClick={(e) => validateTwoFactor()}
        />
      </div>
    );
  }

  return (
    <div
      className={'o-two-fac-form form grid justify-content-center align-center'}
    >
      <div className="col-12 sm:col-8 md:col-7 lg:col-6 xl:col-4">
        <Card>
          {injectHeadline()}
          {injectResetPwForm()}
          {injectActions()}
        </Card>
      </div>
    </div>
  );
}

export default TwoFactorForm;
