import fileDownload from 'js-file-download';
import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import {
  Phase,
  updatePhase
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import {
  convertDocResponse,
  downloadDocumentFromServer,
  ReadMediaArrayResponse,
  translateAlphaMediaIds,
  uploadDocumentToServer
} from '../../../Helper/ApiHelper/MediaNetworkHelper';
import {
  ACCEPTED_FILE_FORMATS,
  DURATION_NOTIFICATION_ERROR_LONG,
  DURATION_NOTIFICATION_SUCCESS_LONG,
  URL_TO_MEDIA_SERVER
} from '../../../Helper/Statics/Constants';
import { zipDocs } from '../../../Helper/StorageHelper/ZipHelper';
import { generateZipName } from '../../../Helper/StorageHelper/FilenameHelper';
import { handlePromisesAndIgnoreErrors } from '../../../Helper/Util/PromiseHelper';
import { isBefore } from '../../../Helper/Util/TimelineStepHelper';
import BeeContentHeadline from '../../Atoms/BeeContentHeadline';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import { DocType, FileUploadType } from '../../Pages/DLPages/DLPriceInputPage';
import BeeDocumentTable from '../BeeDocumentTable';
import BeeDeletionDialog from '../Dialogs/BeeDeletionDialog';
import BeeUploadDialog from '../Dialogs/BeeUploadDialog';
import './AlphaPhaseDocTable.scss';

type AlphaPhaseDocTableProps = {
  phase: Phase;
};

export default function AlphaPhaseDocTable({ phase }: AlphaPhaseDocTableProps) {
  const [documents, setDocuments] = useState<DocType[]>([]);
  const [isLoadingDownloadDocs, setIsLoadingDownloadDocs] =
    useState<boolean>(false);
  const [loadDocsError, setLoadDocsError] = useState<boolean>(false);
  //dialogs
  const [v_DocDialog, setV_DocDialog] = useState<boolean>(false);
  const [docUploadProg, setDocUploadProg] = useState<boolean>(false);
  const [docUploadError, setDocUploadError] = useState<string>();
  const [v_DocDelDialog, setV_DocDelDialog] = useState(false);
  const [currDocId, setCurrDocId] = useState<string>();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    //translate all MediaIds
    if (phase) {
      const phaseMediaIds = phase.publicMediaIds;
      if (phaseMediaIds) {
        translateAlphaMediaIds(phaseMediaIds)
          .then((data: any) => {
            const media: ReadMediaArrayResponse[] = _.cloneDeep(data);
            // documents
            let entries: DocType[] = [];
            if (media && media.length > 0) {
              entries = convertDocResponse(media);
            }
            setLoadDocsError(false);
            setDocuments(entries);
          })
          .catch((error) => {
            setLoadDocsError(true);
          });
      }
    }
  }, [phase]);

  ////////////////////
  // BUSINESS LOGIC //
  ////////////////////

  function createNewDocument(data: FileUploadType) {
    if (data) {
      setDocUploadProg(true);
      if (data.file) {
        uploadDocumentToServer(data.file, true, data.name)
          .then((result: any) => {
            const entries = _.cloneDeep(documents);
            let entry: DocType = {
              id: result.id,
              title: result.title,
              fileSrc: URL_TO_MEDIA_SERVER + result.url,
              timestamp: new Date(result.createdAt),
              type: result.type,
              fileKey: result.access,
              copyright: result.copyright,
              secured: result.access ? true : false,
              filename: result.filename,
              size: result.fileSize
            };
            entries.push(entry);
            let entryIds = [];
            for (let i = 0; i < entries.length; i++) {
              const element = entries[i];
              entryIds.push(element.id);
            }
            setDocUploadProg(false);
            setV_DocDialog(false);
            updatePhaseAtServer(entryIds, entries);
          })
          .catch((error) => {
            setDocUploadProg(false);
            setDocUploadError('Upload ist leider fehlgeschlagen.');
            if (toast && toast.current) {
              toast.current.show({
                severity: 'error',
                summary: 'Upload fehlgeschlagen',
                detail:
                  'Beim letzten Upload eines Dokuments ist etwas schiefgelaufen. Bitte aktualisieren Sie die Seite. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          });
      }
    }
  }

  function updatePhaseAtServer(mediaIds: string[], documents: DocType[]) {
    if (phase) {
      let entry: Phase = _.cloneDeep(phase);
      entry.publicMediaIds = mediaIds;
      updatePhase(phase.id, entry)
        .then(() => {
          setDocuments(documents);
          setV_DocDelDialog(false);
          if (toast && toast.current) {
            toast.current.show({
              severity: 'success',
              summary: 'Phase angepasst',
              detail:
                'Die hinterlegten Dateien für die Phase: "' +
                phase.title +
                '" wurden angepasst.',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_SUCCESS_LONG
            });
          }
        })
        .catch(() => {
          setV_DocDelDialog(false);
          if (toast && toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Phase konnte nicht geupdated werden',
              detail:
                'Leider ist das Update der Phase fehlgeschlagen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem bestehen bleiben wenden Sie sich bitte an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  function deleteDocument(entryId?: string) {
    const entries = _.cloneDeep(documents);
    const entryIds = [];
    const values = [];
    if (entryId) {
      for (let i = 0; i < entries.length; i++) {
        if (entryId !== entries[i].id) {
          entryIds.push(entries[i].id);
          values.push(entries[i]);
        }
      }
    }
    updatePhaseAtServer(entryIds, values);
  }

  function downloadDocumentById(entryId: string) {
    const entry = _.find(documents, function (doc: DocType) {
      return doc.id === entryId;
    });
    if (entry) {
      downloadDocumentFromServer(entry.fileSrc, entry.fileKey)
        .then((response: any) => {
          let filename = entry.filename;
          if (filename) {
            fileDownload(response, filename);
          }
        })
        .catch((error) => {
          if (toast && toast.current) {
            toast.current?.show({
              severity: 'error',
              summary: 'Der Download ist fehlgeschlagen',
              detail:
                'Der Download des Dokuments ist leider fehlgeschlagen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  function downloadDocumentsByIds(entryIds: string[]) {
    setIsLoadingDownloadDocs(true);
    let downloadDocsPromises = [];
    for (let i = 0; i < entryIds.length; i++) {
      const entry = _.find(documents, function (doc: DocType) {
        return doc.id === entryIds[i];
      });

      if (entry) {
        downloadDocsPromises.push(
          downloadDocumentFromServer(entry.fileSrc, entry.fileKey).then(
            (result) => {
              let filename = entry.filename;
              if (filename) {
                return { name: filename, blob: result };
              }
              //no filename
            }
          )
        );
      }
    }
    handlePromisesAndIgnoreErrors(downloadDocsPromises)
      .then((result: any) => {
        if (result.fulfilled && result.fulfilled.length > 0) {
          let docFiles = [];
          for (let j = 0; j < result.fulfilled.length; j++) {
            docFiles.push(result.fulfilled[j].value);
          }

          const phaseName = phase.title ? phase.title + '_' : '';
          //zip data
          zipDocs(docFiles, generateZipName('Dokumente_' + phaseName))
            .then(() => {
              //sucess
              console.log('success');
              if (toast && toast.current) {
                if (!result.rejected || result.rejected.length <= 0) {
                  toast.current?.show({
                    severity: 'success',
                    summary: 'Download erfolgreich abgeschlossen',
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                } else {
                  toast.current?.show({
                    severity: 'warn',
                    summary:
                      'Der Download ist für einige Dokumente fehlgeschlagen',
                    detail:
                      'Der Download der Dokumente ist vereinzelt fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                    //fixme filenames
                    sticky: false,
                    closable: true,
                    life: DURATION_NOTIFICATION_ERROR_LONG
                  });
                }
              }
              setIsLoadingDownloadDocs(false);
            })
            .catch((error) => {
              //Fixme zip didnt work
              console.log(error);
              if (toast && toast.current) {
                toast.current?.show({
                  severity: 'error',
                  summary: 'Der Download ist fehlgeschlagen',
                  detail:
                    'Die Dokumente konnten nicht gezipped und heruntergeladen werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                });
              }
              setIsLoadingDownloadDocs(false);
            });
        } else if (result.rejected && result.rejected.length > 0) {
          //download failed for all documents
          if (toast && toast.current) {
            toast.current?.show({
              severity: 'error',
              summary: 'Der Download ist für alle Dokumente fehlgeschlagen',
              detail:
                'Der Server für den Download der Dokumente konnte nicht erreicht werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
              sticky: true,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
          setIsLoadingDownloadDocs(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (toast && toast.current) {
          toast.current?.show({
            severity: 'error',
            summary: 'Der Download ist fehlgeschlagen',
            detail:
              'Der Download der Dokumente ist leider fehlgeschlagen, bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.',
            sticky: false,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        }
        setIsLoadingDownloadDocs(false);
      });
  }

  ////////////////
  // UI METHODS //
  ////////////////

  function injectFileUploadDialog(): JSX.Element {
    return (
      <BeeUploadDialog
        type={'primary'}
        visible={v_DocDialog}
        disabled={false}
        locale={'de-DE'}
        header={'Dokument hochladen'}
        info={''}
        titleLabel={'Bezeichnung'}
        titleVisible={true}
        copyrightLabel={''}
        copyrightVisible={false}
        dropzoneTitle={'Upload'}
        dropzoneDescription={
          'Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen'
        }
        dropzoneAddLabel={'Klicken zum Hinzufügen'}
        dropzoneFormats={ACCEPTED_FILE_FORMATS}
        progressVisible={docUploadProg}
        progressMessage={'Dokument wird hochgeladen'}
        errorVisible={docUploadError ? true : false}
        errorHeadline={docUploadError}
        errorDescription={docUploadError}
        onHide={() => setV_DocDialog(false)}
        onUpload={(data) => createNewDocument(data)}
      />
    );
  }

  function injectFileDeletionConfirmationDialog(): JSX.Element {
    const doc = _.find(documents, function (entry: DocType) {
      return entry.id === currDocId;
    });
    return (
      <BeeDeletionDialog
        visible={v_DocDelDialog}
        message={
          'Möchten Sie ' +
          (doc && doc.title ? doc.title : 'dieses Dokument') +
          ' wirklich löschen?'
        }
        acceptLabel={'Löschen'}
        rejectLabel={'Abbrechen'}
        header={'Dokument löschen'}
        type={'primary'}
        onAccept={() => {
          deleteDocument(currDocId);
        }}
        onReject={() => setV_DocDelDialog(false)}
        onHide={() => setV_DocDelDialog(false)}
      />
    );
  }

  function injectDocTable() {
    const phaseStarted: boolean =
      phase && phase.start && isBefore(new Date(), new Date(phase.start))
        ? true
        : false;
    const phaseEnded: boolean =
      phase && phase.end && isBefore(new Date(), new Date(phase.end))
        ? true
        : false;
    return (
      <div>
        {loadDocsError ? (
          <div className={'error'}>
            <i className={'icon-error'}></i>
            <span>
              {
                'Beim Laden der Dokumente ist etwas schiefgelaufen. Bitte aktualisieren Sie die Seite. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice.'
              }
            </span>
          </div>
        ) : null}
        <BeeDocumentTable
          type={'dark'}
          data={documents ? documents : []}
          readOnly={loadDocsError}
          disabled={loadDocsError}
          showDownload={true}
          showDelete={!phaseStarted}
          enableBulkDownload={true}
          isBulkDownloadLoading={isLoadingDownloadDocs}
          onDownload={(id) => downloadDocumentById(id)}
          onDownloadAll={(ids) => downloadDocumentsByIds(ids)}
          onDelete={(id) => {
            setCurrDocId(id);
            setV_DocDelDialog(true);
          }}
        />
        {!loadDocsError ? (
          <div className={'add-doc-btn'}>
            <BeeOutlinedButton
              label={'Dokument hinzufügen'}
              disabled={phaseEnded}
              type={'primary'}
              onClick={() => setV_DocDialog(true)}
            />
          </div>
        ) : null}
      </div>
    );
  }

  function injectHeadline() {
    return (
      <div className={'phase-to-doc-headline'}>
        <BeeContentHeadline
          label={phase.title}
          headline={'h2'}
          type={'primary'}
        />
        <BeeContentHeadline
          label={
            phase.start && phase.end
              ? new Date(phase.start).toLocaleDateString('de-DE') +
                ' - ' +
                new Date(phase.end).toLocaleDateString('de-DE')
              : ''
          }
          headline={'h3'}
          type={'secondary'}
        />
      </div>
    );
  }

  return (
    <div className={'alpha-phase-to-doc-table pt-4 pb-4'}>
      {injectHeadline()}
      {injectDocTable()}
      <Toast ref={toast} position={'top-right'} />
      {v_DocDialog ? injectFileUploadDialog() : null}
      {v_DocDelDialog ? injectFileDeletionConfirmationDialog() : null}
    </div>
  );
}
