import {
  Action,
  ReducerProps,
  STORE_REFRESH_TOKEN,
  STORE_ACCESS_TOKEN,
  STORE_TWOFAC_TOKEN,
  STORE_USERNAME,
  LOGOUT_USER,
  STORE_DL_PHASEN,
  CHANGE_USER_PARTICIPATION
} from './authActionTypes';

const initialState = {
  refreshToken: '',
  twoFactorToken: '',
  username: '',
  dlPhasen: null,
  userParticipation: false
};

const appReducer = (
  state: ReducerProps = initialState,
  action: Action
): ReducerProps => {
  switch (action.type) {
    case LOGOUT_USER:
      sessionStorage.removeItem('aT');
      return initialState;

    case STORE_REFRESH_TOKEN:
      const refreshToken = action.payload?.refreshToken;
      return {
        ...state,
        refreshToken: refreshToken ? refreshToken : '',
        twoFactorToken: ''
      };

    case STORE_ACCESS_TOKEN:
      const accessToken = action.payload?.accessToken;
      if (accessToken) {
        sessionStorage.setItem('aT', accessToken);
      }
      return {
        ...state,
        twoFactorToken: ''
      };

    case STORE_TWOFAC_TOKEN:
      const twoFactorToken = action.payload?.twoFactorToken;
      return {
        ...state,
        twoFactorToken: twoFactorToken,
        refreshToken: ''
      };

    case STORE_USERNAME:
      const username = action.payload?.username;
      return {
        ...state,
        username: username
      };

    case STORE_DL_PHASEN:
      const data = action.payload?.dlPhasen;
      return {
        ...state,
        dlPhasen: data
      };

    case CHANGE_USER_PARTICIPATION:
      const flag = action.payload?.userParticipation;
      return {
        ...state,
        userParticipation: flag
      };

    default:
      return state;
  }
};

export default appReducer;
