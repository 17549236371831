import FileSaver from 'file-saver';
import _ from 'lodash';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useRef, useState } from 'react';
import { Phase } from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import {
  createNewLvCatalogForPropertyFromExcel,
  deleteLvCatalogForProperty,
  MinimizedLvCatalog,
  readLvCatalogAsExcelForProperty
} from '../../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  ACCEPTED_FORMATS_EXCEL,
  DURATION_NOTIFICATION_ERROR_LONG
} from '../../../Helper/Statics/Constants';
import { isBefore } from '../../../Helper/Util/TimelineStepHelper';
import BeeLinkButton from '../../Atoms/BeeLinkButton';
import { FileUploadType } from '../../Pages/DLPages/DLPriceInputPage';
import BeeDeletionDialog from '../Dialogs/BeeDeletionDialog';
import BeeUploadDialog from '../Dialogs/BeeUploadDialog';
import './EpLvLiegenschaftLV.scss';

type EpLvLiegenschaftLVProps = {
  phase?: Phase;
  propertyId?: String;
  onAdaptData: (headline: string, message: string) => void;
};

export function EpLvLiegenschaftLV({
  phase,
  propertyId,
  onAdaptData
}: EpLvLiegenschaftLVProps) {
  //states
  const [uploadVisible, setUploadVisible] = useState<boolean>(false);
  const [uploadProgressVisible, setUploadProgressVisible] =
    useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | undefined>();
  const [uploadErrorDescription, setUploadErrorDescription] = useState<
    string | undefined
  >();
  const [deletionVisible, setDeletionVisible] = useState<boolean>(false);
  //refs
  const toast = useRef<Toast>(null);

  /////////////
  // DIALOGS //
  /////////////

  function injectFileUploadDialog() {
    if (phase && propertyId) {
      return (
        <BeeUploadDialog
          type={'primary'}
          visible={uploadVisible}
          disabled={false}
          locale={'de-DE'}
          header={'Leistungsverzeichnis hochladen'}
          info={''}
          titleLabel={''}
          titleVisible={false}
          copyrightLabel={''}
          copyrightVisible={false}
          dropzoneTitle={'Upload - LV für Liegenschaft'}
          dropzoneDescription={
            'Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen'
          }
          dropzoneAddLabel={'Klicken zum Hinzufügen'}
          dropzoneFormats={ACCEPTED_FORMATS_EXCEL}
          progressVisible={uploadProgressVisible}
          progressMessage={'Leistungsverzeichnis wird hochgeladen'}
          errorVisible={uploadError ? true : false}
          errorHeadline={uploadError}
          errorDescription={uploadErrorDescription}
          onHide={() => {
            setUploadError(undefined);
            setUploadVisible(false);
          }}
          onUpload={(data) => uploadLV(phase.id, propertyId, data)}
        />
      );
    } else {
      return null;
    }
  }

  function injectDeletionConfirmationDialog() {
    if (phase && propertyId && phase.id && phase.lvs) {
      const lv = _.find(phase.lvs, function (e: MinimizedLvCatalog) {
        return e.propertyId === propertyId;
      });
      if (lv) {
        return (
          <BeeDeletionDialog
            visible={deletionVisible}
            message={'Möchten Sie das LV dieser Liegenschaft wirklich löschen?'}
            messageIcon={'pi pi-exclamation-triangle'}
            acceptLabel={'Löschen'}
            rejectLabel={'Abbrechen'}
            header={'Leistungsverzeichnis löschen'}
            type={'primary'}
            onAccept={() => deleteLV(phase.id, propertyId, lv.id)}
            onReject={() => setDeletionVisible(false)}
            onHide={() => setDeletionVisible(false)}
          />
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  ///////////////
  // UI HELPER //
  ///////////////

  function adaptData(headline: string, message: string) {
    if (onAdaptData) {
      onAdaptData(headline, message);
    }
  }

  function downloadLVCatalog(
    phaseId: String,
    propertyId: String,
    filename: string
  ) {
    readLvCatalogAsExcelForProperty(phaseId, propertyId)
      .then((result: any) => FileSaver.saveAs(result, filename))
      .catch((error) =>
        toast.current?.show({
          severity: 'error',
          summary: 'Download nicht möglich',
          detail:
            'Dieses LV kann leider nicht heruntergeladen werden. ' + error,
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        })
      );
  }

  function uploadLV(phaseId: String, propertyId: String, file: FileUploadType) {
    setUploadProgressVisible(true);
    createNewLvCatalogForPropertyFromExcel(phaseId, propertyId, file.file)
      .then((result: any) => {
        console.log(result); //FIXME remove after test
        setUploadProgressVisible(false);
        setUploadVisible(false);
        //update stored phase
        const metaLvInfo: MinimizedLvCatalog = result.info;
        let updatedPhase: Phase = _.cloneDeep(phase!);
        if (updatedPhase && updatedPhase.lvs) {
          for (let i = 0; i < updatedPhase.lvs.length; i++) {
            if (updatedPhase.lvs[i].id === metaLvInfo.id) {
              updatedPhase.lvs[i] = metaLvInfo;
              break;
            }
          }
        }
        const headline = 'LV hochgeladen';
        const message =
          'Das Leistungsverzeichnis der Liegenschaft wurde erfolgreich hochgeladen.';
        adaptData(headline, message);
      })
      .catch((error) => {
        console.log(error);
        setUploadProgressVisible(false);
        setUploadError(
          'Das Leistungsverzeichnis kann leider nicht hochgeladen werden.'
        );
        if (error && error.data && error.data.message) {
          setUploadErrorDescription(error.data.message);
        }
        toast.current?.show({
          severity: 'error',
          summary: 'Upload nicht möglich',
          detail: 'Das LV kann leider nicht hochgeladen werden. ' + error,
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        });
      });
  }

  function deleteLV(phaseId: String, propertyId: String, lvId: String) {
    deleteLvCatalogForProperty(phaseId, propertyId, lvId)
      .then(() => {
        //update stored phase
        let updatedPhase: Phase = _.cloneDeep(phase!);
        if (updatedPhase && updatedPhase.lvs) {
          _.remove(updatedPhase.lvs, function (e: MinimizedLvCatalog) {
            return e.id === lvId;
          });
          const headline = 'LV gelöscht';
          const message =
            'Das Leistungsverzeichnis der Liegenschaft wurde erfolgreich gelöscht.';
          adaptData(headline, message);
        }
      })
      .catch((error) => {
        toast.current?.show({
          severity: 'error',
          summary: 'Löschen fehlgeschlagen',
          detail:
            'Das Leistungsverzeichnis kann leider nicht gelöscht werden. Bitte versuchen Sie es später erneut. ' +
            error,
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        });
      });
  }

  ////////////////
  // UI METHODS //
  ////////////////

  const injectUI = () => {
    if (phase && propertyId) {
      if (phase.end && isBefore(phase.end, new Date())) {
        if (phase.ep && phase.ep.id) {
          if (
            phase.lvs &&
            _.find(phase.lvs, function (e: MinimizedLvCatalog) {
              return e.propertyId === propertyId;
            }) !== undefined
          ) {
            //EP exists, LV exists
            return injectPhaseNotDoneWithEpAndLV(phase, propertyId);
          } else {
            //EP exists, LV not
            return injectPhaseNotDoneWithEpNoLV(phase);
          }
        } else {
          //No Ep no lv
          return injectPhaseNotDoneNoEpNoLV();
        }
      } else {
        if (
          phase.lvs &&
          _.find(phase.lvs, function (e: MinimizedLvCatalog) {
            return e.propertyId === propertyId;
          }) !== undefined
        ) {
          // there is an lv to download
          return injectPhaseDoneWithLV(phase, propertyId);
        }
        return injectPhaseDoneNoLV();
      }
    } // render empty otherwise!!
  };

  function injectPhaseDoneNoLV() {
    return <div>—</div>;
  }

  function injectPhaseDoneWithLV(phase: Phase, propertyId: String) {
    const lv: MinimizedLvCatalog | undefined = _.find(
      phase.lvs,
      function (e: MinimizedLvCatalog) {
        return e.propertyId === propertyId;
      }
    );
    const creation = lv && lv.createdAt ? new Date(lv.createdAt) : null;
    const filename: any = lv && lv.filename ? lv.filename : null;

    return (
      <div>
        {lv && lv.id ? (
          <>
            <BeeLinkButton
              label={filename}
              type={'secondary'}
              onClick={() =>
                downloadLVCatalog(phase.id, lv.propertyId, filename)
              }
            />
            <div>
              Stand: {creation ? creation.toLocaleDateString('de-DE') : null}
            </div>
          </>
        ) : null}
      </div>
    );
  }

  function injectPhaseNotDoneNoEpNoLV() {
    return (
      <div>
        <div className={'ep-lv-lvUpload-btn upload-lv-disabled'}>
          <BeeLinkButton
            label={
              <>
                <i className={'pi pi-upload mr-2'}></i>
                <span>LV hochladen</span>
              </>
            }
            type={'primary'}
            disabled={true}
            onClick={() => setUploadVisible(true)}
          />
        </div>
        <Tooltip target=".upload-lv-disabled" mouseTrack mouseTrackTop={10}>
          Bitte laden Sie einen EP-Katalog hoch.
        </Tooltip>
      </div>
    );
  }

  function injectPhaseNotDoneWithEpNoLV(phase: Phase) {
    return (
      <div className={'ep-lv-lvUpload-btn'}>
        {phase.ep && phase.ep.id ? (
          <BeeLinkButton
            label={
              <>
                <i className={'pi pi-upload mr-2'}></i>
                <span>LV hochladen</span>
              </>
            }
            type={'primary'}
            onClick={() => setUploadVisible(true)}
          />
        ) : null}
      </div>
    );
  }

  function injectPhaseNotDoneWithEpAndLV(phase: Phase, propertyId: String) {
    const lv: MinimizedLvCatalog | undefined = _.find(
      phase.lvs,
      function (e: MinimizedLvCatalog) {
        return e.propertyId === propertyId;
      }
    );
    const creation = lv && lv.createdAt ? new Date(lv.createdAt) : null;
    const filename: any = lv && lv.filename ? lv.filename : null;
    return (
      <div className={'ep-lv-lvDownload'}>
        {lv && lv.id ? (
          <div>
            <div className="grid">
              <div className={'col download-btn'}>
                <BeeLinkButton
                  label={filename}
                  type={'secondary'}
                  onClick={() =>
                    downloadLVCatalog(phase.id, lv.propertyId, filename)
                  }
                />
              </div>
              <div className={'col col-fixed delete-btn'}>
                <BeeLinkButton
                  label={<i className={'pi pi-trash'}></i>}
                  type={'danger'}
                  aria-label="Cancel"
                  onClick={() => setDeletionVisible(true)}
                />
              </div>
            </div>
            <div>
              Stand: {creation ? creation.toLocaleDateString('de-DE') : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <Toast ref={toast} position={'top-right'} />
      {injectUI()}
      {uploadVisible ? injectFileUploadDialog() : null}
      {deletionVisible ? injectDeletionConfirmationDialog() : null}
    </div>
  );
}

export default EpLvLiegenschaftLV;
