import { InputText } from 'primereact/inputtext';
import React from 'react';
import { useId } from 'react-id-generator';
import { EMPTY_FIELD_PLACEHOLDER } from '../../Helper/Statics/Constants';
import './BeeTextInput.scss';

type BeeTextInputProps = {
  id?: string;
  label?: string;
  value?: string;
  disabled?: boolean;
  formstate?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocusOut?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
export function BeeTextInput({
  id,
  label,
  value,
  disabled,
  formstate,
  readOnly,
  required,
  onChange,
  onFocusOut
}: BeeTextInputProps) {
  const genId = useId(1, 'bee-txt-input-')[0];
  const currentId = id ? id : genId;

  //classNames
  const formStateClassNames =
    formstate === 'valid'
      ? ' bee-txt-input-valid'
      : formstate === 'error'
      ? ' bee-txt-input-error'
      : formstate === 'neutral'
      ? ' bee-txt-input-neutral'
      : ' bee-txt-input-neutral';

  let readOnlyClassNames = '';
  if (readOnly) {
    readOnlyClassNames = ' bee-txt-input-readOnly';
    disabled = true;
  }

  const classNames = 'bee-txt-input ' + formStateClassNames;
  const containerClassNames =
    'p-float-label bee-txt-input-container' + readOnlyClassNames;

  //label
  const labelClassNames = 'bee-txt-input-label';

  function change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }
  function focusOut(e: React.ChangeEvent<HTMLInputElement>) {
    if (!disabled && onFocusOut) {
      onFocusOut(e);
    }
  }

  const renderPlaceholder = () => {
    return (
      <BeeTextInput
        id={currentId + 'input'}
        label={label}
        value={EMPTY_FIELD_PLACEHOLDER}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        onChange={() => {}}
      />
    );
  };

  return (
    <span id={currentId} className={containerClassNames}>
      {readOnly && !value ? (
        renderPlaceholder()
      ) : (
        <>
          <InputText
            id={currentId + 'input'}
            value={value}
            disabled={disabled}
            className={classNames}
            onChange={(e) => change(e)}
            onBlur={(e) => focusOut(e)}
          />
          {label ? (
            <label htmlFor={currentId + 'input'} className={labelClassNames}>
              {label}
              {required && label ? (
                <span className="bee-txt-input-label-required">
                  <span className="bee-txt-input-label-required-star"> *</span>
                </span>
              ) : null}
            </label>
          ) : null}
        </>
      )}
    </span>
  );
}

export default BeeTextInput;
