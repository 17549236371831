import Lottie from 'lottie-react';
import Animation404 from '../../Style/Lottie-Animations/404-page.json';
import BeeContentHeadline from '../Atoms/BeeContentHeadline';
import './Error404.scss';

function Error404() {
  return (
    <div className={'error-404'}>
      <BeeContentHeadline
        label={'Die Seite konnte leider nicht gefunden werden'}
        headline={'h2'}
        type={'secondary'}
      />
      <div className={'animation animation404'}>
        <Lottie animationData={Animation404} loop={true} autoplay={true} />
      </div>
    </div>
  );
}

export default Error404;
