import { Timeline } from 'primereact/timeline';
import { useSearchParams } from 'react-router-dom';
import {
  MinimizedEpCatalog,
  MinimizedLvCatalog
} from '../../Helper/ApiHelper/LvEpNetworkHelper';
import {
  checkStepActivation,
  checkUserParticipation,
  STEP_IS_ACTIVE,
  STEP_WAS_ACTIVE
} from '../../Helper/Util/TimelineStepHelper';
import BeeChip from './BeeChip';
import './BeeTimeline.scss';
import BeeSwitch from './BeeSwitch';
import { connect } from 'react-redux';
import { AppDispatch, RootState } from '../../Redux/store';
import { useEffect, useRef, useState } from 'react';
import { extractUserId } from '../../Helper/Util/JwtHelper';
import {
  KeyValEntry,
  createNewKeyValueForOrgaAndPhase,
  readAllStoredKeyValuesForOrgaAndPhase,
  updateKeyValueForOrgaAndPhase
} from '../../Helper/ApiHelper/KeyValueNetworkHelper';
import { generateParticipationKey } from '../../Helper/Util/IdGeneratorHelper';
import _ from 'lodash';
import BeeLoadingSpinner from './BeeLoadingSpinner';
import { Toast } from 'primereact/toast';
import { DURATION_NOTIFICATION_ERROR_LONG } from '../../Helper/Statics/Constants';
import { changeUserParticipation } from '../../Redux/Authentication/authActions';

type TimelineProps = {
  steps: TimelineStep[];
  currentPhase: TimelineStep;
  adaptUserParticipation: (flag: any) => void;
};

export type TimelineStep = {
  id: string;
  title?: string;
  start: any;
  end: any;
  //fill from phase!!
  liegenschaftIds?: string[];
  userIds?: string[];
  publicMediaIds?: string[];
  //connected lvs
  lvs?: MinimizedLvCatalog[];
  //connected ep
  ep?: MinimizedEpCatalog;
};

export function BeeTimeline({
  steps,
  currentPhase,
  adaptUserParticipation
}: TimelineProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentState, setCurrentState] = useState<KeyValEntry>();
  const [currP, setCurrP] = useState<TimelineStep>();
  const [progress, setProgress] = useState<boolean>();
  const [loadData, setLoadData] = useState<boolean>(true);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    setLoadData(true);
    if (currentPhase) {
      if (!currP || currP.id !== currentPhase.id) {
        setupUserParticipationLookup();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, currentPhase]);

  function click(id: string) {
    if (searchParams.get('pId') !== id) {
      searchParams.delete('lId');
      searchParams.set('pId', id);
      setSearchParams(searchParams);
    }
  }

  ////////////////////
  // BUSINESS LOGIC //
  ////////////////////

  function setupUserParticipationLookup() {
    let userId = extractUserId();
    userId = userId ? userId : '';
    const key = generateParticipationKey(currentPhase.id);
    readAllStoredKeyValuesForOrgaAndPhase(userId, currentPhase.id)
      .then((data: any) => {
        let entry: any = _.find(data, function (o: KeyValEntry) {
          return o.key === key;
        });
        setCurrentState(entry);
        adaptUserParticipation(
          entry ? (entry.value === 'true' ? true : false) : null
        );
        setCurrP(currentPhase);
        setLoadData(false);
      })
      .catch(() => {
        toast.current?.show({
          severity: 'error',
          summary: 'Daten konnten nicht geladen werden',
          detail:
            'Die Daten zu Ihrer Teilnahme an der Ausschreibung konnten nicht geladen werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
          sticky: true,
          closable: true,
          life: DURATION_NOTIFICATION_ERROR_LONG
        });
      });
  }

  function storeNewStateOnServer(state: boolean) {
    setProgress(true);
    let userId = extractUserId();
    userId = userId ? userId : '';
    const key = generateParticipationKey(currentPhase.id);
    if (currentState) {
      updateKeyValueForOrgaAndPhase(
        userId,
        currentPhase.id,
        currentState.id,
        key,
        '' + state
      )
        .then((entry: any) => {
          setCurrentState(entry);
          adaptUserParticipation(
            entry ? (entry.value === 'true' ? true : false) : null
          );
        })
        .catch(() => {
          toast.current?.show({
            severity: 'error',
            summary: 'Speicherung fehlgeschlagen',
            detail:
              'Leider konnte die Änderung zu Ihrer Teilnahme am Ausschreibungsprojekt nicht auf dem Server gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        })
        .finally(() => {
          setProgress(false);
        });
    } else {
      createNewKeyValueForOrgaAndPhase(userId, currentPhase.id, key, '' + state)
        .then((entry: any) => {
          setCurrentState(entry);
          adaptUserParticipation(
            entry ? (entry.value === 'true' ? true : false) : null
          );
        })
        .catch(() => {
          toast.current?.show({
            severity: 'error',
            summary: 'Speicherung fehlgeschlagen',
            detail:
              'Leider konnte die Änderung zu Ihrer Teilnahme am Ausschreibungsprojekt nicht auf dem Server gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
            sticky: true,
            closable: true,
            life: DURATION_NOTIFICATION_ERROR_LONG
          });
        })
        .finally(() => {
          setProgress(false);
        });
    }
  }

  ////////////////
  // UI METHODS //
  ////////////////

  const customizedMarker = (item: TimelineStep, index: number) => {
    const cpClassNames = item.id === currentPhase.id ? ' currPhase' : '';
    const activation = checkStepActivation(item);
    const willUserParticipate = checkUserParticipation(item);
    if (willUserParticipate) {
      if (activation === STEP_IS_ACTIVE) {
        return (
          <div className={'chip clickable active ' + cpClassNames}>
            <BeeChip
              label={'' + (index + 1)}
              removable={false}
              type={'primary'}
              onClick={() => {
                click(item.id);
              }}
            />
          </div>
        );
      } else if (activation === STEP_WAS_ACTIVE) {
        return (
          <div className={'chip clickable wasActive ' + cpClassNames}>
            <BeeChip
              label={'' + (index + 1)}
              removable={false}
              type={'primary'}
              onClick={() => click(item.id)}
            />
          </div>
        );
      } else {
        <div className={'chip clickable willActive ' + cpClassNames}>
          <BeeChip
            label={'' + (index + 1)}
            removable={false}
            type={'primary'}
          />
        </div>;
      }
    }
    return (
      <div className={'chip non-clickable noParticipation ' + cpClassNames}>
        <BeeChip label={'' + (index + 1)} removable={false} type={'primary'} />
      </div>
    );
  };

  const customizedContent = (item: TimelineStep): JSX.Element => {
    const cpClassNames = item.id === currentPhase.id ? ' currPhase' : '';
    const activation = checkStepActivation(item);
    const willUserParticipate = checkUserParticipation(item);
    if (!willUserParticipate) {
      return (
        <div className={'bold phase non-clickable' + cpClassNames}>
          <div>{item.title + ' (keine Teilname)'}</div>
          <div>
            <span>
              {' '}
              {item.start
                ? new Date(item.start).toLocaleDateString('de-DE')
                : null}{' '}
            </span>
            <span>{'-'}</span>
            <span>
              {' '}
              {item.end ? new Date(item.end).toLocaleDateString('de-DE') : null}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {activation === STEP_IS_ACTIVE ? (
            <div
              onClick={() => {
                click(item.id);
              }}
              className={'bold phase clickable active' + cpClassNames}
            >
              <div>{item.title}</div>
              <div>
                <span>
                  {' '}
                  {item.start
                    ? new Date(item.start).toLocaleDateString('de-DE')
                    : null}{' '}
                </span>
                <span>{'-'}</span>
                <span>
                  {' '}
                  {item.end
                    ? new Date(item.end).toLocaleDateString('de-DE')
                    : null}
                </span>
              </div>
            </div>
          ) : activation === STEP_WAS_ACTIVE ? (
            <div
              onClick={() => {
                click(item.id);
              }}
              className={'bold phase clickable ' + cpClassNames}
            >
              <div> {item.title}</div>
              <div>
                <span>
                  {' '}
                  {item.start
                    ? new Date(item.start).toLocaleDateString('de-DE')
                    : null}{' '}
                </span>
                <span>{'-'}</span>
                <span>
                  {' '}
                  {item.end
                    ? new Date(item.end).toLocaleDateString('de-DE')
                    : null}
                </span>
              </div>
            </div>
          ) : (
            <div className={'bold phase non-clickable ' + cpClassNames}>
              <div> {item.title}</div>
              <div>
                <span>
                  {' '}
                  {item.start
                    ? new Date(item.start).toLocaleDateString('de-DE')
                    : null}{' '}
                </span>
                <span>{'-'}</span>
                <span>
                  {' '}
                  {item.end
                    ? new Date(item.end).toLocaleDateString('de-DE')
                    : null}
                </span>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  const injectUserParticipation = () => {
    if (currentPhase) {
      const activation = checkStepActivation(currentPhase);
      if (!loadData) {
        return (
          <div
            className={
              currentState && currentState.value === 'true'
                ? 'participation-toggle mt-4 mb-2 participation-true'
                : 'participation-toggle mt-4 mb-2 participation-false'
            }
          >
            <BeeSwitch
              label={'An der Ausschreibung teilnehmen'}
              value={currentState && currentState.value === 'true'}
              disabled={loadData || activation !== STEP_IS_ACTIVE}
              formstate={'primary'}
              stateFalse={
                currentState
                  ? 'Ich nehme an der aktuellen Phase nicht teil'
                  : 'Ich nehme an der aktuellen Phase der Ausschreibung NICHT teil.'
              }
              stateTrue={
                'Ich nehme an der aktuellen Phase der Ausschreibung teil.'
              }
              required={false}
              readOnly={false}
              onChange={(e) => storeNewStateOnServer(e.value)}
            />
            <div>
              {currentState ? (
                currentState.value === 'true' ? (
                  <span>
                    Aktuell nehmen Sie an der Ausschreibungsphase teil. Das
                    bedeutet Ihre Preiseingabe wird zum Ablauf der Phase als
                    Angebot abgegeben. Sie können die Teilnahme jedoch vor
                    Ablauf der Phase durch die Betätigung des Schiebereglers
                    jederzeit widerrufen.
                  </span>
                ) : (
                  <span
                    className={
                      activation === STEP_IS_ACTIVE ? 'error-color' : ''
                    }
                  >
                    Eine Nichtteilnahme bedeutet, dass Sie sich mit Abschluss
                    der Phase aus dem Vergabeverfahren zurückziehen. Sie können
                    diese Entscheidung bis zum Ablauf der Phase durch die
                    Betätigung des Schiebereglers widerrufen. Nach Ablauf der
                    Phase scheiden Sie aus dem weiteren Vergabeverfahren aus.
                  </span>
                )
              ) : (
                <span>
                  Aktuell nehmen Sie an der Ausschreibung nicht teil. Bitte
                  bestätigen Sie Ihre Teilnahme durch diesen Schieberegler, um
                  ein Angebot angeben zu können.
                </span>
              )}
            </div>
          </div>
        );
      } else {
        <BeeLoadingSpinner />;
      }
    }
  };

  return (
    <div className="bee-timeline">
      <div className={'timeline'}>
        <Timeline
          value={steps}
          layout="horizontal"
          align="top"
          className="customized-timeline"
          marker={customizedMarker}
          content={customizedContent}
        />
      </div>
      {injectUserParticipation()}
      {progress ? <BeeLoadingSpinner /> : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}

const mapState = (state: RootState) => {
  return {};
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    adaptUserParticipation: (flag: any) =>
      dispatch(changeUserParticipation(flag))
  };
};

export default connect(mapState, mapDispatchToProps)(BeeTimeline);
