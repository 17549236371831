import React from 'react';
import { Button } from 'primereact/button';
import { useId } from 'react-id-generator';
import './BeeButton.scss';

type BeeButtonProps = {
  id?: string;
  label: any;
  disabled?: boolean;
  type?: string;
  isLoading?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function BeeButton({
  id,
  label,
  disabled,
  type,
  isLoading,
  onClick
}: BeeButtonProps) {
  const genId = useId(1, 'bee-button-')[0];
  const currentId = id ? id : genId;

  //classNames
  const typeClassNames =
    type === 'primary'
      ? 'bee-btn-primary'
      : type === 'secondary'
      ? 'p-button-secondary bee-btn-secondary'
      : type === 'danger'
      ? 'p-button-danger bee-btn-danger'
      : type === 'light'
      ? 'bee-btn-light'
      : 'bee-btn-primary';

  const classNames = typeClassNames;

  function clicked(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!disabled && onClick) {
      onClick(e);
    }
  }

  return (
    <Button
      id={currentId}
      loading={isLoading ? true : false}
      iconPos="right"
      disabled={disabled}
      label={label}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        clicked(e)
      }
      className={classNames}
    />
  );
}

export default BeeButton;
