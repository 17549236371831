import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  callAccessToken,
  callLoginUser,
  callTwoFactorAuthentification
} from '../../Helper/ApiHelper/LoginNetworkHelper';
import {
  DURATION_NOTIFICATION_SUCCESS_LONG,
  pwChangedToast
} from '../../Helper/Statics/Constants';
import {
  PATH_FORGOTPW,
  PATH_FORGOTPW_EMAIL,
  PATH_LOGIN,
  PATH_ROOT,
  PATH_TWO_FACTOR
} from '../../Helper/Statics/Routes';
import jwtDecode from 'jwt-decode';

import {
  storeAccessToken,
  storeRefreshToken,
  storeTwoFactorToken,
  storeUsername
} from '../../Redux/Authentication/authActions';
import { AppDispatch, RootState } from '../../Redux/store';
import LoginForm from '../Organisms/Login/LoginForm';
import TwoFactorForm from '../Organisms/Login/TwoFactorForm';
import './LoginPage.scss';

type LoginPageProps = {
  refreshToken: string;
  twoFactorToken: string;
  storeRefreshToken: any;
  storeAccessToken: any;
  storeTwoFactorToken: any;
  storeUsername: any;
};

function LoginPage(props: LoginPageProps) {
  //component state
  const [twoFactorPending, setTwoFactorPending] = useState(false);
  const [twoFactorError, setTwoFactorError] = useState('');
  const [loginUserPending, setLoginUserPending] = useState(false);
  const [loginUserError, setLoginUserError] = useState('');
  let navigate = useNavigate();
  const location = useLocation();
  const { route } = useParams();
  const toast = useRef<Toast>(null);

  useEffect(() => {
    //redirect if valid refresh token/login successful
    if (
      sessionStorage.getItem('aT') !== '' &&
      sessionStorage.getItem('aT') !== null &&
      sessionStorage.getItem('aT') !== undefined
    ) {
      //FIXME:test if redirect to last page before login
      if (location.state?.from) {
        navigate(location.state.from);
      } else {
        navigate(PATH_ROOT);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem('aT')]);

  useEffect(() => {
    if (location && location.state && location.state?.showToast) {
      if (location.state?.showToast === pwChangedToast) {
        toast.current?.show({
          severity: 'success',
          summary: 'Das Passwort wurde erfolgreich geändert',
          detail:
            'Das Passwort wurde erfolgreich geändert und kann nun für den Login genutzt werden',
          sticky: false,
          closable: true,
          life: DURATION_NOTIFICATION_SUCCESS_LONG
        });
      }
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.showToast]);

  function forgotPw(): void {
    navigate(PATH_FORGOTPW + '/' + PATH_FORGOTPW_EMAIL);
  }

  function loginUser(username: string, password: string): void {
    setLoginUserPending(true);
    setLoginUserError('');
    callLoginUser(username, password)
      .then((result) => {
        console.log('RESULT');
        const token = result.data.token;
        console.log(result.data.token);
        if (token) {
          try {
            jwtDecode(token);
            //if refresh token was returned try to get access token
            props.storeRefreshToken(token);
            getAccessToken(token);
          } catch (error) {
            console.log('Error case');
            //if two factor token was returned navigate to two factor form
            props.storeTwoFactorToken(token);
            navigate(PATH_LOGIN + '/' + PATH_TWO_FACTOR);
          }
        } else {
          setLoginUserError(
            'Der Server konnte Ihre Anfrage nicht korrekt verarbeiten. Bitte laden Sie die Seite neu und versuchen Sie es erneut. '
          );
        }
        setLoginUserPending(false);
      })
      .catch((error: string) => {
        setLoginUserPending(false);
        setLoginUserError(error);
      });
  }

  function validateTwoFactor(code: string): void {
    setTwoFactorPending(true);
    setTwoFactorError('');
    if (props.twoFactorToken) {
      callTwoFactorAuthentification(code, props.twoFactorToken)
        .then((result) => {
          const refreshToken = result.data.token;
          const decodedRefreshToken = jwtDecode(refreshToken);
          if (refreshToken && decodedRefreshToken) {
            props.storeRefreshToken(refreshToken);
            getAccessToken(refreshToken);
          } else {
            setLoginUserError(
              'Der Server konnte Ihre Anfrage nicht korrekt verarbeiten. Bitte laden Sie die Seite neu und versuchen Sie es erneut. '
            );
          }
          setTwoFactorPending(false);
        })
        .catch((error: string) => {
          setTwoFactorError(error);
          setTwoFactorPending(false);
        });
    } else {
      //FIXME kein token vorhanden
      setTwoFactorError('Sie haben keine Berechtigung die Anfrage auszuführen');
      setTwoFactorPending(false);
    }
  }

  function getAccessToken(refreshToken: any): void {
    //get Access token with refresh token
    callAccessToken(refreshToken)
      .then((response) => {
        const accessToken = response.data.token;
        const decodedAccessToken = jwtDecode(accessToken);
        if (accessToken && decodedAccessToken) {
          props.storeAccessToken(accessToken);
          navigate(PATH_ROOT);
        } else {
          setLoginUserError(
            'Der Login ist fehlgeschlagen, sie sind nicht für den Zugang berechtigt'
          );
        }
      })
      .catch((error: string) => {
        setLoginUserError(error);
        //Fixme pending case
      });
  }

  return (
    <div className="login-page">
      <>
        {route === PATH_TWO_FACTOR ? (
          <TwoFactorForm
            showProgress={twoFactorPending}
            twoFactorError={twoFactorError}
            onValidateTwoFactor={(code) => validateTwoFactor(code)}
          />
        ) : (
          <LoginForm
            loginError={loginUserError}
            showProgress={loginUserPending}
            onLogin={(username, password) => loginUser(username, password)}
            onForgotPw={() => forgotPw()}
          />
        )}
        <Toast ref={toast} position={'top-right'} />
      </>
    </div>
  );
}

const mapState = (state: RootState) => {
  return {
    refreshToken: state.app.refreshToken,
    twoFactorToken: state.app.twoFactorToken
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    storeRefreshToken: (token: string) => dispatch(storeRefreshToken(token)),
    storeAccessToken: (token: string) => dispatch(storeAccessToken(token)),
    storeTwoFactorToken: (token: string) =>
      dispatch(storeTwoFactorToken(token)),
    storeUsername: (email: string) => dispatch(storeUsername(email))
  };
};

export default connect(mapState, mapDispatchToProps)(LoginPage);
