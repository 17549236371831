import _ from 'lodash';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import {
  createNewKeyValueForOrgaAndPhaseForAlpha,
  updateKeyValueForOrgaAndPhaseForAlpha
} from '../../../Helper/ApiHelper/KeyValueNetworkHelper';
import BeeButton from '../../Atoms/BeeButton';
import './AlphaTargetPricesDialog.scss';
import { LiegenschaftsData } from '../../../Helper/ApiHelper/LiegenschaftenNetworkHelper';
import BeeOutlinedButton from '../../Atoms/BeeOutlinedButton';
import BeeUploadDialog from './BeeUploadDialog';
import {
  ACCEPTED_FORMATS_EXCEL,
  DEFAULT_CALCULATION_MULTIPLIER,
  DURATION_NOTIFICATION_ERROR_LONG,
  LEISTUNGSART,
  LeistungsSlices,
  MAX_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';
import { DataTable } from 'primereact/datatable';
import {
  Column,
  ColumnBodyOptions,
  ColumnEditorOptions
} from 'primereact/column';
import BeeCurrencyInput from '../../Atoms/BeeCurrencyInput';
import {
  generateTargetPriceId,
  generateTargetPriceKey
} from '../../../Helper/Util/IdGeneratorHelper';
import { extractUserId } from '../../../Helper/Util/JwtHelper';
import { importAlphaTargetPricesExcel } from '../../../Helper/StorageHelper/ExcelImportAlphaTargetPriceHelper';
import { arrayToString } from '../../../Helper/ApiHelper/ErrorMessages';

type AlphaTargetPricesDialogProps = {
  phaseId: string;
  properties: LiegenschaftsData[];
  targetPriceId: string;
  targetPriceLookup: Map<string, any>;
  visible: boolean;
  onHide: () => void;
  onSaved: (data: Map<string, any>) => void;
};

export default function AlphaTargetPricesDialog({
  phaseId,
  properties,
  visible,
  targetPriceId,
  targetPriceLookup,
  onHide,
  onSaved
}: AlphaTargetPricesDialogProps) {
  const [vUploadDialog, setVUploadDialog] = useState<boolean>(false);
  const [uploadProg, setUploadProg] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string>();
  const [uploadErrorDescription, setUploadErrorDescription] =
    useState<string>();
  const [targetPrices, setTargetPrices] = useState<Map<string, any>>(new Map());
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (targetPriceLookup) {
      setTargetPrices(targetPriceLookup);
    }
  }, [targetPriceLookup]);

  function uploadCurrentTargetPricesFromExcel(data: any) {
    importAlphaTargetPricesExcel(data)
      .then((values: any) => {
        let errorPropNotExistent: string[] = [];
        let errorPropNotExistentRows: number[] = [];
        let errorSliceNotExistent: number[] = [];
        let errorSliceNotExistentRows: number[] = [];
        let errorNoImportedPropRows: number[] = [];

        let validEntries = [];
        let leistungssliceIds = [];
        for (let i = 0; i < LeistungsSlices.length; i++) {
          leistungssliceIds.push(LeistungsSlices[i].id);
        }

        const lookUp: Map<string, any> = new Map();
        //validation
        for (let i = 0; i < values.length; i++) {
          if (values[i].propId) {
            if (
              _.find(properties, function (prop) {
                return prop.id === values[i].propId;
              })
            ) {
              //property exists
              if (values[i].slices) {
                for (const [key, val] of Object.entries(values[i].slices)) {
                  if (_.includes(leistungssliceIds, key)) {
                    const valueId = generateTargetPriceId(
                      phaseId,
                      values[i].propId,
                      key
                    );
                    if (val) {
                      const valToSave: any = val;
                      lookUp.set(
                        valueId,
                        valToSave * DEFAULT_CALCULATION_MULTIPLIER
                      );
                      validEntries.push(valueId);
                    }
                  } else {
                    //sliceid not found
                    const valToSave: any = val;
                    errorSliceNotExistent.push(
                      valToSave * DEFAULT_CALCULATION_MULTIPLIER
                    );
                    errorSliceNotExistentRows.push(values[i].row);
                  }
                  // slice id does not exist in current tree
                  errorPropNotExistent.push(values[i].propId);
                  errorPropNotExistentRows.push(values[i].row);
                }
              }
            } else {
              //no property found
              // property code does not exist in current tree
              errorPropNotExistent.push(values[i].propId);
              errorPropNotExistentRows.push(values[i].row);
            }
          } else if (!values[i].propId && values[i].slices && values[i].row) {
            //no Propid but prices
            //fixme check if slice values are all null
            errorNoImportedPropRows.push(values[i].row);
          }
        }

        if (validEntries && validEntries.length > 0) {
          if (errorPropNotExistent && errorPropNotExistent.length > 0) {
            //only error ep not existent
            if (toast.current) {
              toast.current.show({
                severity: 'warn',
                summary: 'Nicht alle Preise konnten importiert werden',
                detail:
                  'Es konnte keine Liegenschaft mit der Id ' +
                  arrayToString(errorPropNotExistent) +
                  ' im System gefunden werden. Die Preise in den Zeilen: ' +
                  arrayToString(errorPropNotExistentRows) +
                  ' konnten deshalb nicht hochgeladen werden.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          }
          if (errorNoImportedPropRows && errorNoImportedPropRows.length > 0) {
            //only error not importet eprows
            if (toast.current) {
              toast.current.show({
                severity: 'warn',
                summary: 'Nicht alle Preise konnten importiert werden',
                detail:
                  'Die hochgeladene Excel Tabelle enthält Preise ohne Liegenschaftsid. Für den Import ist eine eindeutige Zuordnung von Preisen zu LiegenschaftsId notwendig. Die Preise in den Zeilen: ' +
                  arrayToString(errorNoImportedPropRows) +
                  ' wurden deshalb nicht importiert.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          }
          if (errorSliceNotExistent && errorSliceNotExistent.length > 0) {
            //only error not importet eprows
            if (toast.current) {
              toast.current.show({
                severity: 'warn',
                summary: 'Nicht alle Preise konnten importiert werden',
                detail:
                  'Die hochgeladene Excel Tabelle enthält Preise die keiner Leistungsgruppe zugeordnet werden konnte. Für den Import ist eine eindeutige Zuordnung von Preisen zu Leistungsgruppe notwendig. Die Preise ' +
                  arrayToString(errorSliceNotExistent) +
                  ' in den Zeilen: ' +
                  arrayToString(errorSliceNotExistentRows) +
                  ' wurden deshalb nicht importiert.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          }
          setUploadProg(false); //do this after parsing all stuff!!
          setUploadError(undefined); //do this after parsing all stuff!!
          setVUploadDialog(false);
        } else {
          //no valid entries to save
          if (
            errorPropNotExistent &&
            errorPropNotExistent.length > 0 &&
            errorNoImportedPropRows &&
            errorNoImportedPropRows.length > 0
          ) {
            //both errors
            if (toast.current) {
              toast.current.show([
                {
                  severity: 'warn',
                  summary: 'Nicht alle Preise konnten importiert werden',
                  detail:
                    'Die Leistungen mit den Ep-Codes: ' +
                    arrayToString(errorPropNotExistent) +
                    ' existieren in dem aktuellen Leistungsverzeichnis nicht. Die Preise in den Zeilen: ' +
                    arrayToString(errorPropNotExistentRows) +
                    ' konnten deshalb nicht hochgeladen werden.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                },
                {
                  severity: 'warn',
                  summary: 'Nicht alle Preise konnten importiert werden',
                  detail:
                    'Die hochgeladene Excel Tabelle enthält Preise ohne Ep-Code. Für den Import ist eine eindeutige Zuordnung von Preis zu EP-Code notwendig. Die Preise in den Zeilen: ' +
                    arrayToString(errorNoImportedPropRows) +
                    ' wurden deshalb nicht importiert.',
                  sticky: true,
                  closable: true,
                  life: DURATION_NOTIFICATION_ERROR_LONG
                }
              ]);
            }
          } else if (errorPropNotExistent && errorPropNotExistent.length > 0) {
            //only error ep not existent
            if (toast.current) {
              toast.current.show({
                severity: 'warn',
                summary: 'Nicht alle Preise konnten importiert werden',
                detail:
                  'Die Leistungen mit den Ep-Codes: ' +
                  arrayToString(errorPropNotExistent) +
                  ' existieren in dem aktuellen Leistungsverzeichnis nicht. Die Preise in den Zeilen: ' +
                  arrayToString(errorPropNotExistentRows) +
                  ' konnten deshalb nicht hochgeladen werden.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          } else if (
            errorNoImportedPropRows &&
            errorNoImportedPropRows.length > 0
          ) {
            //only error not importet eprows
            if (toast.current) {
              toast.current.show({
                severity: 'warn',
                summary: 'Nicht alle Preise konnten importiert werden',
                detail:
                  'Die hochgeladene Excel Tabelle enthält Preise ohne Ep-Code. Für den Import ist eine eindeutige Zuordnung von Preis zu EP-Code notwendig. Die Preise in den Zeilen: ' +
                  arrayToString(errorNoImportedPropRows) +
                  ' wurden deshalb nicht importiert.',
                sticky: true,
                closable: true,
                life: DURATION_NOTIFICATION_ERROR_LONG
              });
            }
          }
        }
        setUploadProg(false); //do this after parsing all stuff!!
        setUploadError(undefined); //do this after parsing all stuff!!
        setVUploadDialog(false);

        setTargetPrices(lookUp);
      })
      .catch((error) => {
        //fails bei keiner eineindeutigkeit von ids?keys?
        setUploadError('Importieren der Preise fehlgeschlagen');

        setUploadErrorDescription(error);
        setUploadProg(false);
      });
  }

  function saveValuesOnServer() {
    let userId = extractUserId();
    userId = userId ? userId : '';
    let key = generateTargetPriceKey(phaseId);
    const arrayOf = Array.from(targetPrices, ([key, value]) => ({
      key,
      value
    }));
    let value = JSON.stringify(arrayOf);
    if (targetPriceId) {
      updateKeyValueForOrgaAndPhaseForAlpha(
        userId,
        phaseId,
        targetPriceId,
        key,
        value
      )
        .then((data: any) => {
          let tmp: any = JSON.parse(data.value);
          const map: any = new Map(tmp.map((obj: any) => [obj.key, obj.value]));
          onSaved(map);
        })
        .catch(() => {
          if (toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Zielpreise konnten nicht gespeichert werden',
              detail:
                'Beim Speichern ist etwas schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice. ',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    } else {
      createNewKeyValueForOrgaAndPhaseForAlpha(userId, phaseId, key, value)
        .then((data: any) => {
          let tmp: any = JSON.parse(data.value);
          const map: any = new Map(tmp.map((obj: any) => [obj.key, obj.value]));
          onSaved(map);
        })
        .catch(() => {
          if (toast.current) {
            toast.current.show({
              severity: 'error',
              summary: 'Zielpreise konnten nicht gespeichert werden',
              detail:
                'Beim Speichern ist etwas schiefgelaufen. Bitte prüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Sollte das Problem weiterhin bestehen wenden Sie sich bitte an den Kundenservice. ',
              sticky: false,
              closable: true,
              life: DURATION_NOTIFICATION_ERROR_LONG
            });
          }
        });
    }
  }

  //////////////////////
  ////  UI METHODS  ////
  //////////////////////

  const injectUploadDialog = () => {
    return (
      <BeeUploadDialog
        type={'primary'}
        visible={vUploadDialog}
        disabled={false}
        locale={'de-DE'}
        header={'Zielpreise hochladen'}
        info={''}
        titleLabel={''}
        titleVisible={false}
        copyrightLabel={''}
        copyrightVisible={false}
        dropzoneTitle={'Upload'}
        dropzoneDescription={
          'Bitte ziehen Sie die gewünschte Datei in diesen Bereich oder Klicken Sie hinzufügen'
        }
        dropzoneAddLabel={'Klicken zum Hinzufügen'}
        dropzoneFormats={ACCEPTED_FORMATS_EXCEL}
        progressVisible={uploadProg}
        progressMessage={'Dokument wird verarbeitet'}
        errorVisible={uploadError || uploadErrorDescription ? true : false}
        errorHeadline={uploadError}
        errorDescription={uploadErrorDescription}
        onHide={() => setVUploadDialog(false)}
        onUpload={(data) => uploadCurrentTargetPricesFromExcel(data)}
      />
    );
  };

  const injectUploadButton = () => {
    return (
      <BeeOutlinedButton
        label={'Zielpreise hochladen'}
        disabled={false}
        type={'primary'}
        onClick={() => setVUploadDialog(true)}
      />
    );
  };

  function injectButtonRow() {
    return (
      <div className={'phase-btns mt-3 mb-3 flex justify-content-between'}>
        <BeeButton
          label={'Abbrechen'}
          disabled={false}
          type={'secondary'}
          isLoading={false}
          onClick={() => onHide()}
        />
        <BeeButton
          label={'Zielpreise speichern'}
          disabled={false}
          type={'primary'}
          isLoading={false}
          onClick={() => saveValuesOnServer()}
        />
      </div>
    );
  }

  const propertyTemplate = (data: any, options: ColumnBodyOptions) => {
    if (options) {
      if (options.field === 'field_0') {
        return (
          <div className={'lv-table-liegenschaft'}>
            <div className={'lv-number'}>{data.nummer}</div>
            <div>{data.name}</div>
          </div>
        );
      } else if (options.field === 'field_1') {
        return (
          <div>
            <div>
              <span>{data.adresse ? data.adresse.strasse : null}</span>{' '}
              <span>{data.adresse ? data.adresse.hausnummer : null}</span>
              <div></div>
              <span>
                {data.adresse ? data.adresse.postleitzahl : null}
              </span>{' '}
              <span>{data.adresse ? data.adresse.stadt : null}</span>
            </div>
          </div>
        );
      }
    }
  };

  const renderBodyTemplate = (
    rowData: LiegenschaftsData,
    slice: LEISTUNGSART
  ) => {
    const tpId = generateTargetPriceId(phaseId, rowData.id!, slice.id);
    const valString = targetPrices ? targetPrices.get(tpId) : undefined;
    const val = valString ? parseInt(valString) : undefined;
    return (
      <BeeCurrencyInput
        value={
          val
            ? _.divide(val, DEFAULT_CALCULATION_MULTIPLIER)
            : val === 0
            ? 0
            : null
        }
        disabled={false}
        minFractionDigits={MAX_NUMBER_OF_DECIMALS}
        maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
        formstate={'neutral'}
        readOnly={true}
        required={false}
        onChange={() => {}}
      />
    );
  };

  const renderEditorTemplate = (
    rowData: LiegenschaftsData,
    slice: LEISTUNGSART
  ) => {
    const tpId = generateTargetPriceId(phaseId, rowData.id!, slice.id);
    const valString = targetPrices ? targetPrices.get(tpId) : undefined;
    const val = valString ? parseInt(valString) : undefined;
    return (
      <BeeCurrencyInput
        value={
          val
            ? _.divide(val, DEFAULT_CALCULATION_MULTIPLIER)
            : val === 0
            ? 0
            : null
        }
        disabled={false}
        minFractionDigits={MAX_NUMBER_OF_DECIMALS}
        maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
        formstate={'neutral'}
        readOnly={false}
        required={false}
        onChange={(e) => {
          let newVal: any = null;
          if (e.target.value !== null) {
            newVal = _.round(
              _.multiply(e.target.value, DEFAULT_CALCULATION_MULTIPLIER)
            );
          }
          let lookup: any = _.cloneDeep(targetPrices);
          lookup.set(tpId, newVal);
          setTargetPrices(lookup);
        }}
      />
    );
  };

  const dynamicColumns = LeistungsSlices.map((slice: LEISTUNGSART) => {
    return (
      <Column
        className="alpha-target-prices-cols"
        // style={{ width: '14em' }}
        body={(rowData: LiegenschaftsData) =>
          renderBodyTemplate(rowData, slice)
        }
        editor={(col: ColumnEditorOptions) =>
          renderEditorTemplate(col.rowData, slice)
        }
        style={{ width: '12em' }}
        header={slice.title ? slice.title.replace('management', 'mgmt') : null}
      />
    );
  });

  const injectDataTable = () => {
    return (
      <div>
        <DataTable
          value={properties}
          className={'alpha-target-prices-input-table'}
          emptyMessage={'Keine Liegenschaften vorhanden'}
          scrollable
          scrollDirection="horizontal"
          style={{ width: '100%' }}
        >
          <Column
            body={propertyTemplate}
            header={'Liegenschaft'}
            style={{ width: '15em' }}
            className={'frozen-col'}
          />
          <Column
            body={propertyTemplate}
            header={'Adresse'}
            style={{ width: '15em' }}
            className={'alpha-target-prices-adresse-col frozen-col'}
          />
          {dynamicColumns}
        </DataTable>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        header={'Zielpreise eingeben'}
        visible={visible}
        className={'alpha-target-prices-dialog'}
        modal={true}
        onHide={() => onHide()}
      >
        <div>
          {injectDataTable()}
          <div className="flex justify-content-end ">
            {injectUploadButton()}
          </div>

          {injectButtonRow()}
        </div>
      </Dialog>
      {vUploadDialog ? injectUploadDialog() : null}
      <Toast ref={toast} position={'top-right'} />
    </div>
  );
}
