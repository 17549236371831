import _ from 'lodash';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { TreeTable } from 'primereact/treetable';
import { useEffect, useState } from 'react';
import { AuthUserType } from '../../../Helper/ApiHelper/LoginNetworkHelper';
import {
  ALPHA_DL_NOT_IN_MARKETAREA,
  ALPHA_DL_NO_PRICE,
  DEFAULT_CALCULATION_MULTIPLIER,
  MAX_NUMBER_OF_DECIMALS
} from '../../../Helper/Statics/Constants';
import BeeCurrencyInput from '../../Atoms/BeeCurrencyInput';
import './AlphaPropertyLV.scss';
import { ReactComponent as MarktgebietIncompleteSVG } from '../../../Style/Icons/svgs/icons8-country-warning.svg';
import { ReactComponent as PriceInputIncompleteSVG } from '../../../Style/Icons/svgs/icons8-incompletePrice.svg';
import { Tooltip } from 'primereact/tooltip';

type AlphaPropertyLVProps = {
  lvTree: any;
  selectedProvider: AuthUserType[];
};

type SpCalcType = {
  provider: AuthUserType;
  price: number;
  inMarketArea: boolean;
  pricingComplete: boolean;
};

export default function AlphaPropertyLV({
  lvTree,
  selectedProvider
}: AlphaPropertyLVProps) {
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    let cols: any = [];
    if (selectedProvider) {
      selectedProvider.forEach((p: AuthUserType) => {
        cols.push({ id: p.id, header: p.organisation });
      });
    }
    setColumns(cols);
  }, [selectedProvider]);

  //////////////
  /// HELPER ///
  //////////////

  function sumGPTree(node: any, providerId: string) {
    let sum = 0;
    if (node.children && node.children.length > 0) {
      for (let i = 0; i < node.children.length; i++) {
        sum += sumGPTree(node.children[i], providerId);
      }
    } else {
      if (
        node.className === 'lv-pos' &&
        node &&
        node.data &&
        node.data.values
      ) {
        const provVal = node.data.values[providerId];
        //only add if its no inactive optional pos
        if (
          !node.data.optionalPosition ||
          (node.data.optionalPosition && node.data.optionalPositionActive)
        ) {
          if (provVal && provVal.gp) {
            sum += provVal.gp;
          }
        }
      }
    }
    return sum;
  }

  function findInLeafNode(node: any, providerId: string, search: string) {
    if (node.children && node.children.length > 0) {
      for (let i = 0; i < node.children.length; i++) {
        const foundNode: any = findInLeafNode(
          node.children[i],
          providerId,
          search
        );
        if (foundNode) {
          return foundNode;
        }
      }
    } else if (node && node.data && node.data.values) {
      const provVal = node.data.values[providerId];
      if (provVal === search) {
        return node;
      }
    }
    return null;
  }

  ///////////////////////
  /// VIEW COMPONENTS ///
  ///////////////////////

  const posTemplate = (node: any, column: any) => {
    if (node && node.data) {
      if (node.className !== 'lv-pos') {
        return (
          <span className={'propertyLv-title'}>
            {'[' + node.data.number + '] ' + node.data.title}
          </span>
        );
      } else {
        return (
          <span className={'propertyLv-title'}>
            {node.data.epCode ? (
              <div className={'dl-property-lv-epCode'}>
                <span>{node.data.epCode}</span>
              </div>
            ) : null}
            <span>{'[' + node.data.posNumber + '] ' + node.data.title}</span>
            {node.data.aks ? (
              <span className={'dl-property-lv-aks'}>
                <span className={'bold'}>{'AKS-Nr: '}</span>
                <span>{node.data.aks}</span>
              </span>
            ) : null}
          </span>
        );
      }
    }
  };

  const amountTemplate = (node: any, column: any) => {
    if (node && node.data) {
      if (node.className !== 'lv-pos') {
        return <span></span>;
      } else {
        return (
          <>
            <div className={'dl-property-lv-unit'}>{node.data.unit}</div>
            <div>{node.data.amount}</div>
          </>
        );
      }
    }
  };

  const typeTemplate = (node: any, column: any) => {
    const typeClassName = 'dl-property-lv-type';
    if (node && node.data) {
      if (node.className !== 'lv-pos') {
        return <span></span>;
      } else {
        if (node.data.optionalPosition) {
          return (
            <>
              <div className={typeClassName}>{'Bedarfspos.'}</div>
              <div>
                {node.data.optionalPositionActive ? 'Aktiv' : 'Inaktiv'}
              </div>
            </>
          );
        } else {
          return <span></span>;
        }
      }
    }
  };

  const providerEpTemplate = (node: any, col: any) => {
    if (node.className !== 'lv-pos') {
      return ''; // empty view
    } else {
      if (col && col.props && col.props.header) {
        const providerId: string = col.props.header.key;
        if (node && node.data && node.data.values) {
          const provVal = node.data.values[providerId];
          if (
            provVal === ALPHA_DL_NOT_IN_MARKETAREA ||
            provVal === ALPHA_DL_NO_PRICE
          ) {
            return (
              <div className={'error-color'}>
                <BeeCurrencyInput
                  value={null}
                  disabled={false}
                  minFractionDigits={MAX_NUMBER_OF_DECIMALS}
                  maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
                  formstate={'none'}
                  readOnly={true}
                />
              </div>
            );
          } else {
            return (
              <div>
                <BeeCurrencyInput
                  value={
                    provVal.ep
                      ? _.divide(provVal.ep, DEFAULT_CALCULATION_MULTIPLIER)
                      : provVal.ep === 0
                      ? 0
                      : null
                  }
                  disabled={false}
                  minFractionDigits={MAX_NUMBER_OF_DECIMALS}
                  maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
                  formstate={'none'}
                  readOnly={true}
                />
              </div>
            );
          }
        }
      }
    }
    return '';
  };

  const providerGpTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node.className !== 'lv-pos') {
      const value = sumGPTree(node, providerId);
      return (
        <BeeCurrencyInput
          value={
            value
              ? _.divide(value, DEFAULT_CALCULATION_MULTIPLIER)
              : value === 0
              ? 0
              : null
          }
          disabled={false}
          minFractionDigits={MAX_NUMBER_OF_DECIMALS}
          maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
          formstate={'none'}
          readOnly={true}
        />
      );
    } else {
      if (node && node.data && node.data.values) {
        const provVal = node.data.values[providerId];
        if (
          provVal === ALPHA_DL_NOT_IN_MARKETAREA ||
          provVal === ALPHA_DL_NO_PRICE
        ) {
          return (
            <div className="error-color">
              <BeeCurrencyInput
                value={null}
                disabled={false}
                minFractionDigits={MAX_NUMBER_OF_DECIMALS}
                maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
                formstate={'none'}
                readOnly={true}
              />
            </div>
          );
        } else if (
          node.data.optionalPosition &&
          !node.data.optionalPositionActive
        ) {
          return (
            <BeeCurrencyInput
              value={null}
              disabled={false}
              minFractionDigits={MAX_NUMBER_OF_DECIMALS}
              maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
              formstate={'none'}
              readOnly={true}
            />
          );
        } else {
          return (
            <BeeCurrencyInput
              value={
                provVal.gp
                  ? _.divide(provVal.gp, DEFAULT_CALCULATION_MULTIPLIER)
                  : provVal.gp === 0
                  ? 0
                  : null
              }
              disabled={false}
              minFractionDigits={MAX_NUMBER_OF_DECIMALS}
              maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
              formstate={'none'}
              readOnly={true}
            />
          );
        }
      }
    }
    return '';
  };

  const providerDiffTemplate = (node: any, col: any) => {
    const providerId: string = col.props.header.key;
    if (node.className !== 'lv-pos') {
      let benchmark: SpCalcType[] = [];
      let self: any = null;
      selectedProvider.forEach((sp: AuthUserType) => {
        const valueGp = sumGPTree(node, sp.id);
        const notInMarket = findInLeafNode(
          node,
          sp.id,
          ALPHA_DL_NOT_IN_MARKETAREA
        );
        const incompletePrice = findInLeafNode(node, sp.id, ALPHA_DL_NO_PRICE);
        if (sp.id === providerId) {
          self = {
            provider: sp,
            price: valueGp,
            inMarketArea: !notInMarket,
            pricingComplete: !incompletePrice
          };
        }
        if (!notInMarket && !incompletePrice) {
          benchmark.push({
            provider: sp,
            price: valueGp,
            inMarketArea: !notInMarket,
            pricingComplete: !incompletePrice
          });
        }
      });
      //sort benchmark
      if (self) {
        if (benchmark && benchmark.length > 0) {
          benchmark = _.sortBy(benchmark, ['price']);
          const calcValue =
            benchmark[0].price === 0
              ? 100
              : _.round((self!.price / benchmark[0].price) * 100);
          const className =
            calcValue === 100 && self.inMarketArea && self.pricingComplete
              ? 'valid-background'
              : 'error-background';
          return (
            <div className={className}>
              {self && !self.pricingComplete ? (
                <div>
                  <PriceInputIncompleteSVG className="priceInputIncomplete" />
                  <Tooltip target=".priceInputIncomplete">
                    unvollständige Preiseingabe
                  </Tooltip>
                </div>
              ) : self && !self.inMarketArea ? (
                !self.pricingComplete ? (
                  <div>
                    <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                    <Tooltip target=".marktgebietIncomplete">
                      Nicht im Marktgebiet
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    <div className="visibility-hidden">
                      <svg />
                    </div>
                    <div>
                      <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                      <Tooltip target=".marktgebietIncomplete">
                        Nicht im Marktgebiet
                      </Tooltip>
                    </div>
                  </div>
                )
              ) : (
                calcValue + ' %'
              )}
            </div>
          );
        } else {
          return (
            <div className={'error-background'}>
              {self && !self.pricingComplete ? (
                <div>
                  <PriceInputIncompleteSVG className="priceInputIncomplete" />
                  <Tooltip target=".priceInputIncomplete">
                    unvollständige Preiseingabe
                  </Tooltip>
                </div>
              ) : self && !self.inMarketArea ? (
                !self.pricingComplete ? (
                  <div>
                    <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                    <Tooltip target=".marktgebietIncomplete">
                      Nicht im Marktgebiet
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    <div className="visibility-hidden">
                      <svg />{' '}
                    </div>
                    <div>
                      <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                      <Tooltip target=".marktgebietIncomplete">
                        Nicht im Marktgebiet
                      </Tooltip>
                    </div>
                  </div>
                )
              ) : null}
            </div>
          );
        }
      }
    } else {
      //if this is an inactive optional position
      if (node.data.optionalPosition && !node.data.optionalPositionActive) {
        return <div className={'grey-background'}></div>;
      }
      const provVal = node.data.values[providerId];
      if (provVal && (provVal.gp || provVal.gp === 0)) {
        let diff = 1;
        if (provVal.gp !== 0) {
          const values = node.data.values;
          let gps: number[] = [];
          selectedProvider.forEach((p) => {
            if (
              values[p.id] !== ALPHA_DL_NOT_IN_MARKETAREA &&
              values[p.id] !== ALPHA_DL_NO_PRICE
            ) {
              gps.push(values[p.id].gp);
            }
          });
          if (gps.length > 0) {
            let lowestGP = _.sortBy(gps)[0];
            diff = _.round(provVal.gp / lowestGP);
          }
        }
        const className =
          diff && diff * 100 === 100 ? 'valid-background' : 'error-background';
        if (diff) {
          return <div className={className}>{diff * 100 + ' %'}</div>;
        }
      } else if (provVal === ALPHA_DL_NOT_IN_MARKETAREA) {
        return (
          <div className={'error-background'}>
            <div className="visibility-hidden">
              <svg />
            </div>
            <div>
              <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
              <Tooltip target=".marktgebietIncomplete">
                Nicht im Marktgebiet
              </Tooltip>
            </div>
          </div>
        );
      } else {
        return <div className={'grey-background'}></div>;
      }
    }
  };

  const dynamicProviderColumns = columns.map((col: any, i: any) => {
    const epUI = {
      className: 'alphaPropLv-epPrice-col',
      key: col.id + '_' + col.header + '_EP',
      body: providerEpTemplate,
      width: '12em'
    };
    const gpUI = {
      className: 'alphaPropLv-gpPrice-col',
      key: col.id + '_' + col.header + '_GP',
      body: providerGpTemplate,
      width: '12em'
    };
    const diffUI = {
      className: 'alphaPropLv-diffPrice-col',
      key: col.id + '_' + col.header + '_DIFF',
      body: providerDiffTemplate,
      width: '7em'
    };
    const values = [epUI, gpUI, diffUI];
    return values.map((e, index) => {
      return (
        <Column
          key={
            e.key
              ? 'aPropLv-providerCol_' + e.key.replaceAll(' ', '_')
              : 'aPropLv-providerCol_' + index
          }
          className={e.className}
          body={e.body}
          header={<div key={col.id}>{col.header}</div>}
          style={{ width: e.width }}
        />
      );
    });
  });

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          header="Positionen"
          rowSpan={2}
          colSpan={1}
          style={{ width: '30em' }}
          className={'propertyLv-title-col frozen-col'}
        />
        <Column
          header="Typ"
          rowSpan={2}
          colSpan={1}
          style={{ width: '6em' }}
          className={'propertyLv-type-col frozen-col'}
        />
        <Column
          header="Menge"
          rowSpan={2}
          colSpan={1}
          style={{ width: '6em' }}
          className={'propertyLv-amount-col frozen-col'}
        />
        {columns.map((col: any) => {
          return [1, 2].map((e) => {
            return e === 1 ? (
              <Column
                header={col.header}
                colSpan={2}
                key={'aPropLv-col-menge_' + col.id.replaceAll(' ', '_')}
                style={{ width: '24em' }}
                className={'propertyLv-epGpDiff-col'}
              />
            ) : (
              <Column
                colSpan={1}
                style={{ width: '7em' }}
                className={'propertyLv-Diff-col'}
              />
            );
          });
        })}
      </Row>
      <Row>
        {columns.map((col: any) => {
          const values = [
            { title: 'EP', width: '12em' },
            { title: 'GP', width: '12em' },
            { title: '', width: '7em' }
          ];
          return values.map((e) => {
            return e.title === 'Diff' ? (
              <Column
                header={e.title}
                key={'aPropLv-col-' + e + '_' + col.id.replaceAll(' ', '_')}
                style={{ width: '7em' }}
              />
            ) : (
              <Column
                header={e.title}
                key={'aPropLv-col-' + e + '_' + col.id.replaceAll(' ', '_')}
                style={{ width: e.width }}
              />
            );
          });
        })}
      </Row>
    </ColumnGroup>
  );

  const calcOverallGP = (providerId: string) => {
    const root = { children: lvTree };
    const value = sumGPTree(root, providerId);
    return (
      <BeeCurrencyInput
        value={
          value
            ? _.divide(value, DEFAULT_CALCULATION_MULTIPLIER)
            : value === 0
            ? 0
            : null
        }
        disabled={false}
        minFractionDigits={MAX_NUMBER_OF_DECIMALS}
        maxFractionDigits={MAX_NUMBER_OF_DECIMALS}
        formstate={'none'}
        readOnly={true}
      />
    );
  };

  const calcOverallDiff = (providerId: string) => {
    const root = { children: lvTree };
    let benchmark: SpCalcType[] = [];
    let self: any = null;
    selectedProvider.forEach((sp: AuthUserType) => {
      const valueGp = sumGPTree(root, sp.id);
      const notInMarket = findInLeafNode(
        root,
        sp.id,
        ALPHA_DL_NOT_IN_MARKETAREA
      );
      const incompletePrice = findInLeafNode(root, sp.id, ALPHA_DL_NO_PRICE);
      if (sp.id === providerId) {
        self = {
          provider: sp,
          price: valueGp,
          inMarketArea: !notInMarket,
          pricingComplete: !incompletePrice
        };
      }
      if (!notInMarket && !incompletePrice) {
        benchmark.push({
          provider: sp,
          price: valueGp,
          inMarketArea: !notInMarket,
          pricingComplete: !incompletePrice
        });
      }
    });
    if (self) {
      //sort benchmark
      if (benchmark && benchmark.length > 0) {
        benchmark = _.sortBy(benchmark, ['price']);
        const calcValue =
          benchmark[0].price === 0
            ? 100
            : _.round((self!.price / benchmark[0].price) * 100);
        const className =
          calcValue === 100 && self.inMarketArea && self.pricingComplete
            ? 'valid-background'
            : 'error-background';
        return (
          <div className={className}>
            {self && !self.pricingComplete ? (
              <div>
                <PriceInputIncompleteSVG className="priceInputIncomplete" />
                <Tooltip target=".priceInputIncomplete">
                  unvollständige Preiseingabe
                </Tooltip>
              </div>
            ) : self && !self.inMarketArea ? (
              !self.pricingComplete ? (
                <div>
                  <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                  <Tooltip target=".marktgebietIncomplete">
                    Nicht im Marktgebiet
                  </Tooltip>
                </div>
              ) : (
                <div>
                  <div className="visibility-hidden">
                    <svg />{' '}
                  </div>
                  <div>
                    <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                    <Tooltip target=".marktgebietIncomplete">
                      Nicht im Marktgebiet
                    </Tooltip>
                  </div>
                </div>
              )
            ) : (
              calcValue + ' %'
            )}
          </div>
        );
      } else {
        return (
          <div className={'error-background'}>
            {self && !self.pricingComplete ? (
              <div>
                <PriceInputIncompleteSVG className="priceInputIncomplete" />
                <Tooltip target=".priceInputIncomplete">
                  unvollständige Preiseingabe
                </Tooltip>
              </div>
            ) : self && !self.inMarketArea ? (
              !self.pricingComplete ? (
                <div>
                  <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                  <Tooltip target=".marktgebietIncomplete">
                    Nicht im Marktgebiet
                  </Tooltip>
                </div>
              ) : (
                <div>
                  <div className="visibility-hidden">
                    <svg />{' '}
                  </div>
                  <div>
                    <MarktgebietIncompleteSVG className="marktgebietIncomplete" />
                    <Tooltip target=".marktgebietIncomplete">
                      Nicht im Marktgebiet
                    </Tooltip>
                  </div>
                </div>
              )
            ) : null}
          </div>
        );
      }
    }
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Summe:"
          colSpan={3}
          className={'propertyLv-title-col frozen-col '}
          style={{ width: '42em' }}
        />
        {columns.map((col: any) => {
          const values = ['', 'GP', 'DIFF'];
          return values.map((e) => {
            if (e === '') {
              return (
                <Column
                  footer={''}
                  colSpan={1}
                  key={
                    'aPropLv-footer-' + e + '_' + col.id.replaceAll(' ', '_')
                  }
                  className={'aPropLv-footer-ep'}
                  style={{ width: '12em' }}
                />
              );
            } else if (e === 'GP') {
              return (
                <Column
                  footer={calcOverallGP(col.id)}
                  colSpan={1}
                  key={
                    'aPropLv-footer-' + e + '_' + col.id.replaceAll(' ', '_')
                  }
                  className={'aPropLv-footer-gp'}
                  style={{ width: '12em' }}
                />
              );
            } else {
              return (
                <Column
                  footer={calcOverallDiff(col.id)}
                  colSpan={1}
                  key={'aPropLv-footer-' + e + '_' + col.pId}
                  className={'aPropLv-footer-diff'}
                  style={{ width: '7em' }}
                />
              );
            }
          });
        })}
      </Row>
    </ColumnGroup>
  );

  return (
    <div className={'alpha-property-lv mb-3'}>
      <TreeTable
        value={lvTree}
        headerColumnGroup={headerGroup}
        footerColumnGroup={footerGroup}
        rowClassName={(rowData) => {
          return {
            'disabled-lv':
              rowData.data.optionalPosition &&
              !rowData.data.optionalPositionActive
          };
        }}
        className={'alpha-propertyLv-table'}
        emptyMessage={'Keine Position gefunden'}
        scrollable
        style={{ width: '100%' }}
      >
        <Column
          columnKey={'propertyLv-title-col'}
          header={'Position'}
          className={'propertyLv-title-col frozen-col '}
          body={posTemplate}
          expander
          style={{ width: '30em' }}
        />
        <Column
          columnKey={'propertyLv-type-col'}
          className={'propertyLv-type-col frozen-col '}
          body={typeTemplate}
          header={'Typ'}
          style={{ width: '6em' }}
        />
        <Column
          columnKey={'propertyLv-amount-col'}
          className={'propertyLv-amount-col frozen-col '}
          body={amountTemplate}
          header={'Menge'}
          style={{ width: '6em' }}
        />
        {dynamicProviderColumns}
      </TreeTable>
    </div>
  );
}
